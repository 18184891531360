export const FIRSTNAME_REGEX = /^[a-z ,.'-]+$/i;
export const LASTNAME_REGEX = /^[a-z ,.'-]+$/i;
export const EMAIL_REGEX =
  /^[A-Za-z][A-Za-z0-9._%+-]*@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const SPACE_REGEX = /\s{2,}/g;
export const NAMES_REGEX = /^[a-z ,.'-]+$/i;
export const NUM_REGEX = /^\+?\d{10,13}$/;
export const PINCODE_REGEX = /^[0-9]+$/;
export const STRONGPASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*])(?=.{4,})/;
// +91 91234-91234 FOR THIS PATTERN
export const INDIANCONTACT_REGEX = /^\+91\s\d{5}-\d{5}$/;
// phone number regex
export const PHONE_REGEX = /^\D*(\d{10})\D*$/;
export const NUMERIC_REGEX = /^\d+$/;
export const PROTOCOL_REGEX =
  /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)/;
// export const WEBSITE_REGEX = /^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}(?:\/\S*)?$/;
export const WEBSITE_REGEX =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/;
// export const WEBSITE_REGEX = /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9](\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/
export const NUM_CHECK_REGEX = /^(?=[0-9]{4,6}$)0*[1-9][0-9]{3,}$/;

// export const MOBILE_NUMBER_REGEX_2 = /^([0|)?([7-9][0-9]{9})$/;
export const MOBILE_NUMBER_REGEX_2 = /^([6-9]\d{9})$/;

export const EMPTY_SPACES = /^\S+$/;
