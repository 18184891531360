import React, { useEffect, useState } from 'react'
//MUI
import Grid from '@mui/material/Grid'

//OTHER
import GenericSwiper from '../../../swiper/CustomSwiper'
import { SwiperSlide } from 'swiper/react'
import { useDispatch, useSelector } from 'react-redux'
import { clearSelectedCandidates, getAllotedJobsData, setAllotedJobs } from '../../../redux/Freelancer/allotedJobsSlice'
import NoJobsFound from '../../../components/NotFound/NoJobsFound'
import AllotedJobsCard from '../../../components/Cards/JobSeeker/AllotedJobsCard'
import { useNavigate } from 'react-router-dom'
import PrimaryWrapper from '../../../components/Wrappers/PrimaryWrapper'
import GlobalLoader from '../../../components/Loader/GlobalLoader'

const AllotedOpening = ({ onClick = () => { } }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allotedJobs = useSelector(state => state.allotedJobs.allotedJobs)
  const [loadAllotedJobs, setLoadAllotedJobs] = useState(false);

  useEffect(() => {
    setLoadAllotedJobs(true);
    dispatch(getAllotedJobsData()).then(res => {
      if (res.type === "getAllotedJobsData/fulfilled") {
        setLoadAllotedJobs(false);
        dispatch(setAllotedJobs(res.payload.data));
      } else if (res.type === "getAllotedJobsData/rejected") {
        setLoadAllotedJobs(false);
      }
    }).catch(err => {
      console.log(err);
    })
  }, [])

  return (
    <>
      <PrimaryWrapper
        content='Allotted Openings'
        linkTitle={allotedJobs?.length > 2 ? 'View All' : ''}
        onClick={() => navigate('/allotted-jobs')}
      >
        {
          loadAllotedJobs ? <GlobalLoader minHeight="200px" /> : (
            <GenericSwiper configSize="medium_200">
              <Grid container>
                {allotedJobs?.length > 1 ? (
                  allotedJobs.map((elem, indx) => {
                    return (
                      <Grid item sx={12} md={4} lg={12} key={indx}>
                        <SwiperSlide>
                          <AllotedJobsCard
                            handleNavigate={() => {
                              dispatch(clearSelectedCandidates());
                              navigate(`/allotted-jobs/job-details/${elem?.id}`)
                            }
                            }
                            size={"medium-200"}
                            data={elem.attributes}
                          />
                        </SwiperSlide>
                      </Grid>
                    );
                  })
                ) : allotedJobs?.length > 0 ? (
                  <Grid item sx={12} md={12} lg={6} key={0}>
                    <AllotedJobsCard
                      handleNavigate={() => {
                        dispatch(clearSelectedCandidates());
                        navigate(`/allotted-jobs/job-details/${allotedJobs[0]?.id}`)
                      }}
                      size={"medium-200"}
                      tags={true}
                      data={allotedJobs[0].attributes}
                    />
                  </Grid>
                ) : (
                  <NoJobsFound height={'220px'} value={"No jobs allotted yet"} />
                )}

              </Grid>
            </GenericSwiper>
          )
        }
      </PrimaryWrapper>
    </>
  )
}

export default AllotedOpening
