import React, { useState } from "react";
import Header from "../../header/header";
import { Box } from "@mui/material";
import FilterAccordion from "../../searchJobsPage/FilterAccordion";
import "./SearchLayout.scss";

const SearchLayout = ({
  children,
  handleSetChips,
  handleDeleteChips,
  uncheckCheckbox,
  allChips,
  setExperienceRange,
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    categories: [],
    workModes: [],
    locations: [],
    skills: [],
    experienceRange: [0, 1],
  });
  return (
    <>
      <Header />
      <Box container className="oh-search-background-setting oh-search-phone-responsive">
        <Box className="filter-phone-responsive">
          <FilterAccordion
            handleSetChips={handleSetChips}
            handleDeleteChips={handleDeleteChips}
            uncheckCheckbox={uncheckCheckbox}
            allChips={allChips}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setExperienceRange={setExperienceRange}
          />
        </Box>
        <Box>
          <Box>{children}</Box>
        </Box>
      </Box>
    </>
  );
};

export default SearchLayout;
