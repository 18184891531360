import React, { useState } from "react";

//OTHER
import MuiBasicDrawer from "../../mui/Drawers/MuiBasicDrawer";
import DrawerHeader from "../Drawer/Components/DrawerHeader";
import DrawerSearch from "../Drawer/Components/DrawerSearch";
import FilterAccordion from "../Accordion/FilterAccordion";

//CSS
import "./index.scss";

const Filter = ({ onClose, open, data }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    categories: [],
    workModes: [],
    locations: [],
    skills: [],
    experienceRange: [0, 1],
  });
  return (
    <MuiBasicDrawer
      sx={{
        position: "relative",
        border: "1px solid var(--oh-white-300-color)",
        boxShadow: "none",
      }}
      anchor="right"
      onClose={onClose}
      open={open}
    >
      <DrawerHeader onClose={onClose} title="Filter" />
      {/* <DrawerSearch /> */}
      <FilterAccordion
        data={data}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        onClose={onClose}
        isDrawer={true}
      />
      {/* <DrawerFooter /> */}
    </MuiBasicDrawer>
  );
};

export default Filter;
