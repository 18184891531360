import React from 'react'
import JobSeekerLayout from '../Layouts/JobSeeker/JobSeekerLayout'
import EmployerLayout from '../Layouts/Employer/EmployerLayout'

const ChatLayout = ({ layoutType, children }) => {
  console.log(layoutType,'......layoutType===')
  if (layoutType === 'Jobseeker') {
    return <JobSeekerLayout>{children}</JobSeekerLayout>
  } else if (layoutType === 'Employer') {
    return <EmployerLayout>{children}</EmployerLayout>
  }
  else if (layoutType === 'Simple') {
    return <>{children}</>
  }
  return null;
}

export default ChatLayout
