import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFreelancerPlacedCandidateData, setFreelancerPlacedCandidateData } from "../../redux/Freelancer/freelancerPlacedCandidatesSlice";
import SkillsTooltip from "../../components/Tooltip/SkillsChipTooltip";
import CandidateDetail from "../../components/Freelancer/CandidateDrawer/CandidateDetail";
import { setIndividualFreelancerData } from "../../redux/Freelancer/freelancerDetailsSlice";
import { handleDownload } from "../../utils";
import ListingTableWithPagination from "../../components/Table/ListingTableWithPagination";
import NoCandidatesDataInTable from "../../components/Table/NoCandidatesFound";

const FreelancerPlacedCandidateTable = ({ rowCount, setRowCount, searchKeyword = "" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const freelancerPlacedCandidates = useSelector((state) => state.freelancerPlacedCandidates.freelancerPlacedCandidates);
  const [loader, setLoader] = useState(false);
  const pageSize = 10;
  const [page, setPage] = useState(1);

  const handlePageChange = (newPage) => {
    let page = newPage + 1;
    setPage(page);
  };

  const handleDrawer = (val) => {
    setIsOpen((prev) => !prev)
    dispatch(setIndividualFreelancerData(val));
  }

  useEffect(() => {
    getFreelancerPlacedCandidates();
  }, [page]);

  const getFreelancerPlacedCandidates = () => {
    setLoader(true);
    dispatch(getFreelancerPlacedCandidateData({ keyword: searchKeyword, page: page, pageSize })).then((res) => {
      if (res.type === "getFreelancerPlacedCandidateData/fulfilled") {
        dispatch(setFreelancerPlacedCandidateData(res.payload.data));
        setRowCount(res?.payload?.meta?.total_records);
      }
      setLoader(false);
    }).catch(err => {
      console.log(err);
      setLoader(false);
    });
  };

  const headCells = [
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleDrawer(params?.row?.attributes)}
          >
            {params?.row?.attributes?.first_name ? `${params?.row?.attributes?.first_name} ${params?.row?.attributes?.last_name}` : '-'}
          </span>
        );
      },
    },
    {
      field: "designation",
      headerName: "Designation",
      sortable: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return <span>{params.row?.attributes?.designation || '-'}</span>;
      },
    },
    {
      minWidth: 130,
      flex: 1,
      field: "experiences",
      headerName: "Experiences",
      sortable: true,
      renderCell: (params) => {
        return <span>{params.row?.attributes?.jobs_status[0]?.experience || '-'}</span>;
      },
    },

    {
      field: 'skill',
      headerName: 'Skills',
      sortable: true,
      minWidth: 150,
      flex: 1,
      renderCell: params => {
        return (
          <SkillsTooltip size='small-300' array={params.row?.attributes?.skills} maxLength={2} />
        )
      }
    },
    {
      minWidth: 150,
      flex: 1,
      field: "cv",
      headerName: "CVs",
      sortable: true,
      renderCell: params => {
        return <span onClick={() => params?.row?.attributes?.resume?.url ? handleDownload(params?.row?.attributes?.resume?.url) : {}}
          style={params.row?.attributes?.resume?.url ? { color: '#B71E58', textDecoration: 'underline' } : {}}>{params.row?.attributes?.resume_name || 'Not uploaded'}</span>
      }
    },
    {
      minWidth: 150,
      flex: 1,
      field: "Jobs",
      headerName: "Jobs",
      sortable: true,
      renderCell: params => {
        return <span onClick={() => navigate(`/candidates/candidate-profile`, { state: { data: params.row.attributes } })}
          style={{ color: 'var(--oh-blue-color)' }}>View</span>
      },
    },
  ];

  return (
    <>
      <ListingTableWithPagination
        rows={freelancerPlacedCandidates}
        columns={headCells}
        hideFooterPagination={false}
        pageSize={pageSize}
        rowCount={rowCount}
        rowsPerPageOptions={[pageSize]}
        page={page - 1}
        loader={loader}
        paginationMode="server"
        onPageChange={handlePageChange}
        customNoResultsComponent={() => <NoCandidatesDataInTable />}
      />
      <CandidateDetail
        setRowCount={setRowCount}
        open={isOpen}
        setOpen={setIsOpen}
        setPage={setPage}
        onClose={() => handleDrawer()}
        isFooter={false}
        title="Candidate Details"
      />
    </>
  )
};
export default FreelancerPlacedCandidateTable;
