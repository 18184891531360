import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";

const initialState = {
  data: [], // why is this defined ? need to discuss
  skills: [],
  locations: [],
  categories: [],
  educations: [],
};

export const getCategoriesData = createAsyncThunk(
  "getCategoriesData",
  async () => {
    return await AxiosApi({
      path: "/categories",
      type: "GET",
    });
  }
);

export const getEducationsData = createAsyncThunk(
  "getEducationsData",
  async () => {
    return await AxiosApi({
      path: "/educations",
      type: "GET",
    });
  }
);

export const getUniversityData = createAsyncThunk("getUniversityData", async () => {
  return await AxiosApi({
    path: "/education_boards",
    type: "GET",
  });
});

export const getSkillsData = createAsyncThunk("getSkillsData", async () => {
  return await AxiosApi({
    path: "/skills",
    type: "GET",
  });
});

export const getLocationData = createAsyncThunk("getLocationData", async () => {
  return await AxiosApi({
    path: "/locations",
    type: "GET",
  });
});

export const getFilteredData = createAsyncThunk(
  "getFilteredData",
  async (
    {
      // str,
      params,
      authParam,
      active = false,
      appliedJobsInJobSeeker = false,
      portalName,
      currentPath,
      isSearched,
      top_hiring = false,
      searchTerm = false,
    },
    thunkapi
  ) => {
    let filterParamsUrl = "";
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          filterParamsUrl += `filter_by[${key}][]=${val}&`;
        });
      } else {
        filterParamsUrl += `filter_by[${key}]${key !== "experience" ? "[]" : ""
          }=${value}&`;
      }
    });

    filterParamsUrl = filterParamsUrl.slice(0, -1);

    console.log(portalName, top_hiring, active, "... top_hiring");
    let isActive = active
      ? `/job_posts?search[is_active]=${active}&`
      : `/job_posts?`;
    let url =
      portalName && currentPath === "all_jobs"
        ? `/job_allocations?`
        : portalName && currentPath === "applied_jobs"
          ? `/job_allocations/applied_job_posts?`
          : top_hiring
            ? `/jobseeker_details/top_hiring?`
            : portalName && isSearched
              ? `/job_allocations/?search[term]=${searchTerm}&`
              : // ? `/freelancer/freelancer_candidates/?search[term]=${searchTerm}`
              portalName
                ? `/job_allocations?`
                : // ? `/freelancer/freelancer_candidates`
                appliedJobsInJobSeeker
                  ? `/job_applications?`
                  : isActive;

    return await AxiosApi({
      path: `${url}${filterParamsUrl}`,
      type: "GET",
      params: "",
      toAppendAuthParams: top_hiring || authParam,
    });
  }
);

export const filterDataSlice = createSlice({
  name: "filterData",
  initialState,
  reducers: {
    setSkills: (state, action) => {
      state.skills = action.payload;
    },
    setEducations: (state, action) => {
      state.educations = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    clearAllStates: (state, action) => {
      state.skills = [];
      state.educations = [];
      state.locations = [];
      state.categories = [];
    },
  },
});
export default filterDataSlice.reducer;
export const {
  setSkills,
  setLocations,
  setCategories,
  setEducations,
  clearAllStates,
} = filterDataSlice.actions;
