import { Box } from "@mui/material";
import React from "react";
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";
import FilledChip from "../../../../components/customChips/FilledChips";
import "./index.scss";

const Skills = ({ skillsData = [], size = "small-200", title = null }) => {
  return (
    <Box className="oh-skills">
      {title && <MuiTypography content={title} className="oh-details-title" />}
      <Box className="oh-skills-box">
        {skillsData &&
          skillsData.map((elem, idx) => {
            return <FilledChip size={size} label={elem} key={idx} />;
          })}
      </Box>
    </Box>
  );
};

export default Skills;
