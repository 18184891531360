import React, { useState, useRef } from "react";
import { useFormikContext, Field } from "formik";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import FieldError from "../Errors/FieldError";
import { Toaster } from "../Toaster/Toaster";
import { handleDownload, returnSubstring } from "../../utils";
import "./index.scss";

const UploadResume = ({
  placeholder = "Doc, DOCx, PDF | Max 2 MB",
  helperText,
  showDownloadBtn = false,
  handleDownloadClick = null,
  setShowResume = () => { },
}) => {
  const formik = useFormikContext();
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // Your file validation logic here
    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      const maxFileSize = 2;
      const allowedTypes = [
        "application/pdf",
        "application/doc",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      if (!allowedTypes.includes(selectedFile.type)) {
        formik.setFieldError(
          "resume",
          "Only PDF and DOC/DOCX files are allowed."
        );
        Toaster.TOAST("Only PDF and DOC/DOCX files are allowed.", "error");
        formik.setFieldValue("resume", "");
        setShowResume(false);
        return;
      }

      if (fileSizeInMB > maxFileSize) {
        formik.setFieldError(
          "resume",
          `File size exceeds the maximum limit of ${maxFileSize} MB`
        );
        formik.setFieldValue("resume", "");
        setShowResume(false);
        Toaster.TOAST(
          `File size exceeds the maximum limit of ${maxFileSize} MB`,
          "error"
        );
      } else {
        formik.setFieldValue("resume", selectedFile);
        formik.setFieldError("resume", "");
        setShowResume(true);
        Toaster.TOAST("Resume Uploaded Successfully !", "success");
      }
    }
  };

  return (
    <Box className="oh-resume-box-upload">
      <Box mt={1} className="detail-box">
        {formik.values.resume && (
          <>
            <MuiTypography
              className="resume-name"
              content={
                formik.values.resume?.name
                  ? formik.values.resume?.name
                  : returnSubstring(formik.values.resume, 44)
              }
            />
            <span>
              {showDownloadBtn && (
                <IconButton
                  style={{ marginTop: 0 }}
                  aria-label="download"
                  className="oh-download"
                >
                  <DownloadIcon onClick={() => !handleDownloadClick ? handleDownload(formik.values.resume) : handleDownloadClick()} />
                </IconButton>
              )}
              <IconButton
                style={{ marginTop: 0 }}
                aria-label="delete"
                onClick={() => {
                  formik.setFieldValue("resume", "");
                  setShowResume(false);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </span>
          </>
        )}
      </Box>
      {!formik.values.resume && (
        <Field name="resume">
          {({ field, meta }) => (
            // <TextField
            //   type="file"
            //   fullWidth
            //   inputRef={fileInputRef}
            //   onChange={(event) => {
            //     field.onChange(event);
            //     handleFileChange(event);
            //   }}
            //   error={meta.touched && meta.error}
            //   helperText={
            //     meta.touched &&
            //     meta.error && <FieldError errorString={meta.error} />
            //   }
            //   className="upload-textfeild"
            //   label={placeholder}
            //   InputProps={{
            //     startAdornment: (
            //       <>
            //         <MuiTypography
            //           className="upload-btn"
            //           content={"Upload Resume"}
            //           onClick={handleUploadClick}
            //         />
            //       </>
            //     ),
            //   }}
            //   InputLabelProps={{
            //     shrink: false,
            //     style: {
            //       color: "#8c8da3",
            //       fontSize: "14px",
            //       textAlign: "center",
            //       paddingLeft: "100px",
            //       width: "100%",
            //       display: "block",
            //     },
            //   }}
            // />
            <TextField
              type="file"
              fullWidth
              inputRef={fileInputRef}
              onChange={(event) => {
                field.onChange(event);
                handleFileChange(event);
              }}
              error={meta.touched && meta.error}
              helperText={
                meta.touched &&
                meta.error && <FieldError errorString={meta.error} />
              }
              className="upload-textfeild"
              label={placeholder}
              InputProps={{
                startAdornment: (
                  <>
                    <MuiTypography
                      className="upload-btn"
                      content={"Upload Resume"}
                      onClick={handleUploadClick}
                    />
                  </>
                ),
              }}
              InputLabelProps={{
                shrink: false,
                style: {
                  color: "#8c8da3",
                  zIndex: -1,
                  fontSize: "14px",
                  textAlign: "center",
                  paddingLeft: "160px",
                  width: "100%",
                  display: "block",
                },
              }}
            />
          )}
        </Field>
      )}
    </Box>
  );
};

export default UploadResume;
