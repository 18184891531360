import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";
// import {
//   DELETE_RESUME,
//   DOWNLOAD_RESUME,
//   JOB_SEEKER,
//   JOB_SEEKER_DETAIL,
// } from "../../apis/Constant/routes";

const initialState = {
    allotedJobs: [],
    allAppliedJobs: [],
    allotedJobDataById: {},
    addSelectedCandidateForJobPost: [],
    tabsValue: "all_jobs",
};

export const getAllotedJobsData = createAsyncThunk(
  "getAllotedJobsData",
  async () => {
    return AxiosApi({
      path: `/job_allocations`,
      type: "GET",
      params: "",
    });
  }
);

export const getAllotedJobDataById = createAsyncThunk(
    "getAllotedJobDataById",
    async (id) => {
      return AxiosApi({
        // path: `/get_all_candidates_of_job_post?data[admin_job_post_id]=${id}`,
        path: `/admin_job_posts/${id}`,
        type: "GET",
        params: ""
      });
    }
  );

  export const getAllAppliedJobsData = createAsyncThunk(
    "getAllAppliedJobsData",
    async () => {
      return AxiosApi({
        path: `/job_allocations/applied_job_posts`,
        type: "GET",
        params: "",
      });
    }
  );

  export const shortlistCandidates = createAsyncThunk(
    "shortlistCandidates",
    async (data) => {
      return AxiosApi({
        path: `/freelancer_candidates_admin_job_posts`,
        type: "POST",
        data: JSON.stringify({ data: data }),
      });
    }
  );

  // /freelancer_candidates_admin_job_posts

export const allotedJobsSlice = createSlice({
  name: "allotedJobs",
  initialState,
  reducers: {
    setAllotedJobs: (state, action) => {
      state.allotedJobs = action.payload;
    },
    setAllAppliedJobs: (state, action) => {
      state.allAppliedJobs = action.payload;  
    },
    setAllotedJobById: (state, action) => {
        state.allotedJobDataById = action.payload;
      },
      setAddSelectedCandidate: (state, action) => {
        state.addSelectedCandidateForJobPost = action.payload;
      },

      clearAllotedJobs: (state, action) => {
        state.allotedJobDataById = {};
        state.allotedJobs = [];
        state.allAppliedJobs = [];
      },
      clearSelectedCandidates: (state, action) => {
        state.addSelectedCandidateForJobPost = [];
      },
      setTabsValue: (state, action) => {
        state.tabsValue = action.payload;
      }

  },
//   extraReducers: (builder) => {
//     builder
//       .addCase(getPersonalDetailApi.pending, (state, action) => {
//         state.loading = true;
//       })
//       .addCase(getPersonalDetailApi.fulfilled, (state, action) => {
//         state.personalData = action.payload;
//         state.loading = false;
//         state.error = false;
//       })
//       .addCase(getPersonalDetailApi.rejected, (state, action) => {
//         state.error = true;
//       });
//   },
});

export default allotedJobsSlice.reducer;
export const { setAllotedJobById,
  clearSelectedCandidates,
  setAddSelectedCandidate, 
  setAllotedJobs, 
  setAllAppliedJobs, 
  setTabsValue,
  clearAllotedJobs
 } = allotedJobsSlice.actions;
