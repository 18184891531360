import React from "react";

//MUI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

//CSS
import "./index.scss";

const PrimaryTab = ({
    value,
    tabData,
    handleChange,
    className
}) => {
    return (
        <Tabs
            value={value}
            onChange={(e, val) => handleChange(e, val)}
            TabIndicatorProps={{
                style: { display: 'none' },
            }}
        >
            {
                tabData && tabData.map((elem) => {
                    return (
                        <Tab label={elem.title}
                            value={elem.value}
                            sx={{ textTransform: "none" }}
                            className={`oh-last-child ${value === elem.value ? className : ''}`}
                        />
                    )
                })
            }
        </Tabs>
    )
}

export default PrimaryTab;