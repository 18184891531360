import React from "react";
//MUI
import Box from "@mui/material/Box";

//OTHER
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";

const NotificationItem = ({ title, description, icon }) => {
    return (
        <Box display="flex" justifyContent="space-between" className="notifcation-box">
            <Box justifyContent="space-between" className="oh-notification-panels">

                <MuiTypography className="oh-rightPanel-titles" content={title} />
                <MuiTypography className="oh-rightPanel-descs" content={description} />

            </Box>
            <Box className="oh-notify-times">{icon}</Box>
        </Box>
    );
};

export default NotificationItem;
