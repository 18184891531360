export const ROOT = "/";
export const LOGIN = "login";
export const SIGNUP = "signup";
export const SIGNUP_PROCEED = "signup-proceed";
export const OTP_VERIFICATION = "otp-verification";
export const FORGOT_PASSWORD = "forgot-password";
export const RESET_PASSWORD = "reset-password";
//portal
export const EMPLOYER = "employer";
export const JOB_SEEKER = "jobseeker";
export const FREELANCER = "freelancer";
export const PROFILE = "profile";
export const SEARCH = "search";
//Employer
export const DASHBOARD = "dashboard";
export const HIRING_ASSISTANCE = "hiring-assistance";
export const JOB_OPENINGS = "job-openings";
export const JOB_DETAILS = "job-details";
export const JOB_POSTING = "job-posting";
export const JOB_STATUS = "job-status";
export const OVERVIEW = "overview";
export const OPEN_POSITIONS = "open-positions";
export const PAST_OPENINGS = "past-openings";
//Jobseekers
export const ALL_JOBS = "all-jobs";
export const JOB_TOPHIRING = "top-hiring";

//Profile
export const COMMUNICATION_DETAILS = "communication-details";
export const COMPANY_DETAILS = "company-details";
export const PERSONAL_DETAILS = "personal-details";
export const OTHER_DETAILS = "other-details";
export const FREELANCER_PERSONAL_DETAILS = "personal-detail";
export const FREELANCER_OTHER_DETAILS = "other-detail";
export const CHANGE_PASSWORD = "change-password";
export const TNC = "terms-and-conditions";
export const SETTING = "setting";

//FreeLancer
export const CANDIDATE = "candidates";
export const CANDIDATE_PROFILE = "candidate-profile";
export const ALLOTTED_JOBS = "allotted-jobs";
export const EARNING = "earning";
export const FREELANCER_JOB_DETAILS = "job-details";

// Third party
export const THIRD_PARTY = "third-party";
export const PRIVACY_POLICY = "privacy-policy";
export const TERMS_CONDITION = "term-condition";
export const HELP_SUPPORT = "help-support";
export const CONTACT_US = "contact-us";
export const BLOGS = "blogs";
export const BLOGS_WITH_ID = "blogs/:slug";

// Blog, hr_policy, job_description
export const BLOGS_PER_PAGE = 8;
export const HR_POLICIES_PER_PAGE = 8;
export const JOB_DESCRIPTIONS_PER_PAGE = 8;
