import React, { useEffect } from "react";
// MUI
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// ASSETS
import freelancer from "../../assets/images/freelancer.png";
import employer from "../../assets/images/employer.svg";
import job_seeker from "../../assets/images/job_seeker.svg";
// OTHER
import MuiTabWithIcon from "../../mui/Tabs/MuiTabWithIcon";
import { useDispatch, useSelector } from "react-redux";
import { setPortalInfo } from "../../redux/globalSlice";
import { returnRoleValue } from "../../utils";

const options = [
  { value: "Jobseeker", name: "Jobseeker", image: job_seeker },
  { value: "Freelancer", name: "Freelancer", image: freelancer },
  { value: "Employer", name: "Employer", image: employer },
];

const LoginDropdown = () => {
  const { portalName } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    portalName && localStorage.setItem("portal", portalName);
  }, [portalName]);

  const handleList = (e) => {
    e.preventDefault();
    const val = e.target.value;
    dispatch(
      setPortalInfo({ portalName: val, portalValue: returnRoleValue(val) })
    );
    localStorage.setItem("portal", val)
  };
  return (
    <div className="dropDownMenu">
      <FormControl>
        <Select
          name="selectPortal"
          id="lock-menu"
          value={portalName}
          onChange={(e) => handleList(e)}
          displayEmpty
          inputProps={{
            sx: {
              paddingTop: "2px !important",
              paddingBottom: "2px !important",
              color: "#fff",
              display: "flex",
              marginTop: "2px",
              "&.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                paddingLeft: "2px !important",
                borderRadius: "40px !important",
                background:
                  "var(--Gradient, linear-gradient(180deg, var(--oh-blue-color) 0%, var(--oh-blue-500-color) 100%))",
                boxShadow: "4px 4px 20px 0px #E7DCFF",
              },
            },
          }}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
            "& .MuiSvgIcon-root": {
              color: "#fff",
              fontSize: "26px",
              right: "10px",
            },
          }}
          IconComponent={ExpandMoreIcon}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={option?.value}
              sx={{

                "&.MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "transparent !important",
                },
                borderRadius: "40px !important",
                minWidth: "20px",
              }}
            >
              <MuiTabWithIcon
                className={`${portalName === option?.value
                  ? "active-secondary-tab"
                  : "inactive-tab"
                  }`}
                style={{
                  maxWidth: "170px",
                  color: "var(--oh-blue-300-color)",
                  fontSize: "16px",
                  paddingRight: "10px",
                }}
                label={option?.name}
                startIcon={option.image}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default LoginDropdown;
