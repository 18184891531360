import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";

const initialState = {
  search: "",
  globalSearch: "",
  searchedJobs: [{ attributes: [] }],
  searchedGlobalJobs: [{ attributes: [] }],
};

export const searchForEmployer = createAsyncThunk(
  "searchForEmployer",
  async (data, thunkApi) => {
    const { portalName } = thunkApi.getState().app;
    const url =
      portalName === "Employer"
        ? `/job_posts/?search[job]=${data}&search[is_active]=true`
        : portalName === "Freelancer"
          ? `/job_allocations/?search[term]=${data}`
          : initialState.search === "" &&
          `/job_posts/?search[is_active]=true&search[job]=${data}`;

    return await AxiosApi({
      path: url,
      type: "GET",
      params: "",
      toAppendAuthParams:
        portalName === "Employer" || portalName === "Freelancer" ? true : false,
    });
  }
);

export const searchForJobseeker = createAsyncThunk(
  "searchForJobseeker",
  async (data, thunkApi) => {
    const url = `/job_posts?search[job]=${data}&search[is_active]=true`;

    return await AxiosApi({
      path: url,
      type: "GET",
      params: "",
      toAppendAuthParams: false,
    });
  }
);

export const searchForDescription = createAsyncThunk(
  "searchForDescription",
  async (data, thunkApi) => {
    const url = `/job_description/?search[job_title]=${data}`;

    return await AxiosApi({
      path: url,
      type: "GET",
      params: "",
      toAppendAuthParams: false,
    });
  }
);

export const employerSearchSlice = createSlice({
  name: "empsearch",
  initialState,
  reducers: {
    setSearchedJobs: (state, action) => {
      state.searchedJobs = action.payload;
    },
    setGlobalSearchedJobs: (state, action) => {
      state.searchedGlobalJobs = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    },
    setGlobalSearchTerm: (state, action) => {
      state.globalSearch = action.payload;
    },
    setDescriptionSearch: (state, action) => {
      state.descriptionSearch = action.payload;
    },
  },
});

export default employerSearchSlice.reducer;
export const { setSearchedJobs, setSearchTerm, setGlobalSearchedJobs, setGlobalSearchTerm } = employerSearchSlice.actions;
