import React from "react";
import "./index.scss";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import CloseIcon from "@mui/icons-material/Close";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import Box from "@mui/material/Box";

const DrawerHeader = ({ title = "", onClose = () => {} }) => {
  return (
    <>
      <Box className="oh-drawer-head-js">
        <MuiTypography className="oh-title" content={title} />
        <CloseIcon
          style={{ marginTop: "-4px" }}
          onClick={onClose}
          className="oh-close-icon"
        />
      </Box>
      <MuiDivider className="oh-drawer-divider" />
    </>
  );
};

export default DrawerHeader;
