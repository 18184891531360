import React, { useEffect } from 'react'

//MUI
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

//OTHER
import ContentWithIcon from '../../ContentWithFilterIcon/ContentWithIcon'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAppliedJobsData, setAllAppliedJobs } from '../../../../redux/Freelancer/allotedJobsSlice'
import NoJobsFound from '../../../NotFound/NoJobsFound'
import CardButton from '../../../Cards/Freelancer/CardButton/CardButton'

const ReferredCan = () => {
  const dispatch = useDispatch();

  const allAppliedJobs = useSelector(
    (state) => state.allotedJobs.allAppliedJobs
  );
  useEffect(() => {
    dispatch(getAllAppliedJobsData())
      .then((res) => {
        if (res.type === "getAllAppliedJobsData/fulfilled") {
          dispatch(setAllAppliedJobs(res.payload.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box>
      <ContentWithIcon content={`${allAppliedJobs?.length} Jobs`} className='oh-content-icon' />
      <Grid container spacing={2} >
        {allAppliedJobs ?
          allAppliedJobs.map(elem => {
            return (
              <Grid item xs={12} md={6} lg={4}>
                <CardButton
                  // Note: issues need to be sorted here later on priority, props drilling, components naming structure & unnecessary code, structure not upright
                  elem={elem.attributes}
                />
              </Grid>
            )
          }) : <NoJobsFound height={"120px"} value={"No alloted jobs yet"} />}


      </Grid>
    </Box>
  )
}

export default ReferredCan
