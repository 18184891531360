import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosApi } from '../apis/AxiosApi'
import { EMPLOYER, EMPLOYER_DETAILS } from '../apis/Constant/routes'

const initialState = {
  organization_name: '',
  organization_type: '',
  website: '',
  employee_count_range: '',
  pincode: '',
  city: '',
  state: '',
  address: '',
}


export const showCompanyInfo = createAsyncThunk(
  'showCompanyInfo',
  async (id) => {
    return await AxiosApi({
      path: `${EMPLOYER}/${id}${EMPLOYER_DETAILS}`,
      type: 'GET',
      // data: JSON.stringify({ data: data.data })
      params: ""
    })
  }
)

export const companyInfo = createAsyncThunk(
  'companyInfo',
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${EMPLOYER}/${data.userId}${EMPLOYER_DETAILS}`,
      type: 'POST',
      data: JSON.stringify({ data: data.data })
    })
  }
)

export const updateCompanyInfo = createAsyncThunk(
  'updateCompanyInfo',
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${EMPLOYER}/${data.id}${EMPLOYER_DETAILS}`,
      type: 'PUT',
      data: JSON.stringify({ data: data.data })
    })
  }
)

export const companyInfoSlice = createSlice({
  name: 'companyInfo',
  initialState,
  reducers: {
    
  },
})

export default companyInfoSlice.reducer
