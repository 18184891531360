import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledFieldError = styled.span`
  font-size: 13px;
  color: var(--oh-error-color);
  padding-left: 0;
  margin-left: 0;
  margin-right: 0;
`;

const FieldError = ({ errorString, sx = {} }) => {
    return (
        <Box sx={sx}>
            <StyledFieldError>
                {errorString}
            </StyledFieldError>
        </Box>
    )
}

export default FieldError;
