import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosApi } from '../apis/AxiosApi'
import { PASSWORD, USERS, RESET_PASSWORD, CHANGE_PASSWORD } from '../apis/Constant/routes'

const initialState = {
  password: '',
  confirm_password: ''
}

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${USERS}${PASSWORD}${RESET_PASSWORD}`,
      type: 'PUT',
      data: JSON.stringify({ data: data })
    })
  }
)


export const changePassword = createAsyncThunk(
  'changePassword',
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${USERS}${CHANGE_PASSWORD}?user_id=${data.id}`,
      type: 'PUT',
      data: JSON.stringify({ data: data.data })
    })
  }
)

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    
  },
})

export default resetPasswordSlice.reducer
