import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPortalInfo } from "../../redux/globalSlice";

//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//ASSETS
import warning from "../../assets/images/warning.svg";

//OTHER
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import AllotedOpening from "./AllotedOpening/AllotedOpening";
import CandidateOpening from "./ReferredCandidate/Candidate";
import RequestToPay from "../../components/Dialog/RequestToPay/RequestToPay";
import FreelancerLayout from "../Layouts/Freelancer/FreelancerLayout";
import { Button } from "@mui/material";
import MuiDivider from "../../mui/Dividers/MuiDivider";
import { setTabsValue } from "../../redux/Freelancer/allotedJobsSlice";
import {
  earningAmountsApi,
  setEarningCardsData,
} from "../../redux/Freelancer/earningDetailSlice";
import { formatAmount } from "../../utils";

//CSS
import "./index.scss";

const FreelancerDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { earningCardsData } = useSelector((state) => state.earningData);
  const userID = localStorage.getItem("login_data");
  const freeId = JSON.parse(userID);
  const [open, setOpen] = React.useState(false);
  const { user } = useSelector((state) => state.auth);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(setTabsValue("all_jobs"));
    dispatch(
      setPortalInfo({ portalName: "Freelancer", portalValue: "freelancer" })
    );
    getEarningCandidatesCard();
  }, []);


  const checkIfProfileCompleted = () => {
    return (
      user?.first_name &&
      user?.first_name != "" &&
      user?.last_name &&
      user?.last_name !== "" &&
      user?.email &&
      user?.mobile_number &&
      user?.personal_details?.education_details instanceof Object &&
      Object.keys(user?.personal_details?.education_details)?.length > 0 &&
      user?.personal_details?.experience_details instanceof Object &&
      Object.keys(user?.personal_details?.experience_details)?.length > 0
    );
  };

  const getEarningCandidatesCard = () => {
    dispatch(earningAmountsApi({ id: freeId?.id }))
      .then((res) => {
        if (res?.type === "earningAmountsApi/fulfilled") {
          dispatch(setEarningCardsData(res?.payload?.data));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <FreelancerLayout>
      <Box className="oh-freelancer-dashboard">
        <MuiTypography
          className={"heading-h1"}
          content={`Hi ${user?.personal_details?.first_name || user?.first_name || "Freelancer!"}`}
        />
        {!checkIfProfileCompleted() && (
          <Box
          className="fill-profile-line"
          sx={{
            marginBottom: '10px'
          }}
           display={"flex"}>
            <img src={warning} alt="warning" />
            <MuiTypography
              onClick={() => navigate("/personal-detail")}
              className={"description"}
              content={
                <span>
                  Are you new with Octahire ? Please complete your{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: 500,
                    }}
                  >
                    Profile
                  </span>{" "}
                  to start working with us!
                </span>
              }
            />
          </Box>
        )}
        <Grid className="earning-container-grid" container spacing={2}>
          <Grid className="earning-grid" item xs={12} md={12} lg={8}>
            <AllotedOpening />
          </Grid>
          <Grid
            className="dashboard-earning-cards earning-grid"
            item
            xs={12}
            md={12}
            lg={4}
          >
            <Box className="oh-earning-first">
              <Box
                className="earning-header"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div>
                  <MuiTypography className="earn-text">
                    <span className="currency-sybmol">₹</span>
                    <span className="currency-value">
                      {/* NOTE currently, rendering values from indexes, as no other way to access response, but this may cause issue if the order is changed in response */}
                      {earningCardsData?.[0]?.my_balance ? formatAmount(
                        earningCardsData?.[0]?.my_balance
                      ) : "00"}
                    </span>
                  </MuiTypography>
                  <MuiTypography className="projected-text" content="My Balance" />
                </div>
                <Button
                  className="request-pay-btn"
                  onClick={() => handleClickOpen()}
                >
                  Request to Pay
                </Button>
              </Box>
            </Box>
            <Box className="oh-earning-card-dashboard">
              <Box
                className="inner-boxes"
                sx={{
                  width: "100%",
                  padding: "10px 22px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <MuiTypography className="earn-text">
                  <span className="currency-sybmol">₹</span>
                  <span className="currency-value">
                    {" "}
                    {earningCardsData?.[2]?.project_earning ? formatAmount(
                      earningCardsData?.[2]?.project_earning
                    ) : "00"}
                  </span>
                </MuiTypography>
                <MuiTypography
                  className="projected-text"
                  content={"Projected Earning"}
                />
              </Box>
              <MuiDivider
                className="oh-divider-earning-card"
                orientation="vertical"
              />
              <Box
                className="inner-boxes"
                sx={{
                  width: "100%",
                  padding: "10px 22px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <MuiTypography className="earn-text">
                  <span className="currency-sybmol">₹</span>
                  <span className="currency-value">
                    {" "}
                    {earningCardsData?.[1]?.actual_earning ? formatAmount(earningCardsData?.[1]?.actual_earning) : "00"}
                  </span>
                </MuiTypography>
                <MuiTypography
                  className="projected-text"
                  content={"Total Earning"}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CandidateOpening />
        {/* <RequestToPay open={open} handleClose={handleClose} /> */}
      </Box>
    </FreelancerLayout>
  );
};

export default FreelancerDashboard;
