import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../apis/AxiosApi";
import { LOGIN, SIGNUP, USERS } from "../apis/Constant/routes";

const initialState = {
  user: null,
  token: "",
};

export const signup = createAsyncThunk("auth/signup", async (params) => {
  return await AxiosApi({
    path: `${USERS}${SIGNUP}`,
    type: "POST",
    data: JSON.stringify(params),
  });
});

export const login = createAsyncThunk("auth/login", async (params) => {
  return await AxiosApi({
    path: `${USERS}${LOGIN}`,
    type: "POST",
    data: JSON.stringify(params),
  });
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setSignUp: (state, action) => {
      state.user = action.payload;
    },
    setLogin: (state, action) => {
      state.user = action.payload;
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    clearAuthStorage: (state, action) => {
      state.token = null;
      state.user = null;
      localStorage.clear();
      localStorage.setItem("portal", "Jobseeker");
    },
  },
});
export default authSlice.reducer;
export const { setToken, setSignUp, setLogin, setUserData, clearAuthStorage } =
  authSlice.actions;
