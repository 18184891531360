import React from "react";

//MUI
import Box from "@mui/material/Box";

//OTHER
// import CardBody from "../CardSection/CardBody";
import CardFooter from "../CardSection/CardFooter";

//CSS
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { clearSelectedCandidates } from "../../../../redux/Freelancer/allotedJobsSlice";
import { useDispatch } from "react-redux";
import ReferCardHeader from "../CardSection/CardHeader";
import FilledChip from "../../../customChips/FilledChips";

const CardButton = ({ elem }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <Box onClick={() => {
            dispatch(clearSelectedCandidates());
            navigate(`/allotted-jobs/job-details/${elem.id}`, { state: { status: elem?.status } })
        }} className="oh-card-box">
            <Box className="oh-card-button">
                <ReferCardHeader {...elem} />
                {/* <CardBody {...elem} /> */}
                <div
                    style={{
                        padding: "20px 5px 5px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        fontWeight: 400,
                        fontSize: "14px",
                        width: "100%",
                    }}
                    className="add-more-candidates"
                >
                    <span style={{ fontSize: "14px", color: "var(--oh-gray-color)" }}>
                        {elem?.freelancer_candidates?.length ? "Candidates Applied: " : "Applicants: "}
                        <span style={{ color: "var(--oh-blue-300-color)" }}>
                            {" "}
                            {elem?.freelancer_candidates?.length || elem?.applicants_count}
                        </span>
                    </span>
                    <span>

                        <FilledChip
                            size="medium-200"
                            sx={{
                                fontSize: "13px",
                                padding: "4px 20px 3px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                dispatch(clearSelectedCandidates());
                                navigate(`/allotted-jobs/job-details/${elem.id}`)
                            }}
                            label={elem?.freelancer_candidates?.length ? "Add More Candidate" : "Add Candidate"}
                            color="var(--oh-blue-color)"
                        />
                    </span>
                </div>
            </Box>
            <CardFooter {...elem} />
        </Box>
    );
};

export default CardButton;
