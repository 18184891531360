import React, { useEffect, useState } from "react";
import BannerImage from "../../../components/Banner/BannerImage";
import { Grid } from "@mui/material";
import PrimaryWrapper from "../../../components/Wrappers/PrimaryWrapper";
import JobInfoCard from "../../../components/Cards/JobSeeker/JobInfoCard";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../../redux/globalSlice";
import { useNavigate } from "react-router-dom";
import GenericSwiper from "../../../swiper/CustomSwiper";
import { SwiperSlide } from "swiper/react";
import JobSeekerLayout from "../../Layouts/JobSeeker/JobSeekerLayout";
import {
  getAppliedJobs,
  getTopHiringJob,
  setAppliedJobs,
  setTopHiringJobs,
} from "../../../redux/JobSeeker/jobSeekerJobsSlice";
import NoJobsFound from "../../../components/NotFound/NoJobsFound";
import JobPostCard from "../../../components/JobPostBox/JobPostCard";
import GlobalLoader from "../../../components/Loader/GlobalLoader";

import "./index.scss";

const JobSeekerDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const appliedJobs = useSelector((state) => state.appliedJobs.appliedJobs);
  const topHiringJobs = useSelector((state) => state.appliedJobs.topHiringJobs);

  const { isLoading } = useSelector((state) => state.app);
  const [loadTopHiring, setLoadTopHiring] = useState(false);

  useEffect(() => {
    dispatch(setIsLoading(true));
    setLoadTopHiring(true);
    getAppliedJobsData();
    getTopHiringData();
  }, []);

  const getAppliedJobsData = () => {
    dispatch(getAppliedJobs({ page: 1, pageSize: 10 }))
      .then((res) => {
        if (res.type === "getAppliedJobs/fulfilled") {
          dispatch(setAppliedJobs(res?.payload?.data));
        }
        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        console.log(err);
      });
  };
  const getTopHiringData = () => {
    dispatch(getTopHiringJob())
      .then((res) => {
        if (res.type === "getTopHiringJob/fulfilled") {
          setLoadTopHiring(false);
          dispatch(setTopHiringJobs(res?.payload?.message?.data || res?.payload?.data));
        } else if (res.type === "getTopHiringJob/rejected") {
          setLoadTopHiring(false);
        }
      })
      .catch((err) => {
        setLoadTopHiring(false);
        console.log(err);
      });
  };
  const handleNavigate = (id) => {
    navigate(`/all-jobs/job-details/${id}`);
  };

  const handleTopHiringNavigate = (id) => {
    navigate(`/top-hiring/job-details/${id}`);
  }
  return (
    <JobSeekerLayout>
      <Grid container lg={12}>
        <Grid item xs={12} md={12} lg={12}>

          <BannerImage title={user?.first_name && user?.first_name !== "" ? `${user?.first_name} ${user?.last_name}` : "Jobseeker"} />
          <PrimaryWrapper
            content="Jobs Applied"
            linkTitle={appliedJobs?.length > 2 ? "View All" : ""}
            onClick={() => navigate("/job-status")}
          >
            <GenericSwiper configSize="medium_400">
              {isLoading ? <GlobalLoader /> : <Grid container>
                {appliedJobs?.length > 0 ? (
                  appliedJobs.map((elem, idx) => {
                    return (
                      <Grid item key={idx} xs={12} md={12} lg={6}>
                        <SwiperSlide>
                          <JobPostCard
                            size="medium-100"
                            data={elem.attributes}
                            handleNavigate={() => handleNavigate(elem?.id)}
                            tags={elem.attributes.skills.length > 0}
                            updateData={appliedJobs}
                            setUpdateData={(updatedJobs) => dispatch(setAppliedJobs(updatedJobs))}
                          />
                        </SwiperSlide>
                      </Grid>
                    );
                  })
                ) : (
                  <NoJobsFound value={"No jobs applied"} height={"80%"} />
                )}
              </Grid>}
            </GenericSwiper>
          </PrimaryWrapper>
          {/* At your location, feature, not in use but may exist in phase - 2 */}
          {/* <SecondaryWrapper
            tabData={[
              { title: "Top Hiring", value: "top_hiring" },
              // { title: `At your location`, value: `preferred_locations` },
            ]}
            linkTitle="View All"
            onClick={() => navigate("/top-hiring")} */}
          {/* > */}
          <PrimaryWrapper
            content="Top Hiring"
            linkTitle={topHiringJobs?.length > 2 ? "View All" : ""}
            onClick={() => navigate("/top-hiring")}
          >
            <GenericSwiper configSize="top_hiring">
              {
                loadTopHiring ? <GlobalLoader minHeight="200px" /> : (
                  <Grid container>
                    {topHiringJobs?.length > 0 ? (
                      topHiringJobs.map((elem, indx) => {
                        return (
                          <Grid item sx={3} md={3} lg={12} key={elem.id}>
                            <SwiperSlide>
                              <JobInfoCard
                                cardType="suggestedJobs"
                                data={elem.attributes}
                                onClick={() => handleTopHiringNavigate(elem?.id)}
                                onApplyClick={() => handleTopHiringNavigate(elem?.id)}
                                footerText={true}
                                updateData={topHiringJobs}
                                setUpdateData={(updatedJobs) => dispatch(setTopHiringJobs(updatedJobs))}
                              />
                            </SwiperSlide>
                          </Grid>
                        );
                      })
                    ) : (
                      <NoJobsFound value={"No jobs at the moment"} height={"80%"} />
                    )}
                  </Grid>
                )
              }
            </GenericSwiper>
          </PrimaryWrapper>
          {/* </SecondaryWrapper> */}
        </Grid>
      </Grid>
    </JobSeekerLayout>
  );
};

export default JobSeekerDashboard;
