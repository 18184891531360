import React from "react";
// MUI
import Box from "@mui/material/Box";

// OTHER
import MuiButtonContained from "../../../mui/Buttons/MuiButtonContained";
import SettingForm from "../../../components/Forms/SettingForm";

// CSS
import "../../../Styles/AccountSetting.scss";
import "../../../Styles/Global.scss";

const AccountSetting = () => {
  return (
    <>
      <Box className="setting-form">
        <SettingForm />
      </Box>
      <Box className="oh-setting-btn">
        <hr className="horizontal-divider" />
        <MuiButtonContained type={"button"} title={"Cancel"} />
        <MuiButtonContained type={"button"} title={"Suubmit"} />
      </Box>
    </>
  )
}

export default AccountSetting;
