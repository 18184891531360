import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import MuiDivider from "../../../mui/Dividers/MuiDivider";

const JobOpeningsTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const navigationData = [
    {
      path: "/job-openings",
      title: "New Openings",
      value: 0,
    },
    {
      path: "/open-positions",
      title: "Open Positions",
      value: 1,
    },
    {
      path: "/past-openings",
      title: "Past Openings",
      value: 2,
    },
  ];

  const isActiveTab = (path) => {
    const pathInUrl = "/" + location.pathname.split("/").filter((x) => x !== params.id).join('')

    return params?.id ? pathInUrl === path : location.pathname === path;
  };

  const handleChange = (event, newValue) => {
    navigate(newValue
      // , { replace: true }
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Tabs
        // value={navigationData.find(elm => elm.path === location.pathname).value} // done to resolve console warnings more than 30 each time a tab is switched
        value={location.pathname}
        className="jobOpeningTabs"
        sx={{ background: "var(--oh-white-color)", padding: "6px 0px" }} // Set background color to white
      >
        {navigationData?.map((item, id) => (
          <>
            {id !== 0 && (
              <MuiDivider orientation="vertical" className="oh-tab-divider" />
            )}
            <Tab
              key={id}
              label={item.title}
              value={item.value}
              onClick={() => navigate(item.path
                // , { replace: true }
              )}
              sx={{
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 400,
                margin: "auto 6px auto 4px",
                opacity: 1,
                "&.ma-active-tab": {
                  color: "var(--oh-blue-color) !important",
                  fontWeight: "700",
                },
                "&.ma-deactive-tab": {
                  color: "var(--oh-blue-300-color) !important",
                },
                "&:last-child": {
                  borderRight: "none",
                },
              }}
              className={`list-item-name ${isActiveTab(item.path) ? "ma-active-tab" : "ma-deactive-tab"
                }`}
            />
          </>
        ))}
      </Tabs>
    </Box>
  );
};

export default JobOpeningsTabs;
