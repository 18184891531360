import { Drawer } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const MuiBasicDrawer = ({ sx = {}, anchor = "right", closeIcon = null, open = false, onClose = () => { }, children = null }) => {
    return (
        <Drawer
            anchor={anchor}
            sx={{
                ...sx,
                '.MuiBackdrop-root': {
                    // background: "transparent"
                    background: "rgba(0, 0, 0, 0.15)"
                },

                '&.MuiDrawer-root .MuiPaper-root .drawer-close-icon': {
                    position: "absolute",
                    zIndex: 1,
                    top: "10px",
                    right: "10px",
                    fontSize: "20px",
                    cursor: "pointer",
                    color: "var(--oh-white-color)"
                },

               
            }}
            open={open}
            onClose={onClose}
        >   
        {closeIcon && <CloseIcon onClick={onClose} className="drawer-close-icon" />}
            {children}
        </Drawer>
    )
}

export default MuiBasicDrawer;