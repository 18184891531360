import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../apis/AxiosApi";

const initialState = {
  notifications: [],
};

// export const notifications = createAsyncThunk("notifications", async () => {
//   const url = `/notifications/`;
//   return await AxiosApi({
//     path: url,
//     type: "GET",
//     params: "",
//     toAppendAuthParams: true,
//   });
// });

export const globalNotificationsData = createAsyncThunk(
  "globalNotificationsData",
  async () => {
    return AxiosApi({
      path: `/notifications`,
      type: "GET",
      params: "",
    });
  }
);

export const readNotifications = createAsyncThunk(
  "readNotifications",
  async (id) => {
    return AxiosApi({
      path: `/notifications/${id}/read_notification`,
      type: "GET",
      params: "",
    });
  }
);

export const globalNotifications = createSlice({
  name: "globalNotifications",
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export default globalNotifications.reducer;
export const { setNotifications } = globalNotifications.actions;

// notifications: {
//     data: [
//       {
//         id: "7",
//         type: "notification",
//         attributes: {
//           id: 7,
//           message: "Your job application for HR Executive is sent successfully",
//           read: false,
//           posted_at: "19 hours and 15 minutes ago",
//         },
//       },
//       {
//         id: "3",
//         type: "notification",
//         attributes: {
//           id: 3,
//           message:
//             "Your job application for Branch Executive is sent successfully",
//           read: true,
//           posted_at: "yesterday",
//         },
//       },

//       {
//         id: "5",
//         type: "notification",
//         attributes: {
//           id: 5,
//           message:
//             "Your job application for Branch Executive is sent successfully",
//           read: true,
//           posted_at: "2 day ago",
//         },
//       },
//     ],
//     meta: {
//       next_page: null,
//       previous_page: null,
//       total_pages: 1,
//       total_records: 2,
//       current_page: 1,
//     },
//   },
