import React from 'react'
// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// ASSETS
import Map from '../../assets/images/globalMap.png';
import FreelancerSideImg from "../../assets/images/loginFreelancer.png";
import PrimarySideImg from "../../assets/images/employerLogin.png";
// OTHER 
import { checkIfFreelancer } from '../../utils';

// CSS
import "./index.scss";
import { useSelector } from 'react-redux';
import Header from '../../components/Header/Header';

const LoginLayout = ({ children, otpSection = false }) => {
  const { portalName } = useSelector(state => state.app);

  return (
    <>
      <Header />
      <Box className='oh-login-layout'>
        <Grid container className='oh-layout-grid'>

          <img
            className={"oh-cover-layout"}
            src={Map}
            alt={''}
          />

          <Grid item xs={10} md={5} lg={5}>
            <Box className={`oh-login-form ${otpSection ? "otp-parent-container" : ""}`}>
              {children}
            </Box>
          </Grid>
          <Grid className='oh-layout-img' item xs={"auto"} md={7} lg={7}>
            <img src={checkIfFreelancer(portalName) ? FreelancerSideImg : PrimarySideImg} className="oh-layout-side-img" alt='' />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default LoginLayout;