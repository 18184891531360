import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosApi } from '../apis/AxiosApi'

const initialState = {
  info: null
}

export const getCompanyDetails = createAsyncThunk(
  'getCompanyDetails',
  async (id) => {
    return await AxiosApi({
      path: `/employers/${id}/employer_details`,
      type: 'GET',
      params: '',
    })
  }
)  

export const updateCompanyDetails = createAsyncThunk(
    'updateCompanyDetails',
    async (id) => {
      return await AxiosApi({
        path: `/employers/${id}/employer_details`,
        type: 'PUT',
        params: '',
      })
    }
  )  

export const companyDetailsSlice = createSlice({
  name: 'companyDetails',
  initialState,
  reducers: {
    
  },
})

export default companyDetailsSlice.reducer