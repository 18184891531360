import React, { useEffect, useRef } from "react";
import MuiTextField from "../../../mui/TextField/MuiTextField";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import "./index.scss";
import { debounce } from "lodash";

const TableSearch = ({
  searchData,
  setSearchData,
  handleSearch,
  placeholder = "Search candidate by keyword",
}) => {
  const inputRef = useRef(null);

  const delayedDispatch = debounce((searchData) => {
    handleSearch(searchData, inputRef);
  }, 500);

  useEffect(() => {
    delayedDispatch(searchData);
    return () => {
      delayedDispatch.cancel();
    };
  }, [searchData]);

  return (
    <>
      <MuiTextField
        ref={inputRef}
        value={searchData}
        id="outlined-start-adornment"
        placeholder={placeholder}
        onChange={(e) => {
          setSearchData(e.target.value.trimStart());
        }}
        autoComplete="off"
        type="text"
        className="oh-drawer-search"
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() => {
                handleSearch(searchData);
                setSearchData("");
              }}
              sx={{ cursor: "pointer" }}
              position="end"
            >
              {searchData !== "" ? (
                <CloseIcon
                  sx={{ svg: { color: "var(--oh-blue-600-color) !important" } }}
                />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default TableSearch;
