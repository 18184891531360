import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TertiaryWrapper from "../../../components/Wrappers/TertiaryWrapper";

import "./index.scss";
import JobSeekerLayout from "../../Layouts/JobSeeker/JobSeekerLayout";
import { useDispatch, useSelector } from "react-redux";
import NoJobsFound from "../../../components/NotFound/NoJobsFound";
import JobPostCard from "../../../components/JobPostBox/JobPostCard";
import { getTopHiringJob, setJobsPage, setJobsRowCount, setJobsSortFilter, setTopHiringJobs } from "../../../redux/JobSeeker/jobSeekerJobsSlice";
import GlobalLoader from "../../../components/Loader/GlobalLoader";
import { setIsLoading } from "../../../redux/globalSlice";
import FilterMenu from "../../Employer/FilterMenu";
import { SortMenuItems } from "../../../Data";
import { Pagination } from "@mui/material";

const TopHiringJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const topHiringJobs = useSelector((state) => state.appliedJobs.topHiringJobs);
  const { isLoading } = useSelector(state => state.app);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const { JobsPage, JobsRowCount, JobsSortFilter } = useSelector((state) => state.appliedJobs);


  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };


  const handleSortClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  useEffect(() => {
    return () => {
      dispatch(setJobsPage(1));
      dispatch(setJobsRowCount(null));
      dispatch(setJobsSortFilter("asc"))
    }
  }, [])

  const getTopHiringData = () => {
    dispatch(getTopHiringJob())
      .then((res) => {
        if (res.type === "getTopHiringJob/fulfilled") {
          dispatch(setIsLoading(false));
          dispatch(setTopHiringJobs(res?.payload?.message?.data || res?.payload?.data));
          dispatch(setJobsRowCount(res?.payload?.meta?.total_pages));
        } else if (res.type === "getTopHiringJob/rejected") {
          dispatch(setIsLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        console.log(err);
      });
  };

  useEffect(() => {
    dispatch(setIsLoading(true));
    getTopHiringData();
  }, [JobsPage, JobsSortFilter])

  const handleNavigate = (id) => {
    navigate(`/top-hiring/job-details/${id}`);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(setJobsPage(newPage));
  };
  const handleSortFilter = (param) => {
    dispatch(setJobsPage(1));
    dispatch(setJobsRowCount(null));
    dispatch(setJobsSortFilter(param));
    handleClose();
  }

  return (
    <>
      <JobSeekerLayout>
        <Box>
          <TertiaryWrapper
            handleSortClick={handleSortClick} showSortOption={true}
            showBtnOptions={topHiringJobs?.length ? true : false}
            content={` Top Hiring Jobs`}
          >
            {
              isLoading ? <GlobalLoader minHeight="300px" /> : (
                <Grid container spacing={1}>
                  {topHiringJobs?.length ? (
                    topHiringJobs?.map((elem, idx) => {
                      return (
                        <Grid className="cards-responsive-jobseeker-1400" item xs={12} md={12} lg={6}>
                          <JobPostCard
                            size="medium-100"
                            handleNavigate={() => handleNavigate(elem?.id)}
                            data={elem.attributes}
                            className={"open-total-applicant"}
                            updateData={topHiringJobs}
                            setUpdateData={(updatedJobs) => dispatch(setTopHiringJobs(updatedJobs))}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <Box sx={{ width: "100%", minHeight: "200px" }}>
                      <NoJobsFound height={"450px"} value="No jobs at the moment" />
                    </Box>
                  )}
                </Grid>
              )
            }
            {topHiringJobs.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Pagination
                  count={JobsRowCount}
                  onChange={handlePageChange}
                  shape="rounded"
                />
              </Box>
            )}
          </TertiaryWrapper>
        </Box>
      </JobSeekerLayout>
      <FilterMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        menuItems={SortMenuItems}
        filter={JobsSortFilter}
        handleFilter={handleSortFilter}
      />
    </>
  );
};

export default TopHiringJobs;
