import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosApi } from '../apis/AxiosApi'
import { FORGOT_PASSWORD, PASSWORD, USERS } from '../apis/Constant/routes'

const initialState = {
  email: '',
  mobile_number: '',
}

export const forgotPassword = createAsyncThunk(
  'forgotPassword',
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${USERS}${PASSWORD}${FORGOT_PASSWORD}`,
      type: 'POST',
      data: JSON.stringify({ data: data })
      //   isLoader: false
    })
  }
)

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setMobileNumber:(state,action)=>{
        state.mobile_number = action.payload
    },
  },
})

export default forgotPasswordSlice.reducer
export const { setEmail, setMobileNumber } = forgotPasswordSlice.actions
