import React, { useEffect, useState } from "react";
import { Form, Formik, useField, useFormikContext } from "formik";
// MUI
import Grid from "@mui/material/Grid";

// OTHER
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../../components/Labels/PrimaryLabel";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import MuiTextField from "../../../mui/TextField/MuiTextField";

// CSS
import "../index.scss";
import { PersonalDetail } from "../../../schemas/Freelancer/PersonalDetailSchema";
import FieldError from "../../../components/Errors/FieldError";
import CustomLoadingButton from "../../../components/Loader/LoadingButton";
import ProfileLayout from "../ProfileLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getFreelancerDetails,
  setFreelancerData,
  updateFreelancerDetails,
} from "../../../redux/Freelancer/freelancerDetailsSlice";
import MuiSelect from "../../../mui/TextField/MuiSelect";
import axios from "axios";
import { setUserData } from "../../../redux/authSlice";
import { employmentStatusArr, experienceArr } from "../../../Data";
import * as Yup from "yup";
import { errMessageMaximum, smoothScroll } from "../../../utils";
import {
  FIRSTNAME_REGEX,
  LASTNAME_REGEX,
  PINCODE_REGEX,
} from "../../../utils/regex";
import { Toaster } from "../../../components/Toaster/Toaster";

const FreelancerPersonal = () => {
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    designation: "",
    employment_status: "",
    experience: "",
    zip_code: "",
    address: "",
    city: "",
    state: "",
    country: "",
  });

  const freelancerData = useSelector(
    (state) => state.freelancerDetailData.freelancerData
  );

  const personalData = freelancerData?.attributes?.personal_details;
  const [checkMobile, setCheckMobile] = useState(true);

  useEffect(() => {
    if (freelancerData) {
      setCheckMobile(!freelancerData?.attributes?.freelancer?.mobile_number || freelancerData?.attributes?.freelancer?.mobile_number == "" ? false : true);
      setInitialData({
        first_name: freelancerData?.attributes?.freelancer?.first_name,
        last_name: freelancerData?.attributes?.freelancer?.last_name,
        email: freelancerData?.attributes?.freelancer?.email,
        mobile: freelancerData?.attributes?.freelancer?.mobile_number || "",
        designation: personalData?.designation || "",
        employment_status: employmentStatusArr.find(opt => opt.numericalValue === personalData?.employment_status)?.value || "",
        experience: personalData?.experience,
        zip_code: personalData?.address?.zip_code,
        address: personalData?.address?.address,
        city: personalData?.address?.city,
        state: personalData?.address?.state,
        country: personalData?.address?.country,
      });
    }
  }, [freelancerData]);

  useEffect(() => {
    showfreelancerDetails();
  }, []);

  const showfreelancerDetails = () => {
    dispatch(getFreelancerDetails())
      .then((res) => {
        if (res.type === "getFreelancerDetails/fulfilled") {
          dispatch(setFreelancerData(res?.payload?.data));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (values, setSubmitting) => {
    const formData = new FormData();
    formData.append("data[first_name]", values.first_name);
    formData.append("data[last_name]", values.last_name);
    // formData.append("data[email]", values.email);
    // formData.append("data[mobile_number]", values.mobile);
    formData.append("data[employment_status]", values.employment_status ? employmentStatusArr.find(opt => opt.value === values.employment_status).numericalValue : "");
    formData.append("data[experience]", values.experience);
    formData.append("data[designation]", values.designation);
    formData.append("data[address_attributes][zip_code]", values.zip_code);
    formData.append("data[address_attributes][address]", values.address);
    formData.append("data[address_attributes][city]", values.city);
    formData.append("data[address_attributes][state]", values.state);
    formData.append("data[address_attributes][country]", values.country);

    dispatch(updateFreelancerDetails({ data: formData }))
      .then((res) => {
        if (res.type === "updateFreelancerDetails/fulfilled") {
          smoothScroll();
          let user_data = res?.payload?.data?.attributes?.["freelancer"];;
          user_data = { ...user_data, personal_details: res.payload.data.attributes?.personal_details };
          localStorage.setItem("login_data", JSON.stringify(user_data));
          dispatch(setUserData(user_data));
          Toaster.TOAST(res.payload?.message, "success");
          showfreelancerDetails();
        } else if (res.type === "updateFreelancerDetails/rejected") {
          smoothScroll();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setSubmitting(false);
  };

  const ChildGrid = ({
    children,
    size = "",
    className = "oh-form-field",
    label = null,
    required = false,
  }) => {
    let gridSize = {};
    switch (size) {
      case "primary":
        gridSize = { xs: 12, md: 12, lg: 12 };
        break;
      case "secondary":
        gridSize = { xs: 12, md: 6, lg: 6 };
        break;
      case "tertiary":
        gridSize = { xs: 12, md: 1, lg: 1 };
        break;
      default:
        gridSize = { xs: 12, md: 6, lg: 3 }; // Default to primary size
    }

    return (
      <Grid className={className} item {...gridSize}>
        {label && (
          <PrimaryLabel
            labelType="secondary"
            label={label}
            required={required}
          />
        )}
        {children}
      </Grid>
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("loginBtn").click();
    }
  };

  const handleChange = (e, setFieldValue, setErrors) => {
    const newPincode = e.target.value;
    setFieldValue("zip_code", newPincode);

    if (newPincode.length === 6) {
      // Make API call
      axios
        .get(`https://api.postalpincode.in/pincode/${newPincode}`)
        .then((response) => {
          if (response.data[0].PostOffice) {
            setFieldValue("city", response.data[0].PostOffice[0].Block);
            setFieldValue("state", response.data[0].PostOffice[0].State);
            setErrors({ zip_code: "" });
            const country = response.data[0].PostOffice[0].Country;
            if (country) {
              setFieldValue("country", country);
            } else {
              setFieldValue("country", ""); // Clear the country field if no country information is found
            }
          } else if (response.data[0].Message === "No records found") {
            setFieldValue("city", "");
            setFieldValue("state", "");
            setFieldValue("country", "");

            setErrors({ zip_code: "Enter a valid pincode" });
          }
        })
        .catch((error) => {
          // Handle errors here
          setErrors({ zip_code: "Enter a valid pincode" });
          console.error("Error fetching pincode data:", error);
        });
    }
  };
  const ADDRESS_REGEX = /^[a-zA-Z0-9\s,.'\/-]+$/;
  const PersonalDetailFreelancer = Yup.object().shape({
    first_name: Yup.string()
      .required("First name is required.")
      .max(150, errMessageMaximum("First name", 150))
      .test(
        "check if  First name not empty",
        "First name must be relevant.",
        (val) => {
          if (!val || val.trim().length === 0 || val.trim().length < 2) {
            return false;
          }
          return true;
        }
      )
      .matches(FIRSTNAME_REGEX, "First name must be valid."),

    last_name: Yup.string()
      .required("Last name is required.")
      .max(150, errMessageMaximum("Last name", 150))
      .test(
        "check if Last name not empty",
        "Last name must be relevant.",
        (val) => {
          if (!val || val.trim().length === 0 || val.trim().length < 2) {
            return false;
          }
          return true;
        }
      )
      .matches(LASTNAME_REGEX, "Last name must be valid."),

    designation: Yup.string()
      // .required("Designation is required.")
      .max(60, errMessageMaximum("Designation", 60))
      // .test(
      //   "check if  Designation not empty",
      //   "Designation must be relevant.",
      //   (val) => {
      //     if (!val || val.trim().length === 0 || val.trim().length < 2) {
      //       return false;
      //     }
      //     return true;
      //   }
      // )
      .matches(FIRSTNAME_REGEX, "Designation must be valid."),
    zip_code: Yup.string()
      .matches(PINCODE_REGEX, "Pincode must contain only numbers")
      .max(6, errMessageMaximum("Pincode", 6)),
    address: Yup.string()
      // .required("Address is required.")
      .max(150, "Address must be less than 150 characters")
      // .test(
      //   "check if Address not empty",
      //   "Address must be relevant.",
      //   (val) => {
      //     return val && val.trim().length >= 2; // Check if the address is at least 2 characters long after trimming
      //   }
      // )
      .matches(ADDRESS_REGEX, "Address must be valid."),
    // percentage: Yup.number().required("Percentage is required").min(1).max(100),
    mobile: Yup.string()
      .matches(/^\d*$/, "Only numerical characters are allowed")
      .max(10, "Please enter valid 10 digit number")
      .required("Mobile number is required."),
  });

  return (
    <ProfileLayout>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);
        }}
        validateOnblur={false}
        enableReinitialize
        validationSchema={PersonalDetailFreelancer}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          setErrors,
        }) => {
          return (
            <Form className="oh-signup-form" autoComplete="off">
              <Grid container lg={12}>
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Personal Details"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid label="First Name" required={true}>
                      <InputField
                        id="first_name"
                        type="text"
                        placeholder="Eg: John"
                        name="first_name"
                        value={values.first_name}
                        validateOnChange={true}
                      />
                    </ChildGrid>

                    <ChildGrid label="Last Name" required={true}>
                      <InputField
                        id="last_name"
                        type="text"
                        placeholder="Eg: John"
                        name="last_name"
                        value={values.last_name}
                        validateOnChange={true}
                      />
                    </ChildGrid>
                    <ChildGrid label="Email ID">
                      <InputField
                        id="email"
                        type="email"
                        placeholder="Eg: jonhdoe@gmail.com"
                        name="email"
                        value={values.email}
                        validateOnChange={true}
                        disabled={true}
                      />
                    </ChildGrid>
                    <ChildGrid label="Mobile">
                      <InputField
                        id="mobile"
                        type="text"
                        placeholder="Eg: 9987654329"
                        name="mobile"
                        value={values.mobile}
                        validateOnChange={true}
                        disabled={checkMobile}
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>

                {/* other details section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Other Details"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid label="Designation">
                      <InputField
                        id="designation"
                        type="text"
                        placeholder="Eg: CEO"
                        name="designation"
                        value={values.designation}
                        validateOnChange={true}
                      />
                    </ChildGrid>
                    <ChildGrid label="Employment Status">
                      <MuiSelect
                        id="employment_status"
                        select
                        name="employment_status"
                        placeholder={!values.employment_status && values.employment_status != "fresher" && "Please select"}
                        value={values.employment_status}
                        handleChange={(e) => {
                          setFieldValue("employment_status", e.target.value);
                          if (e.target.value == "fresher") {
                            setFieldValue("experience", "");
                          }
                        }}
                        selectOptions={employmentStatusArr}
                        props={{
                          onKeyPressCapture: () => handleKeyPress,
                        }}
                      />
                    </ChildGrid>
                    <ChildGrid label="Experienced">
                      <MuiSelect
                        id="experience"
                        select
                        placeholder={
                          !values.experience &&
                          values.experience !== 0 &&
                          "Select Year"
                        }
                        disabled={values.employment_status === "fresher"}
                        name="experience"
                        value={values.experience}
                        handleChange={(e) => {
                          setFieldValue("experience", e.target.value);
                        }}
                        selectOptions={experienceArr}
                        props={{
                          onKeyPressCapture: () => handleKeyPress,
                        }}
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>

                {/* address Section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Address"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid label="Pincode">
                      <MuiTextField
                        id="zip_code"
                        type="number"
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                        placeholder="Enter here"
                        name="zip_code"
                        value={values.zip_code}
                        onChange={(e) =>
                          handleChange(e, setFieldValue, setErrors)
                        }
                        onBlur={(e) =>
                          handleChange(e, setFieldValue, setErrors)
                        }
                        error={touched.zip_code && Boolean(errors.zip_code)}
                        helperText={
                          <FieldError errorString={errors.zip_code} />
                        }
                        inputProps={{
                          maxLength: 6,
                          inputProps: { min: 6, max: 6 },
                        }}
                      />
                    </ChildGrid>
                    <ChildGrid label="City">
                      <MuiTextField
                        id="city"
                        type="text"
                        placeholder="Enter here"
                        name="city"
                        value={values.city}
                        onChange={(e) =>
                          setFieldValue(
                            "city",
                            e.target.value.replace(/^\s+/, "")
                          )
                        }
                        error={touched.city && Boolean(errors.city)}
                        helperText={<FieldError errorString={errors.city} />}
                      />
                    </ChildGrid>
                    <ChildGrid label="State">
                      <MuiTextField
                        id="state"
                        type="text"
                        placeholder="Enter here"
                        name="state"
                        value={values.state}
                        onChange={(e) =>
                          setFieldValue(
                            "state",
                            e.target.value.replace(/^\s+/, "")
                          )
                        }
                        error={touched.state && Boolean(errors.state)}
                        helperText={<FieldError errorString={errors.state} />}
                      />
                    </ChildGrid>
                    <ChildGrid label="Country">
                      <MuiTextField
                        id="country"
                        type="text"
                        placeholder="Enter here"
                        name="country"
                        value={values.country}
                        onChange={(e) =>
                          setFieldValue(
                            "country",
                            e.target.value.replace(/^\s+/, "")
                          )
                        }
                        error={touched.country && Boolean(errors.country)}
                        helperText={<FieldError errorString={errors.country} />}
                      />
                    </ChildGrid>
                    <ChildGrid size="secondary" label="Address">
                      <MuiTextField
                        id="address"
                        type="text"
                        placeholder="Enter here"
                        name="address"
                        value={values.address}
                        onChange={(e) =>
                          setFieldValue(
                            "address",
                            e.target.value.replace(/^\s+/, "")
                          )
                        }
                        error={touched.address && Boolean(errors.address)}
                        helperText={<FieldError errorString={errors.address} />}
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>
              </Grid>
              <MuiDivider className="oh-details-divider" />
              <CustomLoadingButton
                loading={isSubmitting}
                type="submit"
                title={"Submit"}
                className="oh-submit-btn"
              />
            </Form>
          );
        }}
      </Formik>
    </ProfileLayout>
  );
};

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value.replace(/^\s+/, ""), true);
  };

  const error = (validateOnChange || meta.touched) && meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default FreelancerPersonal;
