import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik, useField, useFormikContext } from "formik";
//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//OTHER
import MuiTextField from "../../mui/TextField/MuiTextField";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import FieldError from "../../components/Errors/FieldError";
import { ForgotPasswordSchema } from "../../schemas/ForgotPasswordSchema";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/resetPasswordSlice";
import { Toaster } from "../../components/Toaster/Toaster";
import LoginLayout from "../Login/LoginLayout";
import { clearOtps } from "../../redux/verifyOtpSlice";

const ForgotConfirmPassword = () => {
  const [toggle_password, setToggle_password] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const location = useLocation();
  const { portalValue } = useSelector((state) => state.app);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState({
    password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();

  const togglePasswordHide = () => {
    setToggle_password((prev) => !prev);
  };

  const confirmTogglePwd = () => {
    setConfirmShow((prev) => !prev);
  };

  const handleSubmit = (values, setSubmitting) => {
    let data = {
      ...location?.state,
      new_password: values.password,
      password_confirmation: values.confirm_password,
    };
    dispatch(resetPassword(data))
      .then((res) => {
        setSubmitting(false);
        if (res.type === "resetPassword/fulfilled") {
          Toaster.TOAST(res?.payload?.message, "success");
          dispatch(clearOtps(null));
          navigate(`/login`
            // , { replace: true }
          ); // redirect to dashboard if in case we receive token in response
          // navigate(`/login`);
        }
      })
      .catch((err) => {
        console.log(err);
        Toaster.TOAST(err.message, "error");
        setSubmitting(false);
      });
  };

  return (
    <LoginLayout>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validateOnblur={false}
        enableReinitialize
        validationSchema={ForgotPasswordSchema}
      >
        {({ values, setErrors, isSubmitting }) => {
          return (
            <Form autoComplete="off">
              <Grid container xs={12} md={10} lg={10}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className="oh-heading">
                    <MuiTypography
                      className="oh-login-title"
                      content="Forgot Password"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box sx={{ display: "flex", flexWrap: "no-wrap" }}>
                    <MuiTypography
                      variant="span"
                      style={{
                        display: "inline-block",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "22.4px",
                        color: "var(--oh-black-300-color)",
                        width: "100%",
                        maxWidth: "fit-content",
                      }}
                    >
                      Enter new password and submit to change your Octahire account current password.
                    </MuiTypography>
                  </Box>
                </Grid>

                <Grid
                  className="oh-textfield-grid"
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <PrimaryLabel label="Password" />
                  <InputField
                    id="password"
                    type={toggle_password ? "text" : "password"}
                    placeholder="Enter your password"
                    name="password"
                    value={values.password}
                    validateOnChange={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          {" "}
                          {toggle_password ? (
                            <Visibility
                              className="cursor_pointer"
                              onClick={togglePasswordHide}
                            />
                          ) : (
                            <VisibilityOff onClick={togglePasswordHide} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  className="oh-textfield-grid"
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <PrimaryLabel label="Confirm Password" />
                  <InputField
                    id="confirm_password"
                    type={confirmShow ? "text" : "password"}
                    placeholder="Enter your confirm password"
                    name="confirm_password"
                    value={values.confirm_password}
                    validateOnChange={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          {" "}
                          {confirmShow ? (
                            <Visibility
                              className="cursor_pointer"
                              onClick={confirmTogglePwd}
                            />
                          ) : (
                            <VisibilityOff onClick={confirmTogglePwd} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  style={{ margin: "30px 0px" }}
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <CustomLoadingButton
                    loading={isSubmitting}
                    type="submit"
                    title={"Save"}
                    className="oh-submit-btn"
                  />
                </Grid>
                {!token && (
                  <Grid item xs={12} md={12} lg={12}>
                    <MuiTypography
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "26px",
                        textAlign: "center",
                        margin: "2px 0px 32px",
                        color: "var(--oh-blue-color)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/login"
                          // , { replace: true }
                        );
                      }}
                      content={`Back to Login`}
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </LoginLayout>
  );
};

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value, true);
  };

  const error = (validateOnChange || meta.touched) && meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default ForgotConfirmPassword;
