import * as Yup from "yup";
import {
  PINCODE_REGEX,
  EMAIL_REGEX,
  NUM_REGEX,
  FIRSTNAME_REGEX,
  LASTNAME_REGEX,
  MOBILE_NUMBER_REGEX_2,
} from "../../utils/regex";
import { errMessageMaximum, errMessageMinimum } from "../../utils";
const ADDRESS_REGEX = /^[a-zA-Z0-9\s,.'\/-]+$/;

export const PersonalDetail = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required.")
    .max(150, errMessageMaximum("First name", 150))
    .test(
      "check if  First name not empty",
      "First name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "First name must be valid."),
  alt_mobile_number: Yup.string()
    .matches(/^\d*$/, "Only numerical characters are allowed")
    .max(10, "Please enter valid 10 digit number")
    .required("Alternate Mobile number is required."),

  last_name: Yup.string()
    .required("Last name is required.")
    .max(150, errMessageMaximum("Last name", 150))
    .test(
      "check if Last name not empty",
      "Last name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(LASTNAME_REGEX, "Last name must be valid."),
  // email: Yup.string()
  //   .matches(EMAIL_REGEX, "Please enter a valid email Id")
  //   .max(40, errMessageMaximum("Email", 40)),
  mobile_number: Yup.string()
    .matches(/^\d*$/, "Only numerical characters are allowed")
    .max(10, "Please enter valid 10 digit number")
    .required("Mobile number is required."),
  designation: Yup.string().required("Designation is required."),
  // .max(60, errMessageMaximum("Designation", 60))
  // .test(
  //   "check if  Designation not empty",
  //   "Designation must be relevant.",
  //   (val) => {
  //     if (!val || val.trim().length === 0 || val.trim().length < 2) {
  //       return false;
  //     }
  //     return true;
  //   }
  // )
  // .matches(FIRSTNAME_REGEX, "Designation must be valid."),
  zip_code: Yup.string()
    .matches(PINCODE_REGEX, "Pincode must contain only numbers")
    .max(6, errMessageMaximum("Pincode", 6)),
  address: Yup.string()
    // .required("Address is required.")
    .max(150, "Address must be less than 150 characters")
    // .test("check if Address not empty", "Address must be relevant.", (val) => {
    //   return val && val.trim().length >= 2; // Check if the address is at least 2 characters long after trimming
    // })
    .matches(ADDRESS_REGEX, "Address must be valid."),
  // percentage: Yup.number().required("Percentage is required").min(1).max(100),
});
