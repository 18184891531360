import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

//MUI
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

//OTHER
import ExtrasTooltip from "../Tooltip/ExtrasTooltip";
import FilledChip from "../customChips/FilledChips";
import MuiTooltip from "../../mui/Tooltip/MuiTooltip";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import { returnSubstring } from "../../utils";
import MuiDivider from "../../mui/Dividers/MuiDivider";
import OutlinedChip from "../customChips/OutlinedChip";
import AirBnbIcon from "../../assets/images/Airbnb.svg";
import LocationIcon from "../../assets/images/location.svg";
import SuitcaseIcon from "../../assets/images/suitcaseIcon.svg";
import CurrencyRupeeIcon from "../../assets/images/ruppeeIcon.svg";
//CSS
import "./index.scss";
import SaveUnSaveJobs from "../SaveUnSave/SaveUnSaveJobs";

const JobPostCard = ({
  size = "medium-200",
  data,
  className = "",
  status = "",
  isLanding = false,
  handleNavigate = () => { },
  cardType = "dashboard",
  updateData = [],
  setUpdateData = () => { },
  setIsUpdate = () => { },
  getData = () => { }
}) => {
  const { portalName } = useSelector((state) => state.app);

  const locationArray = data?.locations || [];
  const hasNonNullCity = locationArray.some(location => location.city !== null);
  let displayedLocations = locationArray.slice(0, 2).map((elem) => elem.city);
  let remainingLocations = locationArray.slice(2).map((elem) => elem.city);
  let tooltipLocationText = locationArray.map((elem) => elem.city).join(", ");

  let count = locationArray?.length - 2;
  // Combine the first two locations and check their combined length
  const combinedLocationsLength = displayedLocations.join(", ").length;

  if (combinedLocationsLength > 10) {
    displayedLocations = locationArray
      .slice(0, 1)
      .map((elem) => elem.city).join(",");
    remainingLocations = locationArray.slice(0, 1).map((elem) => elem.city);
    count = count + 1;
    tooltipLocationText = locationArray.map((elem) => elem.city).join(", ");
  }

  const skillArray = data?.skills || [];
  let displayedSkills = skillArray.slice(0, 2).map((elem) => elem.name);
  let remainingSkills = skillArray.slice(2).map((elem) => elem.name);
  let tooltipText = skillArray.map((elem) => elem.name).join(", ");
  let skillCount = skillArray?.length - 2;
  // Combine the first two skills and check their combined length
  const combinedLength = displayedSkills.join(", ").length;
  const data_id = data.id;

  if (combinedLength > 25) {
    displayedSkills = skillArray.slice(0, 1).map((elem) => elem.name);
    remainingSkills = skillArray.slice(0, 1).map((elem) => elem.name);
    skillCount = skillCount + 1;
    tooltipText = skillArray.map((elem) => elem.name).join(", ");
  }

  function splitStringByLength(str, length) {
    const regex = new RegExp(`.{1,${length}}`, 'g');
    return str?.match(regex)?.join('\n');
  }
  return (
    <>
      <Box mb={cardType === "globalSuggestedJobs" ? 2 : 0} className="oh-job-post-card">
        <Box className="oh-post-card-body">
          <Box className="oh-card-header" onClick={handleNavigate} >
            {data?.employer?.profile_photo?.url && <img
              src={data?.employer?.profile_photo?.url || AirBnbIcon}
              alt={"logo"}
            />}
            <div className="oh-card-subheader">
              <span className="oh-title">
                <MuiTooltip
                  length={28}
                  title={data?.job_title}
                />
              </span>
              <MuiTypography
                content={
                  data?.organization_name ||
                  data?.employer?.organization_name ||
                  data?.category?.name
                } // category name will be removed once organization_name is added to the response
                className="oh-subtitle"
              />
              <MuiTypography
                content={`${moment(data?.created_at).fromNow()}`}
                className="oh-timestamp"
              />
            </div>
          </Box>
          <MuiDivider className="oh-divider" />
          <Box className="oh-card-body">

            <Box className="oh-req-box" onClick={handleNavigate} >
              <MuiTypography variant="span" className="oh-requirements">
                <img src={SuitcaseIcon} className="suitcase-icon" alt={"work-exp"} /> {data?.work_experience}
              </MuiTypography>

              <MuiTypography variant="span" className="oh-requirements">
                <img src={CurrencyRupeeIcon} className="rupee-icon" alt={"salary"} /> {data?.ctc}
              </MuiTypography>
            </Box>
            {
              displayedLocations?.length > 0 && hasNonNullCity ? (
                <MuiTypography className="oh-requirements" onClick={handleNavigate} >
                  <img src={LocationIcon} className="location-icon" alt={"locations"} />
                  <MuiTooltip
                    displayTitleOnly={returnSubstring(displayedLocations, 22)}
                    description={displayedLocations}
                  />

                  {count > 0 && (
                    <ExtrasTooltip
                      count={count}
                      tooltipText={tooltipLocationText}
                    />
                  )}
                </MuiTypography>)
                : null
            }
            <Box className="job-desc-box" onClick={handleNavigate}>
              <Typography
                className="oh-description"
                dangerouslySetInnerHTML={{ __html: splitStringByLength(data?.short_description, 30) }}
              >
              </Typography>
            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box className="oh-chip-box">
                {displayedSkills?.map((elem, idx) => {
                  return (
                    <>
                      <OutlinedChip
                        label={returnSubstring(elem, 25)}
                        key={idx}
                        size={size}
                        borderColor="var(--oh-gray-color)"
                        color="var(--oh-blue-300-color)"
                      />
                    </>
                  );
                })}
                {skillCount > 0 && (
                  <ExtrasTooltip
                    count={skillCount}
                    tooltipText={tooltipText}
                  />
                )}


              </Box>
              {
                (portalName === "Jobseeker" || isLanding) &&
                <SaveUnSaveJobs
                  saveData={data}
                  updateData={updateData}
                  setUpdateData={setUpdateData}
                  setIsUpdate={setIsUpdate}
                  getData={getData}
                />
                // <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} onClick={() => isSaved ? handleUnSaved() : handleSaved()}>
                //   {isSaved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                //   <MuiTypography content="Save" />
                // </Box>
              }
            </Box>
          </Box>

          {cardType === "globalSuggestedJobs" &&
            data?.application_status !== "applied" && (
              <Box
                className="oh-post-card-footer"
                display={"flex"}
                justifyContent={"space-between"}
                onClick={handleNavigate}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontWeight: 400,

                    width: "100%",
                    color: "var(--oh-blue-300-color)",
                  }}
                >
                  <FilledChip
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      cursor: "pointer",
                    }}
                    size="medium-300"
                    onClick={handleNavigate}
                    label={"Apply"}
                    color="var(--oh-blue-color)"
                  />
                </div>
              </Box>
            )}
        </Box>

        {portalName === "Employer" && cardType === "jobsCards" && (
          <Box
            className="oh-post-card-footer"
            display={"flex"}
            justifyContent={"space-between"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: 400,
                background: "var(--stock, #dee4ff)",
                width: "100%",
                color: "var(--oh-blue-300-color)",
                padding: "10px 12px"
              }}
            >
              <span style={{ fontWeight: 400, fontSize: "14px" }}>
                Applicants:{" "}
                <b
                  style={{
                    fontSize: "16px",
                    color: "var(--oh-blue-300-color)",
                  }}
                >
                  {" "}
                  {data?.applicants_count > 1000
                    ? "1000+"
                    : data?.applicants_count}
                </b>
              </span>
            </div>
          </Box>
        )}
      </Box >
    </>
  );
};
export default JobPostCard;
