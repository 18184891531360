import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosApi } from '../../apis/AxiosApi'
import { COMMUNICATION_DETAIL, EMPLOYER } from '../../apis/Constant/routes'

const initialState = {
    data: []
}

export const getDetailApi = createAsyncThunk(
    'communication_details/list',
    async (params, thunkApi) => {
        return await AxiosApi({
            path: `${EMPLOYER}/${params.id}${COMMUNICATION_DETAIL}`,
            type: 'GET'
        })
    }
)

export const addDetailApi = createAsyncThunk(
    'communication_details',
    async (params, thunkApi) => {
        return await AxiosApi({
            path: `${EMPLOYER}/${params.id}${COMMUNICATION_DETAIL}`,
            type: 'POST',
            data: JSON.stringify(params.data)
        })
    }
)

export const editDetailApi = createAsyncThunk(
    'communication_details/edit',
    async (params, thunkApi) => {
        return await AxiosApi({
            path: `${EMPLOYER}/${params.id}${COMMUNICATION_DETAIL}/${params.dataId}`,
            type: 'PUT',
            data: JSON.stringify(params.data)
        })
    }
)

export const deleteDetailApi = createAsyncThunk(
    'communication_details/delete',
    async (params, thunkApi) => {
        return await AxiosApi({
            path: `${EMPLOYER}/${params.id}${COMMUNICATION_DETAIL}/${params.dataId}`,
            type: 'DELETE'
        })
    }
)

export const createDetail = createSlice({
    name: 'communication-detail',
    initialState,
    reducer: {
        addDetail: (state, action) => {
            state.data = action.payload
        }
    }
})

export default createDetail.reducer;
export const { addDetail } = createDetail.actions;
