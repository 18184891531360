import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../apis/AxiosApi";
import { AUTHS, FB_AUTH_URL, FB_OMNI_AUTH } from "../apis/Constant/routes";

const initialState = {
  shortListedCandidates: [],
  allCandidates: [],
  placedCandidates: [],
  ACTPage: 1,
  ACTRowCount: null,
  ACTFilter: "all",
  ACTPageSize: 10,
  ACTLoader: false,
  showMessage: false
};

export const getAllCandidates = createAsyncThunk(
  "getAllCandidates",
  async (id, thunkapi) => {
    const {
      ACTFilter: filter,
      ACTPage: page,
      ACTPageSize: pageSize,
    } = thunkapi.getState().tableData;

    const pageHeader = `?page=${page}&per_page=${pageSize}`;
    const idHeader = id ? `&job_post_id=${id}` : "";
    const filterHeader =
      filter === "all"
        ? ""
        : filter === "asc" || filter === "desc"
          ? `&search[sort_attribute]=email&search[sort_type]=${filter}`
          : `&${filter}=true`;
    return await AxiosApi({
      path: `/job_posts/all_candidates${pageHeader}${idHeader}${filterHeader}`,
      type: "GET",
      params: "",
    });
  }
);

export const getPlacedCandidates = createAsyncThunk(
  "getPlacedCandidates",
  async () => {
    return await AxiosApi({
      path: `/job_posts/placed_candidates`,
      type: "GET",
      params: "",
    });
  }
);
export const getShortlistedCandidates = createAsyncThunk(
  "getShortlistedCandidates",
  async () => {
    return await AxiosApi({
      path: `/job_posts/selected_candidates`,
      type: "GET",
      params: "",
    });
  }
);
export const tableDataSlice = createSlice({
  name: "tableData",
  initialState,
  reducers: {
    setPlacedCandidate: (state, action) => {
      state.placedCandidates = action.payload;
    },
    setAllCandidates: (state, action) => {
      state.allCandidates = action.payload;
    },
    setShortlistedCandidates: (state, action) => {
      state.shortListedCandidates = action.payload;
    },
    setACTPage: (state, action) => {
      state.ACTPage = action.payload;
    },
    setACTRowCount: (state, action) => {
      state.ACTRowCount = action.payload;
    },
    setACTFilter: (state, action) => {
      state.ACTFilter = action.payload;
    },
    setACTLoader: (state, action) => {
      state.ACTLoader = action.payload;
    },
    setShowMessage: (state, action) => {
      state.showMessage = action.payload; // set dynamically to true/false based on the payload
    },

  },
});

export const {
  setPlacedCandidate,
  setAllCandidates,
  setShortlistedCandidates,
  setACTPage,
  setACTRowCount,
  setACTFilter,
  setACTLoader,
  setShowMessage,
} = tableDataSlice.actions;
export default tableDataSlice.reducer;
