import { ClickAwayListener } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";

const ExtrasTooltip = ({ tooltipText, remainingItems, count }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableTouchListener
        leaveDelay={0}
        componentsProps={{
          tooltip: {
            sx: {
              background: "var(--oh-blue-300-color)",
              "& .MuiTooltip-arrow": {
                color: "var(--oh-blue-300-color)",
              },
              fontWeight: 400,
              textTransform: "capitalize",
              padding: "8px 20px",
              fontSize: "14px",
              cursor: "pointer",
              lineHeight: "160%",
              textAlign: "center",
            },
          },
        }}
        arrow
        //   className="skills-tooltip-content"
        title={
          <span
            style={{
              height: "100%",
              minHeight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 400,
              whiteSpace: "pre-line",
              // textTransform: "capitalize",
            }}
          >
            {tooltipText}
          </span>
        }
      >
        <span
          style={{ marginLeft: "5px", cursor: "pointer" }}
          onMouseEnter={handleTooltipOpen}
          onMouseLeave={handleTooltipClose}
          onClick={handleTooltipToggle}
        >
          +{remainingItems?.length || count}
        </span>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default ExtrasTooltip;
