import { Stack } from "@mui/material";
import React from "react";
import NoJobsFound from "../NotFound/NoJobsFound";

const NoCandidatesDataInTable = () => {
    return (
        <Stack
            height="100%"
            alignItems="center"
            justifyContent="center"
        >
            <NoJobsFound value={"No data available"} applicants={true} />
        </Stack>
    )
}
export default NoCandidatesDataInTable;