
import * as Yup from 'yup'
import { EMAIL_REGEX, EMPTY_SPACES, PHONE_REGEX, STRONGPASSWORD_REGEX } from '../utils/regex'
import { errMessageMaximum, errMessageMinimum } from '../utils'

export const ForgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      STRONGPASSWORD_REGEX,
      'Password must be at least one uppercase, lowercase, special character and number'
    ).matches(
      EMPTY_SPACES,
      'Password can not contain empty spaces.'
    )
    .max(40, "Password can not be more than 40 characters")
    .min(8, "Password must be atleast 8 characters long!")
    .required("Password can't be empty")
    ,
    confirm_password: Yup.string()
    .oneOf([Yup.ref('password')], 'Confirm Password do not match')
    .required("Confirm Password can't be empty").matches(
      EMPTY_SPACES,
      'Password can not contain empty spaces.'
    )
})

export const ForgotPasswordEmailSchema = Yup.object().shape({
   fieldValue: Yup.string().test(
    'isValidEmailOrNumber',
    '',
    function (value) {
      if (!value) {
        return this.createError({ message: 'Required fields can not be empty!' });
      }
      // Check if the input contains an email address
      // if (/^\S+@\S+\.\S+$/.test(value)) {
        if (EMAIL_REGEX.test(value)) {
        if (Yup.string().email().isValidSync(value)) {
          return true; // Valid email
        } 
        
      }
      return this.createError({ message: 'Please enter a valid Email ID' });
      // // Check if the input contains a 10-digit number
      // if (PHONE_REGEX.test(value)) {
      //   return true; // Valid 10-digit number
      // }
      // return this.createError({ message: 'Invalid input. Please enter a valid email address or a 10-digit number.' });
    }
  )

});
