import React, { useState } from "react";

// MUI
import Box from "@mui/material/Box";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

// OTHER
import Sidebar from "../../components/Sidebar/Sidebar";
import MuiBreadcrumb from "../../mui/BreadCrumbs/MuiBreadcrumbs";
import CustomProfileNavigation from "../../components/Drawer/NavigationDrawer/CustomProfileNavigation";
import Header from "../../components/Header/Header";
import FilledChip from "../../components/customChips/FilledChips";
import DefaultProfilePicture from "../../assets/images/default_profile_picture.svg";
import EditIcon from "@mui/icons-material/Edit";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import { setProfilePhoto, updateUserProfile } from "../../redux/profileSlice";
import { setUserData } from "../../redux/authSlice";

// CSS
import "../Layouts/layout.scss";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "../../components/Toaster/Toaster";
import moment from "moment";
import { orgTypeArr } from "../../Data";

const ProfileLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { user } = useSelector(state => state.auth);
  const { profilePhoto } = useSelector((state) => state.profile);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("data[profile_photo]", file);
      let data = { formData: formData, id: user?.id };
      if (user?.id) {
        dispatch(updateUserProfile(data))
          .then((res) => {
            if (res.type === "updateUserProfile/fulfilled") {
              Toaster.TOAST(res.payload.message, "success");
              let user_data = user;
              user_data = { ...user, profile_photo: res.payload.data.profile_photo }
              dispatch(setUserData(user_data));
              localStorage.setItem("login_data", JSON.stringify(user_data));
              dispatch(
                setProfilePhoto({
                  profilePhoto: res.payload.data.profile_photo,
                })
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };


  return (
    <>
      <Header />
      <Box container className="oh-profile-background-setting">
        <Sidebar className="primary-filter-navigation-bar" profile={true} />
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
            <MuiBreadcrumb />
            <FilledChip
              className="oh-profile-trigger-btn"
              onClick={() => setIsNavOpen((prev) => !prev)}
              cursor={true}
              size="medium-200"
              label={<span className="profile-btn-txt">{"Profile"}</span>}
              icon={<MenuOpenIcon />}
              color="var(--oh-blue-color)"
            />
          </Box>
          <Box className="oh-details-section">
            <Box className="oh-profile-info-box">
              <Box className="oh-profile-box">
                <div className="profile-info-div">

                  <img
                    className="profile-picture"
                    // src={DefaultProfilePicture}
                    src={profilePhoto?.profilePhoto || profilePhoto || DefaultProfilePicture}
                    alt={""}
                  />
                  <div className="user-details">


                    {(user?.organization_name || user?.first_name) && (
                      <MuiTypography
                        content={
                          user?.organization_name ||
                          `${user?.first_name} ${user?.last_name}`
                        }
                        className="user-name"
                      />
                    )}
                    {(user?.personal_details?.city ||
                      user?.personal_details?.designation || user?.personal_details?.organization_type) && (
                        <MuiTypography
                          content={
                            user?.type === "Employer"
                              ? user?.personal_details?.city ||
                              user?.personal_details?.organization_type &&
                              orgTypeArr.find(opt => opt.value === user?.personal_details?.organization_type)?.label
                              : user?.personal_details?.designation
                          }
                          className="user-location"
                        />
                      )}
                    {user?.personal_details?.created_at && (
                      <MuiTypography
                        content={`Updated at - ${moment(
                          user?.personal_details?.updated_at
                        ).format("MMM DD YYYY, h:mm a")}`}
                        className="user-last-update"
                      />
                    )}
                  </div>
                </div>

              </Box>
              <span className="profile-picture-parent">
                <label
                  htmlFor="upload-button"
                  // onClick={handleImageChange}
                  // className="profile-picture-parent"
                  className="change-text"
                >
                  <EditIcon />
                </label>
              </span>

              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Box>
            {children}
          </Box>
        </Box>
      </Box>
      <CustomProfileNavigation
        open={isNavOpen}
        onClose={() => setIsNavOpen(false)}
      />
    </>
  );
};

export default ProfileLayout;
