import React, { useState } from "react";
// MUI
import Box from "@mui/material/Box";
// import CallIcon from "@mui/icons-material/Call";
import CallIcon from "../../../assets/images/call.svg";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

// OTHER
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import MuiRating from "../../../mui/Rating/MuiRating";
import FilledChip from "../../customChips/FilledChips";

// CSS
import "./index.scss";

const PrimaryCard = ({ hiringAssistData }) => {
  const [showPopup, setShowPopup] = useState(false);

  const wordTruncate = (str, limit) => {
    if (str?.length <= limit) {
      return str;
    } else {
      return str?.slice(0, limit) + "...";
    }
  };

  const handlePhoneClick = () => {
    if (window.innerWidth <= 600) {
      window.location.href = `tel: ${hiringAssistData?.phone_number}`;
    } else {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleCall = () => {
    window.location.href = `tel: ${hiringAssistData?.phone_number}`;
  };

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(hiringAssist?attributes?.phone_number);
  // };

  return (
    <>
      <Box className="oh-hiring-box">
        <Box className="oh-imgContent-box">
          <Box className="assistant-img-parent">
            <img
              src={hiringAssistData?.profile_photo}
              alt={`${hiringAssistData?.first_name} ${hiringAssistData?.last_name}`}
            />
          </Box>
          <Box className="person-detail">
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <MuiTypography
                className="space-between person-name"
                content={`${hiringAssistData?.first_name} ${hiringAssistData?.last_name}`}
                style={{ letterSpacing: 0 }}
              />
            </Box>

            <MuiTypography
              className="space-between person-address"
              content={wordTruncate(hiringAssistData?.experience, 39)}
            />
            <MuiTypography
              className="space-between person-email"
              content={hiringAssistData?.email}
            />
            <MuiTypography
              className="space-between person-contact"
              content={hiringAssistData?.phone_number}
            />
            <MuiTypography className="space-between person-rating">
              <MuiRating
                label={hiringAssistData?.ratings}
                value={hiringAssistData?.ratings}
              />
            </MuiTypography>
          </Box>
        </Box>
        <Box className="call-chip-parent" sx={{ display: "flex", margin: "8px 4px", cursor: "pointer" }}>
          <FilledChip
            // icon={<CallIcon />}
            icon={<img src={CallIcon} alt="call" />}
            label={"Call"}
            size="medium-100"
            color="var(--oh-blue-color)"
            onClick={() => handlePhoneClick()}
            className="call-chip-filled"
          />
        </Box>
      </Box>

      {/* Popup */}
      <Modal
        open={showPopup}
        onClose={handleClosePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          zIndex: '9999',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: '500px',
            border: "none",
            outline: 'none',
          }}
        >
          <h3 id="modal-modal-title" style={{ color: "var(--oh-blue-300-color)" }}>Call at {hiringAssistData?.phone_number}</h3>
          <Button sx={{
            background: "var(--oh-white-300-color)",
            border: "1px solid var(--oh-white-300-color)",
            fontWeight: 700, fontSize: "14px", minWidth: "100px",
            padding: "10px"
          }} onClick={handleCall}>Call</Button>
        </Box>
      </Modal>
    </>
  );
};

export default PrimaryCard;
