import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//OTHER
import axios from "axios";
import { clearAuthStorage, setUserData } from "../../redux/authSlice";
import { clearOtps } from "../../redux/verifyOtpSlice";
import ConfirmationDialog from "../../components/Dialog/ComfirmationDialog";
import { setPortalInfo } from "../../redux/globalSlice";
import { returnRoleValue } from "../../utils";
import { setProfilePhoto } from "../../redux/profileSlice";
import { orgTypeArr } from "../../Data";
import MuiTextField from "../../mui/TextField/MuiTextField";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import FieldError from "../../components/Errors/FieldError";
import { CompanyInfoSchema } from "../../schemas/CompanyInfoSchema";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import { companyInfo } from "../../redux/companyInfoSlice";
import { Toaster } from "../../components/Toaster/Toaster";
import MuiSelect from "../../mui/TextField/MuiSelect";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

//CSS
import "./index.scss";

const CompanyInfoForm = () => {
  const navigate = useNavigate();
  const { portalValue } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const [openConfirmationPopup, setComfirmationPopup] = useState(false);

  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState({
    organization_name: user?.personal_details?.organization_name || "",
    organization_type: user?.personal_details?.organization_type || "",
    website: user?.personal_details?.website_url || "",
    employee_count_range: user?.personal_details?.employee_count_range || "",
    pincode: user?.personal_details?.address?.zip_code || "",
    city: user?.personal_details?.address?.city || "",
    state: user?.personal_details?.address?.state || "",
    address: user?.personal_details?.address?.address || "",
  });

  const handleBackNavigation = () => {
    dispatch(clearOtps());
    setComfirmationPopup(false);
    dispatch(clearAuthStorage());
    dispatch(setProfilePhoto(null));
    dispatch(
      setPortalInfo({
        portalName: "Jobseeker",
        portalValue: returnRoleValue("Jobseeker"),
      })
    );
    navigate("/login");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("loginBtn").click();
    }
  };

  const handleSubmit = (values, setSubmitting) => {
    let data = {
      organization_name: values.organization_name,
      organization_type: values.organization_type,
      website_url: values.website,
      employee_count_range: values.employee_count_range,
      address_attributes: {
        zip_code: values.pincode,
        address: values.address,
        city: values.city,
        state: values.state,
      },
    };

    setSubmitting(false);

    if (user?.id && user?.type) {
      dispatch(companyInfo({ data: data, userId: user.id }))
        .then((res) => {
          if (res.type === "companyInfo/fulfilled") {
            Toaster.TOAST(res?.payload?.message, "success");
            let user_data = res.payload.data.attributes[portalValue];
            user_data = {
              ...user_data,
              personal_details: res.payload.data.attributes.personal_details,
            };

            localStorage.setItem("login_data", JSON.stringify(user_data));
            dispatch(setUserData(user_data));
            navigate(`/${portalValue}`);
          }
        })
        .catch((err) => {
          console.log(err);
          Toaster.TOAST(err.message, "error");
        });
    } else {
      Toaster.TOAST("User id not available!", "error");
    }
  };

  const handleChange = (e, setFieldValue, setErrors) => {
    const newPincode = e.target.value;
    setFieldValue("pincode", newPincode);

    if (newPincode.length === 6) {
      // Make API call
      axios
        .get(`https://api.postalpincode.in/pincode/${newPincode}`)
        .then((response) => {
          // Handle response data here
          console.log(response.data);
          if (response.data[0].PostOffice) {
            setFieldValue("city", response.data[0].PostOffice[0].District);
            setFieldValue("state", response.data[0].PostOffice[0].State);
            setErrors({ pincode: "" });
          } else if (response.data[0].Message === "No records found") {
            setFieldValue("city", "");
            setFieldValue("state", "");
            setErrors({ pincode: "Enter a valid pincode" });
          }
        })
        .catch((error) => {
          // Handle errors here
          setErrors({ pincode: "Enter a valid pincode" });
          console.error("Error fetching pincode data:", error);
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validateOnblur={false}
        enableReinitialize
        validationSchema={CompanyInfoSchema}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          touched,
          errors,
          setErrors,
        }) => {
          return (
            <Form className="oh-signup-form" autoComplete="off">
              <Grid container xs={12} md={12} lg={11}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className="oh-heading oh-form2-heading">
                    <MuiTypography
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "26px",
                        textAlign: "center",
                        margin: "10px 0px 12px",
                        color: "var(--oh-blue-color)",
                        cursor: "pointer",
                      }}
                      content={
                        <span
                          onClick={() => setComfirmationPopup(true)}
                          style={{
                            display: "flex",
                            fontSize: "18px",
                            justifyContent: "flex-start",
                            alignContent: "center",
                            gap: "8px",
                          }}
                        >
                          <ArrowCircleLeftIcon
                            sx={{ svg: { fontSize: "24px" } }}
                          />
                          Back
                        </span>
                      }
                    />
                    <MuiTypography
                      className="oh-signup-title"
                      content="Create Account"
                    />
                    <MuiTypography
                      className="oh-signup-subtitle"
                      content={`Company Info`}
                    />
                  </Box>
                </Grid>
                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel
                    required={true}
                    label="Organization Name (as per KYC documents)"
                  />
                  <MuiTextField
                    id="organization_name"
                    type="text"
                    placeholder="Enter here"
                    name="organization_name"
                    value={values.organization_name}
                    error={
                      touched.organization_name &&
                      Boolean(errors.organization_name)
                    }
                    helperText={
                      <FieldError errorString={errors.organization_name} />
                    }
                    onChange={(e) =>
                      setFieldValue("organization_name", e.target.value)
                    }
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel required={true} label="Organization Type" />

                  <MuiSelect
                    className="new"
                    id="organization_type"
                    select
                    name="organization_type"
                    value={values.organization_type}
                    placeholder={!values.organization_type && values.organization_type !== "private_limited_company" && "Please select"}
                    error={
                      touched.organization_type &&
                      Boolean(errors.organization_type)
                    }
                    helperText={
                      <FieldError errorString={errors.organization_type} />
                    }
                    handleChange={(e) => {
                      setFieldValue("organization_type", e.target.value);
                    }}
                    selectOptions={orgTypeArr}
                    props={{
                      onKeyPressCapture: () => handleKeyPress,
                    }}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Website" />
                  <MuiTextField
                    id="website"
                    type="text"
                    placeholder="Enter here"
                    name="website"
                    value={values.website}
                    onChange={(e) => setFieldValue("website", e.target.value)}
                    error={touched.website && Boolean(errors.website)}
                    helperText={<FieldError errorString={errors.website} />}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Employee Count Range" />

                  <MuiSelect
                    id="count_rage"
                    select
                    name="count_rage"
                    value={values.employee_count_range}
                    placeholder={!values.employee_count_range && values.employee_count_range !== "10-15" && "Please select"}
                    onChange={(e) =>
                      setFieldValue("employee_count_range", e.target.value)
                    }
                    error={
                      touched.employee_count_range &&
                      Boolean(errors.employee_count_range)
                    }
                    helperText={
                      <FieldError errorString={errors.employee_count_range} />
                    }
                    handleChange={(e) => {
                      setFieldValue("employee_count_range", e.target.value);
                    }}
                    selectOptions={[
                      { label: "10-15", value: "10-15" },
                      { label: "16-49", value: "16-49" },
                      { label: "50-100", value: "50-100" },
                      { label: "101-200", value: "101-200" },
                      { label: "201-500", value: "201-500" },
                      { label: "501 and above", value: "501 and above" },
                    ]}
                    props={{
                      onKeyPressCapture: () => handleKeyPress,
                    }}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Pincode" />
                  <MuiTextField
                    id="pincode"
                    type="number"
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                    placeholder="Enter here"
                    name="pincode"
                    InputProps={{
                      inputProps: { min: 6 },
                    }}
                    value={values.pincode}
                    onChange={(e) => handleChange(e, setFieldValue, setErrors)}
                    error={touched.pincode && Boolean(errors.pincode)}
                    helperText={<FieldError errorString={errors.pincode} />}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="City" />
                  <MuiTextField
                    id="city"
                    type="text"
                    placeholder="Enter here"
                    name="city"
                    value={values.city}
                    onChange={(e) => setFieldValue("city", e.target.value)}
                    error={touched.city && Boolean(errors.city)}
                    helperText={<FieldError errorString={errors.city} />}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="State" />
                  <MuiTextField
                    id="state"
                    type="text"
                    placeholder="Enter here"
                    name="state"
                    value={values.state}
                    onChange={(e) => setFieldValue("state", e.target.value)}
                    error={touched.state && Boolean(errors.state)}
                    helperText={<FieldError errorString={errors.state} />}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Address" />
                  <MuiTextField
                    id="address"
                    type="text"
                    placeholder="Enter here"
                    name="address"
                    value={values.address}
                    onChange={(e) => setFieldValue("address", e.target.value)}
                    error={touched.address && Boolean(errors.address)}
                    helperText={<FieldError errorString={errors.address} />}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <CustomLoadingButton
                    loading={isSubmitting}
                    type="submit"
                    title={"Complete"}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {
        <ConfirmationDialog
          heading={
            "Going back will expire your session! You have to login again..."
          }
          popupState={openConfirmationPopup}
          setPopupState={setComfirmationPopup}
          handleYes={handleBackNavigation}
        />
      }
    </>
  );
};

export default CompanyInfoForm;
