import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//  MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// ASSETS
import SupportBG from "../../assets/images/support_bg.svg";
import SupportInfoBG from "../../assets/images/support_infobg.svg";

// OTHER
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import PrimaryCard from "../../components/Cards/HiringAssist/PrimaryCard";
import { getHiringAssistance } from "../../redux/Employer/HiringAssistanceSlice";
import EmployerLayout from "../Layouts/Employer/EmployerLayout";

// CSS
import "../../Styles/hiringAssist.scss";

const HiringAssistance = () => {
  const dispatch = useDispatch();
  const hiringAssistance = useSelector((state) => state?.hiringAssistance?.hiring?.data);

  useEffect(() => {
    dispatch(getHiringAssistance());
  }, []);

  return (
    <>
      <EmployerLayout showRightSection={false}>
        <Grid className="employer-hiring-info-page" container pt={2} spacing={2}>
          <Grid item xs={12} md={6} lg={6} className="oh-employer-hiring">
            <Box className="oh-box oh-box-primary">
              <img className="overlay-supportbg" src={SupportInfoBG} alt="" />
              <MuiTypography content="24 x 7 Support number +91 90399-93007" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className="oh-employer-hiring">
            <Box className="oh-box oh-box-secondary">
              <img className="overlay-supportbg" src={SupportBG} alt="" />
              <a href="mailto:support@octahire.com">support@octahire.com</a>
              <span style={{ padding: "0px 10px" }}>|</span>
              <a href="mailto:info@octahire.com">info@octahire.com</a>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12} className="oh-employer-box">
            <MuiTypography
              className="oh-employer-heading"
              content="Assistance Contact Information"
            />
          </Grid>
          {hiringAssistance?.length > 0 &&
            hiringAssistance?.map(hiringAssist => {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    className="assistance-card-info"
                    key={hiringAssist?.attributes?.id}
                  >
                    <PrimaryCard hiringAssistData={hiringAssist?.attributes} />
                  </Grid>
                </>
              );
            })}
        </Grid>



      </EmployerLayout>


    </>
  );
};

export default HiringAssistance;
