import React, { useRef } from "react";
//MUI
import Box from "@mui/material/Box";

//OTHER
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";

//CSS
import "./index.scss";
import { TextField } from "@mui/material";

const DrawerFooter = ({
    className = "",
    placeholder = "Doc, DOCx, PDF | Max 2mb"
}) => {
    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            console.log('Selected File:', selectedFile);
        }
    };
    return (

        <Box className={`${className} oh-box-upload`}>
            <TextField
                type="file"
                fullWidth
                inputRef={fileInputRef}
                onChange={handleFileChange}
                className="upload-textfeild"
                label={placeholder}
                InputProps={{
                    startAdornment: (
                        <MuiTypography
                            className="upload-btn"
                            content={"Upload Resume"}
                            onClick={handleUploadClick}
                        />
                    ),
                }}
                InputLabelProps={{
                    shrink: false,
                    style: {
                        color: "#8c8da3",
                        fontSize: "14px",
                        textAlign: "center",
                        paddingLeft: "50px",
                        width: "100%",
                        display: "block",
                    },
                }}
            />
        </Box>
    )
}

export default DrawerFooter;