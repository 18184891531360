import WorkOffIcon from '@mui/icons-material/WorkOff';
// import { notFoundImage } from "../../assets";
import Stack from "@mui/material/Stack";
import "./index.scss";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const NoJobsFound = ({ value, showImage = true, height = null, applicants = false }) => {
    return (
        <Stack
            width="100%"
            height={height}
            display={'flex'}
            alignItems="center"
            justifyContent="center"
        >
            <div className="notFound">
                {showImage && (<div className="notFoundImageHolder">
                    {applicants ? <PeopleAltIcon className="notFoundImage" /> : <WorkOffIcon className="notFoundImage" />}
                </div>)}
                <h5
                    className="text-center"
                    style={{ color: "var(--oh-blue-300-color)" }}
                >
                    {value}
                </h5>
            </div>
        </Stack>
    );
};

export default NoJobsFound;
