import React, { useEffect, useState } from "react";
import { Form, Formik, useField, useFormikContext } from "formik";

//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";

//ASSETES
import Male from "../../../../assets/images/male.svg";
import Female from "../../../../assets/images/female.svg";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

//OTHER
import DrawerBody from "../DrawerBody/DrawerBody";
import CustomLoadingButton from "../../../../components/Loader/LoadingButton";
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../../Labels/PrimaryLabel";
import MuiTextField from "../../../../mui/TextField/MuiTextField";
import MuiTextFieldSelect from "../../../../mui/TextField/MuiTextFieldSelect";
import MuiDeletableChip from "../../../../mui/Chip/MuiDeletableChip";
import UploadResume from "../../../Upload/UploadResume";
import MuiDivider from "../../../../mui/Dividers/MuiDivider";
import {
  CandidateSchema,
  UpdateCandidateSchema,
} from "../../../../schemas/Freelancer/CandidateSchema";

// CSS
import "./index.scss";
import FieldError from "../../../Errors/FieldError";
import {
  getCategoriesData,
  getSkillsData,
} from "../../../../redux/JobSeeker/filterApis";
import MuiSelect from "../../../../mui/TextField/MuiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  addFreelancerCv,
  clearIndividualFreelancerData,
  setCandidateDetailsInitialData,
  updateFreelancerCv,
} from "../../../../redux/Freelancer/freelancerDetailsSlice";
import { Toaster } from "../../../Toaster/Toaster";
import {
  getFreelancerActiveCandidateData,
  setFreelancerActiveCandidateData,
} from "../../../../redux/Freelancer/freelancerActiveCandidatesSlice";
import { IconButton } from "@mui/material";
import moment from "moment";
import { downloadResumeApi } from "../../../../redux/JobSeeker/PersonalDetailSlice";
import {
  getFreelancerPlacedCandidateData,
  setFreelancerPlacedCandidateData,
} from "../../../../redux/Freelancer/freelancerPlacedCandidatesSlice";
import { freelancerExperienceArr } from "../../../../Data";
import { handleDownload } from "../../../../utils";
import ReactSelect from "../../../../reactSelect/ReactSelect";

const AddCandidateDrawer = ({
  openAddDrawer,
  onClose = () => { },
  title = null,
  setPage,
  setRowCount,
  id,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.freelancerDetailData.individualFreelancerData
  );
  const isUpdateProfile = useSelector(
    (state) => state.freelancerDetailData.isUpdateCandidateProfile
  );
  const isActiveCandidate = useSelector(
    (state) => state.freelancerDetailData.isActiveCandidate
  );
  const initialData = useSelector(
    (state) => state.freelancerDetailData.initialData
  );
  const [categories, setCategories] = useState([]);
  const [skillsOpt, setSkillsOpt] = useState([]);
  const [showResume, setShowResume] = useState(false);

  useEffect(() => {
    getCategories();
    getSkills();
  }, []);

  const handleClose = () => {
    dispatch(clearIndividualFreelancerData());
    setShowResume(false);
    onClose();
  };
  useEffect(() => {
    if (data && isUpdateProfile) {
      dispatch(
        setCandidateDetailsInitialData({
          fname: data?.first_name,
          lname: data?.last_name,
          designation: data?.designation,
          experience: data?.experience,
          category: data?.categories?.map((elem) => {
            return {
              id: String(elem.id),
              label: elem.name,
              value: elem.id,
            };
          }),
          skills: data?.skills?.map((elem) => {
            return {
              id: String(elem.id),
              label: elem.name,
              value: elem.id,
            };
          }),
          resume: data?.resume?.url,
          suggest: data?.suggestion_box,
          gender: data?.gender,
        })
      );
      if (data?.resume?.url) {
        setShowResume(true);
      }
    }
  }, [data]);

  const getCategories = () => {
    dispatch(getCategoriesData())
      .then((res) => {
        if (res.type === "getCategoriesData/fulfilled") {
          const Data = res.payload.data || [];
          const categories = Data.map((cat) => {
            return {
              id: cat.id,
              label: cat.attributes.name,
              value: cat.attributes.id,
            };
          });
          setCategories(categories);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getSkills = () => {
    dispatch(getSkillsData())
      .then((res) => {
        if (res.type === "getSkillsData/fulfilled") {
          const Data = res.payload.data || [];
          const skills = Data.map((cat) => {
            return {
              id: cat.id,
              label: cat.attributes.name,
              value: cat.attributes.id,
            };
          });
          setSkillsOpt(skills);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const handleSubmit = (values, setSubmitting, resetForm) => {
    let formData = new FormData();
    formData.append("data[gender]", values.gender);
    formData.append("data[first_name]", values.fname);
    formData.append("data[last_name]", values.lname);
    formData.append("data[designation]", values.designation);
    formData.append("data[experience]", values.experience);
    formData.append("data[suggestion_box]", values.suggest);
    values.skills?.map((el, index) => {
      formData.append("data[skill_ids][]", el.value);
    });
    values.category.map((el, index) => {
      formData.append("data[category_ids][]", el.value);
    });
    if (!isUpdateProfile || !data?.resume?.url) {
      formData.append("data[resume]", values.resume);
    }

    let apiEndPoint = isUpdateProfile
      ? updateFreelancerCv({ data: formData, id: data?.id })
      : addFreelancerCv({ data: formData });
    dispatch(apiEndPoint)
      .then((res) => {
        if (
          res.type === isUpdateProfile
            ? "updateFreelancerCv/fulfilled"
            : "addFreelancerCv/fulfilled"
        ) {
          Toaster.TOAST(res.payload.message, "success");
          isActiveCandidate
          ? getFreelancerActiveCandidates()
          : getFreelancerPlacedCandidates();
          handleClose();
          resetForm();
          setPage(1);
        } else if (res.type === isUpdateProfile
          ? "updateFreelancerCv/rejected"
          : "addFreelancerCv/rejected") {
            Toaster.TOAST(res.payload.message, "error");
          console.log("error", res);
        }
      })
      .catch((err) => err);
    console.log(isUpdateProfile, "isUpdateProfile");
    setSubmitting(false);

    //api calling
  };

  const getFreelancerActiveCandidates = () => {
    dispatch(getFreelancerActiveCandidateData())
      .then((res) => {
        if (res.type === "getFreelancerActiveCandidateData/fulfilled") {
          dispatch(setFreelancerActiveCandidateData(res.payload.data));
          setRowCount(res?.payload?.meta?.total_records);

        }
      })
      .catch((err) => console.log(err));
  };

  const getFreelancerPlacedCandidates = () => {
    dispatch(getFreelancerPlacedCandidateData()).then((res) => {
      if (res.type === "getFreelancerPlacedCandidateData/fulfilled") {
        dispatch(setFreelancerPlacedCandidateData(res.payload.data));
        setRowCount(res?.payload?.meta?.total_records);
      }
    });
  };

  const ChildGrid = ({
    children,
    size = "secondary",
    label = null,
    required = false,
  }) => {
    let gridSize = {};
    switch (size) {
      case "secondary":
        gridSize = { xs: 12, md: 6, lg: 6 };
        break;
      default:
        gridSize = { xs: 12, md: 12, lg: 12 };
        break;
    }

    return (
      <Grid className="oh-form-field" item {...gridSize}>
        {label && (
          <PrimaryLabel
            labelType="secondary"
            label={label}
            required={required}
          />
        )}
        {children}
      </Grid>
    );
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("loginBtn").click();
    }
  };

  //   const onChangePicture = (e) => {
  //     let imagData = e.target.files[0];
  //     let size = 2000002;
  //     const allowedExtensions = ["jpg", "png"];
  //     const allowedMimeTypes = ["image/jpeg", "image/png"];
  //     const extension = imagData?.name.split(".").pop().toLowerCase();
  //     const mimeType = imagData?.type.toLowerCase();
  //     if (
  //       allowedExtensions.includes(extension) &&
  //       allowedMimeTypes.includes(mimeType)
  //     ) {
  //       if (imagData.size < size) {
  //         setImgDatatemp(true);
  //         if (e.target.files[0]) {
  //           base64FileURL(e.target.files[0], (obj) => {
  //             setImgurl(e.target.files[0]);
  //           });
  //           const reader = new FileReader();
  //           reader.addEventListener("load", () => {
  //             setImgData(reader.result);
  //           });
  //           reader.readAsDataURL(e.target.files[0]);
  //         }
  //       } else {
  //         Toaster.TOAST("Image size more than 2 Mb not accepted.", "error");
  //         if (!imgData) {
  //           setImgData(false);
  //           setImgDatatemp(false);
  //         }
  //       }
  //     } else {
  //       Toaster.TOAST("Please select valid image (PNG or JPEG ).", "error");
  //       if (!imgData) {
  //         setImgData(false);
  //         setImgDatatemp(false);
  //       }
  //     }
  //   };

  return (
    <DrawerBody
      data={data}
      open={openAddDrawer}
      onClose={handleClose}
      isFooter={false}
      title={title}
      id={id}
    >
      {/* form */}
      <Formik
        initialValues={initialData}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          handleSubmit(values, setSubmitting, resetForm);
        }}
        validationSchema={
          isUpdateProfile ? UpdateCandidateSchema : CandidateSchema
        }
        validateOnblur={false}
        enableReinitialize={true}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          setFieldError,
          isSubmitting,
          dirty,
        }) => {
          return (
            <Form className="oh-signup-form" autoComplete="off">
              <Box className="oh-add-candidate">
                {/* avtar */}
                <Box className="oh-profile-header">
                  {console.log(values, "...... values")}
                  <span onClick={() => setFieldValue("gender", "Male")}>
                    {console.log(values.gender, ".... values.gender")}
                    <img
                      src={Male}
                      alt="male"
                      style={{ marginRight: "12px", cursor: "pointer" }}
                      className={values.gender === "Male" ? "avtaar-img-addCv" : "avtaar-img-default"}
                    />
                    {/* <input
                      data-testid="change-pic"
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                    /> */}
                    <MuiTypography className="label" content={"Male"} />
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setFieldValue("gender", "Female")}
                  >
                    <img
                      src={Female}
                      alt="female"
                      className={
                        values.gender === "Female" ? "avtaar-img-addCv" : "avtaar-img-default"
                      }
                    />
                    <MuiTypography className="label" content={"Female"} />
                  </span>
                </Box>
                <Grid container mt={2}>
                  <ChildGrid label="First Name" required={true}>
                    <InputField
                      id="fname"
                      type="text"
                      placeholder="Eg: John"
                      name="fname"
                      value={values.fname}
                      validateOnChange={true}
                    />
                  </ChildGrid>
                  <ChildGrid label="Last Name" required={true}>
                    <InputField
                      id="lname"
                      type="text"
                      placeholder="Eg: Doe"
                      name="lname"
                      value={values.lname}
                      validateOnChange={true}
                    />
                  </ChildGrid>
                  <ChildGrid label="Designation" required={true}>
                    <InputField
                      id="designation"
                      type="text"
                      placeholder="Eg: Sr. Java Developer"
                      name="designation"
                      value={values.designation}
                      validateOnChange={true}
                    />
                  </ChildGrid>
                  <ChildGrid label="Years of Experiences" required={true}>
                    <MuiSelect
                      id="experience"
                      select
                      name="experience"
                      value={values.experience}
                      handleChange={(e) => {
                        setFieldValue("experience", e.target.value);
                      }}
                      placeholder={
                        !values?.experience &&
                        values?.experience !== 0 &&
                        "Please select experience"
                      }
                      selectOptions={freelancerExperienceArr}
                      props={{
                        onKeyPressCapture: () => handleKeyPress,
                      }}
                    />
                  </ChildGrid>
                  <ChildGrid size="primary" label="Categories" required={true}>
                    <ReactSelect
                        name="category"
                        options={categories || []}
                        placeholder="Eg: Non IT"
                        value={values?.category}
                        onChange={(e, newValue) => {
                          let categoryArr = values?.category || [];
                          if (newValue?.action === "select-option") {
                            const updatedCategory = [...categoryArr, newValue?.option];
                            setFieldValue("category", updatedCategory);
                          } else if (newValue?.action === "remove-value" || newValue?.action === "pop-value") {
                            const updatedCategory = categoryArr.filter(elem => elem !== newValue?.removedValue);
                            setFieldValue("category", updatedCategory);
                          } else {
                            setFieldValue("category", []);
                          }
                        }}
                      />
                      <FieldError
                      sx={{
                        margin: "5px 16px",
                        fontSize: "13px",
                        fontWeight: 500
                      }}
                          errorString={
                            touched.category && errors.category
                          }
                        />
                  </ChildGrid>
                  <ChildGrid size="primary" label="Key Skills" required={true}>
                    <ReactSelect
                          name="skills"
                          options={skillsOpt || []}
                          placeholder={"Eg: HTML"}
                          value={values?.skills || []}
                          onChange={(e, newValue) => {
                            let skillsArr = values?.skills || [];
                            if (newValue?.action === "select-option") {
                              const updatedSkills = [...skillsArr, newValue?.option];
                              setFieldValue("skills", updatedSkills);
                            } else if (newValue?.action === "remove-value" || newValue?.action === "pop-value") {
                              const updatedSkills = skillsArr.filter(elem => elem !== newValue?.removedValue);
                              setFieldValue("skills", updatedSkills);
                            } else {
                              setFieldValue("skills", []);
                            }
                          }}
                        />
                        <FieldError
                        sx={{
                          margin: "5px 16px",
                          fontSize: "13px",
                          fontWeight: 500
                        }}
                          errorString={
                            touched.skills && errors.skills
                          }
                        />
                  </ChildGrid>

                  <ChildGrid size="primary">
                    <MuiTypography
                      className="resume-heading"
                      content="Resume"
                    />
                    {data?.resume?.url && showResume ? (
                      <Box
                        sx={{
                          border: "1px solid var(--oh-white-300-color)",
                          minWidth: "60%",
                          background: "var(--oh-white-color)",
                          maxWidth: "500px",
                          padding: "10px",
                          display: "flex",
                          borderRadius: "4px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            lineHeight: "20.5px",
                            fontSize: "14px",
                            fontWeight: "700",
                            color: "#445386",
                          }}
                        >
                          <p style={{ letterSpacing: "0.4px" }}>
                            {" "}
                            {data?.resume_name || "Resume Doc"}
                          </p>
                         {data?.updated_at && <span
                            style={{
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "var(--oh-gray-color)",
                            }}
                          >
                            Uploaded on {moment(data.updated_at).fromNow()}
                          </span>}
                        </div>
                        <span style={{ display: "flex", gap: "0px 5px"}}>
                        <DownloadIcon
                          onClick={() =>
                            handleDownload(data?.resume?.url, data?.resume_name)
                          }
                          sx={{
                            cursor: "pointer",
                            color: "var(--oh-blue-300-color)",
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            padding: "5px",
                            background: "var(--oh-white-300-color)",
                            svg: {
                              fontSize: "20px",
                            },
                          }}
                        />
                        <DeleteOutlineIcon
                          onClick={() => {
                            setFieldValue("resume", "");
                            setShowResume(false);
                          }}
                          sx={{
                            cursor: "pointer",
                            color: "var(--oh-blue-300-color)",
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            padding: "5px",
                            background: "var(--oh-white-300-color)",
                            svg: {
                              fontSize: "20px",
                            },
                          }}
                        />
                        </span>
                      </Box>
                    ) : (
                      <UploadResume
                        setFieldValue={setFieldValue}
                        setShowResume={setShowResume}
                        value={values?.resume}
                        showDownloadBtn={true}
                      />
                    )}
                  </ChildGrid>
                </Grid>
                <Box display={"flex"} className="oh-suggest-box">
                  <Checkbox
                    defaultChecked={values.suggest}
                    name="suggest"
                    checked={values.suggest}
                    onChange={(e) => {
                      setFieldValue("suggest", e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <MuiTypography
                    className="oh-suggest"
                    content={"Suggest Related Job Profiles to this CV"}
                  />
                </Box>
                <span>
                  {touched.suggest && Boolean(errors.suggest)}
                  <FieldError errorString={touched.suggest && errors.suggest} />
                </span>
                <MuiDivider className="oh-details-divider" />
                <Box className="oh-submit-box">
                  <CustomLoadingButton
                    loading={isSubmitting}
                    type="submit"
                    title={isUpdateProfile ? "Update" : "Submit"}
                    className="oh-submit-btn"
                    style={!dirty ? { opacity: 0.5 } : null}
                    disabled={!dirty ? true : false}
                  />
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </DrawerBody>
  );
};

function InputField({
  param,
  InputProps,
  name,
  validateOnChange = false,
  ...rest
}) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value, true);
  };

  const error = (validateOnChange || meta.touched) && meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        param={param}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default AddCandidateDrawer;
