import React from "react";
import { Swiper } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

//OTHER
import { large, medium, medium_100, medium_200, medium_300, medium_400, small, top_hiring } from "./config/breakpoints";

//CSS
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";
import "./index.scss";

const GenericSwiper = ({
  children,
  configSize = "large",
  totalPages = 5, // Set the total number of pages
}) => {
  const customBreakPoints =
    configSize === "large"
      ? large
      : configSize === "medium"
        ? medium
        : configSize === "medium_100"
          ? medium_100
          : configSize === "medium_200"
            ? medium_200
            : configSize === "medium_300"
              ? medium_300
              : configSize === "medium_400"
                ? medium_400 
                : configSize === "top_hiring" 
                  ? top_hiring
                    : small;
  return (
    <span className="oh-swiper">
      <Swiper
        spaceBetween={10}
        slidesPerView={2.2}
        speed={2000}
        className="swiper-slider-main"
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            // Render only five bullets
            if (index < totalPages) {
              return `<span class="${className}"></span>`;
            }
            // Return an empty string for non-rendered bullets
            return "";
          },
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Pagination, Autoplay, Navigation]}
        onSlideChange={() => { }}
        onSwiper={(swiper) => { }}
        breakpoints={customBreakPoints}
      >
        {children}
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </Swiper>
    </span>
  );
};

export default GenericSwiper;
