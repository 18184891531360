import * as Yup from 'yup'
import { EMAIL_REGEX, EMPTY_SPACES, NUM_REGEX, STRONGPASSWORD_REGEX } from '../utils/regex'
import { errMessageMaximum, errMessageMinimum } from '../utils'

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL_REGEX, 'Please enter a valid email Id')
    .max(55, errMessageMaximum('Email', 55))
    .required("Email can't be empty"),
  password: Yup.string()
    .matches(
      STRONGPASSWORD_REGEX,
      'Password must be at least one uppercase, lowercase, special character and number'
    )
    .matches(
      EMPTY_SPACES,
      'Password can not contain empty spaces.'
    )
    .max(40, "Password can not be more than 40 characters")
    .min(8, "Password must be atleast 8 characters long!")
    .required("Password can't be empty"),
  mobile: Yup.string()
    .matches(NUM_REGEX, 'Please enter a valid Mobile number')
  // .required("Mobile number can't be empty"),
})
