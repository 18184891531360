import React, { useEffect, useState } from "react";
//MUI
import Box from "@mui/material/Box";
// OTHER
import CardHeader from "./Components/CardHeader";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import MuiButtonContained from "../../../mui/Buttons/MuiButtonContained";
import Skills from "./Components/Skills";
import ApplicationStatus from "./Components/ApplicationStatus";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  jobApply,
  showAppliedJob,
} from "../../../redux/JobSeeker/jobSeekerJobsSlice";
import { Typography } from "@mui/material";
import { Toaster } from "../../../components/Toaster/Toaster";
import moment from "moment";
import {
  checkIfHtml,
  cleanJobDescription,
  parseHTML,
  returnSubstring,
  truncateDescription,
} from "../../../utils";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import { setFillProfileFirst } from "../../../redux/globalSlice";
import FillProfileDialogBox from "../../../components/Dialog/FillProfileDialogBox";
import GlobalJobSeekerLayout from "../../Layouts/JobSeeker/GlobalJobSeekerLayout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoBox from "../../../components/OtherInfoForDetails";
import "./index.scss";
import SocialSharing from "../../../components/SocialSharing/SocialSharing";
import { Helmet } from "react-helmet";

const GlobalJobDetails = () => {
  const { search, pathname } = useLocation();
  const portal = localStorage.getItem("portal");
  const loginData = JSON.parse(localStorage.getItem("login_data"));
  const token = localStorage.getItem("token");
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobData, setJobData] = useState();
  const [showFullDescription, setShowFullDescription] = useState(false);

  const { fillProfileFirstPopup } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);

  const jobDetailsData = jobData?.attributes;
  const skillsData = jobDetailsData?.skills.map((elem) => elem?.name);
  const shareUrl = `${pathname}${search ? search : ""}`;

  const metaTitle = jobDetailsData?.designation;
  const metaDescription = jobDetailsData?.job_description;

  useEffect(() => {
    showJobPostDetail();
  }, [params?.id]);

  const showJobPostDetail = () => {
    dispatch(showAppliedJob(params?.id))
      .then((res) => {
        if (res?.type === "showAppliedJob/fulfilled") {
          setJobData(res?.payload?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDescription = () => {
    showFullDescription && window.scrollTo(0, 0);
    setShowFullDescription(!showFullDescription);
  };

  const handleClose = () => {
    dispatch(setFillProfileFirst(false));
  };

  const handleSubmitBasedOnProfile = () => {
    if (user?.profile_complete) {
      handleApplyJob();
      dispatch(setFillProfileFirst(false));
    } else {
      dispatch(setFillProfileFirst(true));
    }
  };

  const handleApplyJob = () => {
    console.log("job applied");
    const data = {
      data: { job_post_id: params?.id },
    };
    dispatch(jobApply({ data }))
      .then((res) => {
        if (res?.error) throw new Error("Something went wrong!");
        Toaster.TOAST(res?.payload?.message, "success");
        showJobPostDetail();
        console.log("res", res);
      })
      .catch((err) => {
        Toaster.TOAST(err?.message, "error");
        console.log(err.message, ".err");
      });
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content={shareUrl} />
      </Helmet>
      <GlobalJobSeekerLayout searchParam={search}>
        <Box className="oh-job-details-container">
          <CardHeader
            jobData={jobData}
            updateData={jobData}
            setUpdateData={setJobData}
          />
          <MuiDivider className="oh-divider" />
          {jobDetailsData?.application_status === "applied" ||
            jobDetailsData?.application_status === "viewed" ||
            jobDetailsData?.application_status === "reviewed" ? (
            <>
              <ApplicationStatus title="Application Status" />
              <MuiDivider className="oh-divider" />
            </>
          ) : null}
          <h3 style={{ margin: "20px 0 10px" }}>Job Description</h3>

          {jobDetailsData?.job_description &&
            (checkIfHtml(jobDetailsData?.job_description) ? (
              <div
                // className={'card-short-descr common-txt-style'}
                className="job-description-employer"
                style={{
                  maxHeight: showFullDescription ? "none" : "200px",
                  overflow: "hidden",
                  transition: "all 0.5s ease-in", // Smooth transition with ease effect
                }}
                dangerouslySetInnerHTML={parseHTML(
                  showFullDescription
                    ? jobDetailsData?.job_description
                    : truncateDescription(jobDetailsData?.job_description, 200)
                )}
              />
            ) : (
              <MuiTypography
                // className={'card-short-descr common-txt-style'}
                className="job-description-employer"
                style={{
                  maxHeight: showFullDescription ? "none" : "200px",
                  overflow: "hidden",
                  transition: "all 0.5s ease-in", // Smooth transition with ease effect
                }}
                content={
                  showFullDescription
                    ? jobDetailsData?.job_description
                    : returnSubstring(jobDetailsData?.job_description, 200)
                }
              />
            ))}

          {/* Render the "View More"/"View Less" button */}
          {jobDetailsData?.job_description &&
            jobDetailsData?.job_description.length > 200 && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  className="oh-more-view"
                  onClick={toggleDescription}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  {showFullDescription ? (
                    <Box display={"flex"} alignItems={"center"}>
                      <KeyboardArrowUpIcon />
                      View Less
                    </Box>
                  ) : (
                    <Box display={"flex"} alignItems={"center"}>
                      <KeyboardArrowDownIcon />
                      View More
                    </Box>
                  )}
                </Typography>
              </Box>
            )}

          <h3 style={{ margin: "30px 0 0px" }}>Skills</h3>
          <Skills size="medium-300" skillsData={skillsData} />

          <MuiDivider className="oh-divider" />

          <Box style={{ padding: "30px 0 0" }}>
            <h3>Other Details</h3>
            <InfoBox
              items={[
                { label: "Min. Education Required", value: jobDetailsData?.education?.education_name },
                { label: "Min. Work Experience Required", value: jobDetailsData?.work_experience },
                { label: "Work Mode", value: jobDetailsData?.work_mode },
                { label: "No. of vacancies", value: jobDetailsData?.no_of_vacancies },
                { label: "CTC Offered", value: jobDetailsData?.ctc ? `₹ ${jobDetailsData?.ctc}` : "" },
                { label: "Designation", value: jobDetailsData?.designation },
              ]}
            />
          </Box>

          <MuiDivider className="oh-secondary-divider" />

          {search === "?from=landingPage" &&
            portal === "Jobseeker" &&
            token &&
            loginData?.registration_complete &&
            jobDetailsData?.application_status ? (
            <Box sx={{ mt: 2, textAlign: "start" }}>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ fontSize: "13px", fontStyle: "italic" }}
              >
                You have already applied for this job on ${moment(
                  jobDetailsData?.applied_at
                ).format("MMM DD,YYYY")}
              </Typography>
            </Box>
          ) : search === "?from=landingPage" &&
            portal === "Jobseeker" &&
            token &&
            loginData?.registration_complete ? (
            <Box sx={{ width: "300px", mt: 2 }}>
              <MuiButtonContained
                title="Apply"
                onClick={handleSubmitBasedOnProfile}
              // onClick={() => handleApplyJob()}
              />
            </Box>
          ) : search === "?from=landingPage" &&
            (portal === "Jobseeker" ||
              portal === "Freelancer" ||
              portal === "Employer") &&
            !token &&
            !loginData?.registration_complete ? (
            <Box sx={{ width: "300px", mt: 2 }}>
              <MuiButtonContained
                title="Login / register to apply"
                onClick={() => navigate("/login")}
              />
            </Box>
          ) : (
            ""
          )}
          <SocialSharing shareUrl={shareUrl}
            metaTitle={metaTitle}
            metaDescription={cleanJobDescription(jobDetailsData?.job_description)}
          />
          {/* <Box display="flex" justifyContent="flex-end" gap={"10px"} mt={1} >
            {shareConfig.map(({ Component, Icon, props }, index) => (
              <Component key={index} {...props}>
                <Icon size={32} round />
              </Component>
            ))}
          </Box> */}
        </Box>

      </GlobalJobSeekerLayout>
      {fillProfileFirstPopup && (
        <FillProfileDialogBox
          heading={`Please fill your profile to continue applying to this job!`}
          popupState={fillProfileFirstPopup}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default GlobalJobDetails;
