import React, { useState } from "react";

//MUI
import Box from "@mui/material/Box";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapVertIcon from '@mui/icons-material/SwapVert'


//OTHER
import MuiTypography from "../../mui/Typography/MuiTypograpghy";

//OTHER
import MuiIconChip from "../../mui/Chip/IconChip";
import Filter from "../Filter/Filter";

//CSS
import "./index.scss";
import FilterIconWithBadge from "../../pages/Employer/FilterIconWithBadge";

const TertiaryWrapper = ({
  children = null,
  content = null,
  onClick = () => { },
  showBtnOptions = true,
  sx = {},
  variant = "p",
  showSortOption = false,
  sortFilterBy = "asc",
  sortFilterValue = "asc",
  isFilterActive = false,
  handleSortClick = () => { },
}) => {
  const [popupState, setPopupState] = useState("");

  const handlePopupState = (popupToHandle = "") => {
    return setPopupState(popupToHandle);
  };

  return (
    <>
      <Box sx={sx} className="oh-tertiary-wrapper">
        <Box className="wrapper-header">
          <MuiTypography variant={variant} content={content} className={"oh-heading"} />
          {showBtnOptions && (
            <Box display={"flex"} gap={"0px 5px"} alignItems={"center"}>
              <MuiIconChip
                icon={<FilterIconWithBadge icon={<FilterAltIcon sx={{ width: '16px', transform: "translateX(8px)" }} />} isFilterActive={isFilterActive} />}
                label="Filter"
                onClick={() => handlePopupState("filter")}
                className="oh-iconchip-box"
              />
              {
                showSortOption && (
                  <MuiIconChip
                    style={{
                      cursor: "pointer"
                    }}
                    icon={<FilterIconWithBadge icon={<SwapVertIcon sx={{ width: '16px', transform: "translateX(8px)" }} />} filter={sortFilterBy} value={sortFilterValue} />}
                    label="Sort"
                    onClick={handleSortClick}
                    className="oh-iconchip-box"
                  />
                )
              }
            </Box>
          )}
        </Box>
        {children}
      </Box>
      <Filter onClose={handlePopupState} open={popupState === "filter"} />
    </>
  );
};

export default TertiaryWrapper;
