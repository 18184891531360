import React, { useEffect, useState } from "react";
// MUI
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";

// OTHER
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";

// CSS
import "../../../Styles/Global.scss";
import "./index.scss";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import {
  globalNotificationsData,
  readNotifications,
  setNotifications,
} from "../../../redux/notificationsSlice";
import { useDispatch, useSelector } from "react-redux";

const NotificationDrawer = ({ isNotifyOpen, handleNotifyClose }) => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.globalNotifications);
  const { token } = useSelector((state) => state.auth);
  const [isNotificationData, setIsNotificationData] = useState(false);
  let previousTimestamp = "";

  const notificationsData = () => {
    dispatch(globalNotificationsData())
      .then((res) => {
        if (res.type === "globalNotificationsData/fulfilled") {
          const Data = res?.payload?.data?.data || [];
          dispatch(setNotifications(Data));
          setIsNotificationData(true);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };
  function splitDataByTime(data) {
    const postedAt = data?.posted_at;
    if (postedAt?.includes("hours") || postedAt?.includes("minutes")) {
      return "Today";
    } else if (postedAt === "yesterday") {
      return "Yesterday";
    } else {
      return "Past Notifications";
    }
  }

  useEffect(() => {
    if (token) {
      notificationsData();
    }
  }, [isNotificationData]);

  const handleReadNotifications = (id) => {
    dispatch(readNotifications(id))
      .then((res) => {
        if (res.type === "readNotifications/fulfilled") {
          notificationsData();
        }
      })
      .catch((err) => console.log(err));
  };

  const RenderTimeStamp = (data) => {
    const timestamp = splitDataByTime(data);
    const compareTimestamp = previousTimestamp !== timestamp;
    previousTimestamp = timestamp;
    return (
      compareTimestamp && (
        <>
          <MuiDivider
            label={timestamp}
            textAlign="left"
            className="oh-divider"
          />
        </>
      )
    );
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        sx={{
          ".MuiBackdrop-root": {
            background: "rgba(0, 0, 0, 0.15)",
          },
        }}
        PaperProps={{
          style: {
            border: "1px solid var(--oh-white-300-color)",
            borderRadius: 0,
            marginTop: "4.2rem",
            padding: "5px 10px",
            maxWidth: "420px",
            width: "100%",
          },
        }}
        open={isNotifyOpen}
        onClose={() => handleNotifyClose()}
      >
        <Box className="oh-notify-heading">
          <MuiTypography className="heading-title" content="Notifications" />
          <CloseIcon
            className="close-icon"
            onClick={() => handleNotifyClose()}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 130px) !important",
            overflowY: "auto",
          }}
          className="oh-notification-listing"
        >
          {notifications?.length > 0 &&
            notifications?.map((item, idx) => {
              const data = item?.attributes;
              return (
                <>
                  {RenderTimeStamp(data)}
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      gap: "5px",
                      padding: "10px 10px",
                      lineHeight: "15.2px",
                      marginBottom: "2px",
                      cursor: data?.read ? "default" : "pointer",
                      background: data?.read
                        ? "var(--oh-white-color)"
                        : "var(--oh-white-300-color)",
                      borderTop:
                        idx !== 0
                          ? "1px solid var(--oh-white-300-color)"
                          : "none",
                    }}
                    onClick={() => handleReadNotifications(data.id)}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        color: "var(--oh-blue-300-color)",
                        lineHeight: "22.2px",
                      }}
                    >
                      {data?.message}
                    </span>
                    <span
                      style={{
                        textAlign: "right",
                        fontSize: "10px",
                        fontWeight: 400,
                        color: "var(--oh-blue-300-color)",
                      }}
                    >
                      {data?.posted_at}
                    </span>
                  </Box>
                </>
              );
            })}

          {notifications?.length == 0 && (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
              className="not-found-box"
              sx={{ height: "100%", width: "100%" }}
            >
              <MuiTypography content="No notifications found" />
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default NotificationDrawer;
