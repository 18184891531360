import { CircularProgress, Stack } from "@mui/material";
import "./index.scss";

const GlobalLoader = ({ minHeight = "150px"}) => {

    return (
        <Stack
            sx={{
                pointerEvents: "none",
                position: "relative",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 10,
                overflow: 'hidden'
            }}
            width="100%"
            minHeight={minHeight}
            display={'flex'}
            alignItems="center"
            justifyContent="center"

        >
            <CircularProgress />
        </Stack>
    )
}

export default GlobalLoader;