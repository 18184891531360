import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
//MUI
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

// OTHER
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import {
  clearOtps,
  setCountDownReset,
  setEmailOtp,
  setMobileOtp,
  setTimerState,
  verifyBothOtps,
} from "../../redux/verifyOtpSlice";
import { Toaster } from "../../components/Toaster/Toaster";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import CountdownTimer from "./Timer";
import LoginLayout from "../Login/LoginLayout";
import { resendBothOtps } from "../../redux/ResendBothOtpsSlice";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";

//CSS
import "./index.scss";
import { clearAuthStorage, setUserData } from "../../redux/authSlice";
import ConfirmationDialog from "../../components/Dialog/ComfirmationDialog";
import { setPortalInfo } from "../../redux/globalSlice";
import { returnRoleValue } from "../../utils";
import { setProfilePhoto } from "../../redux/profileSlice";

const VerifyEmailNumberOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email_otp, mobile_otp, timerOn } = useSelector(
    (state) => state.verifyOtp
  );
  const { user, token } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [openConfirmationPopup, setComfirmationPopup] = useState(false);

  useEffect(() => {
    dispatch(clearOtps());
    if (user?.otp_verified) {
      navigate("/signup-proceed");
    }
  }, [user]);

  const handleBackNavigation = () => {
    dispatch(clearOtps());
    setComfirmationPopup(false);
    dispatch(clearAuthStorage());
    dispatch(setProfilePhoto(null));
    dispatch(
      setPortalInfo({
        portalName: "Jobseeker",
        portalValue: returnRoleValue("Jobseeker"),
      })
    );
    navigate("/login");
  };
  const handleVerification = () => {
    setLoader(true);
    if (email_otp
      //  && mobile_otp
    ) {
      setLoader(false);
      let data = {
        email: user?.email,
        mobile_number: user?.mobile_number,
        email_otp: parseInt(email_otp),
        mobile_otp: 123456,
      };
      dispatch(verifyBothOtps(data))
        .then((res) => {
          setLoader(false);
          if (res.type === "verifyBothOtps/fulfilled") {
            Toaster.TOAST(res?.payload?.message, "success");
            let user_data = { ...user, otp_verified: true };
            dispatch(setUserData(user_data)); // to test
            localStorage.setItem("login_data", JSON.stringify(user_data));
            navigate("/signup-proceed", {
              // state: { email: user.email, mobile_number: user.mobile_number, email_otp: parseInt(email_otp), mobile_otp: parseInt(mobile_otp) },
              // replace: true,
            });
          } else if (res.type === "verifyBothOtps/rejected") {
            dispatch(clearOtps(null));
          }
        })
        .catch((err) => {
          setLoader(false);
          dispatch(clearOtps(null));
          Toaster.TOAST(err.message, "error");
          console.log(err);
        });
    } else {
      Toaster.TOAST("Please fill the required fields!", "error");
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const handleResendOtp = () => {
    if (timerOn) {
      return;
    }
    dispatch(
      resendBothOtps({ email: user?.email, mobile_number: user?.mobile_number })
    )
      .then((res) => {
        if (res.type === "resendBothOtps/fulfilled") {
          Toaster.TOAST(res?.payload?.message, "success");
          dispatch(setTimerState(true));
          dispatch(setCountDownReset());
          dispatch(clearOtps(null));
        }
      })
      .catch((err) => {
        Toaster.TOAST(err?.message, "error");
        console.log(err);
      });
  };

  return (
    <>
      <LoginLayout otpSection={true}>
        <Grid container xs={12} md={10} lg={10}>
          <Grid item xs={12} md={12} lg={12}>
            <Box className="oh-heading">
              <MuiTypography
                className="oh-login-title"
                content="OTP Verification"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ display: "flex", flexWrap: "no-wrap" }}>
              <MuiTypography
                variant="span"
                style={{
                  padding: "4px 0px",
                  display: "inline-block",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22.4px",
                  color: "var(--oh-black-300-color)",
                  width: "100%",
                  maxWidth: "fit-content",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                We have sent the code verification to your email address
                <b className="email-no-white-space" style={{ whiteSpace: "nowrap" }}> {user?.email} </b>
                {/* and
                mobile number
                <b style={{ whiteSpace: "nowrap" }}>
                  {" "}
                  {user?.mobile_number}{" "}
                </b>{" "} */}
              </MuiTypography>
            </Box>
          </Grid>

          <Grid
            sx={{
              margin: "34px 0px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            className="oh-textfield-grid"
            item
            xs={12}
            md={12}
            lg={12}
          >
            <PrimaryLabel label="Enter email otp here" required={true} />
            <OtpInput
              value={email_otp}
              onChange={(e) => dispatch(setEmailOtp(e))}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              containerStyle={{
                gap: "0px 14px",
              }}
              inputType="tel"
              className="oh-otp-input"
              inputStyle={"oh-input-otp-box"}
            />
          </Grid>

          {/* will be uncommented once OTP services are integrated */}
          {/* <Grid
            sx={{
              margin: "34px 0px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            className="oh-textfield-grid"
            item
            xs={12}
            md={12}
            lg={12}
          >
            <PrimaryLabel
              label="Enter mobile number otp here"
              required={true}
            />
            <OtpInput
              value={mobile_otp}
              onChange={(e) => dispatch(setMobileOtp(e))}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              containerStyle={{
                gap: "0px 14px",
              }}
              inputType="tel"
              className="oh-otp-input"
              inputStyle={"oh-input-otp-box"}
            />
          </Grid> */}

          <Grid
            className="otp-footer-section"
            style={{ margin: "30px 0px 10px 0px" }}
            item
            xs={12}
            md={12}
            lg={12}
          >
            <CustomLoadingButton
              loading={loader}
              disabled={
                loader
                // || !email_otp || !mobile_otp || email_otp?.length !== 6 || mobile_otp?.length !== 6
              }
              title={"Verify"}
              onClick={() => handleVerification()}
            />
          </Grid>
          <Grid className="otp-footer-section" item xs={12} md={12} lg={12}>
            <CountdownTimer />
          </Grid>
          <Grid className="otp-footer-section" item xs={12} md={12} lg={12}>
            <Box className="resend-div">
              <MuiTypography
                variant="span"
                style={{
                  display: "inline-block",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22.4px",
                  color: "var(--oh-black-300-color)",
                  width: "100%",
                  fontFamily: "Roboto, sans-serif",
                  maxWidth: "fit-content",
                  marginRight: "8px",
                }}
                content="If you didn't receive a code!"
              />
              <span
                style={{
                  display: "inline-block",
                  maxWidth: "fit-content",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "22.4px",
                  color: timerOn
                    ? "var(--oh-gray-300-color)"
                    : "var(--oh-blue-color)",
                  width: "100%",
                  fontFamily: "Roboto, sans-serif",
                  cursor: timerOn ? "default" : "pointer",
                }}
                onClick={() => handleResendOtp()}
              >
                Resend
              </span>
            </Box>
          </Grid>

          <Grid className="otp-footer-section" item xs={12} md={12} lg={12}>
            <MuiTypography
              style={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "26px",
                textAlign: "center",
                margin: "20px 0px 32px",
                color: "var(--oh-blue-color)",
                cursor: "pointer",
              }}
              onClick={() => setComfirmationPopup(true)}
              // onClick={handleBackNavigation}
              // content={`Log Out`}
              content={<span style={{ display: "flex", fontSize: "18px", justifyContent: "center", alignContent: "center", gap: "8px" }}>
                <ArrowCircleLeftIcon sx={{ svg: { fontSize: "24px" } }} />
                Back
              </span>}
            />
          </Grid>
        </Grid>
      </LoginLayout>
      {
        <ConfirmationDialog
          heading={
            "Going back will expire your session! You have to login again..."
          }
          popupState={openConfirmationPopup}
          setPopupState={setComfirmationPopup}
          handleYes={handleBackNavigation}
        />
      }
    </>
  );
};

export default VerifyEmailNumberOtp;
