import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
//ASSETS
import logo from "../../../assets/images/logo.svg";
import humburgerIcon from "../../../assets/images/humburgerIcon.svg";
import profile from "../../../assets/images/default_profile_picture.svg";
//OTHER
import CustomNavigation from "../../../components/Drawer/NavigationDrawer/CustomNavigation";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import MuiButtonContained from "../../../mui/Buttons/MuiButtonContained";
import CustomNavbar from "../../../components/Header/Navbar";
import ProfileDrawer from "../../../components/Drawer/ProfileDrawer/CustomProfile";
//CSS
import "../../../components/Header/index.scss";
import "./index.scss";

const MuiAppBar = styled(AppBar)({
  backgroundColor: "#fff",
  color: "#1A2946",
  padding: "4px 40px",
  borderBottom: "1px solid var(--oh-white-300-color)",
});

const LandingPageHeader = () => {
  const { profilePhoto } = useSelector((state) => state.profile);
  const { notifications } = useSelector((state) => state.globalNotifications);
  const navigate = useNavigate();

  let unreadCount;

  const unreadNotifications = notifications?.filter(
    (notification) => !notification?.attributes?.read
  );
  unreadCount = unreadNotifications?.length;

  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleProfileClose = () => {
    setProfileOpen(false);
  };

  const handleNavigate = () => {
    navigate("/");
  };

  const { token } = useSelector((state) => state.auth);
  const userData = useSelector((state) => state.auth);
  const lsToken = localStorage.getItem("token");
  const lsUserData = localStorage.getItem("login_data");

  const handleLoginNavigation = () => {
    if (
      (token &&
        userData?.user?.otp_verified &&
        (userData?.user?.registration_complete === undefined ||
          userData?.user?.registration_complete === false)) ||
      (lsToken &&
        lsUserData?.otp_verified &&
        (lsUserData?.registration_complete === undefined ||
          lsUserData?.registration_complete === false))
    ) {
      navigate("/signup-proceed");
    } else {
      navigate("/login");
    }
  };

  const [isProfileOpen, setProfileOpen] = useState(false);
  const handleProfileDrawer = () => {
    setProfileOpen(true);
  };

  return (
    <MuiAppBar elevation={0}>
      <Toolbar className="oh-toolbar">
        <Box className="oh-left-headerBox">
          <IconButton
            onClick={() => setMenuOpen((prev) => !prev)}
            className="oh-menu-icon-btn"
          >
            <MenuIcon />
          </IconButton>
          <IconButton onClick={() => handleNavigate()} className="oh-icon-btn">
            <img className="main-logo" src={logo} alt="logo" width="120px" />
          </IconButton>
          <MuiTypography
            className="oh-menu-nav"
            variant="h6"
            component="div"
            style={{ flexGrow: 1 }}
          >
            <CustomNavbar />
          </MuiTypography>
        </Box>
        <Box className="oh-header-rightBox">
          {(token && userData?.user?.registration_complete && userData?.user?.otp_verified) ||
            (lsToken && lsUserData?.registration_complete && lsUserData?.otp_verified) ? (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              className="humburgerMenu"
              onClick={() => handleProfileDrawer()}
            >
              <img className="hamburgerMenu-icon" src={humburgerIcon} alt="" />

              <img
                src={profilePhoto?.profilePhoto || profilePhoto || profile}
                alt=""
              />
            </Box>
          ) : (
            <MuiButtonContained
              className="login-btn"
              title="Login / Registration"
              style={{
                padding: "16px 25px !important",
                fontWeight: "400 !important",
              }}
              onClick={() => handleLoginNavigation()}
            />
          )}
        </Box>
      </Toolbar>

      {isProfileOpen && (
        <ProfileDrawer
          isProfileOpen={isProfileOpen}
          handleProfileClose={handleProfileClose}
        />
      )}
      {isMenuOpen && (
        <CustomNavigation
          staticPageHeader={true}
          open={isMenuOpen}
          onClose={() => setMenuOpen((prev) => !prev)}
        />
      )}
    </MuiAppBar>
  );
};

export default LandingPageHeader;
