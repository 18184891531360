import React from "react";
//MUI
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

//OTHER
import DrawerHeader from "./DrawerHeader";
import DrawerFooter from "./DrawerFooter";

//CSS
import "./index.scss";

const DrawerBody = ({
    open,
    onClose,
    children,
    isfooter = false,
    data = {},
    id = "",
    title = null
}) => {
    return (
        <Drawer
            className="oh-drawer-body"
            anchor="right"
            sx={{
                '.MuiBackdrop-root': {
                    background: "rgba(0, 0, 0, 0.15)"
                }
            }}
            PaperProps={{
                style: {
                    boxShadow: "0px var(--oh-white-color)",
                    border: "1px solid var(--oh-white-300-color)",
                    borderRadius: 0,
                    padding: "5px 0px",
                    maxWidth: "550px",
                    width: "100%",
                }
            }}
            open={open}
            onClose={onClose}
        >
            <DrawerHeader id={id} data={data} title={title} onClose={onClose} />
            <Box> {children} </Box>
            {isfooter && <DrawerFooter />}
        </Drawer>
    )
}

export default DrawerBody;