import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../apis/AxiosApi";
import axios from "axios";

const initialState = {
    pincode: null,
    city: '',
    state: '',
    country: '',
}

// export const getDetailsByPincode = createAsyncThunk(
//     'getDetailsByPincode',
//     async (pincode) => {
//         return await fetch("https://api.postalpincode.in/pincode/452009").then(res => res.json()).then(res => console.log(res, "..... res")).catch(err => console.log(err, "---- err use"))

//         // return await axios({
//         //     path: `https://api.postalpincode.in/pincode/${pincode}`,
//         //     type: 'GET',
//         //     params: '',
//         //   }).then(res => {
//         //     console.log(res, "............. in then block")
//         //     return res;
//         //   }).catch(err => console.log(err, "........... eror"))
//     },
   
// )

export const pincodeSlice = createSlice({
    name: 'pincode',
    initialState,
    reducers: {
      setPincode: async (state, action) => {
        state.pincode = action.payload;
      },
    },
    // extraReducers: (builder) => {
    //     builder
    //       .addCase(getDetailsByPincode.pending, (state, action) => {
    //         // console.log(action, ".....getJobSkills.pending");
    //       })
    //       .addCase(getDetailsByPincode.fulfilled, (state, action) => {
    //         // state.city = action.payload;

    //         console.log(action, state, '.....getDetailsByPincode.fulfilled ')
    //       })
    //       .addCase(getDetailsByPincode.rejected, (state, action) => {
    //         console.log(action, ".....getJobSkills.rejected");
    //       });
    //   },
  })

  export const { setPincode } = pincodeSlice.actions;
  export default pincodeSlice.reducer;