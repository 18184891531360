import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { USERS } from "../apis/Constant/routes";
import { AxiosApi } from "../apis/AxiosApi";

const initialState = {
  portalName: "Jobseeker",
  portalValue: "jobseeker",
  isPublicHeader: true,
  hideMenuBtn: false,
  notAuthorized: null,
  isLoading: false,
  firstLoginWelcomePopup: false,
  fillProfileFirstPopup: false,
};

export const updateFirstLoginAttribute = createAsyncThunk(
  "updateFirstLoginAttribute",
  async () => {
    return await AxiosApi({
      path: `${USERS}/update_first_login`,
      type: "PUT",
      // data: JSON.stringify(data),
    });
  }
);

export const globalSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setPortalInfo: (state, action) => {
      state.portalName = action.payload.portalName;
      state.portalValue = action.payload.portalValue;
    },
    setIsPublicHeader: (state, action) => {
      state.isPublicHeader = action.payload.isPublicHeader;
    },
    setHideMenuBtn: (state, action) => {
      state.hideMenuBtn = action.payload;
    },
    setNotAuthorized: (state, action) => {
      state.notAuthorized = action.payload.notAuthorized;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFirstLoginWelcomePopup: (state, action) => {
      state.firstLoginWelcomePopup = action.payload;
    },
    setFillProfileFirst: (state, action) => {
      state.fillProfileFirstPopup = action.payload;
    },
  },
});

export const {
  setPortalInfo,
  setIsPublicHeader,
  setHideMenuBtn,
  setNotAuthorized,
  setIsLoading,
  setFirstLoginWelcomePopup,
  setFillProfileFirst,
} = globalSlice.actions;
export default globalSlice.reducer;
