import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
// CSS
import "./index.scss";

const PageNotFound = () => {
  const navigate = useNavigate();
  const { portalValue } = useSelector(state => state.app);
  const handleNavigate = () => {
    navigate(`/`)
      // , { replace: true }
    // navigate(-1);
  };
  return (
    <Box container className="ma-404container">
      <div className="ma-404-box">
        <h1>404</h1>
        {/* <img src={PageNotFoundImage} alt="404" className="img-fluid" /> */}
        <div className="ma-404Content-box">
          <h2>Page Not Found</h2>
          <p>
            The page you are looking for might not exists or is not available
            right now.
          </p>
          <p>
            <a onClick={handleNavigate}> Go back </a>
          </p>
        </div>
      </div>
    </Box>
  );
};
export default PageNotFound;
