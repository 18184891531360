import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { googleAuthLogin } from "../../redux/googleAuthSlice";
import { Toaster } from "../../components/Toaster/Toaster";
import { setPortalInfo } from "../../redux/globalSlice";
import { setToken, setUserData } from "../../redux/authSlice";
import { setProfilePhoto } from "../../redux/profileSlice";

const AuthPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { portalValue } = useSelector(state => state.app);

    useEffect(() => {
        const redirect_url = window.location.origin;
        const code = searchParams.get("code");
        const formType = localStorage.getItem("form_type");
        const authVia = localStorage.getItem("auth_via");
        if (code && formType && authVia) {
            const portalType = localStorage.getItem('portal');

            let data = {
                code: code,
                redirect_url: redirect_url,
                user_type: portalType,
                form_type: formType
            }

            switch (formType) {
                case "sign_in":
                case "sign_up":
                    return handleGoogleAuth(data);
                default:
                    const error = new Error("Form type invalid. Please check, something went wrong");
                    Toaster.TOAST(error, "error");
                    console.log('Error: ', error);
                    throw error;
            }
        }
    }, []);

    const handleGoogleAuth = (data) => {
        dispatch(googleAuthLogin(data))
            .then(res => {
                if (res.type === "googleAuth/login/fulfilled") {
                    Toaster.TOAST(res?.payload?.message, "success");
                    let key_name = Object.keys(res?.payload?.data)[0];
                    const auth_token = res?.payload?.authentication_token;
                    let user_data = res?.payload?.data[key_name];
                    const user_type = res?.payload?.data[key_name]?.type;

                    dispatch(setUserData(user_data));
                    dispatch(setToken(res?.payload?.authentication_token));
                    dispatch(setPortalInfo({ portalName: user_type, portalValue: key_name }))
                    dispatch(setProfilePhoto(res?.payload?.data[key_name]?.profile_photo));

                    user_type && localStorage.setItem("portal", user_type);
                    res?.payload?.authentication_token && localStorage.setItem('token', auth_token)
                    user_data && localStorage.setItem('login_data', user_data ? JSON.stringify(user_data) : null)
                    
                    setTimeout(() => {
                        if (res?.payload?.data[portalValue]?.registration_complete) {
                            navigate(`/${key_name}`)
                        } else {
                            navigate('/signup-proceed', {
                                state: { user_id: user_data?.id, portalType: key_name }
                            })
                        }
                    }, 1000)
                } else if (res.type === "googleAuth/login/rejected") {
                    navigate(`/${data.form_type === "sign_in" ? "login" : "signup"}`)
                }
            }).catch(err => {
                console.log(err);
                Toaster.TOAST(err.message, "error");
            })
    };

    return (
        <Box sx={{ width: "100vw", height: "100vh" }}>
            <Typography
                sx={{
                    color: "var(--oh-blue-300-color)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 600
                }}
            >
                Please wait...
            </Typography>
        </Box>
    );
};
export default AuthPage;