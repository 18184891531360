import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//MUI
import Grid from "@mui/material/Grid";

//OTHER
import TertiaryWrapper from "../../../components/Wrappers/TertiaryWrapper";
import JobOpeningsTabs from "./JobOpeningTabs";
import EmployerLayout from "../../Layouts/Employer/EmployerLayout";
import JobPostCard from "../../../components/JobPostBox/JobPostCard";
import {
  getJobPost,
  getOpenJobs,
  setOpenPosition,
} from "../../../redux/Employer/JobPostSlice";
import NoJobsFound from "../../../components/NotFound/NoJobsFound";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const OpeningPostions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openPositions = useSelector((state) => state.jobPost.openPositions);
  useEffect(() => {
    dispatch(getOpenJobs())
      .then((res) => {
        if (res.type === "getOpenJobs/fulfilled") {
          dispatch(setOpenPosition(res?.payload?.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <EmployerLayout>
        <JobOpeningsTabs />
        <TertiaryWrapper content={`${openPositions?.length} Jobs Posted`}>
          <Grid container
            spacing={1}
          >
            {openPositions?.length ? (
              openPositions.map((elem, idx) => {
                return (
                  <Grid item className="cards-responsive-1400" xs={12} md={12} lg={6}>
                    <Box>
                      <JobPostCard
                        cardType="jobsCards"
                        size={"medium-100"}
                        handleNavigate={() => {
                          navigate(`/job-openings/job-details/${elem.id}`, {
                            // replace: true,
                          });
                        }}
                        data={elem.attributes}
                        className={"open-total-applicant"}
                      />
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <Box sx={{ width: "100%", minHeight: "200px" }}>
                <NoJobsFound height={"450px"} value="No jobs found" />
              </Box>
            )}
          </Grid>
        </TertiaryWrapper>
      </EmployerLayout>
    </>
  );
};

export default OpeningPostions;
