import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyD89BFTK7834ni1dACDQe86lbkqBA6iJhM",
    authDomain: "octahire-demo-1mar.firebaseapp.com",
    projectId: "octahire-demo-1mar",
    storageBucket: "octahire-demo-1mar.appspot.com",
    messagingSenderId: "1061708699336",
    appId: "1:1061708699336:web:1afdd63188b6862fa937fd",
    measurementId: "G-0QQHWENF67"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
        const token = await getToken(messaging, {
            vapidKey: "BDdqm74dSCeO0NoA_0jxKKCIq87PCi46XpT5uWALQ4q9tTt_xZPjX9yUtHjMWVCB2ZzMIMturKX3jqZs2QTVB2c"
        })
        console.log(token,'..token')
    }
}
