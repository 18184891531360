import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "./components/Toaster/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthStorage } from "./redux/authSlice";
import { setPortalInfo } from "./redux/globalSlice";
import { returnRoleValue } from "./utils";
import { setProfilePhoto } from "./redux/profileSlice";

const PrivateRoute = ({ children }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { user } = useSelector(state => state.auth)
  const isAuthenticated = () => !!localStorage.getItem("token");


  if (!isAuthenticated()) {
    Toaster.TOAST("Not Authorised! Please log back in.", "error");
    dispatch(clearAuthStorage());
    dispatch(setProfilePhoto(null));
    dispatch(
      setPortalInfo({
        portalName: "Jobseeker",
        portalValue: returnRoleValue("Jobseeker"),
      })
    );
    return <Navigate to="/login" replace />;
  }


  return children;
};

export default PrivateRoute;
