
// Import necessary modules and components
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import MuiBasicDrawer from "../../mui/Drawers/MuiBasicDrawer";
import DrawerHeader from "../Drawer/Components/DrawerHeader";
import { Box, Grid } from "@mui/material";
import MuiDivider from "../../mui/Dividers/MuiDivider";
import MuiButtonContained from "../../mui/Buttons/MuiButtonContained";
import { Form, Formik } from "formik";
import PrimaryLabel from "../Labels/PrimaryLabel";
import FieldError from "../Errors/FieldError";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  clearInitialData,
  getTransanctionHistory,
  setCandidateEarning,
  setEndDate,
  setStartDate,
  setTransactionData,
} from "../../redux/Freelancer/earningDetailSlice";
import { useDispatch } from "react-redux";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import dayjs from "dayjs";
import MuiButtonOutlined from "../../mui/Buttons/MuiButtonOutlined";

// Main functional component
const TransanctionFilter = ({
  onClose = () => { },
  open = false,
  setRowCount,
  setPage,
  setTransactionEarning
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearInitialData)
  },[])

  const getTransactionData = (data = null) => {
    setTransactionEarning(true);
    dispatch(getTransanctionHistory(data))
      .then((res) => {
        if (res?.type === "getTransanctionHistory/fulfilled") {
          dispatch(setTransactionData(res?.payload?.data));
          dispatch(setStartDate(data?.start_date));
          dispatch(setEndDate(data?.end_date));
          setRowCount(res.payload.meta.total_records)
          setTransactionEarning(false);
        } else if (res?.type === "getTransanctionHistory/rejected") {
          setTransactionEarning(false);
        }
        onClose();
      })
      .catch((err) => console.log(err));
  };

  const validationSchema = Yup.object().shape({
    start_date: Yup.string().required("Start date is required"),
    end_date: Yup.string().required("End date is required"),
  });

  const FormatDate = (date) => {
    return dayjs(date).format('YYYY-MM-DD');
  };

  const handleApply = (values) => {
    setPage(1);
    const data = {
      start_date: FormatDate(values.start_date),
      end_date: FormatDate(values.end_date),
    }
    getTransactionData(data);
  };

  const handleReset = () => {
    getTransactionData();
    dispatch(clearInitialData());
    onClose();
  };

  return (
    <MuiBasicDrawer
      sx={{
        position: "relative",
        border: "1px solid var(--oh-white-300-color)",
        boxShadow: "none",
      }}
      anchor="right"
      onClose={() => onClose()}
      open={open}
    >
      <DrawerHeader onClose={() => onClose()} title="Filter" />

      <Formik
        initialValues={{
          start_date: null,
          end_date: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleApply(values)
        }}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
          touched,
          errors,
        }) => (
          <Form autoComplete="off">
            <div style={{ padding: "10px 20px", height: "calc(80vh - 0px)", overflowY: "hidden" }}>
              <Grid container xs={12} md={12} lg={12}>
                <Grid item>
                  <Box sx={{ margin: "10px 0px 15px" }}>
                    <PrimaryLabel required={true} labelType="secondary" label="Start Date" />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="oh-primary-textfield"
                        value={values.start_date}
                        onChange={(date) => {
                          setFieldValue("start_date", date)
                        }}
                        format="YYYY-MM-DD"
                        renderInput={(params) => (<MuiTypography {...params} />)}
                      />
                      {errors.start_date && touched.start_date && (
                        <FieldError sx={{ margin: "8px 16px" }} errorString={errors.start_date} />
                      )}
                    </LocalizationProvider>
                  </Box>

                  <Box sx={{ margin: "15px 0px" }}>
                    <PrimaryLabel required={true} labelType="secondary" label="End Date" />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="oh-primary-textfield"
                        value={values.end_date}
                        onChange={(date) => {
                          setFieldValue("end_date", date)
                        }}
                        format="YYYY-MM-DD"
                        renderInput={(params) => (<MuiTypography {...params} />)}
                      />
                      {errors.end_date && touched.end_date && (
                        <FieldError sx={{ margin: "8px 16px" }} errorString={errors.end_date} />
                      )}
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <Box sx={{ position: "sticky", bottom: "0", height: "70px", background: "#fff" }}>
              <MuiDivider />
              <Box px={2} py={2} gap={2} display={"flex"}>
                <MuiButtonContained
                  title="Apply"
                  style={{ padding: "12px 30px !important", fontWeight: "400 !important" }}
                  type="submit"
                />
                <MuiButtonOutlined
                  style={{
                    padding: "12px 30px !important",
                    fontWeight: "400 !important",
                    "&:hover": { color: "red !important", border: "1px solid red" },
                  }}
                  title="Reset"
                  color="error"
                  onClick={handleReset}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </MuiBasicDrawer>
  );
};

export default TransanctionFilter;
