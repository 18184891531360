import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";
import { EARNING_CANDIDATE } from "../../apis/Constant/routes";

const initialState = {
  candidateEarning: [],
  transactionData: [],
  initialData: {
    start_date: null,
    end_date: null,
  },
  earningCardsData: {},
};
//earning_candidates/transactions_history

// api call methods
export const getCandidateEarnings = createAsyncThunk(
  "getCandidateEarnings",
  async (data, thunkapi) => {
    const { user } = thunkapi.getState().auth;
    let appendHeader =
      data?.keyword && data?.keyword != ""
        ? `&search[term]=${data.keyword}`
        : ``;
        let page = data?.page || 1;
        let pageSize = data?.pageSize || 10;
    let appendFilterHeader =
      data?.start_date || data?.end_date
        ? `&start_date=${data.start_date?.$y}-${data.start_date?.$M + 1}-${data.start_date?.$D}&end_date=${data.end_date?.$y}-${data.end_date?.$M + 1}-${data.end_date?.$D}`
        : `&per_page=${pageSize}`;
    return await AxiosApi({
      path: `/freelancer/${user?.id}${EARNING_CANDIDATE}?page=${page}${appendHeader}${appendFilterHeader}`,
      type: "GET",
      params: "",
    });
  }
);
export const getTransanctionHistory = createAsyncThunk(
  "getTransanctionHistory",
  async (data, thunkapi) => {
    const { user } = thunkapi.getState().auth;
    let appendHeader =
      data?.keyword && data?.keyword != ""
        ? `&search[term]=${data.keyword}`
        : ``;
        let page = data?.page || 1;
        let pageSize = data?.pageSize || 10;
    let appendFilterHeader =
      data?.start_date || data?.end_date
        ? `&filter_by[start_date]=${
            data.start_date
          }&filter_by[end_date]=${data.end_date}`
        : `&per_page=${pageSize}`;
    return await AxiosApi({
      path: `/freelancer/${user?.id}${EARNING_CANDIDATE}/transactions_history?page=${page}${appendHeader}${appendFilterHeader}`,
      type: "GET",
      params: "",
    });
  }
);

export const sendRequestApi = createAsyncThunk(
  "sendRequestApi",
  async (id, thunkapi) => {
    const { user } = thunkapi.getState().auth;
    return await AxiosApi({
      path: `/freelancer/${user?.id}${EARNING_CANDIDATE}/${id}/send_request`,
      type: "GET",
      params: "",
    });
  }
);

export const earningAmountsApi = createAsyncThunk(
  "earningAmountsApi",
  async ({ id }, thunkapi) => {
    const { user } = thunkapi.getState().auth;
    return await AxiosApi({
      path: `/freelancer/${id}/earning_candidates/earnings`,
      // path: `/freelancer/${id}/freelancer_earning_amounts`,
      type: "GET",
      params: "",
    });
  }
);

export const earningDataSlice = createSlice({
  name: "earningData",
  initialState,
  reducers: {
    setCandidateEarning: (state, action) => {
      state.candidateEarning = action.payload;
    },
    setTransactionData: (state, action) => {
      state.transactionData = action.payload;
    },
    setStartDate: (state, action) => {
      state.initialData.start_date = action.payload;
    },
    setEndDate: (state, action) => {
      state.initialData.end_date = action.payload;
    },
    clearInitialData: (state, action) => {
      state.initialData.start_date = null;
      state.initialData.end_date = null;
    },
    setEarningCardsData: (state, action) => {
      state.earningCardsData = action.payload;
    },
  },
});

export default earningDataSlice.reducer;
export const {
  setCandidateEarning,
  setTransactionData,
  setStartDate,
  setEndDate,
  clearInitialData,
  setEarningCardsData,
} = earningDataSlice.actions;
