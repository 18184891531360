import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { USERS } from "../apis/Constant/routes";
import { AxiosApi } from "../apis/AxiosApi";

const initialState = {
    faqData: [],
}

export const getFaqs = createAsyncThunk(
  "getFaqs", async (params) => {
    let appendHeader = params?.general ? "?user_type=General" : "";
  return await AxiosApi({
    path: `/faqs${appendHeader}`,
    type: "GET",
    params: "",
    toAppendAuthParams: !params?.general,
  });
});

export const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
      setFaqData: (state, action) => {
        state.faqData = action.payload;
      }
    },
  })

  export const { setFaqData } = faqSlice.actions;
  export default faqSlice.reducer;