import React, { useState } from "react";

//OTHER
import MuiBasicDrawer from "../../../mui/Drawers/MuiBasicDrawer";
import DrawerHeader from "../../../components/Drawer/Components/DrawerHeader";
import DrawerSearch from "../../../components/Drawer/Components/DrawerSearch";
import FilterAccordion from "./FilterAccordion";

const Filter = ({
  onClose,
  open,
  data,
  handleSetChips,
  handleDeleteChips,
  setExperienceRange,
  uncheckCheckbox,
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    categories: [],
    workModes: [],
    locations: [],
    skills: [],
    experienceRange: [0, 1],
  });
  return (
    <MuiBasicDrawer
      sx={{
        position: "relative",
        border: "1px solid var(--oh-white-300-color)",
        boxShadow: "none",
      }}
      anchor="right"
      onClose={onClose}
      open={open}
    >
      <DrawerHeader onClose={onClose} title="Filter" />
      {/* <DrawerSearch /> */}
      <FilterAccordion
        data={data}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        onClose={onClose}
        isDrawer={true}
        handleSetChips={handleSetChips}
        handleDeleteChips={handleDeleteChips}
        setExperienceRange={setExperienceRange}
        uncheckCheckbox={uncheckCheckbox}
      />
      {/* <DrawerFooter /> */}
    </MuiBasicDrawer>
  );
};

export default Filter;
