import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosApi } from '../apis/AxiosApi'
import { AUTHS, FB_AUTH_URL, FB_OMNI_AUTH } from '../apis/Constant/routes'

const initialState = {
  user: null,
  token: ''
}

export const fbUri = createAsyncThunk(
  'fbAuthUri',
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${AUTHS}${FB_AUTH_URL}?redirect_url=${data.redirect_url}`,
      type: 'GET',
      params: '',
    })
  }
)

export const fbAuthLogin = createAsyncThunk(
    'fbAuth/login',
    async (data, thunkApi) => {
        return await AxiosApi({
          path: `${AUTHS}${FB_OMNI_AUTH}?code=${data.code}&redirect_url=${data.redirect_url}&flow_type=${data.form_type}&user_type=${data.user_type}`,
          type: 'GET',
          params: '',
        })
      }
  )
  

export const fbAuthSlice = createSlice({
  name: 'fbAuth',
  initialState,
  reducers: {
    
  },
})

export default fbAuthSlice.reducer