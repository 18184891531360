import React, { useState } from "react";

//MUI
import Box from "@mui/material/Box";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

//OTHER
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";

//OTHER
import MuiIconChip from "../../../../mui/Chip/IconChip";
import Filter from "../Filter";

//CSS
import "./index.scss";

const TertiaryWrapper = ({
  setExperienceRange,
  uncheckCheckbox,
  children = null,
  content = null,
  onClick = () => {},
  showBtnOptions = true,
  sx = {},
  variant = "p",
}) => {
  const [popupState, setPopupState] = useState("");

  const handlePopupState = (popupToHandle = "") => {
    return setPopupState(popupToHandle);
  };

  const handleClick = (event) => {
    handlePopupState("sort");
  };
  return (
    <>
      <Box sx={sx} className="oh-tertiary-wrapper">
        <Box className="wrapper-header">
          <MuiTypography
            variant={variant}
            content={content}
            className={"oh-heading"}
          />
          {showBtnOptions && (
            <Box>
              <MuiIconChip
                icon={<FilterAltIcon sx={{ marginTop: "-3px" }} />}
                label="Filter"
                onClick={() => handlePopupState("filter")}
                className="oh-iconchip-box"
              />
              {/* <SortBy
                        // open={popupState === "sort"}
                        // onClose={handlePopupState}
                        // onClick={(event) => handleClick(event)}
                    /> */}
            </Box>
          )}
        </Box>
        {children}
      </Box>
      <Filter
        onClose={handlePopupState}
        open={popupState === "filter"}
        setExperienceRange={setExperienceRange}
        uncheckCheckbox={uncheckCheckbox}
      />
    </>
  );
};

export default TertiaryWrapper;
