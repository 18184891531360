import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CandidateDetail from './CandidateDrawer/CandidateDetail';
import SkillsTooltip from '../Tooltip/SkillsChipTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setFreelancerActiveCandidateData, getFreelancerActiveCandidateData } from '../../redux/Freelancer/freelancerActiveCandidatesSlice';
import { handleDownload } from '../../utils';
import { freelancerExperienceArr } from '../../Data';
import { setIndividualFreelancerData } from '../../redux/Freelancer/freelancerDetailsSlice';
import MuiTooltip from '../../mui/Tooltip/MuiTooltip';
import ListingTableWithPagination from '../Table/ListingTableWithPagination';
import NoCandidatesDataInTable from '../Table/NoCandidatesFound';

const ActiveCandidateTable = ({ rowCount, setRowCount, searchKeyword = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const freelancerActiveCandidates = useSelector((state) => state.freelancerActiveCandidates.freelancerActiveCandidates);
  const [loader, setLoader] = useState(false);
  const pageSize = 10;
  const [page, setPage] = useState(1);

  const handlePageChange = (newPage) => {
    let page = newPage + 1;
    setPage(page);
  };

  const handleDrawer = (val) => {
    setIsOpen((prev) => !prev);
    dispatch(setIndividualFreelancerData(val));
  };

  useEffect(() => {
    getFreelancerActiveCandidates();
  }, [page]);

  const getFreelancerActiveCandidates = () => {
    setLoader(true);
    dispatch(getFreelancerActiveCandidateData({ keyword: searchKeyword, page: page, pageSize })).then((res) => {
      if (res.type === "getFreelancerActiveCandidateData/fulfilled") {
        dispatch(setFreelancerActiveCandidateData(res.payload.data));
        setRowCount(res?.payload?.meta?.total_records);
      }
      setLoader(false);
    }).catch(err => {
      console.log(err);
      setLoader(false);
    });
  };

  const headCells = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      minWidth: 150,
      flex: 1,
      renderCell: params => {
        return (
          <span style={{ cursor: 'pointer' }}
            onClick={() => handleDrawer(params?.row?.attributes)}
          >
            {params?.row?.attributes?.first_name ? `${params?.row?.attributes?.first_name} ${params?.row?.attributes?.last_name}` : '-'}
          </span>
        )
      }
    },
    {
      field: 'designation',
      headerName: 'Designation',
      sortable: true,
      minWidth: 150,
      flex: 1,
      renderCell: params => {
        return <span style={{ textTransform: "capitalize" }}>{params.row?.attributes?.designation || '-'}</span>
      }
    },
    {
      field: 'experiences',
      headerName: 'Experiences',
      sortable: true,
      minWidth: 130,
      flex: 1,
      renderCell: params => {
        return <span>{(params?.row?.attributes?.experience || params?.row?.attributes?.experience == 0) ?
          freelancerExperienceArr.find(exp => exp.value == params?.row?.attributes?.experience)?.label
          : '-'}</span>
      }
    },
    {
      field: 'skill',
      headerName: 'Skills',
      sortable: true,
      minWidth: 150,
      flex: 1,
      renderCell: params => {
        return (
          <SkillsTooltip size='small-300' array={params.row?.attributes?.skills} maxLength={2} />
        )
      }
    },
    {
      field: 'cv',
      headerName: 'CVs',
      sortable: true,
      minWidth: 130,
      flex: 1,
      renderCell: params => {
        return <span onClick={() => params.row?.attributes?.resume?.url ? handleDownload(params.row?.attributes?.resume?.url) : {}}
          style={params.row?.attributes?.resume?.url ? { color: '#B71E58', textDecoration: 'underline' } : {}}>{params.row?.attributes?.resume_name || 'Not uploaded'}</span>
      }
    },
    {
      field: 'linked',
      headerName: 'Linked',
      sortable: true,
      renderCell: params => {
        return (
          <MuiTooltip
            length={50}
            displayTitleOnly={
              <span className='status' onClick={() => params.row?.attributes?.linked ? navigate(`/candidates/candidate-profile`, { state: { data: params.row.attributes } }) : {}}>
                <span
                  style={{
                    color: params.row?.attributes?.linked ? '#34A853' : 'var(--oh-blue-300-color)',
                    cursor: params.row?.attributes?.linked ? 'pointer' : "default",
                  }}
                >
                  {params?.row?.attributes?.linked > 3 ? "3+" : (params.row?.attributes?.linked || 'No Jobs')}
                </span>
              </span>
            }
            description={`${params.row?.attributes?.linked} Jobs Linked`}
          />
        )
      }
    }
  ]

  return (
    <>
      <ListingTableWithPagination
        hideFooterPagination={false}
        rows={freelancerActiveCandidates}
        columns={headCells}
        pageSize={pageSize}
        rowCount={rowCount}
        rowsPerPageOptions={[pageSize]}
        page={page - 1}
        loader={loader}
        paginationMode="server"
        onPageChange={handlePageChange}
        customNoResultsComponent={() => <NoCandidatesDataInTable />}
      />

      <CandidateDetail
        setRowCount={setRowCount}
        setPage={setPage}
        open={isOpen}
        setOpen={setIsOpen}
        onClose={() => handleDrawer()}
        title="Candidate Details"
      />
    </>
  );
};

export default ActiveCandidateTable;
