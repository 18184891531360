import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik, useField, useFormikContext } from "formik";
//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";

//REDUX
import { useDispatch, useSelector } from "react-redux";

//OTHER
import LoginDropdown from "../../components/Shared/LoginDropdown";
import MuiTextField from "../../mui/TextField/MuiTextField";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import FieldError from "../../components/Errors/FieldError";
import MuiDivider from "../../mui/Dividers/MuiDivider";
import { Toaster } from "../../components/Toaster/Toaster";
import { clearAuthStorage, setSignUp, setToken, signup } from "../../redux/authSlice";
import { SignupSchema, firstLastNameSchema } from "../../schemas/SignupSchema";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import { returnRoleValue } from "../../utils";
import { setPortalInfo } from "../../redux/globalSlice";
import ThirdPartyAuth from "../Auth/ThirdPartyAuth";
//CSS
import "./index.scss";
import SignupLayout from "./SignupLayout";
import { setProfilePhoto } from "../../redux/profileSlice";

const SignupPrimary = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { portalName, portalValue } = useSelector((state) => state.app);
  const [toggle_password, setToggle_password] = useState(false);
  const isJobSeeker = portalValue === "jobseeker";
  const initialValue = {
    email: "",
    mobile: "",
    password: "",
    type: "",
    agree: false,
    can_send_imp_updates: false,
  };

  const [initialData, setInitialData] = useState(() => {
    if (isJobSeeker) {
      return {
        ...initialValue,
        first_name: "",
        last_name: "",
      };
    } else {
      return initialValue;
    }
  });



  const validationSchema = isJobSeeker ? SignupSchema.concat(firstLastNameSchema) : SignupSchema;

  // const [initialData, setInitialData] = useState({
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   mobile: "",
  //   password: "",
  //   type: "",
  //   agree: false,
  //   can_send_imp_updates: false,
  // });
  const dispatch = useDispatch();

  const togglePasswordHide = () => {
    setToggle_password((toggle_password) => !toggle_password);
  };

  const handleSubmit = (values, setSubmitting) => {
    // Common user data
    const commonData = {
      email: values.email,
      mobile_number: values.mobile,
      password: values.password,
      type: portalName,
      agree: values.agree,
      can_send_imp_updates: values.can_send_imp_updates,
    };

    // Conditionally include first_name and last_name
    const userData = isJobSeeker
      ? {
        ...commonData,
        first_name: values.first_name,
        last_name: values.last_name,
      }
      : commonData;

    // Final data object
    let data = {
      user: userData,
    };

    if (portalName) {
      dispatch(signup(data))
        .then((res) => {
          if (res.type === "auth/signup/fulfilled") {
            dispatch(setSignUp(res?.payload?.data[portalValue]));
            dispatch(
              setPortalInfo({
                portalName: res?.payload?.data[portalValue]?.type,
                portalValue: returnRoleValue(
                  res?.payload?.data[portalValue]?.type
                ),
              })
            );
            dispatch(setToken(res?.payload?.authentication_token));
            dispatch(setProfilePhoto(res?.payload?.data[portalValue]?.profile_photo));

            res?.payload?.data[portalValue]?.type &&
              localStorage.setItem(
                "portal",
                res?.payload?.data[portalValue]?.type
              );
            res?.payload?.authentication_token &&
              localStorage.setItem("token", res?.payload?.authentication_token);
            res?.payload?.data[portalValue] &&
              localStorage.setItem(
                "login_data",
                JSON.stringify(res?.payload?.data[portalValue])
              );

            Toaster.TOAST(res?.payload?.message, "success");
            // navigate("/signup-proceed", { replace: true });
            navigate(`/two-factor-verification`, {
              state: { first_name: values.first_name, last_name: values.last_name, email: values.email, mobile_number: values.mobile },
              replace: true
            });
            setSubmitting(false);
          } else if (res.type === "auth/signup/rejected") {
            setSubmitting(false);
          }
        })
        .catch((err) => {
          console.log(err.message, ".err");
          setSubmitting(false);
          Toaster.TOAST(err.message, "error");
        });
    }
  };

  useEffect(() => {
    localStorage.setItem("form_type", "sign_up");
  }, []);

  return (
    <SignupLayout>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validateOnblur={false}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <Form className="oh-signup-form" autoComplete="off">
              <Grid container xs={12} md={12} lg={6}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className="oh-heading">
                    <MuiTypography
                      className="oh-signup-title"
                      content="Register as"
                    />
                    <LoginDropdown />
                  </Box>
                </Grid>
                <Grid item xs={12} md={10} lg={12}>
                  <Box
                    className="oh-account-question"
                  // sx={{
                  //   display: "flex",
                  //   flexWrap: "no-wrap",
                  //   marginBottom: "20px",
                  // }}
                  >
                    <MuiTypography
                      variant="span"
                      style={{
                        display: "inline-block",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "22.4px",
                        color: "var(--oh-blue-300-color)",
                        width: "100%",
                        maxWidth: "fit-content",
                        marginRight: "4px",
                      }}
                      content="Already Registered?"
                    />
                    <MuiTypography
                      variant="span"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "22.4px",
                        width: "fit-content",
                        color: "var(--oh-blue-color)",
                        // width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/login"
                          // , { replace: true }
                        );
                      }}
                      content="Login here"
                    />
                  </Box>
                </Grid>
                {
                  isJobSeeker &&
                  <>
                    <Grid
                      className="oh-textfield-grid"
                      item
                      xs={12}
                      md={10}
                      lg={12}
                    >
                      <PrimaryLabel label="First Name" />
                      <InputField
                        id="first_name"
                        type="text"
                        placeholder="Enter your First name"
                        name="first_name"
                        value={values.first_name}
                        validateOnChange={true}
                      />
                    </Grid>
                    <Grid
                      className="oh-textfield-grid"
                      item
                      xs={12}
                      md={10}
                      lg={12}
                    >
                      <PrimaryLabel label="Last Name" />
                      <InputField
                        id="last_name"
                        type="text"
                        placeholder="Enter your Last name"
                        name="last_name"
                        value={values.last_name}
                        validateOnChange={true}
                      />
                    </Grid>
                  </>
                }

                <Grid
                  className="oh-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={12}
                >
                  <PrimaryLabel label="Email ID" />
                  <InputField
                    id="email"
                    type="email"
                    placeholder="Enter your active Email ID / Username"
                    name="email"
                    value={values.email}
                    validateOnChange={true}
                  />
                </Grid>
                <Grid
                  className="oh-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={12}
                >
                  <PrimaryLabel label="Mobile Number" />
                  <InputField
                    id="mobile"
                    type="text"
                    placeholder="Enter your active mobile number"
                    name="mobile"
                    value={values.mobile}
                    validateOnChange={true}
                  />
                </Grid>
                <Grid
                  className="oh-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={12}
                >
                  <PrimaryLabel label="Password" />
                  <InputField
                    id="password"
                    type={toggle_password ? "text" : "password"}
                    placeholder="Enter your password"
                    name="password"
                    value={values.password}
                    validateOnChange={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          {" "}
                          {toggle_password ? (
                            <Visibility
                              className="cursor_pointer"
                              onClick={togglePasswordHide}
                            />
                          ) : (
                            <VisibilityOff onClick={togglePasswordHide} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid sx={{ marginTop: "10px" }} item xs={12} md={10} lg={12}>
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  > */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent:"flex-start",
                      alignItems:"flex-start",
                      flexWrap: "no-wrap",
                    }}
                  >
                    <Checkbox
                      defaultChecked={false}
                      name="agree"
                      checked={values.agree}
                      onChange={(e) => {
                        setFieldValue("agree", e.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      style={{ padding: "0px 9px 9px 0" }}
                    />
                    <Box>
                    <MuiTypography
                      variant="span"
                      style={{
                        display: "inline-block",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "22.4px",
                        color: "var(--oh-blue-300-color)",
                        width: "100%",
                        maxWidth: "fit-content",
                        marginRight: "4px",
                      }}
                      content="I agree to the "
                    />

                    <a
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "22.4px",
                        color: "var(--oh-blue-color)",
                        width: "100%",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      href="/term-condition"
                      target="__blank"
                    >
                      Terms and Conditions & Privacy Policy of OctaHire.com
                    </a>
                    </Box>
                    </Box>
                    <span>
                      {touched.agree && Boolean(errors.agree)}
                      <FieldError
                        sx={{
                          marginBottom:
                            touched.agree && Boolean(errors.agree)
                              ? "20px"
                              : "0px",
                          marginLeft:
                            touched.agree && Boolean(errors.agree)
                              ? "15px"
                              : "0px",
                        }}
                        errorString={touched.agree && errors.agree}
                      />
                    </span>
                 
                </Grid>
                <Grid sx={{ marginTop: "10px" }} item xs={12} md={10} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "no-wrap",
                    }}
                  >
                    <Checkbox
                      defaultChecked={false}
                      name="can_send_imp_updates"
                      checked={values.can_send_imp_updates}
                      onChange={(e) => {
                        setFieldValue("can_send_imp_updates", e.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      style={{ padding: "0px 9px 9px 0" }}
                    />
                    <MuiTypography
                      variant="span"
                      style={{
                        display: "inline-block",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "22.4px",
                        color: "var(--oh-blue-300-color)",
                        width: "100%",
                        maxWidth: "fit-content",
                        marginRight: "4px",
                      }}
                      content="Send Important Updates"
                    />

                  </Box>
                </Grid>
                <Grid
                  style={{ margin: "25px 0px 20px" }}
                  item
                  xs={12}
                  md={10}
                  lg={12}
                >
                  <CustomLoadingButton
                    loading={isSubmitting}
                    type="submit"
                    title={"Register"}
                  />
                </Grid>

                <Grid item xs={12} md={10} lg={12}>
                  <MuiDivider label="or" />
                </Grid>
                <Grid item xs={12} md={10} lg={12}>
                  <ThirdPartyAuth />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </SignupLayout>
  );
};

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value, true);
  };

  const error = (validateOnChange || meta.touched) && meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default SignupPrimary;
