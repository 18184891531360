import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";

//MUI
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

//ASSETS
import applicantImage from "../assets/images/profileDefault.png";

//OTHER
import { experienceArr } from "../Data";
import MuiTypography from "../mui/Typography/MuiTypograpghy";
import FilledChip from "./customChips/FilledChips";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getApplicantApi } from "../redux/Employer/ApplicantListDataSlice";
import NoJobsFound from "./NotFound/NoJobsFound";
import { returnSubstring } from "../utils";

// Styles
import "./ApplicantList.scss";
import MuiTooltip from "../mui/Tooltip/MuiTooltip";

const ApplicantList = () => {
  const [applicantsData, setApplicantsData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user?.id) {
      getApplicantListing();
    }
  }, [user]);

  const getApplicantListing = () => {
    dispatch(getApplicantApi(user.id))
      .then((res) => {
        const apiData = res?.payload;
        if (res.type === "getApplicantList/list/fulfilled") {
          setApplicantsData(apiData?.data || {});
        }
      })
      .catch((err) => {
        console.error("Error fetching applicants:", err.message);
      });
  };

  return (
    <>
      {Object.keys(applicantsData).length > 0 ? (
        Object.keys(applicantsData).map((jobTitle, index) => (
          <Box key={index} className="applicant-box">
            <Box
              className="applicant-header"
              onClick={() =>
                navigate(
                  `/job-openings/job-details/${applicantsData[jobTitle]?.job_post_id}`
                )
              }
            >
              <span className="applicant-title-container">
                <span className="applicant-title">
                  {returnSubstring(jobTitle, 22)}
                </span>
                <FilledChip
                  size="small-500"
                  label={applicantsData[jobTitle].applicants?.length}
                />
              </span>
              <KeyboardArrowRightOutlinedIcon
                className="arrow-icon"
                onClick={() =>
                  navigate(
                    `/job-openings/job-details/${applicantsData[jobTitle]?.job_post_id}`
                  )
                }
              />
            </Box>
            <List component="div" disablePadding>
              {applicantsData[jobTitle]?.applicants?.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Divider />
                  <ListItem
                    className="applicant-item"
                    onClick={() =>
                      navigate(`/job-openings/overview/${item.id}`)
                    }
                  >
                    <ListItemAvatar className="avatar-container">
                      <img
                        src={item?.url || applicantImage}
                        alt="avatar"
                        className="avatar"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <MuiTypography
                          className="typography-1"
                          style={{
                            fontSize: "16px",
                            fontWeight: "700",
                            textTransform: "capitalize"
                          }}
                          content={item?.full_name}
                        />
                      }
                      secondary={
                        <MuiTypography
                          className="typography-2"
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#445386",
                          }}
                        >
                          <span>
                            {
                              item?.designation ? (
                              <MuiTooltip
                                length={18}
                                title={item?.designation}
                              />) : "No designation"
                            }
                            <span className="dot-styling">
                              {
                                experienceArr.find(
                                  (elem) => elem.value === item?.experience
                                )?.label
                              }
                            </span>
                          </span>
                        </MuiTypography>
                      }
                    />
                  </ListItem>
                </React.Fragment>
              ))}
              {applicantsData[jobTitle]?.applicants?.length > 5 && (
                <>
                  <Divider />
                  <span className="view-all">
                    <Link
                      to={`/job-openings/job-details/${applicantsData[jobTitle]?.job_post_id}`}
                    >
                      View All
                    </Link>
                  </span>
                </>
              )}
            </List>
          </Box>
        ))
      ) : (
        <NoJobsFound
          value={"No jobs or Applicants yet"}
          applicants={true}
          height={"80%"}
        />
      )}
    </>
  );
};
export default ApplicantList;
