import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import "./index.scss";

const CustomLoadingButton = ({
    loading,
    disabled = loading,
    id = "",
    fullWidth = false,
    variant = "contained",
    testid = "",
    autoFocus = false,
    spanTextClass,
    style = {},
    img = null,
    href = "",
    type = "button",
    className = "",
    onClick = () => { },
    title = "",
}) => {
    const defaultClassStyle = variant === "contained" ? "oh-contained-button" : "oh-outlined-button";

    const renderContent = () => {
        if (spanTextClass) {
            return <span className={spanTextClass}>{title}</span>;
        } else if (img) {
            return <span>{img} {title}</span>;
        } else if (href) {
            return (
                <a href={href} target="_blank" rel="noreferrer" style={style}>
                    {title}
                </a>
            );
        } else {
            return title;
        }
    };
    return (
        <LoadingButton
            loading={loading}
            loadingIndicator={<CircularProgress />}
            id={id}
            fullWidth={fullWidth}
            className={`oh-default-button ${defaultClassStyle} ${className}`}
            disabled={disabled}
            data-testid={testid}
            type={type}
            variant={variant}
            style={{...style, color: loading ? "transparent" : ""}}
            autoFocus={autoFocus}
            sx={style}
            onClick={onClick}
        >
            {renderContent()}
        </LoadingButton>
    )
}

export default CustomLoadingButton;