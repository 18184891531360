import React from "react";
//MUI
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";

//OTHER
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";

//CSS
import "./index.scss";

const DrawerHeader = ({
    data = {},
    onClose,
    id = "",
    title = null
}) => {
// NOTE* why to nest or break components this much ?
    return (
        <Box>
            <Box className="oh-header">
                <MuiTypography className="header-title"
                    content={title}
                />
                <CloseIcon
                    className='close-icon'
                    onClick={onClose}
                />
            </Box>
            <Divider />
        </Box>
    )
}

export default DrawerHeader;