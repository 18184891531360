import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DefaultImage from "../../assets/images/job_seeker.svg";
import "./index.scss";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";

export default function WelcomeFirstLoginDialog({
  heading,
  popupState,
  handleClose = () => {},
}) {
  const { portalName } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);

  return (
    <Dialog
      open={popupState}
      onClose={handleClose}
      className="welcome-dialog-parent-container"
      sx={{ margin: "auto" }}
    >
      <DialogTitle className="welcome-dialog-title">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: "90px",
              height: "90px",
              borderRadius: "50%",
              marginBottom: "10px",
            }}
            src={DefaultImage}
            aria-label="recipe"
            alt="Remy Sharp"
            className="oh-profile-avatar"
          />
          <div style={{ marginTop: "5px", textAlign: "center" }}>
            <h4
              style={{
                fontSize: "20px",
                fontWeight: 600,
                color: "var(--oh-blue-300-color)",
              }}
            >
              Welcome to Octahire!
            </h4>
            <p
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "var(--oh-blue-600-color)",
              }}
            >
              { portalName=== "Jobseeker"
                ? `${user.first_name} Let's find your dream job together.`
                : portalName === "Freelancer"
                ? "Get started on your freelancing journey now."
                : "Congratulations! Your account has been successfully created."}
            </p>
          </div>
        </div>
        <Button
          className="ma-cross-btn"
          onClick={handleClose}
          color="primary"
          autoFocus
        >
          <CloseIcon />
        </Button>
      </DialogTitle>
    </Dialog>
  );
}
