import React from 'react';

import Select from 'react-select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { reactSelectChipsetStyling } from './ChipsetInputStyling';

const ReactSelect = ({
    value, name, options, onChange, placeholder
}) => {
    return (
        <>
            <Select
                defaultValue={value}
                getOptionLabel={option => option?.label}
                getOptionValue={option => option?.value}
                isMulti
                components={{
                    DropdownIndicator: () => <ArrowDropDownIcon sx={{ color: "#847a7a" }} />,
                }}
                styles={reactSelectChipsetStyling}
                name={name}
                options={options}
                placeholder={placeholder}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e, val) => {
                    onChange(e, val)
                }}
            />
        </>
    )
};

export default ReactSelect;