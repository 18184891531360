import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Slider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import { useDispatch } from "react-redux";
import {
  getAllCategories,
  getAllLocations,
  getAllSkills,
} from "../../../redux/LandingPage/LandingPageSlice";
import { jobDatePostArray, jobPostArray } from "../../../Data";

const FilterAccordion = ({
  isDrawer = false,
  selectedFilters,
  data,
  setSelectedFilters,
  handleSetChips,
  handleDeleteChips,
  uncheckCheckbox,
  allChips,
  setExperienceRange,
}) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState();
  const [locations, setLocations] = useState();
  const [skills, setSkills] = useState();

  useEffect(() => {
    uncheckCheckbox(
      setSelectedCategories,
      setSelectedWorkModes,
      setSelectedLocations,
      setSelectedSalaries,
      setSelectedSkills,
      setSelectedJobTypes,
      setSelectedJobPosted
    );
    geCategoriesList();
    getLocationsList();
    getSkillsList();
  }, []);

  const geCategoriesList = () => {
    dispatch(getAllCategories())
      .then((res) => {
        if (res?.error) throw new Error("Something went wrong!");
        const apiData = res?.payload?.data;
        const categories = apiData.map((category) => {
          return {
            id: category?.id,
            label: category?.attributes?.name,
            value: category?.attributes?.name,
          };
        });
        setCategories(categories);
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getLocationsList = () => {
    dispatch(getAllLocations())
      .then((res) => {
        if (res?.error) throw new Error("Something went wrong!");
        const apiData = res?.payload?.data;
        const locations = apiData.map((location) => {
          return {
            id: location?.id,
            label: location?.attributes?.city,
            value: location?.attributes?.city,
          };
        });
        setLocations(locations);
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getSkillsList = () => {
    dispatch(getAllSkills())
      .then((res) => {
        if (res?.error) throw new Error("Something went wrong!");
        const apiData = res?.payload?.data;
        const skills = apiData.map((skill) => {
          return {
            id: skill?.id,
            label: skill?.attributes?.name,
            value: skill?.attributes?.name,
          };
        });
        setSkills(skills);
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const accordionData = [
    { id: 1, title: "Experiences", type: "range", min: 0, max: 25, step: 5 },
    {
      id: 2,
      title: "Categories",
      checkboxes: categories,
    },
    {
      id: 3,
      title: "Work Mode",
      checkboxes: [
        {
          id: 4,
          label: "Work From Office",
          value: "Office",
        },
        { id: 5, label: "Remote", value: "Remote" },
        { id: 6, label: "Hybrid", value: "Hybrid" },
      ],
    },
    {
      id: 4,
      title: "Location",
      checkboxes: locations,
    },
    {
      id: 5,
      title: "Salary",
      checkboxes: [
        { id: 0, label: "1 - 3 Lakhs", value: "1-3 Lakhs" },
        { id: 1, label: "3 - 5 Lakhs", value: "3-5 Lakhs" },
        { id: 2, label: "6 - 8 Lakhs", value: "6-8 Lakhs" },
        { id: 3, label: "9 - 11 Lakhs", value: "9-11 Lakhs" },
      ],
    },
    {
      id: 6,
      title: "Skills",
      checkboxes: skills,
    },
    {
      id: 7,
      title: "Job Type",
      checkboxes: jobPostArray,
    },
    {
      id: 7,
      title: "Job Posted",
      checkboxes: jobDatePostArray,
    },
  ];

  const [expanded, setExpanded] = useState(
    accordionData.map((item) => item.id)
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded
        ? [...expanded, panel]
        : expanded.filter((item) => item !== panel)
    );
  };

  const [rangeValue, setRangeValue] = useState(
    selectedFilters?.experienceRange
  );

  const handleRangeChange = (event, newValue) => {
    setRangeValue(newValue);
    setExperienceRange(newValue);
  };

  const [maxCheckBoxes, setMaxCheckBoxes] = useState({
    Categories: 4,
    "Work Mode": 4,
    Location: 4,
    Salary: 4,
    Skills: 4,
  });

  const [selectedCategories, setSelectedCategories] = useState(
    selectedFilters?.categories || []
  );
  const [selectedWorkModes, setSelectedWorkModes] = useState(
    selectedFilters?.workModes || []
  );
  const [selectedLocations, setSelectedLocations] = useState(
    selectedFilters?.locations || []
  );
  const [selectedSalaries, setSelectedSalaries] = useState(
    selectedFilters?.locations || []
  );
  const [selectedSkills, setSelectedSkills] = useState(
    selectedFilters?.skills || []
  );
  const [selectedJobTypes, setSelectedJobTypes] = useState(
    selectedFilters?.job_type || []
  );
  const [selectedJobPosted, setSelectedJobPosted] = useState(
    selectedFilters?.posted_days || []
  );

  const handleCheckboxChange = (e, category, value, id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      handleSetChips({ id, value, category });
    } else {
      handleDeleteChips({ id, value, category });
    }
    switch (category) {
      case "Categories":
        setSelectedCategories((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Work Mode":
        setSelectedWorkModes((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Location":
        setSelectedLocations((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Salary":
        setSelectedSalaries((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Skills":
        setSelectedSkills((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Job Type":
        setSelectedJobTypes((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Job Posted":
        setSelectedJobPosted((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      // Add cases for other categories as needed
      default:
        break;
    }
  };

  return (
    <div
      className="filter-sidebar"
      style={{
        position: "sticky",
        top: isDrawer ? "50px" : "110px",
        padding: "0px",
        height: isDrawer ? "calc(100vh - 40px)" : "calc(100vh - 140px)",
        overflow: "auto",
      }}
    >
      {accordionData.map((accordion, index) => {
        return (
          <Accordion
            key={accordion.id}
            expanded={expanded.includes(accordion.id)}
            onChange={handleChange(accordion.id)}
            sx={{
              "&.MuiAccordion-root.Mui-expanded": {
                margin: 0,
                border: 0,
                boxShadow: 0,
                borderBottom: "1px solid var(--oh-white-300-color)",
                borderTop:
                  index !== 0 ? "1px solid var(--oh-white-300-color)" : "none",
              },
              "&.MuiAccordion-root": {
                margin: 0,
                border: 0,
                boxShadow: 0,
                borderTop:
                  index !== 0 ? "1px solid var(--oh-white-300-color)" : "none",
                borderBottom: "1px solid var(--oh-white-300-color)",
              },
              ".MuiAccordionSummary-expandIconWrapper": {
                svg: {
                  color: "var(--oh-blue-300-color)",
                },
              },
              ".MuiAccordionDetails-root": {
                padding: 0,
              },
              ".MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "30px",
              },
              ".MuiAccordionSummary-content.Mui-expanded": {
                paddingTop: "5px",
                margin: "10px 0",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${accordion.id}-content`}
              id={`panel${accordion.id}-header`}
            >
              <MuiTypography
                className="oh-accordion-title"
                content={accordion.title}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Box className="oh-accordion-content">
                {index === 0 && accordion.type === "range" && (
                  <Slider
                    defaultValue={0}
                    max={25}
                    value={rangeValue}
                    onChange={handleRangeChange}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    marks={Array.from(
                      {
                        length:
                          (accordion.max - accordion.min) / accordion.step + 1,
                      },
                      (_, i) => ({
                        value: accordion.min + accordion.step * i,
                        label: `${accordion.min + accordion.step * i}`,
                      })
                    )}
                    sx={{
                      "& .MuiSlider-markLabel": {
                        fontSize: "12px", // Adjust font size for marks
                      },
                      ".MuiSlider-thumb ": {
                        backgroundColor: "white",
                      },
                      ".MuiSlider-rail": {
                        backgroundColor: "#95a7f7",
                      },
                    }}
                  />
                )}
                {index !== 0 &&
                  accordion.checkboxes &&
                  accordion.checkboxes
                    .slice(0, maxCheckBoxes[accordion.title])
                    .map((checkbox) => (
                      <FormControlLabel
                        sx={{
                          alignItems: "flex-start !important",
                          ".MuiTypography-root": {
                            paddingRight: "5px",
                            wordBreak: "break-word",
                            fontSize: "14px",
                          },
                        }}
                        key={checkbox.id}
                        control={
                          <Checkbox
                            sx={{
                              "&.MuiCheckbox-root": {
                                paddingTop: "2px",
                                "& .MuiSvgIcon-root": {
                                  width: "16px",
                                  height: "16px",
                                  borderRadius: "4px",
                                },
                              },
                            }}
                            checked={
                              index === 1
                                ? selectedCategories.includes(checkbox.value)
                                : index === 2
                                  ? selectedWorkModes.includes(checkbox.value)
                                  : index === 3
                                    ? selectedLocations.includes(checkbox.value)
                                    : index === 4
                                      ? selectedSalaries.includes(checkbox.value)
                                      : index === 5
                                        ? selectedSkills.includes(checkbox.value)
                                        : index === 6
                                          ? selectedJobTypes.includes(checkbox.value)
                                          : index === 7
                                            ? selectedJobPosted.includes(checkbox.value)
                                            : false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                accordion.title,
                                checkbox.value,
                                checkbox?.id
                              )
                            }
                          />
                        }
                        label={checkbox.label}
                        value={checkbox.value}
                      />
                    ))}
                {accordion.checkboxes &&
                  accordion.checkboxes.length >
                  maxCheckBoxes[accordion.title] && (
                    <div>
                      <Button
                        sx={{
                          textTransform: "none",
                          "&.MuiButton-root": {
                            padding: "6px 2px",
                            margin: "5px 15px 15px",
                            fontSize: "12px",
                            color: "var(--oh-strongpink-color)",
                          },
                        }}
                        onClick={() => {
                          setMaxCheckBoxes((prevMax) => ({
                            ...prevMax,
                            [accordion.title]: accordion.checkboxes.length,
                          }));
                        }}
                      >
                        + {accordion.checkboxes.length - 4} More
                      </Button>
                    </div>
                  )}
                {accordion.checkboxes && maxCheckBoxes[accordion.title] > 4 && (
                  <div>
                    <Button
                      sx={{
                        textTransform: "none",
                        "&.MuiButton-root": {
                          padding: "6px 2px",
                          margin: "5px 15px 15px",
                          fontSize: "12px",
                          color: "var(--oh-strongpink-color)",
                        },
                      }}
                      onClick={() => {
                        setMaxCheckBoxes((prevMax) => ({
                          ...prevMax,
                          [accordion.title]: 4,
                        }));
                      }}
                    >
                      View Less
                    </Button>
                  </div>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default FilterAccordion;
