import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";
import { JOB_POST, SKILLS } from "../../apis/Constant/routes";

const initialState = {
  job_post: null,
  jobListing: [],
  editJobPost: {},
  jobSkills: [],
  openPositions: [],
  pastOpenings: [],
  newOpeningsData: {
    title: "",
    vacancies: null,
    experience: "",
    ctc: "",
    industry: "",
    mode: "",
    education: "",
    skills: [],
    location: [],
    description: "",
    designation: "",
  },
  popupOpenStates: {
    openLocationsList: false,
    openSkillsList: false,
  },
  created_skills:[]
};

export const createJobPost = createAsyncThunk(
  "jobPost/create-job",
  async (params, thunkApi) => {
    return await AxiosApi({
      path: JOB_POST,
      type: "POST",
      data: JSON.stringify(params),
    });
  }
);

export const createSkills = createAsyncThunk(
  "jobPost/create-skills",
  async (params, thunkApi) => {
    return await AxiosApi({
      path: SKILLS,
      type: "POST",
      data: JSON.stringify(params),
    });
  }
);

export const showJobPost = createAsyncThunk(
  "showJobPost",
  async (params, thunkApi) => {
    return await AxiosApi({
      path: `${JOB_POST}/${params}`,
      type: "GET",
      params: "",
    });
  }
);

export const updateJobPost = createAsyncThunk(
  "jobPost/update-job",
  async (params, thunkApi) => {
    return await AxiosApi({
      path: `${JOB_POST}/${params.job_id}`,
      type: "PUT",
      data: JSON.stringify(params.data),
    });
  }
);

export const deleteJobPost = createAsyncThunk(
  "jobPost/delete-job",
  async (params, thunkApi) => {
    return await AxiosApi({
      path: `${JOB_POST}/${params}`,
      type: "DELETE",
    });
  }
);

export const getJobPost = createAsyncThunk(
  "jobPost/get-job",
  async (_, thunkApi) => {
    return await AxiosApi({
      path: JOB_POST,
      type: "GET",
    });
  }
);
export const getOpenJobs = createAsyncThunk("getOpenJobs", async () => {
  return await AxiosApi({
    path: `/job_posts/?search[is_active]=true`,
    // &page=${page}&per_page=${pageSize}`,
    type: "GET",
  });
});
export const getPastJobs = createAsyncThunk(
  "getPastJobs",
  async (_, thunkApi) => {
    return await AxiosApi({
      path: "/job_posts/?search[is_active]=false",
      type: "GET",
    });
  }
);

export const getJobSkills = createAsyncThunk(
  "jobPost/skills",
  async (_, thunkApi) => {
    return await AxiosApi({
      path: SKILLS,
      type: "GET",
    });
  }
);

export const jobPostSlice = createSlice({
  name: "jobPost",
  initialState,
  reducers: {
    resetJobPostSlice: () => initialState,
    setJobPost: (state, action) => {
      state.job_post = action.payload;
    },
    setJobPostList: (state, action) => {
      state.jobListing = action.payload;
    },
    setEditData: (state, action) => {
      state.editJobPost = action.payload;
    },
    setOpenPosition: (state, action) => {
      state.openPositions = action.payload;
    },
    setPastPosition: (state, action) => {
      state.pastOpenings = action.payload;
    },
    setNewOpeningsData: (state, action) => {
      state.newOpeningsData = action.payload;
    },
    setSkillsListState: (state, action) => {
      state.popupOpenStates.openSkillsList = action.payload;
      state.popupOpenStates.openLocationsList = false;
    },
    setLocationsListState: (state, action) => {
      state.popupOpenStates.openSkillsList = false;
      state.popupOpenStates.openLocationsList = action.payload;
    },
    closeAllLists: (state, action) => {
      state.popupOpenStates.openSkillsList = false;
      state.popupOpenStates.openLocationsList = false;
    },
    resetNewOpeningsData: (state, action) => {
      state.newOpeningsData.title = "";
      state.newOpeningsData.vacancies = null;
      state.newOpeningsData.experience = "";
      state.newOpeningsData.ctc = "";
      state.newOpeningsData.industry = "";
      state.newOpeningsData.mode = "";
      state.newOpeningsData.education = "";
      state.newOpeningsData.skills = [];
      state.newOpeningsData.location = [];
      state.newOpeningsData.description = "";
      state.newOpeningsData.designation = "";
    },
    setCreateSkills: (state, action) => {
      state.created_skills = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobPost.pending, (state, action) => {
        // console.log(action, ".....getJobPost.pending");
      })
      .addCase(getJobPost.fulfilled, (state, action) => {
        state.jobListing = action.payload;
        // console.log(action,'.....getJobPost.fulfilled ')
      })
      .addCase(getJobPost.rejected, (state, action) => {
        console.log(action, ".....getJobPost.rejected");
      })
      .addCase(getJobSkills.pending, (state, action) => {
        // console.log(action, ".....getJobSkills.pending");
      })
      .addCase(getJobSkills.fulfilled, (state, action) => {
        state.jobSkills = action.payload;
        // console.log(action,'.....getJobSkills.fulfilled ')
      })
      .addCase(getJobSkills.rejected, (state, action) => {
        console.log(action, ".....getJobSkills.rejected");
      });
  },
});
export default jobPostSlice.reducer;
export const {
  setJobPost,
  setJobPostList,
  setEditData,
  setOpenPosition,
  setPastPosition,
  setNewOpeningsData,
  setSkillsListState,
  setLocationsListState,
  closeAllLists,
  resetNewOpeningsData,
  setCreateSkills
} = jobPostSlice.actions;
