import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import "./index.scss";

const MuiSelect = ({
  fullWidth = true,
  id = "select-field",
  type = "text",
  placeholder = "Please select",
  name = "",
  style = {},
  className = "oh-primary-textfield",
  value = "",
  handleChange = () => { },
  error = false,
  helperText = "",
  InputProps = {},
  props = {},
  select = true,
  multiple = "false",
  selectOptions = [],
  disabled = false,
}) => {
  return (
    <TextField
      sx={{
        ...style,

        ".Mui-disabled .MuiOutlinedInput-notchedOutline": {
          background: "rgba(0, 0, 0, 0.03)"
        },
        ".MuiInputBase-root svg": {
          right: "20px",
        },
      }}
      variant="outlined"
      fullWidth={fullWidth}
      id={id}
      type={type}
      disabled={disabled}
      placeholder={value ? "" : placeholder} // Conditionally set placeholder
      name={name}
      className={`${className} oh-primary-textfield oh-select-customize`}
      value={value}
      mulitple={multiple}
      select={select}
      label={placeholder}
      onChange={(e) => {
        handleChange(e);
      }}
      InputProps={InputProps}
      error={error}
      helperText={helperText}
      InputLabelProps={{
        shrink: false,
        style: {
          color: value ? "transparent" : "#8C8DA3",
          fontSize: "14px",
          paddingLeft: "8px",
        },
      }}
      {...props}
    >
      {select &&
        selectOptions.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default MuiSelect;
