import React from "react";
import Box from "@mui/material/Box";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import MuiButtonContained from '../../../mui/Buttons/MuiButtonContained';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';

//ASSETS
import DeleteIcon from "../../../assets/images/delete-icon.svg";

//CSS
import "./index.scss";
import CustomLoadingButton from "../../Loader/LoadingButton";

const DeletePopup = ({
    loading = false,
    heading = "",
    description = "",
    isDelete = false,
    handleYes = () => { },
    handleNo = () => { }
}) => {
    return (
        <Dialog onClose={handleNo} open={isDelete}>
            <Box className="oh-delete-main-box">
                <Box className="oh-close-icon" onClick={() => handleNo()} >
                    <CloseIcon />
                </Box>
                <img className="oh-delete-icon" src={DeleteIcon} alt="delete" />
                <Box className="oh-deletepopup-box">
                    <MuiTypography className="oh-heading" content={heading} />
                    <MuiTypography
                        className="oh-description"
                        content={description}
                    />
                    <Box className="oh-button-box">
                        <CustomLoadingButton
                            title={"Yes"}
                            loading={loading}
                            type='button'
                            className={"oh-yes"}
                            onClick={() => handleYes()}
                        />
                        <MuiButtonContained
                            title={"No"}
                            variant={"contained"}
                            type={"button"}
                            className={"oh-no"}
                            onClick={() => handleNo()}
                        />
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}


export default DeletePopup;

