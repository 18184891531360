import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosApi } from '../../apis/AxiosApi'
import { HIRING_ASSISTANCE } from '../../apis/Constant/routes'

const initialState = {
  hiring: null,
}

export const getHiringAssistance = createAsyncThunk(
  'HiringAssistance',
  async (_, thunkApi) => {
    return await AxiosApi({
      path: HIRING_ASSISTANCE,
      type: 'GET'
    })
  }
)

export const hiringAssistanceSlice = createSlice({
  name: 'HiringAssistance',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getHiringAssistance.pending, (state, action) => {
        console.log(action, '.....getHiringAssistance.pending')
      })
      .addCase(getHiringAssistance.fulfilled, (state, action) => {
        state.hiring = action.payload
        // console.log(action,'.....getHiringAssistance.fulfilled ')
      })
      .addCase(getHiringAssistance.rejected, (state, action) => {
        console.log(action, '.....getHiringAssistance.rejected')
      })
  }
})
export default hiringAssistanceSlice.reducer
