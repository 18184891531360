// import React from 'react'
import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
//ASSETS
import requestPay from '../../../assets/images/Mask group.svg'
import MuiButtonContained from '../../../mui/Buttons/MuiButtonContained'
import { Box } from '@mui/material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const RequestToPay = ({ open, handleClose }) => {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
          Request for Payment
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            '&.MuiDialogContent-root': {
              px: 0,
              pt: 0,
              pb:10
            }
          }}
        >
          <Box>
            <Typography gutterBottom>
              <img src={requestPay} alt='requesToPay' />
            </Typography>
            <Typography
              gutterBottom
              sx={{ textAlign: 'center', color: '#445386', fontSize: '14px' }}
            >
              Requested Amount
            </Typography>
            <Typography
              gutterBottom
              sx={{
                color: '#445386',
                textAlign: 'center',
                fontSize: '32px',
                fontWeight: '800'
              }}
            >
              ₹50,000
            </Typography>
            <Typography
              gutterBottom
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <MuiButtonContained
                type='submit'
                title='Send'
                autoFocus
                onClick={handleClose}
                style={{ width: '35% !important' }}
              />
            </Typography>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  )
}
export default RequestToPay
