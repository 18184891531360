import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//OTHER


//CSS
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getCandidateEarnings,
  sendRequestApi,
  setCandidateEarning,
} from "../../../../redux/Freelancer/earningDetailSlice";
import moment from "moment";
import NoJobsFound from "../../../../components/NotFound/NoJobsFound";
import ListingTableWithPagination from "../../../../components/Table/ListingTableWithPagination";
import NoCandidatesDataInTable from "../../../../components/Table/NoCandidatesFound";
import { Toaster } from "../../../../components/Toaster/Toaster";

const EarningTable = ({ loadEarning, setLoadEarning, rowCount, setRowCount, page, setPage, popupState, }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = () => { };
  const pageSize = 10;

  const { candidateEarning } = useSelector((state) => state.earningData);

  const handlePageChange = (newPage) => {
    let page = newPage + 1;
    setPage(page);
  };

  useEffect(() => {
    setLoadEarning(true);
    popupState == "" && getEarningCandidatesData();
  }, [page]);

  const getEarningCandidatesData = () => {
    setLoadEarning(true);
    dispatch(getCandidateEarnings({ page: page, pageSize }))
      .then((res) => {
        if (res?.type === "getCandidateEarnings/fulfilled") {
          dispatch(setCandidateEarning(res?.payload?.data));
          setRowCount(res?.payload?.meta?.total_records);
          setLoadEarning(false);
        } else if (res?.type === "getCandidateEarnings/rejected") {
          setLoadEarning(false);
        }
      })
      .catch((err) => {
        setLoadEarning(false);
        console.log(err)
      });
  };

  const handleSendRequest = (id) => {
    // handleClickOpen();
    dispatch(sendRequestApi(id))
      .then((res) => {
        if (res?.type === "sendRequestApi/fulfilled") {
          getEarningCandidatesData();
          if (res.payload.success) {
            Toaster.TOAST(res.payload.message, "success");
          } else {
            Toaster.TOAST(res.payload.message, "error", false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const headCells = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      renderCell: (params) => {
        return (
          <span style={{ cursor: "pointer" }} onClick={() => handleNavigate()}>
            {params?.row?.id || "-"}
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{ cursor: "pointer" }} onClick={() => handleNavigate()}>
            {params?.row?.attributes?.candidate_detail?.full_name || "-"}
          </span>
        );
      },
    },
    {
      field: "designation",
      headerName: "Designation",
      sortable: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.attributes?.candidate_detail?.designation || "-"}
          </span>
        );
      },
    },
    {
      field: "experience",
      headerName: "Year of Experience",
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.attributes?.candidate_detail?.experience || "-"}
          </span>
        );
      },
    },
    {
      field: "date_of_joining",
      headerName: "Date of Joining",
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <span>
            {moment(
              params?.row?.attributes?.candidate_detail?.date_of_joining
            ).format("DD MMM,YY") || "-"}
          </span>
        );
      },
    },
    {
      field: "earning_amount",
      headerName: "Earning Amount",
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <span>
            <span className="oh-earning-currency">₹</span>
            <span className="oh-earning-amount">
              {params?.row?.attributes?.earning_amount || "-"}
            </span>
          </span>
        );
      },
    },
    {
      field: "request_to_pay",
      headerName: "Requested to Pay",
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <span
            className="oh-request-pay"
            onClick={() => handleSendRequest(params.row?.id)}
          >
            {/* {params.row?.request_to_pay || "Not requested"} */}
            Send Request
          </span>
        );
      },
    },
  ];

  return (
    <ListingTableWithPagination
      hideFooterPagination={false}
      rows={candidateEarning}
      columns={headCells}
      pageSize={pageSize}
      rowCount={rowCount}
      rowsPerPageOptions={[pageSize]}
      page={page - 1}
      loader={loadEarning}
      paginationMode="server"
      onPageChange={handlePageChange}
      customNoResultsComponent={() => <NoCandidatesDataInTable />}
    />
  )
};
export default EarningTable;
