import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";
import {
  EMPLOYER,
  JOB_SEEKER_DETAIL,
  NEW_APPLICANT_DESIGNATION,
} from "../../apis/Constant/routes";

const initialState = {
  data: [],
};

export const getApplicantApi = createAsyncThunk(
  "getApplicantList/list",
  async (id) => {
    return await AxiosApi({
      // path: `${JOB_SEEKER_DETAIL}/${NEW_APPLICANT_DESIGNATION}`,
      path: `/employer_details/new_applicants_by_disignation?employer_id=${id}`,
      type: "GET",
    });
  }
);
