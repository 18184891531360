import React, { useEffect } from "react";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// OTHER
import { setFirstLoginWelcomePopup, setHideMenuBtn, setIsPublicHeader, updateFirstLoginAttribute } from "../redux/globalSlice";
import { checkIfAuthPage, smoothScroll } from "../utils";
import WelcomeFirstLoginDialog from "../components/Dialog/WelcomeFirstLoginDialog";
import { setUserData } from "../redux/authSlice";
import { setProfilePhoto } from "../redux/profileSlice";

const AppLayout = ({ layout }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { firstLoginWelcomePopup } = useSelector(
    (state) => state.app
  );
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const { pathname } = location;
    const checkForPath = pathname === "/signup-proceed" || pathname === "/two-factor-verification";
    dispatch(setIsPublicHeader({ isPublicHeader: !!checkIfAuthPage() }));
    dispatch(setHideMenuBtn(checkForPath))
    smoothScroll();
  }, [location.pathname]);

  useEffect(() => {
    if (user?.first_login) {
      dispatch(setFirstLoginWelcomePopup(true));
    }
    if (user?.profile_photo) {
      dispatch(setProfilePhoto(user?.profile_photo));
    }
  }, [user])


  const handleClose = () => {
    dispatch(updateFirstLoginAttribute()).then((res) => {
      if (res.type === "updateFirstLoginAttribute/fulfilled") {
        let user_data = user;
        user_data = { ...user_data, first_login: false };

        localStorage.setItem("login_data", JSON.stringify(user_data));
        dispatch(setUserData(user_data));
      }
    })
    dispatch(setFirstLoginWelcomePopup(false));
  };

  return (
    <>
      <Outlet />
      {
        firstLoginWelcomePopup && <WelcomeFirstLoginDialog heading={`Welcome to the Octahire!`} popupState={firstLoginWelcomePopup} handleClose={handleClose} />
      }
    </>
  );
};

export default AppLayout;
