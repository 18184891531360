export const reactSelectChipsetStyling = {
    multiValue: provided => ({
        ...provided,
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--oh-blue-300-color)',
        fontWeight: 400,
        border: '0.5px solid #3a3e4e4f',
        borderRadius: '20px',
        width: 'fit-content', // Updated width to auto
        height: "25px",
        padding: '1px 5px 0px', // Updated padding
        fontSize: '14px', // Updated font size
        lineHeight: '160%', // Added line height
        marginTop: '2px', // Added margin top
        marginRight: "10px",
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        svg: {
            background: "var(--oh-blue-300-color)",
            borderRadius: "50%",
            color: "#fff",
            padding: "0.5px 2px",
        }
    }),
    multiValueLabel: provided => ({
        ...provided,
        color: "var(--oh-blue-300-color)",
        fontSize: "13px",
        fontWeight: "400",
        letterSpacing: "0.2px",
        fontFamily: "Helvetica, 'sans-serif'"
    }),
    multiValueRemove: provided => ({
        ...provided,
        color: "var(--oh-blue-300-color)",
        background: "transparent",
        width: "25px",
        height: "25px",
        transform: "translateY(-1px)",
        borderRadius: "50%",

        '&:hover': {
            color: "var(--oh-blue-300-color)",
            background: "transparent",
        },
    }),
    control: (provided) => ({
        ...provided,
        width: '100%',
        minWidth: '200px',
        // maxWidth: '400px',
        borderRadius: '28px',
        border: '0',
        border: '1px solid rgba(0, 0, 0, 0.27)',
        backgroundColor: 'white',
        maxHeight: 'auto',
        minHeight: "47px",
        padding: "6px 2px",
    }),
    input: (provided) => ({
        ...provided,
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: 'transparent',
        maxHeight: 'auto',
        paddingRight: "30px"
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "rgba(0, 0, 0, 0.3)",
        fontSize: "14px"
    }),
    menu: (provided) => ({
        ...provided,
        padding: '8px', // Add padding to the menu
        borderBottom: 'none', // Remove the divider
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none"
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        padding: "0",
        color: "#rgba(0, 0, 0, 0.54)",
        marginRight: "6px",
        position: 'absolute', // Make the position absolute
        right: '2px', // Position the container to the right
        // top: '8px', // Position the container to the top
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        marginTop: '-3px', // Move the dropdown indicator upwards
        border: 'none', // Remove the border around the dropdown indicator
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '2px', // Set margin-top
        borderRadius: '4px', // Set border radius
        boxShadow: '0px 0px 10px 0px var(--oh-white-500-color)', // Set box shadow
        border: '1px solid rgba(0, 0, 0, 0.1)', // Set border
        color: 'var(--oh-blue-600-color)', // Set text color
    }),
    option: (provided, state) => ({
        ...provided,
        background: "#fff",
        padding: '10px 20px 8px', // Set padding
        fontWeight: '400', // Set font weight
        borderTop: '0.5px solid var(--oh-gray-500-color)', // Set top border
        borderRight: '0', // Remove right border
        borderLeft: '0', // Remove left border
        fontSize: '14px', // Set font size
        backgroundColor: state.isFocused ? 'var(--oh-bgcolor-color)' : '#fff', // Change background color on focus
        color: state.isSelected ? '#fff' : 'inherit', // Change text color if option is selected
        letterSpacing: "0.2px",
        fontFamily: "Helvetica, 'sans-serif'",
        '&:hover': {
            background: 'var(--oh-bgcolor-color)', // Change background color on hover
            color: '#fff', // Change text color on hover
        },
    }),

}