import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const FilterMenu = ({ menuItems, anchorEl, open, handleClose, handleFilter, filter }) => {


  return (
    <Menu
      id="menu"
      sx={{
        overflow: "auto",
        ".MuiMenuItem-root": {
          color: "var(--oh-blue-300-color) !important",
          padding: "6px 25px !important",
          textAlign: "center",
          justifyContent: "center",
          p: {
            color: "var(--oh-blue-300-color)",
            textAlign: "center"
          }
        }
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      getContentAnchorEl={null}
    >
      {menuItems.map((item) => (
        <MenuItem
          key={item.value}
          className={filter === item.value ? 'selected-active' : ''}
          onClick={() => handleFilter(item.value)}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default FilterMenu;
