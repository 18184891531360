import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { ReactComponent as Clock } from "../../../assets/images/clock.svg";
import moment from "moment";
import { checkIfHtml, parseHTML, truncateCharacter, truncateDescription } from "../../../utils";
import { useNavigate } from "react-router-dom";
import DummyBlogImage from "../../../assets/images/dummy-blog-image.png";
import "./blogCard.css";

const BlogCard = ({
  id,
  image,
  tag,
  date,
  category,
  title,
  description,
  slug,
}) => {
  const navigate = useNavigate();
  const data = {
    id,
    image,
    tag,
    date,
    category,
    title,
    description,
    slug,
  };

  const handleReadMore = () => {

    navigate(`/blogs/${slug}`, {
      state: data,
    });
  };


  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        boxShadow={"0px 4px 12px 0px rgba(143, 155, 178, 0.20)"}
        borderRadius={"4px"}
        bgcolor={"#fff"}
      // height={"474px"}

      >
        <Box
          // width={"100%"}
          // position={"relative"}
          className="image-container"
        >
          <img
            src={image || DummyBlogImage}
            alt="card_image"
            style={{
              // maxWidth: "100%",
              // height: "auto", cursor: "pointer"
            }}
            onClick={handleReadMore}
          />
          {/* <Box
            position={"absolute"}
            bottom={"10px"}
            left={"12px"}
            display={"inline-flex"}
            padding={"2px 8px"}
            style={{
              background: "linear-gradient(180deg, #576EDB 0%, #8957DB 100%)",
            }}
          >
            <Typography fontSize={"14px"} fontWeight={400} color={"#fff"}>
              {tag}
            </Typography>
          </Box> */}
        </Box>
        <Box padding={"15px"}>
          <Box display={"flex"} alignItems={"center"}>
            <Clock />
            <Typography
              fontSize={{ xs: "12px", sm: "14px" }}
              fontWeight={400}
              color={"#A1A7BC"}
              ml={1}
            >
              {/* 25 March 2024 */}
              {moment(date).format("DD MMMM YYYY")}
            </Typography>
          </Box>
          <Box
            // bgcolor={"#576edb"}
            display={"inline-flex"}
            alignItems={"center"}
            padding={"5px 10px"}
            borderRadius={"30px"}
            my={"3px"}
            border={"1px solid #A1A7BC"}
          >
            <Typography
              letterSpacing={"0.5px"}
              fontSize={"12px"}
              color={"#A1A7BC"}
              fontWeight={600}
            >
              {category}
            </Typography>
          </Box>
          {title && checkIfHtml(title) ? (
            <div
              style={{
                margin: "5px 0 10px 0",
                fontSize: "20px",
                fontWeight: "700",
                color: "#202020",
                lineHeight: "32px",
                width: "95%",
                overflow: "hidden",
                height: "96px", // Fixed height to accommodate 3 lines
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: title.length > 80 ? 3 : 1, // Show 3 lines if content length > 80
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                title,
              }}

              dangerouslySetInnerHTML={parseHTML(
                truncateCharacter(title, 80)
              )}
            ></div>
          ) : (
            <Box marginBottom={"10px"} marginTop={"5px"}>
              <Typography
                fontSize={"20px"}
                fontWeight={700}
                color={"#202020"}
                lineHeight={"32px"}
                width={"95%"}
                overflow={"hidden"}
                display={"-webkit-box"}
                WebkitLineClamp={title.length > 80 ? 3 : 1} // Show 3 lines if content length > 80
                textOverflow={"ellipsis"}
                whiteSpace={"normal"}
                sx={{
                  height: "96px", // Fixed height to accommodate 3 lines
                }}
                title={title}
              >
                {truncateCharacter(title, 80)}
              </Typography>
            </Box>
          )}

          {description && checkIfHtml(description) ? (
            <div
              style={{
                margin: "5px 0 10px 0",
                height: "52px", // Fixed height for two lines of text (adjust based on line-height)
                overflow: "hidden", // Hides overflow text
                fontSize: "16px",
                fontWeight: "400",
                color: "#202020",
                lineHeight: "160%", // Adjust this to control the line height
                display: "-webkit-box", // Required for line clamping
                WebkitBoxOrient: "vertical", // Specifies vertical box orientation
                WebkitLineClamp: 2, // Limits to two lines
                textOverflow: "ellipsis", // Adds "..." when truncated
              }}
              className="blog-description"
              dangerouslySetInnerHTML={{
                __html: description, // Render HTML safely
              }}
            ></div>
          ) : (
            <Box
              marginBottom={"10px"}
              marginTop={"5px"}
              height={"52px"} // Fixed height for two lines of text
              overflow={"hidden"} // Hides overflow text
            >
              <Typography
                fontSize={"16px"}
                fontWeight={400}
                color={"#202020"}
                lineHeight={"160%"} // Adjust this to control the line height
                sx={{
                  display: "-webkit-box", // Required for line clamping
                  WebkitBoxOrient: "vertical", // Specifies vertical box orientation
                  WebkitLineClamp: 2, // Limits to two lines
                  textOverflow: "ellipsis", // Adds "..." when truncated
                  overflow: "hidden", // Hides overflow content
                }}
              >
                {truncateCharacter(description, 70)}
              </Typography>
            </Box>
          )}


          <Box
            display={"flex"}
            justifyContent={"space-between"}
            marginTop={"20px"}
          >
            <Button
              sx={{
                width: { xs: "100%", sm: "140px" },
                // width: "125px",
                borderRadius: "30px",
                background: "#576EDB",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "14px 16px",
                color: "#fff",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                "&:hover": {
                  background: "#576EDB",
                },
              }}
              onClick={handleReadMore}
            >
              Read More
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BlogCard;
