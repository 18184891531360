import * as Yup from "yup";
import {
  EMAIL_REGEX,
  STRONGPASSWORD_REGEX,
  PHONE_REGEX,
  MOBILE_NUMBER_REGEX_2,
  FIRSTNAME_REGEX,
  EMPTY_SPACES,
} from "../utils/regex";
import { errMessageMaximum, errMessageMinimum } from "../utils";

export const firstLastNameSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(FIRSTNAME_REGEX, "Please enter a valid first name")
    .max(40, "First name can not be more than 40 characters")
    .required("First name can't be empty"),
  last_name: Yup.string()
    .matches(FIRSTNAME_REGEX, "Please enter a valid last name")
    .max(40, "Last name can not be more than 40 characters")
    .required("Last name can't be empty"),
})

export const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL_REGEX, "Please enter a valid email Id")
    // .max(40, errMessageMaximum('Email', 40))
    // .min(5, errMessageMinimum('Email', 5))
    .required("Email can't be empty"),
  mobile: Yup.string()
    .matches(MOBILE_NUMBER_REGEX_2, "Please enter a valid Mobile number")
    .max(10, "Mobile number must be exactly 10 characters")
    .required("Mobile number can't be empty"),
  password: Yup.string()
    .matches(
      STRONGPASSWORD_REGEX,
      "Password must be at least one uppercase, lowercase, special character and number"
    )
    .matches(EMPTY_SPACES, "Password can not contain empty spaces.")
    .max(40, "Password can not be more than 40 characters")
    .min(8, "Password must be atleast 8 characters long!")
    .required("Password can't be empty"),
  agree: Yup.boolean().oneOf([true], "Please accept terms and conditions."),
});
