import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { USERS } from "../apis/Constant/routes";
import { AxiosApi } from "../apis/AxiosApi";

const initialState = {
  profilePhoto: null,
};

export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (data) => {
    return await AxiosApi({
      path: `${USERS}/${data.id}`,
      type: "PUT",
      data: data.formData,
    });
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfilePhoto: (state, action) => {
      state.profilePhoto = action.payload;
    },
  },
});

export const { setProfilePhoto } = profileSlice.actions;
export default profileSlice.reducer;
