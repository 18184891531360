import React, { useState } from "react";

//MUI
import Grid from "@mui/material/Grid";

//OTHER
//CSS
import "./index.scss";
import FreelancerLayout from "../../Layouts/Freelancer/FreelancerLayout";
import ReferredCan from "../../../components/Freelancer/AllotedJobSection/AllJobs/ReferredCan";

const ReferredCandidates = ({ onClick = () => { } }) => {
    return (
        <FreelancerLayout>
            <Grid container lg={12} mt={1} className="oh-alloted-job">
                <Grid item xs={12} md={12} lg={12}>
                    <ReferredCan />
                </Grid>
            </Grid>
        </FreelancerLayout>
    )
}

export default ReferredCandidates;