import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getFaqs, setFaqData } from "../redux/faqSlice";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../landingPage/components/Layout/Layout";

const FrequentlyAskedQuestions = () => {
  const [expanded, setExpanded] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const faqData = useSelector((state) => state.faq.faqData);

  const getFaqData = () => {
    let pathName = window.location.pathname
    let apiEndPoint = ''
    if(pathName.includes('faq')) {
      apiEndPoint = getFaqs({ general: true })
    } else {
    apiEndPoint = params?.user_type
      ? getFaqs({ general: true })
      : getFaqs();
    }
    dispatch(apiEndPoint).then((res) => {
      if (res.type === "getFaqs/fulfilled") {
        dispatch(setFaqData(res?.payload?.data));
      }
    });
  };

  useEffect(() => {
    getFaqData();
  }, []);

  const handleAccordionChange = (index) => () => {
    const currentIndex = expanded.indexOf(index);
    const newExpanded = [...expanded];
    if (currentIndex === -1) {
      newExpanded.push(index);
    } else {
      newExpanded.splice(currentIndex, 1);
    }
    setExpanded(newExpanded);
  };

  return (
    <>
      <Layout>

      <Box
          marginTop={{ xs: "75px", sm: "80px" }}
          backgroundColor={"#F1F3FC"}
          textAlign={"center"}
          padding={{ xs: "30px 0px", sm: "40px 0px" }}
          // display={"flex"}
          // justifyContent={"center"}
          alignItems={"center"}
          // height={{ xs: "94px", sm: "188px" }}
        >
          <Typography
            fontSize={{ xs: "20px", sm: "24px" }}
            fontWeight={700}
            color={"#141621"}
          >
            Frequently Asked Questions
            <div style={{ display: "flex", justifyContent: "center"}}>
              <Typography className={"subheading"} style={{ color: "rgb(123 127 139)", width: "60%", fontSize: 18}}>
              Explore our FAQs to see how OctaHire simplifies managing hiring, job applications, and freelancing. 
              We hope you find what you’re looking for; if not, you are always welcome to contact our 
              <span style={{ color: "blue", cursor: "pointer"}} onClick={() => navigate('/contact-us')}> Client Services </span> 
              team directly.
              </Typography>
          </div>
          </Typography>
        </Box>
        <Box
          sx={{
            minHeight: "60vh",
          }}
          className="oh-faq-container"
        >
          <div className="oh-accordion-container">
            {faqData?.length > 0 ? (
              faqData?.map((elem, index) => {
                const isExpanded = expanded.includes(index);
                return (
                  <div key={index}>
                    {index != 0 && <Divider className="oh-divider" />}
                    <Accordion
                      sx={{
                        "&.MuiPaper-root.MuiAccordion-root": {
                          boxShadow: "none",
                          "&::before": {
                            backgroundColor: "transparent !important",
                          },
                        },
                      }}
                      expanded={isExpanded}
                      onChange={handleAccordionChange(index)}
                      className="oh-accordion"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}a-content`}
                        id={`panel${index + 1}a-header`}
                        sx={{ height: "fit-content !important" }}
                      >
                        <p
                          className={`oh-accordion-question ${
                            isExpanded ? "oh-expanded" : ""
                          }`}
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: isExpanded
                              ? "var(--oh-blue-color)"
                              : "black",
                          }}
                        >
                          {elem.attributes.question}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails className="oh-accordion-details">
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: elem.attributes.answer,
                          }}
                        ></Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })
            ) : (
              <Box textAlign={"center"} fontWeight={700} fontSize={"16px"}>
                No FAQ's at the moment!
              </Box>
            )}
          </div>
        </Box>
      </Layout>
    </>
  );
};

export default FrequentlyAskedQuestions;
