import React, { useState } from "react";
// MUI
import Box from "@mui/material/Box";
// OTHER
import MuiBreadcrumb from "../../../mui/BreadCrumbs/MuiBreadcrumbs";
import FilterAccordion from "../../../components/Accordion/FilterAccordion";
// CSS
import "../../Layouts/layout.scss";
import "./index.scss";
import Header from "../../../components/Header/Header";
import LandingPageHeader from "../../../landingPage/components/header/header";

const SearchLayout = ({
  children,
  showFilter = true,
  landingPage = false,
  className = "oh-search-background-setting",
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    categories: [],
    workModes: [],
    locations: [],
    skills: [],
    experienceRange: [0, 1],
  });
  return (
    <>
      {landingPage ? <LandingPageHeader /> : <Header />}
      <Box container className={className}>
        {showFilter && (
          <Box className="primary-filter-navigation-bar">
            <FilterAccordion
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              isDrawer={true}
            />
          </Box>
        )}
        <Box>
          <MuiBreadcrumb />
          <Box>{children}</Box>
        </Box>
      </Box>
    </>
  );
};

export default SearchLayout;
