import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

//MUI
import Box from "@mui/material/Box";

//OTHER
import Sidebar from "../../../components/Sidebar/Sidebar";
import MuiBreadcrumb from "../../../mui/BreadCrumbs/MuiBreadcrumbs";
import NotAuthorized from "../../../components/NotFound/NotAuthorized";
import Header from "../../../components/Header/Header";

// CSS
import "../layout.scss";
import Loader from "../../../components/Loader/Loader";

const FreelancerLayout = ({ children }) => {
  const location = useLocation();
  const { portalName } = useSelector((state) => state.app);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    localStorage.removeItem("form_type");
    localStorage.removeItem("auth_via");

    // Mimic async state fetching/updating preventing default not authorized page to show
    setTimeout(() => {
      setLoading(false);
    }, 10);

  }, []);

  if (loading) {
    return <Loader />;
  }


  return (
    <>
      {portalName === "Freelancer" ? (
        <>
          <Header />
          <Box
            container
            className="oh-background-setting"
          >
            <Sidebar className="primary-navigation-bar" />
            <Box>
              {location.pathname !== "/freelancer" && <MuiBreadcrumb />}
              {children}
            </Box>
          </Box>
        </>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
};

export default FreelancerLayout;
