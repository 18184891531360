import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import "./index.scss";
import MuiButtonOutlined from "../../mui/Buttons/MuiButtonOutlined";
import MuiButtonContained from "../../mui/Buttons/MuiButtonContained";
import MuiDivider from "../../mui/Dividers/MuiDivider";
import {
  getCategoriesData,
  getFilteredData,
  getLocationData,
  getSkillsData,
} from "../../redux/JobSeeker/filterApis";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  searchForEmployer,
  setSearchTerm,
  setSearchedJobs,
} from "../../redux/Employer/searchSlice";
import {
  getOpenJobs,
  getPastJobs,
  setOpenPosition,
  setPastPosition,
} from "../../redux/Employer/JobPostSlice";
import {
  getAllActiveJobs,
  getAppliedJobs,
  setAllActiveJobs,
  setAppliedJobs,
  setTopHiringJobs,
} from "../../redux/JobSeeker/jobSeekerJobsSlice";
import {
  getAllAppliedJobsData,
  getAllotedJobsData,
  setAllAppliedJobs,
  setAllotedJobs,
} from "../../redux/Freelancer/allotedJobsSlice";
import { jobPostArray, jobDatePostArray } from "../../Data";

const FilterAccordion = ({
  onClose = () => { },
  isDrawer = false,
  selectedFilters,
  data,
  setSelectedFilters,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    geCategories();
    getSkills();
    getLocation();
  }, []);
  const [categories, setCategories] = useState([]);
  const Location = useLocation();
  const currentPath = Location.pathname;
  const appliedJobsInJobSeeker = currentPath === "/job-status";
  const openPositionsInEmp = currentPath === "/open-positions";
  const pastOpeningsInEmp = currentPath === "/past-openings";
  const { portalName } = useSelector((state) => state.app);
  const { search } = useSelector((state) => state.searchForEmployer);
  const [skills, setSkills] = useState([]);
  const [location, setLocation] = useState([]);
  const [rangeValue, setRangeValue] = useState(
    selectedFilters?.experienceRange
  );
  const [selectedCategories, setSelectedCategories] = useState(
    selectedFilters?.categories || []
  );
  const [selectedWorkModes, setSelectedWorkModes] = useState(
    selectedFilters?.workModes || []
  );
  const [selectedLocations, setSelectedLocations] = useState(
    selectedFilters?.locations || []
  );
  const [selectedSkills, setSelectedSkills] = useState(
    selectedFilters?.skills || []
  );
  const [selectedJobTypes, setSelectedJobTypes] = useState(
    selectedFilters?.job_type || []
  );
  const [selectedJobPosted, setSelectedJobPosted] = useState(
    selectedFilters?.posted_days || []
  );

  const [maxCheckBoxes, setMaxCheckBoxes] = useState({
    Categories: 4,
    "Work Mode": 4,
    Location: 4,
    Skills: 4,
  });

  const geCategories = () => {
    dispatch(getCategoriesData())
      .then((res) => {
        if (res.type === "getCategoriesData/fulfilled") {
          const Data = res.payload.data || [];
          const categories = Data.map((cat) => {
            return {
              id: cat.id,
              label: cat.attributes.name,
              value: cat.attributes.id,
            };
          });
          setCategories(categories);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getSkills = () => {
    dispatch(getSkillsData())
      .then((res) => {
        if (res.type === "getSkillsData/fulfilled") {
          const Data = res.payload.data || [];
          const skills = Data.map((cat) => {
            return {
              id: cat.id,
              label: cat.attributes.name,
              value: cat.attributes.id,
            };
          });
          setSkills(skills);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getLocation = () => {
    dispatch(getLocationData())
      .then((res) => {
        if (res.type === "getLocationData/fulfilled") {
          const Data = res.payload.data || [];
          const skills = Data.map((cat) => {
            return {
              id: cat.id,
              label: cat.attributes.city,
              value: cat.attributes.id,
            };
          });
          setLocation(skills);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const handleCheckboxChange = (category, value) => {
    switch (category) {
      case "Categories":
        setSelectedCategories((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Work Mode":
        setSelectedWorkModes((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Location":
        setSelectedLocations((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Skills":
        setSelectedSkills((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Job Type":
        setSelectedJobTypes((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      case "Job Posted":
        setSelectedJobPosted((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
        break;
      default:
        break;
    }
  };
  const accordionData = [
    { id: 1, title: "Experiences", type: "range", min: 0, max: 25, step: 5 },
    {
      id: 2,
      title: "Categories",
      checkboxes: categories,
    },
    {
      id: 3,
      title: "Work Mode",
      checkboxes: [
        {
          id: 4,
          label: "Work From Office",
          value: "Office",
        },
        { id: 5, label: "Remote", value: "Remote" },
        { id: 6, label: "Hybrid", value: "Hybrid" },
      ],
    },
    {
      id: 4,
      title: "Location",
      checkboxes: location,
    },
    // {
    //   id: 5,
    //   title: "Salary",
    //   checkboxes: [
    //     { id: 4, label: "0 - 3 Lakhs", value: "valueA" },
    //     { id: 5, label: "3 - 6 Lakhs", value: "valueB" },
    //     { id: 6, label: "6 - 9 Lakhs", value: "valueC" },
    //     { id: 6, label: "9 - 12 Lakhs", value: "valueC" },
    //   ],
    // },
    {
      id: 6,
      title: "Skills",
      checkboxes: skills,
    },
    {
      id: 7,
      title: "Job Type",
      checkboxes: jobPostArray,
    },
    {
      id: 8,
      title: "Job Posted",
      checkboxes: jobDatePostArray,
    },
  ];
  const [expanded, setExpanded] = useState(
    accordionData.map((item) => item.id)
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded
        ? [...expanded, panel]
        : expanded.filter((item) => item !== panel)
    );
  };

  const handleRangeChange = (event, newValue) => {
    setRangeValue(newValue);
  };

  const handleApply = () => {
    const params = {};

    const appendToParams = (key, value) => {
      if (params[key]) {
        params[key].push(value);
      } else {
        params[key] = [value];
      }
    };

    const handlePath = () => {
      const formattedRange = `${rangeValue[0]}-${rangeValue[1]}years`;
      if (rangeValue[0] !== 0 || rangeValue[1] !== 1) {
        params['experience'] = formattedRange;
      }
      selectedCategories?.forEach((item) => {
        appendToParams('categories', item);
      });
      selectedLocations?.forEach((item) => {
        appendToParams('location', item);
      });
      selectedSkills?.forEach((item) => {
        appendToParams('skills', item);
      });
      selectedWorkModes?.forEach((item) => {
        appendToParams('work_mode', item);
      });
      selectedJobTypes?.forEach((item) => {
        appendToParams('job_type', item);
      });
      selectedJobPosted?.forEach((item) => {
        appendToParams('posted_days', item);
      });
    };

    handlePath();

    let apiParams = {};
    if (
      (portalName === "Employer" && openPositionsInEmp) ||
      pastOpeningsInEmp
    ) {
      apiParams = {
        active: openPositionsInEmp,
      };
    } else if (portalName === "Jobseeker") {
      apiParams = {
        appliedJobsInJobSeeker: appliedJobsInJobSeeker,
      };
    }
    if (appliedJobsInJobSeeker || openPositionsInEmp || pastOpeningsInEmp) {
      dispatch(
        getFilteredData({
          ...apiParams,
          // str,
          params,
          authParam: true
        })
      )
        .then((res) => {
          if (res.type === "getFilteredData/fulfilled") {
            const Data = res.payload.data || [];
            if (openPositionsInEmp && portalName) {
              dispatch(setOpenPosition(Data));
            } else if (pastOpeningsInEmp && portalName) {
              dispatch(setPastPosition(Data));
            } else if (appliedJobsInJobSeeker && portalName) {
              dispatch(setAppliedJobs(Data));
            }
          }
        })
        .catch((err) => {
          console.log(err.message, ".err");
        });
    } else if (portalName === "Freelancer" && data?.value) {
      dispatch(
        getFilteredData({
          // str,
          params,
          authParam: true,
          portalName: "Freelancer",
          currentPath: data?.value,
        })
      )
        .then((res) => {
          if (res.type === "getFilteredData/fulfilled") {
            const Data = res.payload.data || [];
            if (data?.value === "all_jobs") {
              dispatch(setAllotedJobs(Data));
            } else if (data?.value === "applied_jobs") {
              dispatch(setAllAppliedJobs(Data));
            }
          }
        })
        .catch((err) => {
          console.log(err.message, ".err");
        });
    } else if (portalName === "Freelancer") {
      const isSearchTermExist = search.length >= 3;
      dispatch(
        getFilteredData({
          // str,
          params,
          authParam: true,
          portalName: "Freelancer",
          isSearched: isSearchTermExist,
          searchTerm: search,
        })
      )
        .then((res) => {
          if (res.type === "getFilteredData/fulfilled") {
            const Data = res.payload.data || [];
            if (currentPath === "/search") {
              dispatch(setSearchedJobs(Data));
            }
          }
        })
        .catch((err) => {
          console.log(err.message, ".err");
        });
    } else {
      dispatch(
        getFilteredData({
          // str,
          params,
          authParam: portalName === "Jobseeker" ? false : true,
          top_hiring: !!(currentPath === "/top-hiring")
        })
      )
        .then((res) => {
          if (res.type === "getFilteredData/fulfilled") {
            const Data = res?.payload?.data || [];
            if (currentPath === "/search") {
              dispatch(setSearchedJobs(Data));
            } else if (currentPath === "/all-jobs") {
              dispatch(setAllActiveJobs(Data));
            } else if (currentPath === "/job-status") {
              dispatch(setAppliedJobs(Data));
            } else if (currentPath === "/top-hiring") {
              dispatch(setTopHiringJobs(res?.payload?.message?.data || res?.payload?.data));
            }
          }
        })
        .catch((err) => {
          console.log(err.message, ".err");
        });
    }
    // if (currentPath !== "/search") {
    setSelectedFilters({
      categories: selectedCategories,
      workModes: selectedWorkModes,
      locations: selectedLocations,
      skills: selectedSkills,
      job_type: selectedJobTypes,
      posted_days: selectedJobPosted,
      experienceRange: rangeValue,
    });
    // }
    onClose();
  };

  const handleReset = () => {
    if (currentPath === "/search") {
      dispatch(searchForEmployer(Location?.state?.search || ""))
        .then((res) => {
          if (res.type === "searchForEmployer/fulfilled") {
            dispatch(setSearchedJobs(res?.payload?.data));
          }
        })
        .catch((err) => console.log(err));
    } else if (currentPath === "/all-jobs") {
      dispatch(getAllActiveJobs({ page: 1, pageSize: 10 }))
        .then((res) => {
          if (res.type === "getAllActiveJobs/fulfilled") {
            dispatch(setAllActiveJobs(res?.payload?.data));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (openPositionsInEmp && portalName) {
      dispatch(getOpenJobs())
        .then((res) => {
          if (res.type === "getOpenJobs/fulfilled") {
            dispatch(setOpenPosition(res?.payload?.data));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (pastOpeningsInEmp && portalName) {
      dispatch(getPastJobs())
        .then((res) => {
          if (res.type === "getPastJobs/fulfilled") {
            dispatch(setPastPosition(res?.payload?.data));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (appliedJobsInJobSeeker && portalName) {
      dispatch(getAppliedJobs({ page: 1, pageSize: 10 }))
        .then((res) => {
          if (res.type === "getAppliedJobs/fulfilled") {
            dispatch(setAppliedJobs(res?.payload?.data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (portalName === "Freelancer" && data.value === "all_jobs") {
      dispatch(getAllotedJobsData())
        .then((res) => {
          if (res.type === "getAllotedJobsData/fulfilled") {
            dispatch(setAllotedJobs(res.payload.data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (portalName === "Freelancer" && data.value === "applied_jobs") {
      dispatch(getAllAppliedJobsData())
        .then((res) => {
          if (res.type === "getAllAppliedJobsData/fulfilled") {
            dispatch(setAllAppliedJobs(res.payload.data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (currentPath !== "/search") {
      setSelectedFilters({
        categories: [],
        workModes: [],
        locations: [],
        skills: [],
        experienceRange: [0, 1],
      });
    } else {
      setSelectedCategories([]);
      setSelectedWorkModes([]);
      setSelectedLocations([]);
      setSelectedSkills([]);
      setSelectedJobTypes([]);
      setSelectedJobPosted([]);
      setRangeValue([0, 1]);
    }
    onClose();
  };

  return (
    <div
      className="filter-sidebar"
      style={{
        position: "sticky",
        top: isDrawer ? "50px" : "110px",
        padding: "0px",
        height: isDrawer ? "calc(100vh - 40px)" : "calc(100vh - 140px)",
        overflow: "auto",
      }}
    >
      {/* Mapping through the accordionData to create dynamic accordions */}
      {accordionData.map((accordion, index) => (
        <Accordion
          key={accordion.id}
          expanded={expanded.includes(accordion.id)}
          onChange={handleChange(accordion.id)}
          sx={{
            "&.MuiAccordion-root.Mui-expanded": {
              margin: 0,
              border: 0,
              boxShadow: 0,
              borderBottom: "0.5px solid var(--oh-white-300-color)",
              borderTop:
                index !== 0 ? "0.5px solid var(--oh-white-300-color)" : "none",
            },
            "&.MuiAccordion-root": {
              margin: 0,
              border: 0,
              boxShadow: 0,
              borderTop:
                index !== 0 ? "0.5px solid var(--oh-white-300-color)" : "none",
              borderBottom: "0.5px solid var(--oh-white-300-color)",
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              svg: {
                color: "var(--oh-blue-300-color)",
              },
            },
            ".MuiAccordionDetails-root": {
              padding: 0,
            },
            ".MuiAccordionSummary-root.Mui-expanded":
            {
              minHeight: "30px",
            },
            ".MuiAccordionSummary-content.Mui-expanded": {
              paddingTop: "5px",
              margin: "10px 0",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${accordion.id}-content`}
            id={`panel${accordion.id}-header`}
          >
            <MuiTypography
              className="oh-accordion-title"
              content={accordion.title}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box className="oh-accordion-content">
              {index === 0 && accordion.type === "range" && (
                <Slider
                  defaultValue={0}
                  max={25}
                  value={rangeValue}
                  onChange={handleRangeChange}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  marks={Array.from(
                    {
                      length:
                        (accordion.max - accordion.min) / accordion.step + 1,
                    },
                    (_, i) => ({
                      value: accordion.min + accordion.step * i,
                      label: `${accordion.min + accordion.step * i}`,
                    })
                  )}
                  sx={{
                    "& .MuiSlider-markLabel": {
                      fontSize: "12px", // Adjust font size for marks
                    },
                    ".MuiSlider-thumb ": {
                      backgroundColor: "white",
                    },
                    ".MuiSlider-rail": {
                      backgroundColor: "#95a7f7",
                    },
                  }}
                />
              )}
              {index !== 0 &&
                accordion.checkboxes &&
                accordion.checkboxes
                  .slice(0, maxCheckBoxes[accordion.title])
                  .map((checkbox) => (
                    <FormControlLabel
                      sx={{
                        alignItems: "flex-start !important",
                        ".MuiTypography-root": {
                          paddingRight: "5px",
                          wordBreak: "break-word",
                          fontSize: "14px"
                        }
                      }}
                      key={checkbox.id}
                      control={
                        <Checkbox
                          sx={{
                            '&.MuiCheckbox-root': {
                              paddingTop: "2px",
                              '& .MuiSvgIcon-root': {
                                width: "16px",
                                height: "16px",
                                borderRadius: "4px"
                              }
                            }
                          }}
                          checked={
                            index === 1
                              ? selectedCategories.includes(checkbox.value)
                              : index === 2
                                ? selectedWorkModes.includes(checkbox.value)
                                : index === 3
                                  ? selectedLocations.includes(checkbox.value)
                                  : index === 4
                                    ? selectedSkills.includes(checkbox.value)
                                    : index === 5
                                      ? selectedJobTypes.includes(checkbox.value)
                                      : index === 6
                                        ? selectedJobPosted.includes(checkbox.value)
                                        : false
                          }
                          onChange={() =>
                            handleCheckboxChange(
                              accordion.title,
                              checkbox.value
                            )
                          }
                        />
                      }
                      label={checkbox.label}
                      value={checkbox.value}
                    />
                  ))}
              {accordion.checkboxes &&
                accordion.checkboxes.length >
                maxCheckBoxes[accordion.title] && (
                  <div>
                    <Button
                      sx={{
                        textTransform: "none",
                        "&.MuiButton-root": {
                          padding: "6px 2px",
                          margin: "5px 15px 15px",
                          fontSize: "12px",
                          color: "var(--oh-strongpink-color)",
                        },
                      }}
                      onClick={() => {
                        setMaxCheckBoxes((prevMax) => ({
                          ...prevMax,
                          [accordion.title]: accordion.checkboxes.length,
                        }));
                      }}
                    >
                      +{accordion.checkboxes.length - 4} More
                    </Button>
                  </div>
                )}
              {accordion.checkboxes && maxCheckBoxes[accordion.title] > 4 && (
                <div>
                  <Button
                    sx={{
                      textTransform: "none",
                      "&.MuiButton-root": {
                        padding: "6px 2px",
                        margin: "5px 15px 15px",
                        fontSize: "12px",
                        color: "var(--oh-strongpink-color)",
                      },
                    }}
                    onClick={() => {
                      setMaxCheckBoxes((prevMax) => ({
                        ...prevMax,
                        [accordion.title]: 4,
                      }));
                    }}
                  >
                    View Less
                  </Button>
                </div>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box
        sx={{
          position: "sticky",
          bottom: "0",
          height: "80px",
          background: "#fff",
        }}
      >
        <MuiDivider />
        <Box px={2} py={2} gap={2} display={"flex"}>
          <MuiButtonContained
            title="Apply"
            style={{
              padding: "12px 30px !important",
              fontWeight: "400 !important",
            }}
            onClick={handleApply}
          />
          <MuiButtonOutlined
            style={{
              padding: "12px 30px !important",
              fontWeight: "400 !important",
              "&:hover": {
                color: "red !important",
                border: "1px solid red",
              },
            }}
            title="Reset"
            color="error"
            onClick={handleReset}
          />
        </Box>
      </Box>
    </div>
  );
};

export default FilterAccordion;
