import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";
// import {
//   DELETE_RESUME,
//   DOWNLOAD_RESUME,
//   JOB_SEEKER,
//   JOB_SEEKER_DETAIL,
// } from "../../apis/Constant/routes";

const initialState = {
    referredCandidates: [],
    referredCandidatesDataById: [],
    // allAppliedJobs: [],
    // allotedJobDataById: {},
};

export const getReferredCandidatesData = createAsyncThunk(
  "getReferredCandidatesData",
  async () => {
    return AxiosApi({
      path: `/freelancer_candidates_admin_job_posts`,
      type: "GET",
      params: "",
    });
  }
);

export const getReferredCandidatesDataById = createAsyncThunk(
  "getReferredCandidatesDataById",
  async (id) => {
    return AxiosApi({
      path: `/freelancer_candidates_admin_job_posts/${id}`,
      type: "GET",
      params: "",
    });
  }
);

// export const getAllotedJobDataById = createAsyncThunk(
//     "getAllotedJobDataById",
//     async (id) => {
//       return AxiosApi({
//         // path: `/get_all_candidates_of_job_post?data[admin_job_post_id]=${id}`,
//         path: `/admin_job_posts/${id}`,
//         type: "GET",
//         params: ""
//       });
//     }
//   );


export const referredCandidatesSlice = createSlice({
  name: "referredCandidates",
  initialState,
  reducers: {
    setReferredCandidates: (state, action) => {
      state.referredCandidates = action.payload;
    },
    setReferredCandidatesById: (state, action) => {
      state.referredCandidatesDataById = action.payload;
    },
    // setAllAppliedJobs: (state, action) => {
    //   state.allAppliedJobs = action.payload;  
    // },
    // setAllotedJobById: (state, action) => {
    //     state.allotedJobDataById = action.payload;
    //   },

      clearReferredCandidates: (state, action) => {
        // state.allotedJobDataById = {};
        state.referredCandidates = [];
        state.referredCandidatesDataById = {};
        // state.allAppliedJobs = [];
      }
  },
//   extraReducers: (builder) => {
//     builder
//       .addCase(getPersonalDetailApi.pending, (state, action) => {
//         state.loading = true;
//       })
//       .addCase(getPersonalDetailApi.fulfilled, (state, action) => {
//         state.personalData = action.payload;
//         state.loading = false;
//         state.error = false;
//       })
//       .addCase(getPersonalDetailApi.rejected, (state, action) => {
//         state.error = true;
//       });
//   },
});

export default referredCandidatesSlice.reducer;
export const { setReferredCandidates, setReferredCandidatesById, clearReferredCandidates } = referredCandidatesSlice.actions;
