import React from "react";
import FieldError from "../Errors/FieldError";

const CustomTextArea = ({
    value, placeholder, className, onChange = () => { }, error, helperText
}) => {
    return (
        <div>
            <textarea
                placeholder={placeholder}
                className={className}
                id="textarea"
                name="custom-textarea"
                rows="4"
                cols="50"
                onChange={(e) => onChange(e.target.value)}
                value={value}
                style={{ borderColor: error ? 'red' : '' }} // Optionally change border color on error
            />
            {error && <FieldError sx={{ marginLeft: "15px" }} errorString={helperText} />}
        </div>
    )
}

export default CustomTextArea;
