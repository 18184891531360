import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PDFViewer from "./pdfViewer";
import { ReactComponent as Clock } from "../../../assets/images/clock.svg";
import { ReactComponent as Download } from "../../../assets/images/downloadIcon.svg";
import { ReactComponent as Eye } from "../../../assets/images/blueEyeIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useDispatch } from "react-redux";
import { viewPdf } from "../../../redux/LandingPage/LandingPageSlice";
import { handleDownload } from "../../../utils";

const PolicyCard = ({ id, image, date, title, docURL, docName }) => {
  const [show, setShow] = useState(false);
  // const dispatch = useDispatch();

  console.log("id", id);

  const handleView = () => {
    setShow((prev) => !prev);
  };

  // const handleViewPdf = () => {
  //   dispatch(viewPdf(id))
  //     .then((res) => {
  //       console.log("res", res);
  //       if (res?.error) throw new Error("Something went wrong!");
  //       const apiData = res?.payload?.data;
  //       // setHrPolicy(apiData);
  //     })
  //     .catch((err) => {
  //       console.log(err.message, ".err");
  //     });
  // };

  // const handleDownloadPDF = () => {
  //   // const pdfUrl = DummyPdf;
  //   const pdfUrl = docURL;
  //   const link = document.createElement("a");
  //   link.href = pdfUrl;
  //   link.target = "_blank";
  //   link.download = `${docName}.pdf`;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const renderDownloadButton = () => {
    return (
      <Button
        sx={{
          // width: { xs: "125px", sm: "147px", md: "125px" },
          width: "125px",
          borderRadius: "30px",
          background: "#576EDB",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "14px 8px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 400,
          textTransform: "none",
          "&:hover": {
            background: "#576EDB",
          },
        }}
        onClick={() => handleDownload(docURL)}
        // onClick={handleDownloadPDF}
      >
        Download
        <Download style={{ marginLeft: "2px" }} />
      </Button>
    );
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        boxShadow={"0px 4px 12px 0px rgba(143, 155, 178, 0.20)"}
        borderRadius={"4px"}
        bgcolor={"#fff"}
      >
        <Box
          width={"100%"}
          // height={{ xs: "120px", sm: "200px" }}
        >
          <img
            src={image}
            alt="card_image"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
        <Box padding={"15px"}>
          <Box display={"flex"} alignItems={"center"}>
            <Clock />
            <Typography
              fontSize={{ xs: "12px", sm: "14px" }}
              fontWeight={400}
              color={"#A1A7BC"}
              ml={1}
            >
              {/* 25 March 2024 */}
              {moment(date).format("DD MMMM YYYY")}
            </Typography>
          </Box>
          <Box marginBottom={"10px"} marginTop={"5px"}>
            <Typography
              fontSize={"20px"}
              fontWeight={700}
              color={"#202020"}
              lineHeight={"32px"}
              width={"95%"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              title={title}
            >
              {title}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            marginTop={"20px"}
          >
            {renderDownloadButton()}
            <Button
              sx={{
                // width: { xs: "125px", sm: "147px", md: "125px" },
                width: "125px",
                borderRadius: "30px",
                background: "#fff",
                border: "1px solid #576EDB",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                padding: "14px 8px",
                color: "#576EDB",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                "&:hover": {
                  background: "#fff",
                },
              }}
              onClick={handleView}
            >
              View
              <Eye style={{ marginLeft: "2px" }} />
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog
        sx={{
          "& .MuiPaper-root": {
            width: "80%",
            // maxHeight: "80%",
            height: "90%",
          },
        }}
        open={show}
        onClose={handleView}
      >
        <DialogTitle
          style={{
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #DEE4FF",
          }}
        >
          <Typography
            fontSize={{ xs: "12px", sm: "16px" }}
            fontWeight={400}
            color="#141621"
          >
            {/* Post Interview Rejection Letter Template */}
            {title}
          </Typography>
          <CloseIcon onClick={handleView} style={{ cursor: "Pointer" }} />
        </DialogTitle>
        <DialogContent style={{ padding: "20px", background: "white" }}>
          {/* <PDFViewer doc={docURL} /> */}
          <iframe
            src={docURL}
            width="100%"
            minWidth={"80vw"}
            minHeight={"80vh"}
            height="100%"
          ></iframe>
        </DialogContent>
        <DialogActions
          style={{
            height: "70px",
            display: "flex",
            padding: "20px",
            justifyContent: "flex-start",
            borderTop: "1px solid #DEE4FF",
          }}
        >
          {renderDownloadButton()}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PolicyCard;
