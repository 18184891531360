import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";

const initialState = {
  freelancerActiveCandidates: [],
  freelancerActiveCandidateById: {},
  interestedJobsFreelancer: [],
};

export const getFreelancerActiveCandidateData = createAsyncThunk(
  "getFreelancerActiveCandidateData",
  async (data) => {
    let page = data?.page || 1;
    let pageSize = data?.pageSize || 10;
    let appendHeader = data?.keyword ? `?search[term]=${data?.keyword}` : `?page=${page}&per_page=${pageSize}`;
    return AxiosApi({
      path: `/freelancer/freelancer_candidates${appendHeader}`,
      type: "GET",
      params: "",
    });
  }
);

export const getFreelancerActiveCandidateDataById = createAsyncThunk(
  "getFreelancerActiveCandidateDataById",
  async (id) => {
    return AxiosApi({
      // path: `/get_all_candidates_of_job_post?data[admin_job_post_id]=${id}`,
      path: `/freelancer/freelancer_candidates/${id}`,
      type: "GET",
      params: "",
    });
  }
);

export const getInterestedJobsFreelancer = createAsyncThunk(
  "getInterestedJobsFreelancer",
  async () => {
    return AxiosApi({
      path: `/job_posts/?filter_by[suggested_jobs_for_freelancer]=true`,
      type: "GET",
      params: "",
    });
  }
);

export const freelancerActiveCandidatesSlice = createSlice({
  name: "freelancerActiveCandidates",
  initialState,
  reducers: {
    setFreelancerActiveCandidateData: (state, action) => {
      state.freelancerActiveCandidates = action.payload;
    },
    setFreelancerActiveCandidateDataById: (state, action) => {
      state.freelancerActiveCandidateById = action.payload;
    },
    clearFreelancerActiveCandidateData: (state, action) => {
      state.freelancerActiveCandidateById = {};
      state.freelancerActiveCandidates = [];
    },

    setInterestedJobsFreelancer: (state, action) => {
      state.interestedJobsFreelancer = action.payload;
    },
  },
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(getPersonalDetailApi.pending, (state, action) => {
  //         state.loading = true;
  //       })
  //       .addCase(getPersonalDetailApi.fulfilled, (state, action) => {
  //         state.personalData = action.payload;
  //         state.loading = false;
  //         state.error = false;
  //       })
  //       .addCase(getPersonalDetailApi.rejected, (state, action) => {
  //         state.error = true;
  //       });
  //   },
});

export default freelancerActiveCandidatesSlice.reducer;
export const {
  setFreelancerActiveCandidateData,
  setFreelancerActiveCandidateDataById,
  clearFreelancerActiveCandidateData,
  setInterestedJobsFreelancer,
} = freelancerActiveCandidatesSlice.actions;
