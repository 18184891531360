import React, { useEffect } from "react";
import MuiTextField from "../../../mui/TextField/MuiTextField";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import { debounce } from "lodash";
import "./index.scss";

const DrawerSearch = ({ searchData, setSearchData, handleSearch }) => {

    const delayedDispatch = debounce((searchData) => {
        handleSearch(searchData);
    }, 500);

    useEffect(() => {
        delayedDispatch(searchData);
        return () => {
            delayedDispatch.cancel();
        };
    }, [searchData]);

    return (
        <>
            <MuiTextField
                value={searchData}
                id="outlined-start-adornment"
                placeholder="Search"
                onChange={(e) => {
                    setSearchData(e.target.value.trimStart())
                }}
                autoComplete="off"
                type="text"
                className="oh-drawer-search-primary"
                InputProps={{
                    endAdornment: <InputAdornment onClick={() => {
                        handleSearch(searchData);
                        setSearchData("");
                    }} sx={{ cursor: "pointer" }} position="end">
                        {searchData !== "" ? <CloseIcon sx={{ svg: { color: "var(--oh-blue-600-color) !important" } }} /> : <SearchIcon />}

                    </InputAdornment>
                }}
            />
            <MuiDivider className="oh-drawer-divider" />
        </>
    )
}

export default DrawerSearch;