import * as Yup from "yup";
import { errMessageMaximum, errMessageMinimum } from "../../utils";
import { FIRSTNAME_REGEX } from "../../utils/regex";

export const CandidateSchema = Yup.object().shape({
  fname: Yup.string()
    .required("First name is required.")
    .max(60, errMessageMaximum("fname", 60))
    .test(
      "check if  First name not empty",
      "First name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "First name must be valid."),
  lname: Yup.string()
    .required("Last name is required.")
    .max(60, errMessageMaximum("lname", 60))
    .test(
      "check if  Last name not empty",
      "Last name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "Last name must be valid."),
  designation: Yup.string()
    .required("Designation is required.")
    .max(60, errMessageMaximum("Designation", 60))
    .test(
      "check if  Designation not empty",
      "Designation must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "Designation must be valid."),

  experience: Yup.string()
    .max(10, errMessageMaximum("Experience", 10))
    .min(1, errMessageMinimum("Experience", 1))
    .required("Experience can't be empty"),
  category: Yup.array().min(1, "At least one category is required"),

    skills: Yup.array().min(1, "At least one skill is required"),

  resume: Yup.mixed()
    .required("Resume is required")
    .test(
      "fileSize",
      "File size is too large. Max size is 2MB",
      (value) => !value || (value && value.size <= 2 * 1024 * 1024)
    ),
  suggest: Yup.boolean().oneOf(
    [true],
    "You must agree to suggest related job profiles"
  ),
});


export const UpdateCandidateSchema = Yup.object().shape({
  fname: Yup.string()
    .required("First name is required.")
    .max(60, errMessageMaximum("fname", 60))
    .test(
      "check if  First name not empty",
      "First name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "First name must be valid."),
  lname: Yup.string()
    .required("Last name is required.")
    .max(60, errMessageMaximum("lname", 60))
    .test(
      "check if  Last name not empty",
      "Last name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "Last name must be valid."),
  designation: Yup.string()
    .required("Designation is required.")
    .max(60, errMessageMaximum("Designation", 60))
    .test(
      "check if  Designation not empty",
      "Designation must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "Designation must be valid."),

  experience: Yup.string()
    .max(10, errMessageMaximum("Experience", 10))
    .min(1, errMessageMinimum("Experience", 1))
    .required("Experience can't be empty"),
  category: Yup.array().min(1, "At least one category is required"),

    skills: Yup.array().min(1, "At least one skill is required"),
  suggest: Yup.boolean().oneOf(
    [true],
    "You must agree to suggest related job profiles"
  ),
});