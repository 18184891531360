import React, { useState, useEffect } from 'react'
import { NavLink as NavLinkBase, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// MUI
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

// ASSETS
import VectorImage from '../../assets/images/Vector_01.svg'
import DefaultProfilePicture from '../../assets/images/default_profile_picture.svg'
import { ReactComponent as MessageIcon } from '../../assets/images/messages.svg'

import AccountBoxIcon from '@mui/icons-material/AccountBox'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import WalletIcon from '@mui/icons-material/Wallet'

import VerifiedIcon from '@mui/icons-material/Verified'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Face3Icon from '@mui/icons-material/Face3'
import EditIcon from '@mui/icons-material/Edit'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import SearchIcon from '@mui/icons-material/Search'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import PersonIcon from '@mui/icons-material/Person'
import ArticleIcon from '@mui/icons-material/Article'
import BookmarkIcon from '@mui/icons-material/Bookmark'
// OTHER
import { setProfilePhoto, updateUserProfile } from '../../redux/profileSlice'
import MuiTypography from '../../mui/Typography/MuiTypograpghy'

// CSS
import './index.scss'
import { Toaster } from '../Toaster/Toaster'
import { setUserData } from '../../redux/authSlice'
import { orgTypeArr } from '../../Data'

const Sidebar = ({
  profile = false,
  isMobile = false,
  staticPageHeader = null,
  className = ''
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { portalName } = useSelector(state => state.app)
  const { user, token } = useSelector(state => state.auth)
  const { profilePhoto } = useSelector(state => state.profile)

  const NavLink = React.forwardRef((props, ref) => {
    return (
      <NavLinkBase ref={ref} {...props} className={props.activeClassName} />
    )
  })

  const [data, setData] = useState([])

  const EmployerNavigationData = [
    {
      title: 'Dashboard',
      to: '/employer',
      listItemIconTxt: 'dashboard',
      upcoming: false,
      activeTabIcon: function () {
        return <GridViewRoundedIcon />
      }
    },
    {
      title: 'Job Postings',
      to: '/job-openings',
      listItemIconTxt: 'job-posting',
      upcoming: false,
      activeTabIcon: function () {
        return <BusinessCenterIcon />
      }
    },
    {
      title: 'Hiring Assistance',
      to: '/hiring-assistance',
      listItemIconTxt: 'hiring-assistance',
      upcoming: false,
      activeTabIcon: function () {
        return <Face3Icon />
      }
    },
    // {
    //   title: 'Message',
    //   to: '/message',
    //   listItemIconTxt: 'message',
    //   upcoming: false,
    //   activeTabIcon: function () {
    //     return <MessageIcon />
    //   }
    // }
  ]

  const JobSeekerNavigationData = [
    {
      title: 'Dashboard',
      to: '/jobseeker',
      listItemIconTxt: 'dashboard',
      upcoming: false,
      activeTabIcon: function () {
        return <GridViewRoundedIcon />
      }
    },
    {
      title: 'Jobs',
      to: '/all-jobs',
      listItemIconTxt: 'all-jobs',
      upcoming: false,
      activeTabIcon: function () {
        return <BusinessCenterIcon />
      }
    },
    {
      title: 'Job Status',
      to: '/job-status',
      listItemIconTxt: 'job-status',
      upcoming: false,
      activeTabIcon: function () {
        return <VerifiedIcon />
      }
    },
    {
      title: 'Saved Jobs',
      to: '/saved-jobs',
      listItemIconTxt: 'saved-jobs',
      upcoming: false,
      activeTabIcon: function () {
        return <BookmarkIcon />
      }
    },
    // {
    //   title: 'Message',
    //   to: '/message',
    //   listItemIconTxt: 'message',
    //   upcoming: false,
    //   activeTabIcon: function () {
    //     return <MessageIcon />
    //   }
    // }
  ]

  const FreelancerNavigationData = [
    {
      title: 'Dashboard',
      to: '/freelancer',
      listItemIconTxt: 'dashboard',
      upcoming: false,
      activeTabIcon: function () {
        return <GridViewRoundedIcon />
      }
    },
    {
      title: 'Candidates',
      to: '/candidates',
      listItemIconTxt: 'candidates',
      upcoming: false,
      activeTabIcon: function () {
        return <AccountBoxIcon />
      }
    },
    {
      title: 'Allotted Jobs',
      to: '/allotted-jobs',
      listItemIconTxt: 'allotted-jobs',
      upcoming: false,
      activeTabIcon: function () {
        return <BusinessCenterIcon />
      }
    },
    {
      title: 'Earning',
      to: '/earning',
      listItemIconTxt: 'earning',
      upcoming: false,
      activeTabIcon: function () {
        return <WalletIcon />
      }
    }
  ]

  const ProfileEmployerData = [
    {
      title: 'Communication Details',
      to: '/communication-details',
      listItemIconTxt: 'communication-details',
      activeTabIcon: function () {
        return null
      }
    },
    {
      title: 'Company Details',
      to: '/company-details',
      listItemIconTxt: 'company-details',
      activeTabIcon: function () {
        return null
      }
    },
    {
      title: 'Change Password',
      to: '/change-password',
      listItemIconTxt: 'change-password',
      activeTabIcon: function () {
        return null
      }
    },
    {
      title: 'Terms & Conditions',
      to: '/term-condition',
      listItemIconTxt: 'term-condition',
      activeTabIcon: function () {
        return null
      }
    }
  ]

  const ProfileFreelancerData = [
    {
      title: 'Personal Details',
      to: '/personal-detail',
      listItemIconTxt: 'communication-details',
      activeTabIcon: function () {
        return null
      }
    },
    {
      title: 'Professional Details',
      to: '/other-detail',
      listItemIconTxt: 'company-details',
      activeTabIcon: function () {
        return null
      }
    },
    {
      title: 'Change Password',
      to: '/change-password',
      listItemIconTxt: 'change-password',
      activeTabIcon: function () {
        return null
      }
    },
    {
      title: 'Terms & Conditions',
      to: '/term-condition',
      listItemIconTxt: 'term-condition',
      activeTabIcon: function () {
        return null
      }
    }
  ]

  const ProfileData = [
    {
      title: 'Personal Details',
      to: '/personal-details',
      listItemIconTxt: 'communication-details',
      activeTabIcon: function () {
        return null
      }
    },
    {
      title: 'Professional Details',
      to: '/other-details',
      listItemIconTxt: 'company-details',
      activeTabIcon: function () {
        return null
      }
    },
    {
      title: 'Change Password',
      to: '/change-password',
      listItemIconTxt: 'change-password',
      activeTabIcon: function () {
        return null
      }
    },
    {
      title: 'Terms & Conditions',
      to: '/term-condition',
      listItemIconTxt: 'term-condition',
      activeTabIcon: function () {
        return null
      }
    }
  ]

  useEffect(() => {
    if (!profile && !isMobile && token) {
      switch (portalName) {
        case 'Employer':
          setData(EmployerNavigationData)
          break
        case 'Freelancer':
          setData(FreelancerNavigationData)
          break
        default:
          setData(JobSeekerNavigationData)
          break
      }
    } else if (!profile && isMobile && token && !staticPageHeader) {
      switch (portalName) {
        case 'Employer':
          setData([...EmployerNavigationData, ...ExtraDataForMobileScreen])
          break
        case 'Freelancer':
          setData([...FreelancerNavigationData, ...ExtraDataForMobileScreen])
          break
        default:
          setData([...JobSeekerNavigationData, ...ExtraDataForMobileScreen])
          break
      }
    }
    // else if (!profile && isMobile && token && !user?.otp_verified || !user?.registration_complete) {
    //   setData(ExtraDataForMobileScreen);
    // }
    else if (!profile && isMobile && staticPageHeader) {
      setData([...MoreDataForMobileScreen, ...ExtraDataForMobileScreen])
    } else if (profile) {
      switch (portalName) {
        case 'Employer':
          setData(ProfileEmployerData)
          break
        case 'Freelancer':
          setData(ProfileFreelancerData)
          break
        default:
          setData(ProfileData)
          break
      }
    }
  }, [portalName, token])

  const ExtraDataForMobileScreen = [
    {
      title: 'Blogs',
      to: '/blogs',
      listItemIconTxt: 'blogs',
      activeTabIcon: function () {
        return <NewspaperIcon />
      }
    },
    {
      title: 'Contact Us',
      to: '/contact-us',
      listItemIconTxt: 'contact-us',
      activeTabIcon: function () {
        return <ContactPhoneIcon />
      }
    }
  ]

  const MoreDataForMobileScreen = [
    {
      title: 'Jobseekers',
      to: '/jobseekers',
      listItemIconTxt: 'jobseeker',
      activeTabIcon: function () {
        return <PersonIcon />
      },
      expanded: false
    },
    {
      title: 'Employers',
      to: '/employers',
      listItemIconTxt: 'employer-landingPage',
      activeTabIcon: function () {
        return <PersonIcon />
      },
      expanded: false
    },
    {
      title: 'Freelancers',
      to: '/freelancers',
      listItemIconTxt: 'freelancer-landingPage',
      activeTabIcon: function () {
        return <PersonIcon />
      },
      expanded: false
    },
    {
      title: 'HR Documents',
      listItemIconTxt: 'hrPolicy',
      activeTabIcon: function () {
        return <ArticleIcon />
      },
      subMenuItems: [
        {
          title: 'HR Policy',
          to: '/hr-policy',
          listItemIconTxt: 'hrPolicy',
          activeTabIcon: function () {
            return null
          }
        },
        {
          title: 'Job Descriptions',
          to: '/job-description',
          listItemIconTxt: 'jobDescription',
          activeTabIcon: function () {
            return null
          }
        }
      ],
      expanded: false
    }
  ]

  const returnClassName = (to, index) => {
    const { pathname } = location
    const isActive = pathname === to
    return isActive ? 'active' : 'notactive'
  }

  const handleImageChange = e => {
    const file = e.target.files[0]
    if (file) {
      const formData = new FormData()
      formData.append('data[profile_photo]', file)
      let data = { formData: formData, id: user?.id }
      if (user?.id) {
        dispatch(updateUserProfile(data))
          .then(res => {
            if (res.type === 'updateUserProfile/fulfilled') {
              Toaster.TOAST(res.payload.message, 'success')
              let user_data = user
              user_data = {
                ...user,
                profile_photo: res.payload.data.profile_photo
              }
              dispatch(setUserData(user_data))
              localStorage.setItem('login_data', JSON.stringify(user_data))
              dispatch(
                setProfilePhoto({
                  profilePhoto: res.payload.data.profile_photo
                })
              )
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }

  return (
    <>
      <Box className={`oh-sidebar ${className}`}>
        <List className='oh-sidebar-list'>
          <Box className='oh-sidebar-header'>
            <img className='oh-header-vector-img' src={VectorImage} alt='' />{' '}
            {/* need to replace this image by masked group */}
            {profile ? (
              <Box className='oh-profile-box'>
                <label
                  htmlFor='upload-button'
                  // onClick={handleImageChange}
                  className='profile-picture-parent'
                >
                  <img
                    className='profile-picture'
                    // src={DefaultProfilePicture}
                    src={
                      profilePhoto?.profilePhoto ||
                      profilePhoto ||
                      DefaultProfilePicture
                    }
                    alt={''}
                  />

                  <span className='change-text'>
                    Change
                    <EditIcon />
                  </span>
                </label>
                <input
                  type='file'
                  id='upload-button'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                {(user?.organization_name || user?.first_name) && (
                  <MuiTypography
                    content={
                      user?.organization_name ||
                      `${user?.first_name} ${user?.last_name}`
                    }
                    className='user-name'
                  />
                )}
                {(user?.personal_details?.city ||
                  user?.personal_details?.designation ||
                  user?.personal_details?.organization_type) && (
                  <MuiTypography
                    content={
                      user?.type === 'Employer'
                        ? user?.personal_details?.city ||
                          (user?.personal_details?.organization_type &&
                            orgTypeArr.find(
                              opt =>
                                opt.value ===
                                user?.personal_details?.organization_type
                            )?.label)
                        : user?.personal_details?.designation
                    }
                    className='user-location'
                  />
                )}
                {user?.personal_details?.created_at && (
                  <MuiTypography
                    content={`Updated at - ${moment(
                      user?.personal_details?.updated_at
                    ).format('MMM DD YYYY, h:mm a')}`}
                    className='user-last-update'
                  />
                )}
              </Box>
            ) : (
              <Box>
                <MuiTypography
                  className='oh-heading'
                  content={
                    token
                      ? portalName === 'Jobseeker'
                        ? 'Job Seeker'
                        : portalName
                      : 'Welcome to Octahire'
                  }
                />
              </Box>
            )}
          </Box>
          {data?.map((item, index) =>
            !item?.subMenuItems || !item?.subMenuItems?.length ? (
              <ListItem
                key={index}
                sx={{
                  '&:hover': {
                    background: 'var(--oh-white-300-color)'
                  },
                  '&:active': {
                    background: 'var(--oh-white-300-color)'
                  },
                  '&.active': {
                    background: 'var(--oh-white-300-color)'
                  }
                }}
                className={`${returnClassName(item?.to)} ${
                  profile ? 'oh-profile-list-item' : 'oh-list-item'
                }`}
              >
                <ListItemButton
                  to={item.to}
                  component={NavLink}
                  className='oh-item-btn'
                >
                  {item?.activeTabIcon && (
                    <ListItemIcon sx={{ minWidth: '27px' }}>
                      {item.activeTabIcon()}
                    </ListItemIcon>
                  )}
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            ) : (
              <div key={index}>
                <ListItem
                  sx={{
                    '&:hover': {
                      background: 'var(--oh-white-300-color)'
                    },
                    background:
                      returnClassName(item.to, index) === 'active'
                        ? 'var(--oh-white-300-color)'
                        : 'var(--oh-white-color)',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  className={` ${
                    profile ? 'oh-profile-list-item' : 'oh-list-item'
                  }`}
                >
                  <ListItemButton
                    onClick={() => {
                      setData(prevData => {
                        const newData = [...prevData]
                        newData[index].expanded = !newData[index].expanded
                        return newData
                      })
                    }}
                    className='oh-item-btn'
                    component={NavLink}
                  >
                    {item?.activeTabIcon && (
                      <ListItemIcon sx={{ minWidth: '27px' }}>
                        {item.activeTabIcon()}
                      </ListItemIcon>
                    )}
                    <ListItemText primary={item.title} />
                    {item?.subMenuItems?.length > 0 && (
                      <ListItemIcon
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        {item.expanded ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                </ListItem>
                {item?.expanded && (
                  <List disablePadding>
                    {item.subMenuItems.map((submenuItem, subIndex) => (
                      <ListItem key={subIndex}>
                        <ListItemButton to={submenuItem.to} component={NavLink}>
                          {submenuItem.activeTabIcon && (
                            <ListItemIcon sx={{ minWidth: '27px' }}>
                              {submenuItem.activeTabIcon()}
                            </ListItemIcon>
                          )}
                          <ListItemText primary={submenuItem.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                )}
              </div>
            )
          )}
        </List>
      </Box>
    </>
  )
}

export default Sidebar
