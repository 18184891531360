import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosApi } from "../../apis/AxiosApi";
import { SAVED_JOBS, UNSAVED_JOBS } from '../../apis/Constant/routes';

const initialState = {
  savedJobs: {},
  allSavedJobs: [],
  SavedJobPage: 1,
  SavedJobPageSize: 20,
  SavedJobRowCount: null,
  isUpdate: false,
  savedId: ''
};

export const saveJob = createAsyncThunk("create-saveJob", async ({ data }) => {
  return await AxiosApi({
    path: `${SAVED_JOBS}`,
    type: "POST",
    data: data,
  });
});

export const unsaveJob = createAsyncThunk("unsave-job", async ({ data }) => {
  return await AxiosApi({
    path: `${UNSAVED_JOBS}`,
    type: "DELETE",
    data: data,
  });
});


const savedJobsSlice = createSlice({
  name: 'savedJobs',
  initialState,
  reducers: {
    setAllSavedJobs: (state, action) => {
      state.allSavedJobs = action.payload;
    },
    setSavedJobPage: (state, action) => {
      state.SavedJobPage = action.payload;
    },
    setSavedJobPageSize: (state, action) => {
      state.SavedJobPageSize = action.payload;
    },
    setSavedJobRowCount: (state, action) => {
      state.SavedJobRowCount = action.payload;
    },
    setIsUpdate: (state, action) => {
      state.isUpdate = action.payload;
    },
    setSavedId: (state, action) => {
      state.savedId = action.payload;
    }
  },
});

export const getAllSavedJobs = createAsyncThunk(
  "allSavedJobs",
  async (data, thunkapi) => {
    const {
      SavedJobPage: page,
      SavedJobPageSize: pageSize,
    } = thunkapi.getState().savedJob;
    let pageHeader = `&page=${page}&per_page=${pageSize}`;
    return await AxiosApi({
      path: `${SAVED_JOBS}?${pageHeader}`,
      type: "GET",
    });
  }
);

export default savedJobsSlice.reducer;
export const {
  setAllSavedJobs,
  setSavedJobs,
  setSavedJobPage,
  setSavedJobPageSize,
  setSavedJobRowCount,
  setIsUpdate,
  setSavedId,
} = savedJobsSlice.actions;
