import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//OTHER
import { REJECTED, SUCCESSFUL } from "../../../../utils/constant";

//CSS
import "./index.scss";
import {
  getTransanctionHistory,
  setTransactionData,
} from "../../../../redux/Freelancer/earningDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import NoJobsFound from "../../../../components/NotFound/NoJobsFound";
import moment from "moment";
import ListingTableWithPagination from "../../../../components/Table/ListingTableWithPagination";
import NoCandidatesDataInTable from "../../../../components/Table/NoCandidatesFound";

const TranscationTable = ({ transactionEarning, setTransactionEarning, rowCount, setRowCount, page, setPage, popupState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pageSize = 10;
  const { transactionData } = useSelector((state) => state.earningData);

  useEffect(() => {
    popupState == "" && getTransanctionData();
  }, [page]);


  const handlePageChange = (newPage) => {
    let page = newPage + 1;
    setPage(page);
  };

  const getTransanctionData = () => {
    setTransactionEarning(true);
    dispatch(getTransanctionHistory({ page: page, pageSize }))
      .then((res) => {
        if (res?.type === "getTransanctionHistory/fulfilled") {
          dispatch(setTransactionData(res?.payload?.data));
          setRowCount(res?.payload?.meta?.total_records);
        }
        setTransactionEarning(false);
      })
      .catch((err) => {
        console.log(err);
        setTransactionEarning(false);
      });
  };
  const handleNavigate = () => { };

  const handleStatus = (status) => {
    switch (status) {
      case SUCCESSFUL:
        return "oh-successfully";
      case REJECTED:
        return "oh-rejected";
      default:
        return null;
    }
  };

  const headCells = [
    {
      field: "id",
      headerName: "Transcation ID",
      sortable: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{ cursor: "pointer" }} onClick={() => handleNavigate()}>
            {params?.row?.attributes?.transaction_id || "-"}
          </span>
        );
      },
    },
    {
      field: "date_time",
      headerName: "Date & Time",
      minWidth: 190,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <span>
            {moment(params.row?.attributes?.date_time).format(
              "DD MMM, YY | HH:MM A"
            ) || "-"}
          </span>
        );
      },
    },
    {
      field: "from",
      headerName: "From",
      minWidth: 170,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <span>{params.row?.attributes?.from || "-"}</span>;
      },
    },
    {
      field: "to",
      headerName: "To",
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <span>{params.row?.attributes?.to || "-"}</span>;
      },
    },
    {
      field: "earning_amount",
      headerName: "Earning Amount",
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <span>
            <span className="oh-earning-currency">₹</span>
            <span className="oh-earning-amount">
              {params.row?.attributes?.amount || "-"}
            </span>
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const statusClass = handleStatus(params.row?.attributes?.status);
        return (
          <span className={statusClass}>
            {params.row?.attributes?.status || "-"}
          </span>
        );
      },
    },
  ];

  return (
    <ListingTableWithPagination
      hideFooterPagination={false}
      rows={transactionData}
      columns={headCells}
      pageSize={pageSize}
      rowCount={rowCount}
      rowsPerPageOptions={[pageSize]}
      page={page - 1}
      loader={transactionEarning}
      paginationMode="server"
      onPageChange={handlePageChange}
      customNoResultsComponent={() => <NoCandidatesDataInTable />}
    />
  );
};
export default TranscationTable;
