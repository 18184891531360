import React, { useEffect, useState } from 'react';
import MuiTypography from '../../mui/Typography/MuiTypograpghy';
import { useDispatch, useSelector } from 'react-redux';
import { setMinutes, setSeconds, setTimerState } from '../../redux/verifyOtpSlice';

const CountdownTimer = () => {

    const dispatch = useDispatch();

    const { mins, sec } = useSelector((state) => state.verifyOtp);

    useEffect(() => {
        const interval = setInterval(() => {
            if (sec > 0) {
                dispatch(setTimerState(true));
                dispatch(setSeconds(sec - 1))
            }

            if (sec === 0) {
                if (mins === 0) {
                    dispatch(setTimerState(false));
                    clearInterval(interval)
                } else {
                    dispatch(setSeconds(59))
                    dispatch(setMinutes(mins - 1))
                }
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [sec, mins]);

    return (
        <>
            {sec > 0 || mins > 0 ? (
                <MuiTypography style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "var(--oh-green-color)",
                    fontSize: "18px",
                    fontWeight: "500",
                    fontFamily: "Roboto, sans-serif"
                }}>
                    {mins < 10 ? `0${mins}` : mins}:
                    {sec < 10 ? `0${sec}` : sec}
                </MuiTypography>
            ) : (
                ' '
            )
            }
        </>
    )
};

export default CountdownTimer;
