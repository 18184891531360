import React from "react";
import MuiBasicDrawer from "../../../mui/Drawers/MuiBasicDrawer";
import Sidebar from "../../Sidebar/Sidebar";
// CSS
import "./index.scss";
import "../../../Styles/Global.scss";

const CustomNavigation = ({
    data = [],
    open,
    onClose,
    profile = false,
    staticPageHeader = null,
}) => {
    return (
        <>
            <MuiBasicDrawer anchor="left" onClose={onClose} closeIcon={true} open={open}>
                <Sidebar staticPageHeader={staticPageHeader} profile={profile} isMobile={true} className="drawer-navigation-bar" {...data} />
            </MuiBasicDrawer>
        </>
    );
}

export default CustomNavigation;