import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function SearchInput() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const handleSearch = () => {
    console.log("search", searchValue);
    if (searchValue?.trim()?.length < 1) {
      setError(true);
    } else {
      setError(false);
      navigate(`/search-jobs?query=${searchValue}`);
    }
  };

  const searchInputRef = useRef(null);
  const searchButtonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        searchButtonRef.current.click();
      }
    };

    const searchInput = searchInputRef.current;
    searchInput.addEventListener("keydown", handleKeyDown);

    return () => {
      searchInput.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div>
        <TextField
          ref={searchInputRef}
          className="search-field"
          value={searchValue}
          onChange={handleChange}
          placeholder="Search by skills/job title/companies"
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  ref={searchButtonRef}
                  className="search-btn-desktop"
                  sx={{
                    borderRadius: "30px",
                    background: "#576EDB",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": {
                      background: "#576EDB",
                    },
                  }}
                  onClick={handleSearch}
                >
                  <SearchIcon />
                  Search Job
                </Button>
                <Button
                  ref={searchButtonRef}
                  className="search-btn-mobile"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                  }}
                  onClick={handleSearch}
                >
                  <SearchIcon style={{ color: "#576EDB" }} />
                </Button>
              </InputAdornment>
            ),
            sx: {
              width: "90%",
              height: "72px",
              margin: "0 auto",
              backgroundColor: "#fff",
              borderRadius: "40px",
              "&:hover": {
                outline: "none",
                boxShadow: "none",
              },
            },
          }}
        />
        {error && (
          <div
            style={{
              width: "85%",
              textAlign: "left",
              margin: "0 auto",
              fontSize: "14px",
              color: "var(--oh-error-color)",
              marginBottom: "10px",
              paddingLeft: "10px",
            }}
          >
            Please enter keywords to search relevant jobs
          </div>
        )}
      </div>
    </>
  );
}

export default SearchInput;
