import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";

const handlePaste = (e) => {
  const target = e.target;
  const isQuillEditor = target.closest('.ql-editor');

  if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA' && !isQuillEditor) {
    e.preventDefault();
  }
};
document.body.addEventListener('paste', handlePaste);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <React.StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </React.StrictMode>
    <ToastContainer
      // limit={1}
      toastClassName="dark-toast"
      autoClose={4500}
      className="toast-container"
      style={{ zIndex: 9999 }}
      closeButton={true}
    />
  </>
);
window.addEventListener('beforeunload', () => {
  document.body.removeEventListener('paste', handlePaste);
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
