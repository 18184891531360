import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//MUI
import Grid from "@mui/material/Grid";

//OTHER
import TertiaryWrapper from "../../../components/Wrappers/TertiaryWrapper";
import JobOpeningsTabs from "./JobOpeningTabs";
import EmployerLayout from "../../Layouts/Employer/EmployerLayout";
import JobPostCard from "../../../components/JobPostBox/JobPostCard";
import {
  getJobPost,
  getOpenJobs,
  getPastJobs,
  setOpenPosition,
  setPastPosition,
} from "../../../redux/Employer/JobPostSlice";
import NoJobsFound from "../../../components/NotFound/NoJobsFound";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PastOpenings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pastOpenings = useSelector((state) => state.jobPost.pastOpenings);
  useEffect(() => {
    dispatch(getPastJobs())
      .then((res) => {
        if (res.type === "getPastJobs/fulfilled") {
          dispatch(setPastPosition(res?.payload?.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <EmployerLayout>
        <JobOpeningsTabs />
        <TertiaryWrapper content={`${pastOpenings?.length} Jobs Posted`}>
          <Grid container spacing={2}>
            {pastOpenings?.length ? (
              pastOpenings.map((elem, idx) => {
                return (
                  <Grid item className="cards-responsive-1400" xs={12} md={12} lg={6}>
                    <JobPostCard
                      cardType="jobsCards"
                      size={"medium-100"}
                      handleNavigate={() => {
                        navigate(`/job-openings/job-details/${elem.id}`, {
                          // replace: true,
                        });
                      }}
                      data={elem.attributes}
                      className={"open-total-applicant"}
                    />
                  </Grid>
                );
              })
            ) : (
              <Box sx={{ width: "100%", minHeight: "200px" }}>
                <NoJobsFound height={"450px"} value="No jobs found" />
              </Box>
            )}
          </Grid>
        </TertiaryWrapper>
      </EmployerLayout>
    </>
  );
};

export default PastOpenings;
