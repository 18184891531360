import React, { useEffect, useRef, useState } from "react";
import { Typography, Button, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { getBrowseJobsByTechnologiesList } from "../../../../redux/LandingPage/LandingPageSlice";
import { Toaster } from "../../../../components/Toaster/Toaster";
import { ReactComponent as RightArrow } from "../../../../assets/images/right-arrow-darkBlue.svg";
import { useNavigate } from "react-router-dom";
import GreaterThanIcon from "@mui/icons-material/ArrowForward";

const JOB_FIELDS = [
  "IT Services & Consulting",
  "Business Analysis & Project Manager",
  "Product Management ",
  "UIUX Designer",
  "Mobile App Developer",
  "Banking & Finance",
  "Sales & Marketing",
  "Full Stack Developer",
  "Technical Lead",
  "Internship",
];
function BrowseJobs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobFields, setJobFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const countRef = useRef(20);
  const mobileScreenWidth = window.innerWidth;

  useEffect(() => {
    getAllTechnologiesList();

    if (mobileScreenWidth < 600) {
      countRef.current = 5;
    } else {
      countRef.current = 20;
    }
  }, []);

  const getAllTechnologiesList = () => {
    setLoading(true);
    dispatch(getBrowseJobsByTechnologiesList())
      .then((res) => {
        if (res?.error) throw new Error("Something went wrong!");
        const apiData = res?.payload?.data;
        const jobFields = apiData?.map((data) => {
          return {
            id: data?.[0],
            value: data?.[1],
          };
        });
        setJobFields(jobFields);
      })
      .catch((err) => {
        Toaster.TOAST(err?.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const handleViewJobs = (value) => {
    if (!value) {
      navigate("/search-jobs");
    } else {
      navigate(`/search-jobs?query=${value}`);
    }
  };

  return (
    <>
      <section
        style={{
          padding: "40px",
          textAlign: "center",
        }}
        className="browse-jobs-section"
      >
        <Typography
          variant="h5"
          fontSize={{ xs: "20px", sm: "24px" }}
          fontWeight={700}
          color="#141621"
          className="bjs-heading"
        >
          Browse across popular job roles
        </Typography>
        <Typography
          variant="subtitle1"
          marginBottom={"32px"}
          fontSize={{ xs: "12px", sm: "14px" }}
          fontWeight={400}
          color={"#4A4B4F"}
          className="bjs-subHeading"
        >
          Select from the most trending job roles in the latest industry
        </Typography>
        <Box
          mt={2}
          mb={4}
          display="flex"
          flexWrap={{ xs: "nowrap", sm: "wrap" }}
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "center", sm: "" }}
        >
          {jobFields?.slice(0, countRef.current)?.map((item) => {
            return (
              <Box
                id={item?.id}
                marginRight={"10px"}
                marginBottom={"10px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={"30"}
                border={"1px solid #DEE4FF"}
                padding={"15px"}
                borderRadius={"12px"}
                style={{ cursor: "pointer" }}
                onClick={() => handleViewJobs(item?.value)}
              >
                <Typography
                  fontWeight={"700"}
                  fontSize={"14px"}
                  color={"#445386"}
                  mr={"5px"}
                >
                  {item?.value}
                </Typography>
                <RightArrow />
              </Box>
            );
          })}
        </Box>
        {/* ---------- view more button -------------- */}
        {jobFields?.length > 0 && (
          <Box
            mt={{ xs: 0, sm: 2 }}
            mb={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={"50px"}
          >
            <Button
              endIcon={<GreaterThanIcon />}
              style={{
                border: "1px solid #DEE4FF",
                borderRadius: "30px",
                padding: "12px 24px",
                color: "#445386",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                cursor: "pointer",
              }}
              onClick={() => handleViewJobs("")}
            >
              Explore more jobs
            </Button>
          </Box>
        )}
      </section>
    </>
  );
}

export default BrowseJobs;
