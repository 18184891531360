import React, { useState, useEffect } from "react";
import { Form, Formik, useField, useFormikContext } from "formik";
// MUI
import Grid from "@mui/material/Grid";

// OTHER
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import MuiDivider from "../../mui/Dividers/MuiDivider";
import MuiTextField from "../../mui/TextField/MuiTextField";
import MuiTextFieldSelect from "../../mui/TextField/MuiTextFieldSelect";

// CSS
import "./index.scss";
import UploadResume from "../../components/Upload/UploadResume";
import DownloadResume from "../../components/Upload/DowloadResume";
import FieldError from "../../components/Errors/FieldError";
import { PersonalDetail } from "../../schemas/Freelancer/PersonalDetailSchema";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import {
  addPersonalDetailApi,
  deleteResumeApi,
  downloadResumeApi,
  editPersonalDetailApi,
  getDesignationsList,
  getPersonalDetailApi,
} from "../../redux/JobSeeker/PersonalDetailSlice";
import { Toaster } from "../../components/Toaster/Toaster";
import { useDispatch, useSelector } from "react-redux";
import ProfileLayout from "./ProfileLayout";
import MuiSelect from "../../mui/TextField/MuiSelect";
import axios from "axios";
import { setUserData } from "../../redux/authSlice";
import { employmentStatusArr, experienceArr, workExperienceArr } from "../../Data";
import { handleDownload, returnSubstring, smoothScroll } from "../../utils";
import { useNavigate } from "react-router-dom";
import MuiSelectWithSearch from "../../mui/TextField/MuiSelectWithSearch";
import { Box, IconButton, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import ActionsOnResume from "../../components/Upload/ActionsOnResume";

const PersonalDetails = () => {
  const navigate = useNavigate();
  const [showResume, setShowResume] = useState(false);
  const [designationList, setDesignationList] = useState([]);
  const [showCity, setShowCity] = useState([]);
  const [initialData, setInitialData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    alt_mobile_number: "",
    designation: "",
    employment_status: "",
    experience: "",
    zip_code: "",
    address: "",
    city: "",
    state: "",
    country: "",
    resume: "",
  });
  const dispatch = useDispatch();
  // const personalData = useSelector(
  //   (state) => state?.personalDetail?.personalData?.data?.attributes
  // );
  const [checkMobile, setCheckMobile] = useState(true);

  const userId = localStorage.getItem("login_data")
    ? JSON.parse(localStorage.getItem("login_data")).id
    : null;

  const { user } = useSelector((state) => state?.auth);
  const { portalValue } = useSelector((state) => state?.app);
  // const jobseekerId = useSelector(
  //   (state) => state?.auth?.user?.personal_details?.id
  // );

  // let id = JSON.parse(localStorage.getItem("login_data"))?.id;
  // useEffect(() => {
  //   if (personalData) {
  //     const resumeName = personalData?.personal_details?.resume?.url?.substring(
  //       personalData?.personal_details?.resume?.url.lastIndexOf("/") + 1
  //     );
  //     setInitialData({
  //       first_name: personalData?.jobseeker.first_name,
  //       last_name: personalData?.jobseeker.last_name,
  //       email: personalData?.jobseeker.email,
  //       mobile_number: personalData?.jobseeker.mobile_number,
  //       alt_mobile_number: user?.personal_details?.alternate_mobile_no,
  //       designation: personalData?.personal_details?.designation,
  //       employment_status: personalData?.personal_details?.employment_status,
  //       experience: personalData?.personal_details?.experience,
  //       zip_code: personalData?.personal_details?.address?.zip_code,
  //       address: personalData?.personal_details?.address?.address,
  //       city: personalData?.personal_details?.address?.city,
  //       state: personalData?.personal_details?.address?.state,
  //       country: personalData?.personal_details?.address?.country,
  //       resume: resumeName,
  //     });
  //   }
  // }, [personalData]);

  useEffect(() => {
    getDesignations();
    getData();
  }, []);

  const getData = () => {
    // dispatch(getPersonalDetailApi(user.id));
    dispatch(getPersonalDetailApi(userId))
      .then((res) => {
        if (res.type === "personal_details/list/fulfilled") {
          let response = res?.payload?.data?.attributes;
          const resumeName = response?.personal_details?.resume?.url?.substring(
            response?.personal_details?.resume?.url.lastIndexOf("/") + 1
          );
          setCheckMobile(
            !response?.jobseeker.mobile_number ||
              response?.jobseeker.mobile_number == ""
              ? false
              : true
          );
          setInitialData({
            first_name: response?.jobseeker.first_name,
            last_name: response?.jobseeker.last_name,
            email: response?.jobseeker.email,
            mobile_number: response?.jobseeker.mobile_number || "",
            alt_mobile_number:
              user?.personal_details?.alternate_mobile_no ||
              response?.personal_details?.alternate_mobile_no,
            designation: response?.personal_details?.designation,
            employment_status: response?.personal_details?.employment_status || "",
            experience: response?.personal_details?.experience,
            zip_code: response?.personal_details?.address?.zip_code,
            address: response?.personal_details?.address?.address,
            city: response?.personal_details?.address?.city,
            state: response?.personal_details?.address?.state,
            country: response?.personal_details?.address?.country,
            resume: resumeName,
          });

          // const data = res?.payload?.data.attributes.personal_details;
          // const educationDetailsData = toArray(data?.education_details);
          // const experienceDetailsData = toArray(data?.experience_details);
          // const isDataExists = Object.keys(data.education_details).length === 0;
          // if (!isDataExists) {
          //   setInitialData((prev) => ({
          //     ...prev,
          //     educationDetails: educationDetailsData,
          //     experienceDetails: experienceDetailsData,
          //     ctc: data?.ctc,
          //     ectc: data?.ectc,
          //     locations: data?.locations?.map((elem) => {
          //       return {
          //         id: String(elem.id),
          //         label: elem.name,
          //         value: elem.id,
          //       };
          //     }),
          //     experties_in: data?.expertise?.map((elem, index) => {
          //       const data = { id: String(index), value: elem, label: elem };
          //       return data;
          //     }),
          //     skills: data?.skills?.map((elem) => {
          //       return {
          //         id: String(elem.id),
          //         label: elem.name,
          //         value: elem.id,
          //       };
          //     }),
          //     categories: data?.categories?.map((elem) => {
          //       return {
          //         id: String(elem.id),
          //         label: elem.name,
          //         value: elem.id,
          //       };
          //     }),
          //   }));
          //   setEngRatingValue(data?.languages[0]?.proficiency);
          //   setHinRatingValue(data?.languages[1]?.proficiency);
          // }
        }
      })
      .catch((err) => console.log(err));
  };

  const getDesignations = () => {
    dispatch(getDesignationsList())
      .then((res) => {
        console.log(res, "resssssssssssssss");
        if (res.type === "getDesignationsList/fulfilled") {
          const data = res?.payload?.data?.map((elem) => {
            return {
              value: elem,
              label: elem,
            };
          });
          setDesignationList(data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (values, setSubmitting) => {
    const formData = new FormData();
    formData.append("data[first_name]", values.first_name);
    formData.append("data[last_name]", values.last_name);
    formData.append("data[email]", values.email);
    formData.append("data[mobile_number]", values.mobile_number);
    formData.append("data[alternate_mobile_no]", values.alt_mobile_number);
    formData.append("data[employment_status]", values.employment_status || "");
    formData.append("data[experience]", values.experience);
    formData.append("data[designation]", values.designation);
    formData.append("data[address_attributes][zip_code]", values.zip_code);
    formData.append("data[address_attributes][address]", values.address || "");
    formData.append("data[address_attributes][city]", values.city || "");
    formData.append("data[address_attributes][state]", values.state || "");
    formData.append("data[address_attributes][country]", values.country || "");
    if (showResume) {
      formData.append("data[resume]", values.resume);
    }

    setSubmitting(false);
    handleUpate(formData);
  };

  const handleUpate = (data) => {
    dispatch(editPersonalDetailApi({ id: user.id, data: data }))
      .then((res) => {
        if (res.type === "personal_details/edit/fulfilled") {
          smoothScroll();
          Toaster.TOAST(res.payload?.message, "success");
          getData();
          let user_data = res.payload.data.attributes["jobseeker"];
          user_data = {
            ...user_data,
            personal_details: res.payload.data.attributes.personal_details,
          };

          // localStorage.setItem("login_data", JSON.stringify(user_data));
          let data = {
            ...user_data,
            profile_complete:true
          }
          localStorage.setItem("login_data", JSON.stringify(data));
          dispatch(setUserData(data));
          if (res.payload.data.attributes["jobseeker"]?.profile_complete) {
            Toaster.TOAST("Profile completed successfully! Please proceed to apply to jobs.")
            navigate("/all-jobs")
          }
        } else if (res.type === "personal_details/edit/rejected") {
          smoothScroll();
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  // const resumeDelete = (setFieldValue) => {
  //   dispatch(deleteResumeApi({ id, dataId: jobseekerId }))
  //     .then((res) => {
  //       if (res.type === "personal_details/delete_resume/fulfilled") {
  //         Toaster.TOAST(res.payload?.message, "success");
  //         setFieldValue("resume", null);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.message, ".err");
  //     });
  // };

  const handleDownload = (value) => {
    dispatch(
      downloadResumeApi({
        id: user.id,
        dataId: user?.personal_details?.id,
      })
    )
      .then((res) => {
        if (res.type === "personal_details/download_resume/fulfilled") {
          const fileContent = res.payload;
          if (!fileContent) {
            console.error("No file content received.");
            return;
          }
          const fileBlob = new Blob([fileContent]);
          const fileURL = window.URL.createObjectURL(fileBlob);
          const link = document.createElement("a");
          link.href = fileURL;

          link.setAttribute("download", value);
          document.body.appendChild(link);
          link.click();
          Toaster.TOAST("Resume downloaded successfully", "success");
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const handleChange = (e, setFieldValue, setErrors) => {
    const newPincode = e.target.value;
    setFieldValue("zip_code", newPincode);

    if (newPincode?.length === 6) {
      // Make API call
      axios
        .get(`https://api.postalpincode.in/pincode/${newPincode}`)
        .then((response) => {
          if (response.data[0].PostOffice) {
            setFieldValue("city", response.data[0].PostOffice[0].Block);
            setFieldValue("state", response.data[0].PostOffice[0].State);
            setErrors({ zip_code: "" });
            const country = response.data[0].PostOffice[0].Country;
            if (country) {
              setFieldValue("country", country);
            } else {
              setFieldValue("country", ""); // Clear the country field if no country information is found
            }
          } else if (response.data[0].Message === "No records found") {
            setFieldValue("city", "");
            setFieldValue("state", "");
            setFieldValue("country", "");

            setErrors({ zip_code: "Enter a valid pincode" });
          }
        })
        .catch((error) => {
          // Handle errors here
          setErrors({ zip_code: "Enter a valid pincode" });
          console.error("Error fetching pincode data:", error);
        });
    }
  };

  const ChildGrid = ({
    children,
    size = "",
    className = "oh-form-field",
    label = null,
    required = false,
  }) => {
    let gridSize = {};
    switch (size) {
      case "primary":
        gridSize = { xs: 12, md: 12, lg: 12 };
        break;
      case "secondary":
        gridSize = { xs: 12, md: 6, lg: 6 };
        break;
      case "tertiary":
        gridSize = { xs: 12, md: 1, lg: 1 };
        break;
      case "resumeColumn":
        gridSize = { xs: 12, md: 12, lg: 6 };
        break;
      default:
        gridSize = { xs: 12, md: 6, lg: 3 }; // Default to primary size
    }

    return (
      <Grid className={className} item {...gridSize}>
        {label && (
          <PrimaryLabel
            labelType="secondary"
            label={label}
            required={required}
          />
        )}
        {children}
      </Grid>
    );
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("loginBtn").click();
    }
  };

  return (
    <ProfileLayout>
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validateOnblur={false}
        validationSchema={PersonalDetail}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          touched,
          errors,
          setErrors,
          dirty,
        }) => {
          return (
            <Form className="oh-signup-form" autoComplete="off">
              <Grid container lg={12}>
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Personal Details"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid label="First Name" required={true}>
                      <InputField
                        id="first_name"
                        type="text"
                        placeholder="Eg: John"
                        name="first_name"
                        value={values.first_name}
                        validateOnChange={true}
                      />
                    </ChildGrid>
                    <ChildGrid label="Last Name" required={true}>
                      <InputField
                        id="last_name"
                        type="text"
                        placeholder="Eg: John"
                        name="last_name"
                        value={values.last_name}
                        validateOnChange={true}
                      />
                    </ChildGrid>
                    <ChildGrid label="Email">
                      <InputField
                        id="email"
                        type="email"
                        placeholder="Eg: jonhdoe@gmail.com"
                        name="email"
                        value={values.email}
                        validateOnChange={true}
                        disabled={true}
                      />
                    </ChildGrid>
                    <ChildGrid label="Mobile Number">
                      <InputField
                        id="mobile_number"
                        type="text"
                        placeholder="Eg: 9987654329"
                        name="mobile_number"
                        value={values.mobile_number}
                        validateOnChange={true}
                        disabled={checkMobile}
                      />
                    </ChildGrid>
                    <ChildGrid required={true} label="Alternate Mobile Number">
                      {/* <InputField
                        id="alt_mobile_number"
                        type="text"
                        placeholder="Eg: 99999-99999"
                        name="alt_mobile_number"
                        value={values.alt_mobile_number}
                        validateOnChange={true}
                        inputProps={{
                          maxLength: 10,
                          // inputProps: {
                          //   max: 10000,
                          //   min: 1,
                          //   isInteger: true,
                          // },
                        }}
                        // disabled={true}
                      /> */}
                      <InputField
                        id="alt_mobile_number"
                        type="text"
                        placeholder="Eg: 9987654329"
                        name="alt_mobile_number"
                        value={values.alt_mobile_number}
                        validateOnChange={true}
                        inputProps={{
                          maxLength: 10,
                        }}
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>

                {/* other details section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Other Details"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid size="secondary" required={true} label="Designation">
                      <MuiSelectWithSearch
                        id="designation"
                        placeholder={
                          !values.designation &&
                          values.designation !== 0 &&
                          "Eg: CEO"
                        }
                        select
                        name="designation"
                        value={values.designation}
                        handleChange={(val) => {
                          setFieldValue("designation", val.label);
                        }}
                        error={Boolean(errors.designation)}
                        helperText={
                          <FieldError errorString={errors.designation} />
                        }
                        selectOptions={designationList}
                      // props={{
                      //   onKeyPressCapture: () => handleKeyPress,
                      // }}
                      />
                    </ChildGrid>
                    <ChildGrid label="Employment Status">
                      <MuiSelect
                        id="employment_status"
                        select
                        name="employment_status"
                        value={values.employment_status}
                        placeholder={
                          !values.employment_status &&
                          values.employment_status !== "fresher" &&
                          "Please select"
                        }
                        handleChange={(e) => {
                          setFieldValue("employment_status", e.target.value);
                          if (e.target.value == "fresher") {
                            setFieldValue("experience", "");
                          }
                        }}
                        selectOptions={employmentStatusArr}
                        props={{
                          onKeyPressCapture: () => handleKeyPress,
                        }}
                      />
                    </ChildGrid>
                    <ChildGrid label="Experience">
                      <MuiSelect
                        id="experience"
                        select
                        name="experience"
                        disabled={values.employment_status === "fresher"}
                        value={values.experience}
                        placeholder={
                          !values.experience &&
                          values.experience !== 0 &&
                          "Please select"
                        }
                        handleChange={(e) => {
                          setFieldValue("experience", e.target.value);
                        }}
                        selectOptions={workExperienceArr}
                        props={{
                          onKeyPressCapture: () => handleKeyPress,
                        }}
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>

                {/* address Section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Address"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid label="Pincode">
                      <MuiTextField
                        id="zip_code"
                        type="number"
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                        placeholder="Enter here"
                        name="zip_code"
                        value={values.zip_code}
                        onChange={(e) =>
                          handleChange(e, setFieldValue, setErrors)
                        }
                        onBlur={(e) =>
                          handleChange(e, setFieldValue, setErrors)
                        }
                        error={Boolean(errors.zip_code)}
                        helperText={
                          <FieldError errorString={errors.zip_code} />
                        }
                        inputProps={{
                          maxLength: 6,
                          inputProps: { min: 6, max: 6 },
                        }}
                      />
                    </ChildGrid>
                    <ChildGrid label="City">
                      <MuiTextField
                        id="city"
                        type="text"
                        placeholder="Enter here"
                        name="city"
                        value={values.city}
                        onChange={(e) => setFieldValue("city", e.target.value)}
                        error={Boolean(errors.city)}
                        helperText={<FieldError errorString={errors.city} />}
                      />
                    </ChildGrid>
                    <ChildGrid label="State">
                      <MuiTextField
                        id="state"
                        type="text"
                        placeholder="Enter here"
                        name="state"
                        value={values.state}
                        onChange={(e) => setFieldValue("state", e.target.value)}
                        error={Boolean(errors.state)}
                        helperText={<FieldError errorString={errors.state} />}
                      />
                    </ChildGrid>
                    <ChildGrid label="Country">
                      <MuiTextField
                        id="country"
                        type="text"
                        placeholder="Enter here"
                        name="country"
                        value={values.country}
                        onChange={(e) =>
                          setFieldValue("country", e.target.value)
                        }
                        error={Boolean(errors.country)}
                        helperText={<FieldError errorString={errors.country} />}
                      />
                    </ChildGrid>
                    <ChildGrid size="secondary" label="Address">
                      <MuiTextField
                        id="address"
                        type="text"
                        placeholder="Enter here"
                        name="address"
                        value={values.address}
                        onChange={(e) =>
                          setFieldValue("address", e.target.value)
                        }
                        error={Boolean(errors.address)}
                        helperText={<FieldError errorString={errors.address} />}
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>

                {/* Social Media links */}
                <Grid item className="oh-details-container">
                  <Typography
                    // className="oh-details-label"
                    sx={{ color: "#a1a7bc", fontStyle: "italic", fontSize: "12px" }}
                  >
                    *Kindly upload your resume to continue applying to job posts
                  </Typography>
                  <Grid className="oh-company-details resume-grid" container>
                    <ChildGrid size="resumeColumn">
                      <UploadResume
                        setFieldValue={setFieldValue}
                        setShowResume={setShowResume}
                        value={values?.resume}
                        showDownloadBtn={true}
                        handleDownloadClick={() => handleDownload(values?.resume)}
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>
              </Grid>
              <MuiDivider className="oh-details-divider" />
              <CustomLoadingButton
                style={!dirty ? { opacity: 0.5 } : null}
                disabled={!dirty ? true : false}
                loading={isSubmitting}
                type="submit"
                title={"Submit"}
                className="oh-submit-btn"
              />
            </Form>
          );
        }}
      </Formik>
    </ProfileLayout>
  );
};

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value.replace(/^\s+/, ""), true);
  };

  // const error = (validateOnChange || meta.touched) && meta.error;
  const error = meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default PersonalDetails;
