import React, { useEffect, useState } from "react";

//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";

//OTHER
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import EarningTable from "./EarningTable/EarningTable";
import EarningCard from "../../../components/Freelancer/Card/Earning/EarningCard";
import TableHeader from "../../../components/Freelancer/TableHeader/TableHeader";
import TranscationTable from "./TranscationTable/TranscationTable";
import { tabData } from "../../../Data";

//CSS
import "./index.scss";
import RequestToPay from "../../../components/Dialog/RequestToPay/RequestToPay";
import FreelancerLayout from "../../Layouts/Freelancer/FreelancerLayout";
import {
  earningAmountsApi,
  getCandidateEarnings,
  setCandidateEarning,
  setEarningCardsData,
} from "../../../redux/Freelancer/earningDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import MuiIconChip from "../../../mui/Chip/IconChip";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TableSearch from "../../../components/Freelancer/TableSearch/TableSearch";
import EarningFilter from "../../../components/Filter/EarningFilter";
import TransanctionFilter from "../../../components/Filter/TransanctionFilter";
import Tooltip from "@mui/material/Tooltip";
import { formatAmount } from "../../../utils";
import OutlinedChip from "../../../components/customChips/OutlinedChip";

const Earning = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(tabData[0]?.value);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openRequestToPay, setOpenRequestToPay] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [popupState, setPopupState] = useState("");
  const initialData = useSelector((state) => state.earningData.initialData);
  const { earningCardsData } = useSelector((state) => state.earningData);
  const userID = localStorage.getItem("login_data");
  const freeId = JSON.parse(userID);
  const [loadEarning, setLoadEarning] = useState(false);
  const [transactionEarning, setTransactionEarning] = useState(false);

  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(1);

  let data = [
    {
      earn: earningCardsData?.[2]?.project_earning ? formatAmount(
        earningCardsData?.[2]?.project_earning
      ) : "00",

      project_earning: "Projected Earning",
      // icon: <ArrowDropUpOutlinedIcon />,
      // day_percent: "30%",
      // days: "past 7 days",
      days: earningCardsData?.[2]?.projected_earning_amount
    },
    {
      earn: earningCardsData?.[1]?.actual_earning ? formatAmount(earningCardsData?.[1]?.actual_earning) : "00",
      project_earning: "Actual Earning",
      // icon: <ArrowDropDownOutlinedIcon />,
      // day_percent: "29%",
      days: earningCardsData?.[1]?.actual_earning_amount,
    },
  ];

  useEffect(() => {
    getEarningCandidatesCard();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpenPopup(true);
  };
  const handleClose = () => {
    setOpenPopup(false);
    setOpenRequestToPay(false);
  };

  const handlePopupState = (popupToHandle = "") => {
    setPopupState(popupToHandle);
  };

  const getEarningCandidatesData = (keyword = "") => {
    setLoadEarning(true);
    dispatch(getCandidateEarnings({ keyword: keyword }))
      .then((res) => {
        if (res?.type === "getCandidateEarnings/fulfilled") {
          dispatch(setCandidateEarning(res?.payload?.data));
          setRowCount(res.payload.meta.total_records)
          setLoadEarning(false);
        } else if (res?.type === "getCandidateEarnings/rejected") {
          setLoadEarning(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getEarningCandidatesCard = () => {
    dispatch(earningAmountsApi({ id: freeId?.id }))
      .then((res) => {
        if (res?.type === "earningAmountsApi/fulfilled") {
          dispatch(setEarningCardsData(res?.payload?.data));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = (keyword = null) => {
    value === "earning_candidate" && getEarningCandidatesData(keyword);
  };

  const myBalEarning = earningCardsData?.[0]?.my_balance ? formatAmount(
    earningCardsData?.[0]?.my_balance
  ) : "00"

  return (
    <FreelancerLayout>
      <Box className="oh-earning">
        <MuiTypography className="oh-earn-heading" content="Earning" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4.5} lg={5}>
            <Box className="oh-earning-first">
              <Tooltip
                title={`Exact Amount: ₹${earningCardsData?.[0]?.my_balance}`}
              >
                <Box
                  className="earning-header"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MuiTypography className="earn-text">
                    <span className="currency-sybmol">₹</span>
                    <span className="currency-value">
                      {/* {earningCardsData?.data?.attributes?.my_balance} */}
                      {myBalEarning}
                    </span>
                  </MuiTypography>
                  <Button
                    className="request-pay-btn"
                    // onClick={() => setOpenRequestToPay(true)}
                  >
                    Request to Pay
                  </Button>
                </Box>
              </Tooltip>
              <MuiTypography className="projected-text" content="My Balance" />
              <MuiTypography className="days-box">
                {/* <ArrowDropDownOutlinedIcon /> */}
                {/* <span className="days-percentage">29%</span> */}
                <span className="days-text">{
                  earningCardsData?.[0]?.my_balance_earning_amount || "No change"
                }</span>
              </MuiTypography>
            </Box>
          </Grid>
          {data.map((elem, index) => (
            <Grid key={index} className="earning-cards-grid-second" item xs={6} md={3.75} lg={3.5}>
              <EarningCard
                {...elem}
              // className={
              //   elem.day_percent < "30%"
              //     ? "days-percentage-less"
              //     : "days-percentage-more"
              // }
              />
            </Grid>
          ))}
        </Grid>
        <Card className="oh-card-tab">
          <TableHeader
            tabData={tabData}
            value={value}
            isAddDrawerOpen={openPopup}
            handleChange={handleChange}
            onClose={handleClickOpen}
            filterShow={true}
            renderExtraOptions={
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <MuiIconChip
                  icon={<FilterAltIcon sx={{ marginTop: "-1px" }} />}
                  label="Filter"
                  onClick={() =>
                    handlePopupState(
                      value === "earning_candidate"
                        ? "filter"
                        : "transaction_filter"
                    )
                  }
                  className="oh-iconchip-box oh-filter-chip"
                  spanClass={"oh-span"}
                />
                {/* <TableSearch
                  searchData={searchKeyword}
                  setSearchData={setSearchKeyword}
                  handleSearch={handleSearch}
                /> */}
              </div>
            } // to be commented temporarily till earning candidates search api are not ready
          >
            {value === "earning_candidate" ? (
              <EarningTable
                loadEarning={loadEarning} setLoadEarning={setLoadEarning}
                popupState={popupState} setPage={setPage} page={page} rowCount={rowCount} setRowCount={setRowCount} handleClickOpen={handleClickOpen} />
            ) : (
              <TranscationTable
              transactionEarning={transactionEarning} setTransactionEarning={setTransactionEarning}
              popupState={popupState} setPage={setPage} page={page} rowCount={rowCount} setRowCount={setRowCount} />
            )}
          </TableHeader>
        </Card>
        <RequestToPay open={openRequestToPay} handleClose={handleClose} />
      </Box>
      <EarningFilter
        onClose={handlePopupState}
        open={popupState === "filter"}
        setRowCount={setRowCount}
        setLoadEarning={setLoadEarning}
        initialData={initialData}
        setPage={setPage}
      />
      <TransanctionFilter
        onClose={handlePopupState}
        open={popupState === "transaction_filter"}
        setRowCount={setRowCount}
        setPage={setPage}
        setTransactionEarning={setTransactionEarning}
      />
    </FreelancerLayout>
  );
};

export default Earning;
