import Tooltip from "@mui/material/Tooltip";

const MuiTooltip = ({ title, icon, description = null, displayTitleOnly = null, handleIconClick, length = 15 }) => {
    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        background: "var(--oh-blue-300-color)",
                        "& .MuiTooltip-arrow": {
                            color: "var(--oh-blue-300-color)",
                        },
                        fontWeight: 400,
                        textTransform: "capitalize",
                        padding: "8px 20px",
                        fontSize: "14px",
                        cursor: "pointer",
                        lineHeight: "160%",
                        textAlign: "center"
                    },
                },
            }}
            arrow
            title={description || title}
            placement="top-start"
        >
            {
                icon ? (
                    <span onClick={handleIconClick} className="iconContact">
                        {icon}
                    </span>
                )
                    : (displayTitleOnly || <span className="ma-userName-table">
                        {title?.length < length ? title : title?.substring(0, parseInt(length) - 1) + "..."}
                    </span>)
            }
        </Tooltip>
    )
}

export default MuiTooltip
