import React from 'react'
//MUI
import Box from '@mui/material/Box'
//ASSETS
import Male from '../../../assets/images/male.svg';
import Female from '../../../assets/images/female.svg';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import CloseIcon from '@mui/icons-material/Close';

//CSS
import './index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setAddSelectedCandidate } from '../../../redux/Freelancer/allotedJobsSlice'
import { freelancerExperienceArr } from '../../../Data';

const CandidateSelected = ({ data }) => {
    const dispatch = useDispatch();

    const addSelectedCandidateForJobPost = useSelector(state => state.allotedJobs.addSelectedCandidateForJobPost)

    const handleClearSelectedCandidate = (id) => {
        const data = addSelectedCandidateForJobPost.filter(item => item.id !== id);
        dispatch(setAddSelectedCandidate(data));
    };

    return (

        <Box className='add-candidate-blank'
            sx={{
                height: '170px',
                width: '180px',
                borderRadius: '5px',
                // margin: '15px 0',
                border: '1px solid #dee4ff',
                display: 'flex',
                flexDirection: "column",
                justifyContent: 'center',
                alignItems: 'center',
                gap: "5px",
                position: "relative",
                backgroundColor: 'var(--oh-white-200-color)'
            }}
            onClick={() => handleClearSelectedCandidate(data.id)}
        >
            <CloseIcon sx={{
                // fontSize: "1px",
                position: 'absolute',
                top: 8,
                right: 8,
                backgroundColor: 'var(--oh-blue-300-color)',
                border: "1px solid var(--oh-blue-300-color)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: '50%',
                zIndex: 3,
                // color: '#fff', 
                cursor: 'pointer',
                width: "20px", height: "20px",
                padding: "2px",
                color: "white"

            }}
            />
            <Box
                sx={{
                    minWidth: '40px',
                    width: '40px',
                    marginRight: '10px',
                    marginBottom: "10px",
                }}
            >
                <CircularProgressbarWithChildren
                    value={66}
                    strokeWidth={6}
                    backgroundPadding={0}
                    styles={buildStyles({
                        pathColor: '#34A853'
                    })}
                >
                    <img
                        src={data?.gender === 'Male' ? Male : Female}
                        alt='avatar'
                        style={{
                            width: '36px',
                            height: '36px',
                            borderRadius: '50%'
                        }}
                    />
                </CircularProgressbarWithChildren>
            </Box>
            <p className='title-name text-center'>{data?.name || "David Elbert"}</p>
            <p className='text text-center'>
                {data?.designation || "Senior Java Developer"}{' '}
            </p>
            <p className='candidate-experience text text-center'>
                {freelancerExperienceArr.find((elem) => elem.value == data?.experience)?.label || "-"}
            </p>
        </Box>
    )
}

export default CandidateSelected
