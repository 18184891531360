import * as Yup from "yup";
import { FIRSTNAME_REGEX } from "../utils/regex";
const FIRSTNAME_REGEX1 = /^[a-zA-Z0-9\s/]+$/; // Updated regex to allow "/"
export const NewOpeningSchema = Yup.object().shape({
  title: Yup.string()
    .required("Job title / designation is required.")
    .test("check if not empty", "Job title .", (val) => {
      if (!val || val.trim().length === 0) {
        return false;
      }
      return true;
    }),
    // .matches(FIRSTNAME_REGEX1, "Enter a valid job title"),
  skills: Yup.array()
    .min(1, "Atleast one key skill is required.")
    .required("Please select skills"),
  vacancies: Yup.number()
    .typeError("Must be a valid number") // Error message for non-numeric input
    .integer("Must be a valid number") // Error message for non-integer input
    .positive("Must be a valid number") // Error message for negative numbers
    .max(1000, "Enter a number less than or equal to 1000") // Error message for maximum value
    .required("No. of vacancies are required"), // Error message for required field,

  experience: Yup.number().required("Please Select Experience"),
  designation: Yup.string().required("Please Select Designation"),
  ctc: Yup.number().required("Please Select CTC"),
  description: Yup.string()
    .required("Job description is required.")
    .test("check if empty", "Job description must be relevant.", (val) => {
      if (!val || val.trim().length === 0) {
        return false;
      }
      return true;
    })
    .test(
      "matchIfEmpty",
      "Job Description must be at least 150 characters long!",
      (val) => {
        const text = val ? val.replace(/<[^>]*>?/gm, "") : "";
        return text.trim().length >= 150;
      }
    ),
  industry: Yup.number().required("Please Select Industry / Category"),
  mode: Yup.number().required("Please Select Work Mode"),
  location: Yup.array()
    .min(1, "Atleast one location is required.")
    .required("Please Select Location"),
  education: Yup.number().required("Please Select Education"),
});
