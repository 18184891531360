import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import Box from "@mui/material/Box";
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";
import "./index.scss"

const MuiHorizontalStepper = ({ steps = [], activeStep }) => {


  return (
    <Box sx={{ width: "100%" }}>
      <div className="oh-stepper">
        {steps.map((elem, index) => (
          <div key={elem.label} className={`oh-step ${index <= activeStep ? 'completed' : ''} 
          ${index != steps.length - 1 ? "oh-connector-step" : ""} 
          ${index === activeStep ? 'active' : index == 1 ? 'oh-connector-last-step' : ''}`}>
            {index <= activeStep ? (
              <CheckCircleIcon className="check-icon" sx={{ color: "#28A745" }} />
            ) : <CircleIcon className="check-icon" sx={{ color: "var(--oh-gray-200-color)" }} />}
            <div className="step-content">
              <MuiTypography content={elem.label} className="oh-subtitle" />
              <MuiTypography content={elem.description} className="oh-desc" />
            </div>


          </div>
        ))}
      </div>
    </Box>
  );
};

const ApplicationStatus = ({ title = "" }) => {
  const showAppliedJobDetail = useSelector(
    (state) => state.appliedJobs.showAppliedJobDetail
  );

  const dateFormatter = (date) => {
    return moment(date).format("MMM DD, YYYY");
  };

  // Determine the active step index based on application status
  let activeStep = 0;
  if (showAppliedJobDetail?.attributes?.resume_viewed_date) {
    activeStep = 2; // Reviewed
  } else if (showAppliedJobDetail?.attributes?.viewed_at) {
    activeStep = 1; // Viewed
  }

  // Define the steps array
  const steps = [
    {
      description: dateFormatter(showAppliedJobDetail?.attributes?.applied_at),
      label: "Applied",
    },
    {
      description: showAppliedJobDetail?.attributes?.viewed_at
        ? dateFormatter(showAppliedJobDetail?.attributes?.viewed_at)
        : "Pending",
      label: "Viewed",
    },
    {
      description: showAppliedJobDetail?.attributes?.resume_viewed_date
        ? dateFormatter(showAppliedJobDetail?.attributes?.resume_viewed_date)
        : "Pending",
      label: "Reviewed",
    },
  ];

  return (
    <Box className="oh-status-box">
      <span className="oh-title">{title}</span>
      <MuiHorizontalStepper steps={steps} activeStep={activeStep} />
    </Box>
  );
};

export default ApplicationStatus;
