import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosApi } from '../apis/AxiosApi'
import { PASSWORD, USERS, VERIFY_OTP } from '../apis/Constant/routes'

const initialState = {
  otp: null,
  timerOn: true,
  mins: 1,
  sec: 0,
  email_otp: null,
  mobile_otp: 123456,
}

export const verifyOtp = createAsyncThunk(
  'otpVerification',
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${USERS}${PASSWORD}${VERIFY_OTP}`,
      type: 'POST',
      data: JSON.stringify({ data: data })
    })
  }
)

export const verifyBothOtps = createAsyncThunk(
  'verifyBothOtps',
  async (data) => {
    return await AxiosApi({
      path: `${USERS}/confirmation/sign_up_otp_verification`,
      type: 'POST',
      data: JSON.stringify({ data: data })
    })
  }
)

export const verifyOtpSlice = createSlice({
    name: 'otpVerification',
    initialState,
    reducers: {
        setOtp: (state, action) => {
            state.otp = action.payload
        },
        setEmailOtp: (state, action) => {
          state.email_otp = action.payload
        },
        setMobileOtp: (state, action) => {
          state.mobile_otp = action.payload
        },
        setTimerState: (state, action) => {
          state.timerOn = action.payload
        },
        setCountDownReset: (state, action) => {
          state.mins = 1;
          state.sec = 0;
        },
        setMinutes: (state, action) => {
          state.mins = action.payload;
        },
        setSeconds: (state, action) => {
          state.sec = action.payload;
        },
        clearOtps: (state, action) => {
          state.otp = null;
          state.email_otp = null;
          state.mobile_otp = null;
        }
    }
})

export default verifyOtpSlice.reducer
export const { setOtp, setEmailOtp, setMobileOtp, setTimerState, clearOtps, 
  setCountDownReset, setMinutes, setSeconds } = verifyOtpSlice.actions
