import React from "react";
import MuiBasicDrawer from "../../../mui/Drawers/MuiBasicDrawer";
import Sidebar from "../../Sidebar/Sidebar";
// CSS
import "./index.scss";
import "../../../Styles/Global.scss";

const CustomProfileNavigation = ({
    open,
    onClose,
    profile = true
}) => {

    return (
        <>
            <MuiBasicDrawer sx={{ 
                '.MuiPaper-root': {
                    width: "15em",
                    '.oh-sidebar': {
                        width: "100%",
                        minWidth: "15em"
                    }
                }
             }} anchor="left" onClose={onClose} closeIcon={true} open={open}>
                <Sidebar profile={profile} className="drawer-navigation-bar" />
            </MuiBasicDrawer>
        </>
    );
}

export default CustomProfileNavigation;