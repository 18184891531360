import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";

const initialState = {
    freelancerPlacedCandidates: [],
    freelancerPlacedCandidateById: {},
};

export const getFreelancerPlacedCandidateData = createAsyncThunk(
  "getFreelancerPlacedCandidateData",
  async (data) => {
    let page = data?.page || 1;
    let pageSize = data?.pageSize || 10;
    let appendHeader = data?.keyword ? `?search[term]=${data?.keyword}` : `?page=${page}&per_page=${pageSize}`;
    return AxiosApi({
      path: `/freelancer/freelancer_candidates/placed_candidates${appendHeader}`,
      type: "GET",
      params: "",
    });
  }
);

export const getFreelancerPlacedCandidateDataById = createAsyncThunk(
    "getFreelancerPlacedCandidateDataById",
    async (id) => {
      return AxiosApi({
        // path: `/get_all_candidates_of_job_post?data[admin_job_post_id]=${id}`,
        path: `/freelancer/freelancer_candidates/placed_candidates/${id}`,
        type: "GET",
        params: ""
      });
    }
  );


export const freelancerPlacedCandidatesSlice = createSlice({
  name: "freelancerPlacedCandidates",
  initialState,
  reducers: {
    setFreelancerPlacedCandidateData: (state, action) => {
      state.freelancerPlacedCandidates = action.payload;
    },
    setFreelancerPlacedCandidateDataById: (state, action) => {
      state.freelancerPlacedCandidateById = action.payload;  
    },
      clearFreelancerPlacedCandidateData: (state, action) => {
        state.freelancerPlacedCandidateById = {};
        state.freelancerPlacedCandidates = [];
      }
  },
//   extraReducers: (builder) => {
//     builder
//       .addCase(getPersonalDetailApi.pending, (state, action) => {
//         state.loading = true;
//       })
//       .addCase(getPersonalDetailApi.fulfilled, (state, action) => {
//         state.personalData = action.payload;
//         state.loading = false;
//         state.error = false;
//       })
//       .addCase(getPersonalDetailApi.rejected, (state, action) => {
//         state.error = true;
//       });
//   },
});

export default freelancerPlacedCandidatesSlice.reducer;
export const { 
    setFreelancerPlacedCandidateData, 
    setFreelancerPlacedCandidateDataById, 
    clearFreelancerPlacedCandidateData } = freelancerPlacedCandidatesSlice.actions;
