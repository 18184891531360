import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Person from "../../../../assets/images/freelancer-image.png";
import personImg from "../../../../assets/images/landing-page-short.png";
import { ReactComponent as Verify } from "../../../../assets/images/verifyIcon.svg";
import { ReactComponent as RightArrowTrans } from "../../../../assets/images/rightArrowIcon.svg";
import "./index.scss";

const OpportunityBanner = () => {
  const navigate = useNavigate();
  return (
    <Grid
      className="opportunity-box"
      container
      alignItems={"center"}
      spacing={4}
      md={10}
      sx={{
        backgroundImage: "linear-gradient(147deg, #FFF 18.05%, #F3F3FF 65.91%)",
        border: "1px solid #DEE4FF",
        borderRadius: { xs: 0, sm: 0, md: "10px" },
        mx: "auto",
      }}
    >
      <Grid item lg={5} md={4} xs={12} className="opportunity-box--grid1">
        <Typography
          className="grid1-heading"
          fontSize={"28px"}
          fontWeight={700}
          color="#445386"
          textAlign={"left"}
        >
          Explore Extra Income
          <span style={{ color: "#576EDB" }}> Opportunities </span>
          with
          <span style={{ color: "#576EDB" }}> OctaHire</span>
        </Typography>
        <Typography
          className="grid1-subHeading"
          fontSize={"14px"}
          fontWeight={"400"}
          color={"#445386"}
          textAlign={"left"}
        >
          Get started as a freelancer, explore projects and lead your way.
        </Typography>
      </Grid>
      <Grid item lg={4} md={4} xs={12} className="opportunity-box--grid2">
        <img
          src={personImg}
          alt="freelancer"
          style={{ height: "auto", maxWidth: "100%" }}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12} className="opportunity-box--grid3">
        <Typography
          className="grid3-heading"
          fontSize={"16px"}
          fontWeight={700}
          color="#445386"
          textAlign={"left"}
        >
          3 Simple Steps:
        </Typography>
        <Box className="grid3-box">
          <Box display={"flex"} alignItems={"center"} className="g3b-1">
            <Verify />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="#445386"
              ml={1}
            >
              Refer Candidates for Jobs
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} className="g3b-2">
            <Verify />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="#445386"
              ml={1}
            >
              Wait for resume selection
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} className="g3b-3">
            <Verify />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="#445386"
              ml={1}
            >
              Get Paid
            </Typography>
          </Box>
        </Box>
        <Box className="grid3-btn">
          <Button
            mt={2}
            sx={{
              borderRadius: "30px",
              background: "#576EDB",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px 12px",
              color: "#fff",
              fontSize: "12px",
              fontWeight: 400,
              textTransform: "none",
              "&:hover": {
                background: "#576EDB",
              },
            }}
            onClick={() => {
              navigate("/freelancers");
            }}
          >
            Join As Freelancer
            <RightArrowTrans style={{ marginLeft: "2px" }} />
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OpportunityBanner;
