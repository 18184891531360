import React, { useState } from "react";

//MUI
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//OTHER
import { allotedData } from "../../../Data";
import TableHeader from "../../../components/Freelancer/TableHeader/TableHeader";
import AllJobs from "../../../components/Freelancer/AllotedJobSection/AllJobs/AllJobs";
import AppliedJobs from "../../../components/Freelancer/AllotedJobSection/AppliedJobs/AppliedJobs";

//CSS
import "./index.scss";
import FreelancerLayout from "../../Layouts/Freelancer/FreelancerLayout";
import { useDispatch, useSelector } from "react-redux";
import { setTabsValue } from "../../../redux/Freelancer/allotedJobsSlice";

const AllotedJobs = ({ onClick = () => { } }) => {
  // const [value, setValue] = useState(allotedData[0]?.value);

  const dispatch = useDispatch();
  const value = useSelector(state => state.allotedJobs.tabsValue)
  const handleChange = (event, newValue) => {
    // setValue(newValue);
    dispatch(setTabsValue(newValue));
  };
  return (
    <FreelancerLayout>
      <Grid container lg={12} mt={1} className="oh-alloted-job">
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <TableHeader
              className="alloted-tab-header"
              tabData={allotedData}
              value={value}
              handleChange={handleChange}
              filterShow={false}
            />
          </Card>
          {value === "all_jobs" ? (
            <AllJobs value={value} />
          ) : (
            <AppliedJobs value={value} />
          )}
        </Grid>
      </Grid>
    </FreelancerLayout>
  );
};

export default AllotedJobs;
