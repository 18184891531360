import { API_BASE_URL } from "./Constant/BaseUrl";
import axios from "axios";
import "../App.css";
import { Toaster } from "../components/Toaster/Toaster";
import { toast } from "react-toastify";

export function AxiosApi({
  path,
  type,
  data = false,
  contentType = "application/json",
  isdownload = false,
  isLoader = true,
  toAppendAuthParams = true,
  extraHeaders = {},
}) {
  const url = `${API_BASE_URL}${path}`;

  const user_email = JSON.parse(localStorage.getItem("login_data"))?.email;
  const token = localStorage.getItem("token");
  const portal = localStorage.getItem("portal");
  let headers = {
    Accept: "application/json",
    // ...extraHeaders
  };

  if (user_email && token && portal && toAppendAuthParams) {
    headers = {
      ...headers,
      Authorization: token,
      "X-USER-EMAIL": user_email,
      "X-USER-TOKEN": token,
      "X-USER-TYPE": portal,
    };
  }

  if (contentType) {
    headers = { ...headers, "Content-Type": contentType };
  }
  if (extraHeaders) {
    headers = { ...headers, ...extraHeaders };
  }

  let apiParams = {
    method: type,
    headers: headers,
    data: data,
  };
  if (isdownload) {
    apiParams = { ...apiParams, responseType: "blob" }; // Set responseType to "blob" for downloading files
  }

  return new Promise((resolve, reject) => {
    // if (isLoader) {
    //   loader(true);
    // }
    axios(url, apiParams)
      .then((response) => {
        resolve(response?.data);
        // loader(false);
      })
      .catch((error) => {
        console.log("No response received:", error);
        if (error.response) {
          if (error?.request.status === 401) {
            localStorage.clear();
          }
          console.error("Status Code:", error.response.data);
          if (
            error?.response?.data?.message &&
            error?.response?.data?.status !== 404
          ) {
            Toaster.TOAST(error?.response?.data?.message, "error");
          }
          if (
            error?.response?.data?.error &&
            error?.response?.data?.status !== 404
          ) {
            Toaster.TOAST(error?.response?.data?.error, "error");
          }
        } else if (error.request) {
          toast.error("No response received or Network error");
        } else {
          toast.error("Error setting up the request:");
        }
        reject(error);
        //  loader(false);
      });
  });
}
