import React from "react";
//MUI
import Box from "@mui/material/Box";
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";

//OTHER

const EarningCard = ({
    earn,
    project_earning,
    icon,
    day_percent,
    days,
    className
}) => {

    return (
        <Box className="oh-earning-card">
            <MuiTypography className="earn-text">
                <span className="currency-sybmol">₹</span>
                <span className="currency-value">{earn}</span>
            </MuiTypography>
            <MuiTypography className="projected-text" content={project_earning} />
            <MuiTypography className="days-box">
                {/* <span className={`arrow-icons ${className}`}>{icon}</span>
                <span className={`days-percentage ${className}`}> {day_percent} </span> */}
                <span className="days-text">{days}</span>
            </MuiTypography>
        </Box>
    )
}

export default EarningCard;