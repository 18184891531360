import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";

// MUI
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// OTHER
import MuiTypography from "../Typography/MuiTypograpghy";

// CSS
import "./index.scss";
import { checkIfAuthPage } from "../../utils";

const MuiBreadcrumb = () => {
  const location = useLocation();
  const params = useParams();
  const { portalValue } = useSelector((state) => state.app);
  const pathSegments = location.pathname
    .split("/")
    .filter((x) => x !== params?.id && x !== params?.jobSeekerID && x !== "");
  const capitalize = (str) => {
    return str
      .split("-")
      .map((elem) => elem.charAt(0).toUpperCase() + elem.slice(1))
      .join(" ");
  };

  const breadcrumbs = [
    <Link
      key="dashboard"
      component={RouterLink}
      to={checkIfAuthPage() ? "/" : `/${portalValue}`}
      className="breadcrumbs-txt constant-route"
    >
      {checkIfAuthPage() ? "Home Page" : "Dashboard"}
    </Link>,
    ...pathSegments.map((segment, index) => {
      const routeTo = `/${pathSegments.slice(0, index + 1).join("/")}`;
      const isLastSegment = index === pathSegments.length - 1;

      return (
        <span key={index}>
          {isLastSegment ? (
            <MuiTypography
              className="breadcrumbs-txt"
              content={capitalize(segment)}
            />
          ) : (
            <Link
              component={RouterLink}
              to={routeTo}
              className="breadcrumbs-txt constant-route"
            >
              {capitalize(segment)}
            </Link>
          )}
        </span>
      );
    }),
  ];

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="oh-breadcrumbs"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </>
  );
};

export default MuiBreadcrumb;
