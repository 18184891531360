import React from 'react'
// MUI
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
// OTHER
import { DataGrid } from '@mui/x-data-grid'
import ChatBox from '../../pages/ChatBox/ChatBox'

const ListingTableWithPagination = ({
  rows = {},
  columns = {},
  disableColumnFilter = true,
  disableSelectionOnClick = true,
  disableColumnSelector = true,
  hideFooterPagination = true,
  rowHeight = 58.5,
  headerHeight = 52,
  checkboxSelection = false,
  onPageChange = null,
  rowCount,
  page,
  loader,
  pageSize,
  paginationMode = 'client',
  customNoResultsComponent = null,
  minHeight = '250px'
}) => {
  const handlePageChange = newPage => {
    if (onPageChange) {
      onPageChange(newPage)
    }
  }

  return (
    <Grid container>
      <Grid xs={12} md={12} item>
        <Paper elevation={0} sx={{ mb: 1 }}>
          <div style={{ minHeight: minHeight }}>
            <DataGrid
              disableColumnFilter={disableColumnFilter}
              disableSelectionOnClick={disableSelectionOnClick}
              disableColumnSelector={disableColumnSelector}
              rows={rows}
              columns={columns}
              getRowId={row => row.id}
              hideFooterPagination={hideFooterPagination}
              sx={{
                '& .MuiDataGrid-virtualScroller': {
                  minHeight: minHeight
                },
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: '0',
                '& .MuiPaper-root': {
                  boxShadow: 'none'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: '600',
                  fontSize: '14px',
                  textTransform: 'uppercase',
                  color: '#445386'
                },
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#F9F9FB'
                },
                '.MuiDataGrid-iconSeparator': {
                  display: 'none'
                },
                '& .MuiDataGrid-columnHeader': {
                  border: '1px solid #E8E8ED',
                  borderLeft: 'none',
                  borderTop: 'none',
                  borderBottom: 'none'
                },
                '& .MuiDataGrid-columnHeaders': {
                  border: '1px solid #E8E8ED',
                  borderLeft: 'none',
                  borderTop: 'none',
                  backgroundColor: '#DEE4FF',
                  color: '#445386'
                },
                '& .MuiDataGrid-columnHeader:nth-last-of-type(1)': {
                  borderRight: 'none'
                },
                '& .MuiDataGrid-columnHeader:first-of-type': {
                  paddingLeft: '18px'
                },
                '& .MuiDataGrid-footerContainer': {
                  minHeight: rowHeight,
                  // border: "none",
                  background: '#F9F9FB'
                },
                '& .MuiDataGrid-row': {
                  color: '#445386',
                  cursor: 'pointer',
                  '.MuiDataGrid-cell:first-of-type': {
                    paddingLeft: '18px'
                  }
                },
                '&. .MuiDataGrid-withBorderColor': {
                  borderBottom: 0
                  // overflowX: "scroll"
                }
              }}
              rowHeight={rowHeight}
              headerHeight={headerHeight}
              checkboxSelection={checkboxSelection}
              onPageChange={handlePageChange}
              onPaginationModelChange={onPageChange}
              rowsPerPageOptions={[pageSize]}
              pageSize={pageSize}
              paginationMode={paginationMode}
              rowCount={rowCount}
              page={page}
              autoHeight
              loading={loader}
              components={{
                NoRowsOverlay: customNoResultsComponent,
                NoResultsOverlay: customNoResultsComponent
              }}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ListingTableWithPagination
