import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//OTHER
import MuiTextField from "../../mui/TextField/MuiTextField";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import FieldError from "../../components/Errors/FieldError";
import { ForgotPasswordEmailSchema } from "../../schemas/ForgotPasswordSchema";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import { NUMERIC_REGEX } from "../../utils/regex";
import {
  forgotPassword,
  setEmail,
  setMobileNumber,
} from "../../redux/ForgotPasswordSlice";
import { Toaster } from "../../components/Toaster/Toaster";
import LoginLayout from "../Login/LoginLayout";
import { clearOtps } from "../../redux/verifyOtpSlice";

const ForgotWithEmail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState({
    fieldValue: "",
  });
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  const handleForgot = (values, setSubmitting) => {
    // if (NUMERIC_REGEX.test(values.fieldValue)) {
    //   let data = {
    //     mobile_number: values.fieldValue,
    //   };
    //   dispatch(clearOtps(null));
    //   dispatch(setMobileNumber(values.fieldValue));
    //   alert("Forgot password through mobile number is under developement!");
    //   // navigate("/otp-verification", {
    //   //   state: { mobile_number: values.fieldValue },
    //   // });   // will be uncommented when api is available
    //   navigate(`/login`
    //     // , { replace: true }
    //   );  // this navigation will be removed once api is available
    //   setSubmitting(false);
    // } else {

    let data = {
      email: values.fieldValue,
    };

    dispatch(forgotPassword(data))
      .then((res) => {
        setSubmitting(false);
        dispatch(setEmail(values.fieldValue));
        if (res.type === "forgotPassword/fulfilled") {
          Toaster.TOAST(res?.payload?.message, "success");
          navigate(`/otp-verification`, {
            state: { email: values.fieldValue },
            // replace: true
          });
        }
      })
      .catch((err) => {
        setSubmitting(false);
        Toaster.TOAST(err?.message, "error");
        console.log(err);
      });
    // }
  };

  return (
    <>
      <LoginLayout>
        <Formik
          initialValues={initialData}
          onSubmit={(values, { setSubmitting }) => {
            handleForgot(values, setSubmitting);
          }}
          validateOnblur={false}
          enableReinitialize
          validationSchema={ForgotPasswordEmailSchema}
        >
          {({ values, isSubmitting, setFieldValue, errors, touched }) => {
            return (
              <Form autoComplete="off">
                <Grid container xs={12} md={10} lg={10}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box className="oh-heading">
                      <MuiTypography
                        className="oh-login-title"
                        content="Forgot Password"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box sx={{ display: "flex", flexWrap: "no-wrap" }}>
                      <MuiTypography
                        variant="span"
                        style={{
                          display: "inline-block",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "22.4px",
                          color: "var(--oh-black-300-color)",
                          width: "100%",
                          maxWidth: "fit-content",
                        }}
                      >
                        Enter your registered email address to change your Octahire account password.
                        {/* or mobile number  */}
                        {/* , or username  */}
                        {/* to change your Octahire account password. */}
                      </MuiTypography>
                    </Box>
                  </Grid>

                  <Grid
                    className="oh-textfield-grid"
                    item
                    mt={1}
                    xs={12}
                    md={12}
                    lg={12}
                  >
                    <PrimaryLabel label="Email ID" required={true} />
                    {/* <PrimaryLabel label="Email ID / Mobile Number" required={true} /> */}
                    <MuiTextField
                      id="email"
                      placeholder="Enter your active Email ID"
                      // placeholder="Enter your active Email ID / Mobile Number"
                      name="email"
                      value={values.fieldValue}
                      onChange={(e) =>
                        setFieldValue("fieldValue", e.target.value)
                      }
                      error={touched.fieldValue && Boolean(errors.fieldValue)}
                      helperText={
                        <FieldError errorString={errors.fieldValue} />
                      }
                    />
                  </Grid>

                  <Grid
                    style={{ margin: "20px 0px 30px" }}
                    item
                    xs={12}
                    md={12}
                    lg={12}
                  >
                    <CustomLoadingButton
                      loading={isSubmitting}
                      type="submit"
                      title={"Next"}
                    />
                  </Grid>
                  {!token && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MuiTypography
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "26px",
                          textAlign: "center",
                          margin: "2px 0px 32px",
                          color: "var(--oh-blue-color)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate("/login"
                            // , { replace: true }
                          );
                        }}
                        content={`Back to Login`}
                      />
                    </Grid>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </LoginLayout>
    </>
  );
};

export default ForgotWithEmail;
