import React from "react";
import "./index.scss";

const PrimaryLabel = ({
    asteriskAlignment = "right", 
    labelType = "primary",
    label = "",
    required = null,
    className = labelType === "primary" ? "oh-primary-label" : "oh-secondary-label",
    style = {}
}) => {
    const Asterisk = () => {
        return <span style={{ margin: "0px 1px" }} >*</span>;
    }

    return (
        <label style={style} className={`oh-default-label ${className}`}>
            {asteriskAlignment === "left" && required && <Asterisk />}
            {label}
            {asteriskAlignment === "right" && required && <Asterisk />} 

        </label>
    )
}

export default PrimaryLabel;