import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik, useField, useFormikContext } from "formik";
// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

// OTHER
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import MuiDivider from "../../mui/Dividers/MuiDivider";
import MuiTextField from "../../mui/TextField/MuiTextField";
import MuiButtonOutlined from "../../mui/Buttons/MuiButtonOutlined";
import MuiSelect from "../../mui/TextField/MuiSelect";
// CSS
import "./index.scss";
import FieldError from "../../components/Errors/FieldError";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import ProfileLayout from "./ProfileLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  showCompanyInfo,
  updateCompanyInfo,
} from "../../redux/companyInfoSlice";
import { Toaster } from "../../components/Toaster/Toaster";
import { getYearsData, smoothScroll } from "../../utils";
import axios from "axios";
import { setUserData } from "../../redux/authSlice";
import CustomTextArea from "../../components/Textarea/CustomTextArea";
import * as Yup from "yup";
import { errMessageMaximum } from "../../utils";
import {
  FIRSTNAME_REGEX,
  PINCODE_REGEX,
  WEBSITE_REGEX,
} from "../../utils/regex";
import { orgTypeArr } from "../../Data";

const CompanyDetails = () => {
  const dispatch = useDispatch();
  const [yearsData, setYearsData] = useState([]);
  const { portalValue } = useSelector((state) => state?.app);

  const [initialData, setInitialData] = useState({
    organization_name: "",
    organization_type: "",
    nature_of_business: "",
    website: "",
    count_range: "",
    about_organization: "",
    establish_year: "",
    pincode: "",
    address: "",
    city: "",
    state: "",
    country: "",
    // award_year: '',
    // name_of_award: '',
    award_certification: [{ award_year: "", name_of_award: "" }],
    social_media_fb: "",
    social_media_linkdln: "",
    social_media_instagram: "",
  });

  const CompanyDetailSchema1 = Yup.object().shape({
    organization_name: Yup.string()
      .required("Organization name is required.")
      .max(150, errMessageMaximum("Organization name", 150))
      .test(
        "check if not empty",
        "Organization name must be relevant.",
        (val) => {
          if (!val || val.trim().length === 0 || val.trim().length < 2) {
            return false;
          }
          return true;
        }
      )
      .matches(FIRSTNAME_REGEX, "Organization name must be valid."),
    organization_type: Yup.string().required("Please Select Organization type"),
    website: Yup.string()
      .matches(WEBSITE_REGEX, "Website must be valid")
      .max(50, errMessageMaximum("Website", 50))
      .test(
        "check if website not empty",
        "Website must be relevant.",
        (val) => {
          if (!val || val.trim().length === 0 || val.trim().length < 2) {
            return false;
          }
          return true;
        }
      ),
    about_organization: Yup.string()
      .test(
        "check if About Organization not empty",
        "About organization must be relevant.",
        (val) => {
          if (!val || val.trim().length === 0 || val.trim().length < 2) {
            return false;
          }
          return true;
        }
      )
      .max(500, errMessageMaximum("About Organization", 500)),

    // award_certification: Yup.array().of(
    //   Yup.object().shape({
    //     award_year: Yup.string().required("Award year is required"),
    //     name_of_award: Yup.string()
    //       .required("Name of award/certification is required")
    //       .min(2, "Name of award/certification must be at least 2 characters")
    //       .max(30, "Name of award/certification must be at most 30 characters"),
    //   })
    // ),
    social_media_fb: Yup.string()
      .max(70, errMessageMaximum("Facebook link", 70))
      .matches(WEBSITE_REGEX, "Facebook link must be valid"),
    social_media_linkdln: Yup.string()
      .max(70, errMessageMaximum("Linkdln Link", 70))
      .matches(WEBSITE_REGEX, "Linkdln Link must be valid"),
    social_media_instagram: Yup.string()
      .max(70, errMessageMaximum("Instagram Link", 70))
      .matches(WEBSITE_REGEX, "Instagram Link must be valid"),
    pincode: Yup.string()
      .matches(PINCODE_REGEX, "Pincode must contain only numbers")
      .max(6, errMessageMaximum("Pincode", 6)),
    address: Yup.string().max(60, errMessageMaximum("Address", 60)),
    city: Yup.string().max(40, errMessageMaximum("City", 40)),
    state: Yup.string().max(40, errMessageMaximum("State", 40)),
  });

  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    const date = new Date();
    let present_year = parseInt(date.getFullYear());
    const yrsData = getYearsData(present_year + 1);
    setYearsData(yrsData);
  }, []);

  useEffect(() => {
    if (user?.id) {
      dispatch(showCompanyInfo(user.id))
        .then((res) => {
          if (res.type === "showCompanyInfo/fulfilled") {
            let response = res.payload.data.attributes.personal_details;

            let data = {
              organization_name: response?.organization_name,
              organization_type: response?.organization_type,
              nature_of_business: response?.nature_of_business,
              website: response?.website_url,
              count_range: response?.employee_count_range,
              about_organization: response?.description,
              establish_year: response?.establishment_year,
              pincode: response?.address?.zip_code,
              address: response?.address?.address,
              city: response?.address?.city,
              state: response?.address?.state,
              country: response?.address?.country,
              // award_year: '',
              // name_of_award: '',
              award_certification:
                Object.keys(response?.award_certification).length === 0
                  ? [
                    {
                      award_year: "",
                      name_of_award: "",
                    },
                  ]
                  : response?.award_certification?.map((elem) => {
                    return {
                      award_year: elem.year,
                      name_of_award: elem.award,
                    };
                  }),
              social_media_fb: response?.social_media_links?.facebook,
              social_media_linkdln: response?.social_media_links?.linkedin,
              social_media_instagram: response?.social_media_links?.instagram,
            };
            setInitialData({ ...data });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const handleSubmit = (values, setSubmitting) => {
    try {
      let data = {
        organization_name: values.organization_name,
        organization_type: values.organization_type,
        nature_of_business: values.nature_of_business,
        website_url: values.website,
        employee_count_range: values.count_range,
        description: values.about_organization,
        establishment_year: values.establish_year,
        award_certification: values.award_certification.map((elem) => {
          return {
            year: elem.award_year,
            award: elem.name_of_award,
          };
        }),
        social_media_links: {
          facebook: values.social_media_fb,
          linkedin: values.social_media_linkdln,
          instagram: values.social_media_instagram,
        },
        address_attributes: {
          zip_code: values.pincode,
          address: values.address,
          city: values.city,
          state: values.state,
          country: values.country,
        },
      };
      setSubmitting(false);
      //api calling
      dispatch(updateCompanyInfo({ data: data, id: user?.id }))
        .then((res) => {
          if (res.type === "updateCompanyInfo/fulfilled") {
            smoothScroll();
            Toaster.TOAST(res.payload.message, "success");
            let user_data = res.payload.data.attributes[portalValue];
            user_data = {
              ...user_data,
              personal_details: res.payload.data.attributes.personal_details,
            };

            localStorage.setItem("login_data", JSON.stringify(user_data));
            dispatch(setUserData(user_data));
          } else if (res.type === "updateCompanyInfo/rejected") {
            smoothScroll();
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log("error:", error);
    }
  };

  const ChildGrid = ({
    children,
    size = "",
    className = "oh-form-field",
    label = null,
    required = false,
  }) => {
    let gridSize = {};
    switch (size) {
      case "primary":
        gridSize = { xs: 12, md: 12, lg: 12 };
        break;
      case "secondary":
        gridSize = { xs: 12, md: 6, lg: 9 };
        break;
      case "tertiary":
        gridSize = { xs: 12, md: 1, lg: 1 };
        break;
      case "half":
        gridSize = { xs: 12, md: 6, lg: 6 };
        break;
      default:
        gridSize = { xs: 12, md: 6, lg: 3 }; // Default to primary size
    }

    return (
      <Grid className={className} item {...gridSize}>
        {label && (
          <PrimaryLabel
            labelType="secondary"
            label={label}
            required={required}
          />
        )}
        {children}
      </Grid>
    );
  };

  const handleChange = (e, setFieldValue, setErrors) => {
    const newPincode = e.target.value;
    setFieldValue("pincode", newPincode);

    if (newPincode.length === 6) {
      // Make API call
      axios
        .get(`https://api.postalpincode.in/pincode/${newPincode}`)
        .then((response) => {
          // Handle response data here
          console.log(response.data);
          if (response.data[0].PostOffice) {
            setFieldValue("city", response.data[0].PostOffice[0].District);
            setFieldValue("state", response.data[0].PostOffice[0].State);
            setFieldValue("country", response.data[0].PostOffice[0].Country);
            setErrors({ pincode: "" });
          } else if (response.data[0].Message === "No records found") {
            setFieldValue("city", "");
            setFieldValue("state", "");
            setFieldValue("country", "");
            setErrors({ pincode: "Enter a valid pincode" });
          }
        })
        .catch((error) => {
          // Handle errors here
          setErrors({ pincode: "Enter a valid pincode" });
          console.error("Error fetching pincode data:", error);
        });
    }
  };

  return (
    <ProfileLayout>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validateOnblur={false}
        enableReinitialize
        validationSchema={CompanyDetailSchema1}
      >
        {({
          values,
          setFieldValue,
          setErrors,
          touched,
          errors,
          dirty,
          isSubmitting,
        }) => {
          return (
            <Form autoComplete="off">
              <Grid container lg={12}>
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Company Details"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid label="Organization Name" required={true}>
                      <InputField
                        id="organization_name"
                        type="text"
                        placeholder="Eg: John"
                        name="organization_name"
                        value={values.organization_name}
                        validateOnChange={true}
                      />
                    </ChildGrid>

                    <ChildGrid label="Organization Type" required={true}>
                      <MuiSelect
                        id="organization_type"
                        select
                        placeholder={
                          !values.organization_type &&
                          values.organization_type !== 0 &&
                          "Eg: Eedwert"
                        }
                        name="organization_type"
                        value={values.organization_type}
                        handleChange={(e) => {
                          setFieldValue("organization_type", e.target.value);
                        }}
                        error={
                          touched.organization_type &&
                          Boolean(errors.organization_type)
                        }
                        helperText={
                          <FieldError
                            errorString={
                              touched.organization_type &&
                              errors.organization_type
                            }
                          />
                        }
                        selectOptions={orgTypeArr}
                      />
                    </ChildGrid>

                    <ChildGrid label="Nature of Business">
                      <MuiSelect
                        id="nature_of_business"
                        select
                        placeholder={
                          !values.nature_of_business &&
                          values.nature_of_business !== 0 &&
                          "Eg: Virtual"
                        }
                        name="nature_of_business"
                        value={values.nature_of_business}
                        handleChange={(e) => {
                          setFieldValue("nature_of_business", e.target.value);
                        }}
                        selectOptions={[
                          {
                            label: "Virtual",
                            value: 0,
                          },
                          {
                            label: "Office",
                            value: 1,
                          },
                        ]}
                      />
                    </ChildGrid>

                    <ChildGrid label="Website"required={true}>
                      <InputField
                        id="website"
                        type="text"
                        placeholder="Eg: www.google.com"
                        name="website"
                        value={values.website}
                        validateOnChange={true}
                      />
                    </ChildGrid>

                    <ChildGrid size="half">
                      <PrimaryLabel
                        labelType="secondary"
                        label="Employee Count Range"
                      />
                      <MuiSelect
                        id="count_range"
                        select
                        placeholder={
                          !values.count_range &&
                          values.count_range !== 0 &&
                          "Eg: 5"
                        }
                        name="count_range"
                        value={values.count_range}
                        handleChange={(e) => {
                          setFieldValue("count_range", e.target.value);
                        }}
                        selectOptions={[

                          { label: "10-15", value: "10-15" },
                          { label: "16-49", value: "16-49" },
                          { label: "50-100", value: "50-100" },
                          { label: "101-200", value: "101-200" },
                          { label: "201-500", value: "201-500" },
                          { label: "501 and above", value: "501 and above" },
                        ]}
                      />
                      <PrimaryLabel
                        style={{ marginTop: "20px" }}
                        labelType="secondary"
                        label="Established Year"
                      />
                      <MuiSelect
                        id="establish_year"
                        select
                        placeholder={
                          !values.establish_year &&
                          values.establish_year !== 0 &&
                          "Eg: 2012"
                        }
                        name="establish_year"
                        value={values.establish_year}
                        handleChange={(e) => {
                          setFieldValue("establish_year", e.target.value);
                        }}
                        selectOptions={yearsData}
                      />
                    </ChildGrid>

                    <ChildGrid label="About Organization"required={true} size="half">
                      <CustomTextArea
                        value={values.about_organization}
                        onChange={(val) => {
                          setFieldValue("about_organization", val);
                        }}
                        className={"oh-primary-textarea"}
                        placeholder="Enter about your organization"
                        error={
                          touched.about_organization &&
                          Boolean(errors.about_organization)
                        }
                        helperText={
                          touched.about_organization &&
                          errors.about_organization
                        }
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>

                {/* Address Section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Address"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid label="Pincode">
                      <MuiTextField
                        id="pincode"
                        type="number"
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                        placeholder="Enter here"
                        name="pincode"
                        InputProps={{
                          inputProps: { min: 6 },
                        }}
                        value={values.pincode}
                        onChange={(e) =>
                          handleChange(e, setFieldValue, setErrors)
                        }
                        error={touched.pincode && Boolean(errors.pincode)}
                        helperText={<FieldError errorString={errors.pincode} />}
                      />
                    </ChildGrid>

                    <ChildGrid label="City">
                      <MuiTextField
                        id="city"
                        select
                        placeholder={!values.city && "Eg: Indore"}
                        name="city"
                        value={values.city}
                        onChange={(e) => {
                          setFieldValue("city", e.target.value);
                        }}
                      // selectOptions={cityData}
                      />
                    </ChildGrid>

                    <ChildGrid label="State">
                      <MuiTextField
                        id="state"
                        type="select"
                        placeholder={!values.state && "Eg: Madhya Pradesh"}
                        name="state"
                        value={values.state}
                        onChange={(e) => {
                          setFieldValue("state", e.target.value);
                        }}
                      // selectOptions={stateData}
                      />
                    </ChildGrid>

                    <ChildGrid label="Country">
                      <MuiTextField
                        id="country"
                        type="select"
                        placeholder={!values.country && "Eg: India"}
                        name="country"
                        value={values.country}
                        // selectOptions={countryData}
                        onChange={(e) => {
                          setFieldValue("country", e.target.value);
                        }}
                      />
                    </ChildGrid>

                    <ChildGrid size="secondary" label="Address">
                      <InputField
                        id="address"
                        type="text"
                        placeholder="Eg: Old Palasia"
                        name="address"
                        value={values.address}
                        validateOnChange={true}
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>

                {/* Awards / certification section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Any Award / Certification"
                  />
                  <FieldArray name="award_certification">
                    {(fieldArrayProps) => {
                      const { form, push, remove } = fieldArrayProps;
                      const { values } = form;
                      const { award_certification } = values;
                      return award_certification.map((el, index) => {
                        return (
                          <Grid className="oh-company-details" container>
                            <ChildGrid label="Year">
                              <MuiSelect
                                id={award_certification[index]}
                                placeholder={
                                  !award_certification[index].award_year &&
                                  award_certification[index].award_year !== 0 &&
                                  "Select"
                                }
                                name={`award_certification[${index}].award_year`}
                                value={award_certification[index].award_year}
                                handleChange={(e) => {
                                  setFieldValue(
                                    `award_certification[${index}].award_year`,
                                    e.target.value
                                  );
                                }}
                                selectOptions={yearsData}
                              />
                            </ChildGrid>

                            <ChildGrid label="Name of Award / Certification">
                              <InputField
                                id={award_certification[index]}
                                type="text"
                                placeholder="Enter"
                                name={`award_certification[${index}].name_of_award`}
                                value={award_certification[index].name_of_award}
                                validateOnChange={true}
                              />
                            </ChildGrid>
                            {(values.award_certification[index].type || index ===
                              values.award_certification.length - 1 &&
                              values.award_certification[0].value !==
                              "") &&
                              <ChildGrid size="tertiary">
                                <Box sx={{ display: "inline-flex", gap: "10px" }}>
                                  {values.award_certification[index].type && (
                                    <MuiButtonOutlined
                                      className="oh-add-btn"
                                      logo={<DeleteIcon />}
                                      title="Delete"
                                      onClick={() => remove(index)}
                                    />
                                  )}
                                  {index ===
                                    values.award_certification.length - 1 &&
                                    values.award_certification[0].value !==
                                    "" && (
                                      <MuiButtonOutlined
                                        className="oh-add-btn"
                                        logo={<AddIcon />}
                                        title="Add"
                                        onClick={() =>
                                          push({
                                            award_year: "",
                                            name_of_award: "",
                                            type: "new",
                                          })
                                        }
                                      />
                                    )}
                                </Box>
                              </ChildGrid>
                            }
                          </Grid>
                        );
                      });
                    }}
                  </FieldArray>
                </Grid>

                {/* Social Media links */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Social Media Links"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid label="Facebook">
                      <InputField
                        id="social_media_fb"
                        type="text"
                        placeholder="Enter link"
                        name="social_media_fb"
                        value={values.social_media_fb}
                        validateOnChange={true}
                      />
                    </ChildGrid>

                    <ChildGrid label="Linkedin">
                      <InputField
                        id="social_media_linkdln"
                        type="text"
                        placeholder="Enter link"
                        name="social_media_linkdln"
                        value={values.social_media_linkdln}
                        validateOnChange={true}
                      />
                    </ChildGrid>

                    <ChildGrid label="Instagram">
                      <InputField
                        id="social_media_instagram"
                        type="text"
                        placeholder="Enter link"
                        name="social_media_instagram"
                        value={values.social_media_instagram}
                        validateOnChange={true}
                      />
                    </ChildGrid>

                    {/* <ChildGrid size='tertiary'>
                      <MuiButtonOutlined
                        className='oh-add-btn'
                        logo={<AddIcon />}
                        title='Add'
                      />
                    </ChildGrid> */}
                  </Grid>
                </Grid>
              </Grid>
              <MuiDivider className="oh-details-divider" />
              <CustomLoadingButton
                loading={isSubmitting}
                type="submit"
                title={"Submit"}
                className="oh-submit-btn"
                style={!dirty ? { opacity: 0.5 } : null}
                disabled={!dirty ? true : false}
              />
            </Form>
          );
        }}
      </Formik>
    </ProfileLayout>
  );
};

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value, true);
  };

  const error = (validateOnChange || meta.touched) && meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default CompanyDetails;
