import React from "react";

//MUI
import Box from "@mui/material/Box";

//OTHER
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import LinkText from "../Links/LinkText";

//CSS
import "./index.scss";

const PrimaryWrapper = ({ sx = {},
    className = "",
    children = null,
    content = null,
    linkTitle = null,
    onClick = () => { }
}) => {
    return (
        <Box sx={sx} className="oh-primary-wrapper" >
            <Box className="wrapper-header">
                <MuiTypography
                    content={content}
                    className={"oh-heading"}
                />
                {linkTitle && <LinkText title={linkTitle} onClick={onClick} />}
            </Box>
            {children}
        </Box>
    )
}

export default PrimaryWrapper;