import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
//MUI
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// OTHER
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import { clearOtps, setCountDownReset, setOtp, setTimerState, verifyOtp } from "../../redux/verifyOtpSlice";
import { Toaster } from "../../components/Toaster/Toaster";
import { forgotPassword } from "../../redux/ForgotPasswordSlice";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";

//CSS
import "./index.scss";
import CountdownTimer from "./Timer";
import LoginLayout from "../Login/LoginLayout";

const OtpVerification = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { otp, timerOn } = useSelector((state) => state.verifyOtp);
  const { token } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);

  const handleVerification = () => {
    setLoader(true);
    if (otp) {
      setLoader(false);
      let data = {
        ...location?.state,
        otp_code: parseInt(otp),
      };
      dispatch(verifyOtp(data))
        .then((res) => {
          setLoader(false);
          if (res.type === "otpVerification/fulfilled") {
            Toaster.TOAST(res?.payload?.message, "success");
            navigate("/reset-password", {
              state: { ...location?.state, otp_code: parseInt(otp) },
              // replace: true,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          dispatch(setOtp(null));
          Toaster.TOAST(err.message, "error");
          console.log(err);
        });
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const handleResendOtp = () => {
    if (timerOn) {
      return;
    }

    dispatch(forgotPassword(location?.state))
      .then((res) => {
        if (res.type === "forgotPassword/fulfilled") {
          Toaster.TOAST(res?.payload?.message, "success");
          dispatch(setTimerState(true));
          dispatch(setCountDownReset());
          dispatch(clearOtps(null));

        }
      })
      .catch((err) => {
        Toaster.TOAST(err?.message, "error");
        console.log(err);
      });
  };

  return (
    <LoginLayout>
      <Grid container xs={12} md={10} lg={10}>
        <Grid item xs={12} md={12} lg={12}>
          <Box className="oh-heading">
            <MuiTypography
              className="oh-login-title"
              content="OTP Verification"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box sx={{ display: "flex", flexWrap: "no-wrap" }}>
            <MuiTypography
              variant="span"
              style={{
                padding: "4px 0px",
                display: "inline-block",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "22.4px",
                color: "var(--oh-black-300-color)",
                width: "100%",
                maxWidth: "fit-content",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              We have sent the code verification to your{" "}
              {location?.state?.email ? "email address " : "mobile number "}{" "}
              <b className="email-no-white-space" style={{ whiteSpace: "nowrap" }}>
                {" "}
                {location?.state?.email || location?.state?.mobile_number}{" "}
              </b>{" "}
            </MuiTypography>
          </Box>
        </Grid>

        <Grid
          sx={{
            margin: "34px 0px 0px",
            display: "flex",
            justifyContent: "center",
          }}
          className="oh-textfield-grid"
          item
          xs={12}
          md={12}
          lg={12}
        >
          <OtpInput
            value={otp}
            onChange={(e) => dispatch(setOtp(e))}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
            containerStyle={{
              gap: "0px 14px",
            }}
            inputType="tel"
            className="oh-otp-input"
            inputStyle={"oh-input-otp-box"}
          />
        </Grid>

        <Grid
          style={{ margin: "30px 0px 10px 0px" }}
          item
          xs={12}
          md={12}
          lg={12}
        >
          <CustomLoadingButton
            loading={loader}
            disabled={loader || !otp || otp?.length !== 6}
            title={"Verify"}
            onClick={() => handleVerification()}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <CountdownTimer />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "no-wrap",
              margin: "15px 0px",
            }}
          >
            <MuiTypography
              variant="span"
              style={{
                display: "inline-block",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "22.4px",
                color: "var(--oh-black-300-color)",
                width: "100%",
                fontFamily: "Roboto, sans-serif",
                maxWidth: "fit-content",
                marginRight: "8px",
              }}
              content="If you didn't receive a code !"
            />
            <span
              style={{
                display: "inline-block",
                maxWidth: "fit-content",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "22.4px",
                color: timerOn
                  ? "var(--oh-gray-300-color)"
                  : "var(--oh-blue-color)",
                width: "100%",
                fontFamily: "Roboto, sans-serif",
                cursor: timerOn ? "default" : "pointer",
              }}
              onClick={() => handleResendOtp()}
            >
              Resend
            </span>
          </Box>
        </Grid>

        {!token && (
          <Grid item xs={12} md={12} lg={12}>
            <MuiTypography
              style={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "26px",
                textAlign: "center",
                margin: "50px 0px 32px",
                color: "var(--oh-blue-color)",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(
                  "/login"
                  // , { replace: true }
                )
              }
              content={`Login through Email`}
            // content={`Login through Username / Email`}
            />
          </Grid>
        )}
      </Grid>
    </LoginLayout>
  );
};

export default OtpVerification;
