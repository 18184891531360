import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosApi } from '../apis/AxiosApi'

const initialState = {
  dashboardStatsData: null,
}

export const getEmployerStats = createAsyncThunk(
    'getEmployerStats',
    async (id) => {
        return await AxiosApi({
          path: `/employers/${id}/employer_details/employer_dashboard_counts`,
          type: 'GET',
          params: '',
        })
      }
  )
  

export const statsDataSlice = createSlice({
  name: 'statsData',
  initialState,
  reducers: {
    setStatsData: (state, action) => {
        state.dashboardStatsData = action.payload;
    }
  },
})
export default statsDataSlice.reducer;
export const { setStatsData } = statsDataSlice.actions;