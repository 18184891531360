import React from "react";
import "./index.scss";

const OutlinedChip = ({
  label = "",
  color = "",
  className = "",
  size = "small-100",
  borderColor = color,
  backgroundColor = "inherit",
  icon = null,
  style = {},
  onClick = null,
}) => {
  return (
    <span
      onClick={onClick}
      style={{
        borderColor: borderColor,
        color: color,
        backgroundColor: backgroundColor,
        ...style,
      }}
      className={`${className} oh-outlined-chip-${size}`}
    >
      {icon && <span className="oh-iconChip">{icon}</span>}
      {label}
    </span>
  );
};

export default OutlinedChip;
