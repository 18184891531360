import React from "react";

// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// OTHER
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import MuiTextField from "../../mui/TextField/MuiTextField";

const ParentGrid = ({ children }) => {
    return (
        <>
            <Grid container xs={12} md={12} lg={12}>
                {children}
            </Grid>
        </>
    )
}

const ChildGrid = (props) => {
    return (
        <>
            <Grid item xs={"auto"} lg={"auto"} md={"auto"} sx={{ marginRight: "15px" }}>
                <Box sx={{ width: "100%", maxWidth: "228px", paddingBottom: "25px" }} className="oh-txtfeild-box">
                    <label className="oh-label">{props.label}</label>
                    <MuiTextField {...props} />
                </Box>
            </Grid>
        </>
    )
}

const Heading = (props) => {
    return (
        <Box
            className="oh-heading-box"
            display={"flex"} justifyContent={"flex-start"}
            alignItems={"center"}
        >

            <MuiTypography content={props.content} />
            <MuiTypography className="oh-divider">
                <hr className="horizontal-divider" />
            </MuiTypography>
        </Box>
    )
}

const SettingForm = () => {
    const handleChange = (name, val) => {
    }

    const generateChildGrids = (elem) => {
        return elem.map((config, index) => (
            <ChildGrid key={index} {...config} handleChange={(value) => handleChange(config.name, value)} />
        ));
    };

    const parentGrids = [
        { label: "Username", name: "username", type: "text", placeholder: "Enter username", className: "oh-textfeild", value: "" },
        { label: "Email", name: "email", type: "text", placeholder: "Enter email", className: "oh-textfeild", value: "" },
        { label: "Mobile Number", name: "contact", type: "text", placeholder: "Enter contact number", className: "oh-textfeild", value: "" },
        { label: "Education", name: "education", type: "text", placeholder: "Enter eduction", className: "oh-textfeild", value: "" },
    ];
    const secondGrids = [
        { label: "Old Password", name: "old_password", type: "text", placeholder: "Enter old password", className: "oh-textfeild", value: "" },
        { label: "New Password", name: "new_password", type: "text", placeholder: "Enter new password", className: "oh-textfeild", value: "" },
        { label: "Confirm Password", name: "confirm_password", type: "text", placeholder: "Enter confirm password", className: "oh-textfeild", value: "" },
    ];
    const thirdGrids = [
        { label: "Experiences as Freelancer", name: "experience", type: "text", placeholder: "Enter experience", className: "oh-textfeild", value: "" },
        { label: "Job Location", name: "job_location", type: "text", placeholder: "Enter job location", className: "oh-textfeild", value: "" },
        { label: "Job Category", name: "job_category", type: "text", placeholder: "Enter job category", className: "oh-textfeild", value: "" },

    ];

    return (
        <>
            <ParentGrid>
                {generateChildGrids(parentGrids)}
            </ParentGrid>
            <Box>
                <Heading content="Change Password" />
                <ParentGrid>
                    {generateChildGrids(secondGrids)}
                </ParentGrid>
            </Box>
            <Box>

                <Heading content="Information" />
                <ParentGrid>
                    {generateChildGrids(thirdGrids)}
                </ParentGrid>
            </Box>
        </>
    )
}

export default SettingForm;