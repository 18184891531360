import React, { useEffect, useRef, useState } from 'react'
import SearchLayout from '../components/Layout/SearchLayout/SearchLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as CloseIcon } from '../../assets/images/crossIconSmall.svg'
import { Box, Grid, Pagination, Typography } from '@mui/material'
import TertiaryWrapper from '../components/searchJobsPage/TertiaryWrapper/TertiaryWrapper'
import MuiIconChip from '../../mui/Chip/IconChip'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NoJobsFound from '../../components/NotFound/NoJobsFound'
import JobPostCard from '../../components/JobPostBox/JobPostCard'
import Filter from '../components/searchJobsPage/Filter'
import { useDispatch, useSelector } from 'react-redux'
import { Toaster } from '../../components/Toaster/Toaster'
import { getAllJobPosts } from '../../redux/LandingPage/LandingPageSlice'
import { setIsLoading } from '../../redux/globalSlice'
import GlobalLoader from '../../components/Loader/GlobalLoader'

const SearchJobs = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const { isLoading } = useSelector(state => state.app)
  const searchParam =
    decodeURIComponent(search.split('=')?.[1]?.split('&')?.[0]) || ''
  const categoryType = decodeURIComponent(
    search.split('&')?.[1]?.split('=')?.[1]
  )

  const [chips, setChips] = useState(
    search
      ? [
          {
            id: '0',
            value: searchParam,
            category: categoryType ? 'Location' : 'search'
          }
        ]
      : []
  )

  const [popupState, setPopupState] = useState('')

  const handlePopupState = (popupToHandle = '') => {
    return setPopupState(popupToHandle)
  }

  const [searchedJobs, setSearchedJobs] = useState('')

  const { pathname } = useLocation()

  const [pageNo, setPageNo] = useState(1)
  const [pagesCount, setPagesCount] = useState(0)
  const recordsPerPage = 20

  const handlePageChange = (event, value) => {
    setPageNo(value)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    getAllJobPostsList(`page=${pageNo}&per_page=${recordsPerPage}&`)
  }, [pageNo])

  const [url, setUrl] = useState('')
  const getAllJobPostsList = (url = '') => {
    // setUrl(url); // to select experience range multiple times
    dispatch(setIsLoading(true))
    dispatch(getAllJobPosts({ jobTitle: searchParam, filterUrl: url }))
      .then(res => {
        dispatch(setIsLoading(false))
        if (res?.error) throw new Error('Something went wrong!')
        const apiData = res?.payload?.data
        const { total_pages } = res?.payload?.meta
        setSearchedJobs(apiData)
        setPagesCount(total_pages)
        window.scrollTo(0, 0)
      })
      .catch(err => {
        dispatch(setIsLoading(false))
        Toaster.TOAST(err?.message, 'error')
      })
  }

  const handleSetChips = value => {
    setChips(prev => [...prev, value])
  }

  const handleDeleteChips = chip => {
    if (chip?.value === searchParam) {
      navigate('/search-jobs', { replace: true })
    }
    setChips(prev => prev.filter(el => el?.value !== chip?.value))
  }

  const handleRemoveChips = chip => {
    setChips(prev => prev.filter(el => el?.value !== chip?.value))

    switch (chip?.category) {
      case 'Categories':
        selectedCategoriesRef.current(prevSelected =>
          prevSelected.includes(chip?.value)
            ? prevSelected.filter(item => item !== chip?.value)
            : [...prevSelected, chip?.value]
        )
        break
      case 'Work Mode':
        selectedWorkModesRef.current(prevSelected =>
          prevSelected.includes(chip?.value)
            ? prevSelected.filter(item => item !== chip?.value)
            : [...prevSelected, chip?.value]
        )
        break
      case 'Location':
        selectedLocationsRef.current(prevSelected =>
          prevSelected.includes(chip?.value)
            ? prevSelected.filter(item => item !== chip?.value)
            : [...prevSelected, chip?.value]
        )
        break
      case 'Salary':
        selectedSalariesRef.current(prevSelected =>
          prevSelected.includes(chip?.value)
            ? prevSelected.filter(item => item !== chip?.value)
            : [...prevSelected, chip?.value]
        )
        break
      case 'Skills':
        selectedSkillsRef.current(prevSelected =>
          prevSelected.includes(chip?.value)
            ? prevSelected.filter(item => item !== chip?.value)
            : [...prevSelected, chip?.value]
        )
        break
      // Add cases for other categories as needed
      default:
        break
    }
  }

  const selectedCategoriesRef = useRef(null)
  const selectedWorkModesRef = useRef(null)
  const selectedLocationsRef = useRef(null)
  const selectedSalariesRef = useRef(null)
  const selectedSkillsRef = useRef(null)

  const handleUncheckCheckbox = (
    setSelectedCategories,
    setSelectedWorkModes,
    setSelectedLocations,
    setSelectedSalaries,
    setSelectedSkills
  ) => {
    selectedCategoriesRef.current = setSelectedCategories
    selectedWorkModesRef.current = setSelectedWorkModes
    selectedLocationsRef.current = setSelectedLocations
    selectedSalariesRef.current = setSelectedSalaries
    selectedSkillsRef.current = setSelectedSkills
  }

  useEffect(() => {
    if (categoryType !== undefined && categoryType === 'Location')
      selectedLocationsRef.current([searchParam])
  }, [])

  useEffect(() => {
    const categoriesData = chips?.filter(
      chip => chip?.category === 'Categories'
    )
    const workModeData = chips?.filter(chip => chip?.category === 'Work Mode')
    const locationData = chips?.filter(chip => chip?.category === 'Location')
    const salaryData = chips?.filter(chip => chip?.category === 'Salary')
    const skillsData = chips?.filter(chip => chip?.category === 'Skills')
    const jobTypeData = chips?.filter(chip => chip?.category === 'Job Type')
    const jobPostData = chips?.filter(chip => chip?.category === 'Job Posted')
    let url = `search[sort_attribute]=created_at&search[sort_type]=desc&page=${pageNo}&per_page=${recordsPerPage}&`
    if (categoriesData?.length > 0) {
      categoriesData?.forEach(category => {
        url += `filter_by[categories][]=${category?.id}&`
      })
    }

    if (workModeData?.length > 0) {
      workModeData?.forEach(wm => {
        url += `filter_by[work_mode][]=${wm?.value}&`
      })
    }

    if (locationData?.length > 0) {
      locationData?.forEach(loc => {
        url += loc?.id === '0' ? '' : `filter_by[location][]=${loc?.id}&`
      })
    }

    if (salaryData?.length > 0) {
      salaryData?.forEach(sal => {
        url += `filter_by[ctc]=${sal?.id}&`
      })
    }

    if (skillsData?.length > 0) {
      skillsData?.forEach(skill => {
        url += `filter_by[skills]=${skill?.id}&`
      })
    }

    if (jobTypeData?.length > 0) {
      jobTypeData?.forEach(job => {
        url += `filter_by[job_type]=${job?.value}&`
      })
    }
    if (jobPostData?.length > 0) {
      jobPostData?.forEach(post => {
        url += `filter_by[posted_days]=${post?.value}&`
      })
    }

    setUrl(url) // to select experience range single times
    if (url?.length > 0) {
      getAllJobPostsList(`${url}${experienceUrl}`)
    }
  }, [chips])

  const [experienceRange, setExperienceRange] = useState('')
  const [experienceUrl, setExperienceUrl] = useState('')

  const handleExperienceRange = range => {
    setExperienceRange(range)
  }

  useEffect(() => {
    if (experienceRange?.length > 0) {
      const experienceUrl = `filter_by[experience]=${experienceRange[0]}-${experienceRange[1]} years&`
      setExperienceUrl(experienceUrl)
      getAllJobPostsList(`${url}${experienceUrl}`)
    }
  }, [experienceRange])

  const handleRemoveSearchParam = chip => {
    handleRemoveChips(chip)
    navigate('/search-jobs', { replace: true })
    getAllJobPostsList()
  }

  return (
    <SearchLayout
      handleSetChips={handleSetChips}
      handleDeleteChips={handleDeleteChips}
      uncheckCheckbox={handleUncheckCheckbox}
      allChips={chips}
      setExperienceRange={handleExperienceRange}
    >
      <Box>
        <TertiaryWrapper
          setExperienceRange={handleExperienceRange}
          uncheckCheckbox={handleUncheckCheckbox}
          variant={'div'}
          sx={{
            padding: 0,
            '.wrapper-header': {
              padding: '20px 0px 10px',
              '.oh-heading': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }
            }
          }}
          showBtnOptions={false}
          content={
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Box>
                <div>Search Results ({searchedJobs.length})</div>
                <Box display={'flex'} my={'21px'} flexWrap={'wrap'}>
                  {chips?.map(chip => {
                    return (
                      <Box
                        id={chip?.id}
                        border={'1px solid #445386'}
                        borderRadius={'20px'}
                        bgcolor={'#fff'}
                        display={'flex'}
                        alignItems={'center'}
                        padding={'6px 12px'}
                        mr={'10px'}
                        mb={'10px'}
                      >
                        <Typography
                          fontSize={'14px'}
                          fontWeight={400}
                          color={'#445386'}
                          mr={'5px'}
                          textTransform={'capitalize'}
                        >
                          {chip?.value}
                        </Typography>

                        <CloseIcon
                          style={{ cursor: 'pointer' }}
                          onClick={
                            chip?.id === '0'
                              ? () => handleRemoveSearchParam(chip)
                              : () => handleRemoveChips(chip)
                          }
                        />
                        {/* {chip?.id !== "0" && (
                          <CloseIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveChips(chip)}
                          />
                        )} */}
                      </Box>
                    )
                  })}
                </Box>
              </Box>
              <div>
                <MuiIconChip
                  icon={<FilterAltIcon sx={{ marginTop: '-3px' }} />}
                  label='Filter'
                  onClick={() => handlePopupState('filter')}
                  className='search-page-filter-btn'
                />
              </div>
            </Box>
          }
        >
          <Box>
            {isLoading ? (
              <GlobalLoader minHeight='300px' />
            ) : (
              <Grid container spacing={2}>
                {searchedJobs?.length > 0 ? (
                  searchedJobs.map((elem, idx) => {
                    return (
                      <Grid item xs={12} md={6} lg={4}>
                        <JobPostCard
                          size={'medium-100'}
                          handleNavigate={() => {
                            navigate(
                              `/search-jobs/jobDetails/${elem?.id}?from=landingPage`
                            )
                          }}
                          data={elem.attributes}
                          className={'open-total-applicant'}
                          isLanding={true}
                        />
                      </Grid>
                    )
                  })
                ) : (
                  <Box sx={{ width: '100%', minHeight: '200px' }}>
                    <NoJobsFound height={'450px'} value='No jobs found' />
                  </Box>
                )}
              </Grid>
            )}
          </Box>
          {searchedJobs?.length > 0 && !isLoading && (
            <Box display={'flex'} justifyContent={'center'}>
              <Pagination
                count={pagesCount}
                page={pageNo}
                onChange={handlePageChange}
                shape='rounded'
              />
            </Box>
          )}
        </TertiaryWrapper>
      </Box>
      <Filter
        onClose={handlePopupState}
        open={popupState === 'filter'}
        handleSetChips={handleSetChips}
        handleDeleteChips={handleDeleteChips}
        setExperienceRange={handleExperienceRange}
        uncheckCheckbox={handleUncheckCheckbox}
      />
    </SearchLayout>
  )
}

export default SearchJobs
