import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { returnRoleValue } from "./utils";

const PublicRoute = ({ children }) => {
  let navigate = useNavigate();
  const location = useLocation();

  // const { portalValue } = useSelector(state => state.app);
  // const { user } = useSelector(state => state.auth);
  const user = localStorage.getItem("login_data") ? JSON.parse(localStorage.getItem("login_data")) : null;
  const user_data = user ? Object.keys(user)?.length > 0 : null;
  const isAuthenticated = () => !!(localStorage.getItem("token"));
  const portalTemp = localStorage.getItem("portal");

  if ((!user?.otp_verified && !user?.auth_provider) && isAuthenticated() && user_data) {
    return <Navigate to={`/two-factor-verification`} replace state={{ email: user?.email, mobile_number: user?.mobile_number }} />
  } else if (!user?.registration_complete && isAuthenticated()) {
    return <Navigate to={`/signup-proceed`} replace />
  } else if (isAuthenticated() && user?.registration_complete) {
    return <Navigate to={`/${returnRoleValue(portalTemp)}`} replace />;
  }

  return children;
};

export default PublicRoute;