import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI IMPORTS
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";

// ASSETS
import DefaultProfilePicture from "../../../assets/images/default_profile_picture.svg";

// OTHER
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import { clearLocalStorage } from "../../../utils";
import { setLogin, setSignUp, setToken } from "../../../redux/authSlice";

// CSS
import "./index.scss";
import "../../../Styles/Global.scss";
import { setFreelancerData } from "../../../redux/Freelancer/freelancerDetailsSlice";
import { setNotifications } from "../../../redux/notificationsSlice";
import { setProfilePhoto } from "../../../redux/profileSlice";
import ConfirmationDialog from "../../Dialog/ComfirmationDialog";

const ProfileDrawer = ({ isProfileOpen, handleProfileClose }) => {
  const navigate = useNavigate();
  const { portalName } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { profilePhoto } = useSelector((state) => state.profile);
  const [openSignoutConfirmation, setSignoutComfirmation] = useState(false);

  const handleSignOut = () => {
    setSignoutComfirmation(false);
    clearLocalStorage();
    dispatch(setToken(null));
    dispatch(setSignUp(null));
    dispatch(setLogin(null));
    dispatch(setFreelancerData(null));
    dispatch(setProfilePhoto(null));
    dispatch(setNotifications([]));
    navigate("/login");
    handleProfileClose();
  };

  const handleNavigate = (val) => {
    navigate(val);
    handleProfileClose();
  };

  const handleNavigation = () => {
    switch (portalName) {
      case "Employer":
        return handleNavigate(`/communication-details`);
      case "Freelancer":
        return handleNavigate(`/personal-detail`); // removed s for now to prevent same route name
      default:
        return handleNavigate(`/personal-details`);
    }
  };

  const handleDashboardNavigation = () => {
    switch (portalName) {
      case "Employer":
        return handleNavigate(`/employer`);
      case "Freelancer":
        return handleNavigate(`/freelancer`); // removed s for now to prevent same route name
      default:
        return handleNavigate(`/jobseeker`);
    }
  };

  let profileTab = [
    {
      label: "Dashboard",
      handleClick: () => handleDashboardNavigation(),
    },
    {
      label: "Profile",
      handleClick: () => handleNavigation(),
    },
    {
      label: "FAQs",
      handleClick: () => handleNavigate("/frequently-asked"),
    },
    {
      label: "Help & Support",
      handleClick: () => handleNavigate("/help-support"),
      // handleClick: () => { },
    },
    {
      label: "Sign Out",
      handleClick: () => setSignoutComfirmation(true),
    },
  ];

  return (
    <>
      <Box>
        <Drawer
          anchor="right"
          sx={{
            ".MuiBackdrop-root": {
              background: "rgba(0, 0, 0, 0.15)",
            },
          }}
          PaperProps={{
            style: {
              boxShadow: "0px 10px 50px 0px var(--oh-white-400-color)",
              border: "1px solid var(--oh-white-300-color)",
              borderRadius: 0,
              marginTop: "4.75rem",
              paddingTop: "20px",
              maxWidth: "300px",
              width: "100%",
            },
          }}
          open={isProfileOpen}
          onClose={() => handleProfileClose()}
        >
          <div style={{
            minHeight: "510px",
            overflowY: "auto",
          }}>

            <Box className="oh-account-box">
              <CloseIcon
                className="close-icon"
                onClick={() => handleProfileClose()}
              />
              {/* <Avatar
            // src={DefaultProfilePicture}
            src={profilePhoto || DefaultProfilePicture}
            aria-label="recipe"
            alt="Remy Sharp"
            className="oh-profile-avatar"
          /> */}
              <img
                className="oh-profile-avatar"
                // src={DefaultProfilePicture}
                src={
                  profilePhoto?.profilePhoto ||
                  profilePhoto ||
                  DefaultProfilePicture
                }
                alt={""}
              />
              <MuiTypography
                className="oh-profile-user-name"
                content={
                  user?.personal_details?.first_name +
                  user?.personal_details?.last_name || ""
                }
              />
              <MuiTypography
                className="oh-profile-user-email"
                style={{ color:"#000 !important" }}
                content={user?.type || ""}
              />
              <MuiTypography
                className="oh-profile-user-email"
                content={user?.email || "catherine.jedwet@reliance.com"}
              />
              <MuiTypography
                className="oh-profile-user-id"
                content={`User ID: ${user?.user_number || "434XXXX5"}`}
              />
            </Box>
            {profileTab &&
              profileTab.map((elem) => {
                return (
                  <MuiTypography
                    className="oh-profile-menu-item"
                    onClick={elem.handleClick}
                    content={elem.label}
                  />
                );
              })}
          </div>
        </Drawer>
      </Box>
      {openSignoutConfirmation && (
        <ConfirmationDialog
          heading={"End Session & Sign Out"}
          popupState={openSignoutConfirmation}
          setPopupState={setSignoutComfirmation}
          handleYes={handleSignOut}
        />
      )}
    </>
  );
};

export default ProfileDrawer;
