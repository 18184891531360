import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";

// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";

// OTHER
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../../components/Labels/PrimaryLabel";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import MuiTextField from "../../../mui/TextField/MuiTextField";
import MuiDeletableChip from "../../../mui/Chip/MuiDeletableChip";
import FieldError from "../../../components/Errors/FieldError";

// CSS
import "../index.scss";
import CustomLoadingButton from "../../../components/Loader/LoadingButton";
import ProfileLayout from "../ProfileLayout";
import { FIRSTNAME_REGEX } from "../../../utils/regex";
import {
  errMessageMaximum,
  getYearsData,
  smoothScroll,
} from "../../../utils";
import MuiSelect from "../../../mui/TextField/MuiSelect";
import { Autocomplete, IconButton } from "@mui/material";
import { getSkillsData } from "../../../redux/JobSeeker/filterApis";
import { useDispatch } from "react-redux";
import {
  getFreelancerDetails,
  updateFreelancerDetails,
} from "../../../redux/Freelancer/freelancerDetailsSlice";
import { toArray } from "lodash";
import { Toaster } from "../../../components/Toaster/Toaster";
import { experienceArr } from "../../../Data";
import { setUserData } from "../../../redux/authSlice";

const FreelnacerOther = () => {
  useEffect(() => {
    getSkills();
    showfreelancerDetails();
    const date = new Date();
    let present_year = parseInt(date.getFullYear());
    const yrsData = getYearsData(present_year);
    setYearsData(yrsData);
  }, []);
  const dispatch = useDispatch();

  const [skillsOpt, setSkillsOpt] = useState([]);
  const [initialData, setInitialData] = useState({
    educationDetails: [{ year: null, university: "", percentage: "" }],
    experienceDetails: [{ year: null, company: "", designation: "" }],
    skills: [],
  });
  const [yearsData, setYearsData] = useState([]);

  const OtherDetailSchema = Yup.object().shape({
    educationDetails: Yup.array().of(
      Yup.object().shape({
        year: Yup.string().required("Year is required"),
        university: Yup.string()
          .required("University name is required.")
          .max(150, errMessageMaximum("University name", 150))
          .test(
            "check if  University namenot empty",
            "University name must be relevant.",
            (val) => {
              if (!val || val.trim().length === 0 || val.trim().length < 2) {
                return false;
              }
              return true;
            }
          )
          .matches(FIRSTNAME_REGEX, "University name must be valid."),
        percentage: Yup.number()
          .required("Percentage is required")
          .min(1, `Percentage must be at atleast greater than 0%`)
          .max(100, `Percentage can't be more than 100%`),
      })
    ),
    experienceDetails: Yup.array().of(
      Yup.object().shape({
        year: Yup.string().required("Year is required"),
        company: Yup.string()
          .required("Company name is required.")
          .max(150, errMessageMaximum("Company name", 150))
          .test(
            "check if  Company namenot empty",
            "Company name must be relevant.",
            (val) => {
              if (!val || val.trim().length === 0 || val.trim().length < 2) {
                return false;
              }
              return true;
            }
          )
          .matches(FIRSTNAME_REGEX, "Company name must be valid."),
        designation: Yup.string()
          .required("Designation is required.")
          .max(60, errMessageMaximum("Designation", 60))
          .test(
            "check if  Designation not empty",
            "Designation must be relevant.",
            (val) => {
              if (!val || val.trim().length === 0 || val.trim().length < 2) {
                return false;
              }
              return true;
            }
          )
          .matches(FIRSTNAME_REGEX, "Designation must be valid."),
      })
    ),
    skills: Yup.array().min(1, "At least one skill is required"),
  });

  const getSkills = () => {
    dispatch(getSkillsData())
      .then((res) => {
        if (res.type === "getSkillsData/fulfilled") {
          const Data = res.payload.data || [];
          const skills = Data.map((cat) => {
            return {
              id: cat.id,
              label: cat.attributes.name,
              value: cat.attributes.id,
            };
          });
          setSkillsOpt(skills);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const showfreelancerDetails = () => {
    dispatch(getFreelancerDetails())
      .then((res) => {
        if (res.type === "getFreelancerDetails/fulfilled") {
          const data = res?.payload?.data?.attributes?.personal_details;
          const educationDetailsData = toArray(data?.education_details);
          const experienceDetailsData = toArray(data?.experience_details);
          const isDataExists = Object.keys(data.education_details).length === 0;
          if (!isDataExists) {
            setInitialData((prev) => ({
              ...prev,
              educationDetails: educationDetailsData,
              experienceDetails: experienceDetailsData,
              skills: data?.skills?.map((elem) => {
                return {
                  id: String(elem.id),
                  label: elem.name,
                  value: elem.id,
                };
              }),
            }));
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const handleSubmit = (values, setSubmitting) => {
    let formData = new FormData();
    values.educationDetails.map((el, index) => {
      formData.append(`data[education_details][${index}][year]`, el.year);
      formData.append(
        `data[education_details][${index}][university]`,
        el.university
      );
      formData.append(
        `data[education_details][${index}][percentage]`,
        el.percentage
      );
    });
    values.experienceDetails.map((el, index) => {
      formData.append(`data[experience_details][${index}][year]`, el.year);
      formData.append(
        `data[experience_details][${index}][company]`,
        el.company
      );
      formData.append(
        `data[experience_details][${index}][designation]`,
        el.designation
      );
    });
    values.skills.map((el, index) => {
      formData.append("data[skill_ids][]", el.value);
    });
    formData.append("data[languages][0][language]", "English");
    formData.append("data[languages][0][language]", values.english);
    formData.append("data[languages][1][language]", "Hindi");
    formData.append("data[languages][1][language]", values.hindi);
    dispatch(updateFreelancerDetails({ data: formData }))
      .then((res) => {
        if (res.type === "updateFreelancerDetails/fulfilled") {
          smoothScroll();
          Toaster.TOAST(res.payload?.message, "success");
          // getData();
          let user_data = res?.payload?.data?.attributes?.["freelancer"];;
          user_data = { ...user_data, personal_details: res.payload.data.attributes?.personal_details };
          localStorage.setItem("login_data", JSON.stringify(user_data));
          dispatch(setUserData(user_data));
        } else if (res.type === "updateFreelancerDetails/rejected") {
          smoothScroll();
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
    setSubmitting(false);
  };
  const ChildGrid = ({
    children,
    size = "",
    className = "oh-form-field",
    label = null,
    required = false,
  }) => {
    let gridSize = {};
    switch (size) {
      case "primary":
        gridSize = { xs: 12, md: 12, lg: 12 };
        break;
      case "secondary":
        gridSize = { xs: 12, md: 6, lg: 6 };
        break;
      case "tertiary":
        gridSize = { xs: 12, md: 1, lg: 1 };
        break;
      case "default_secondary":
        gridSize = { xs: 12, md: 12, lg: 6 };
        break;
      default:
        gridSize = { xs: 12, md: 12, lg: 3 }; // Default to primary size
    }

    return (
      <Grid className={className} item {...gridSize}>
        {label && (
          <PrimaryLabel
            labelType="secondary"
            label={label}
            required={required}
          />
        )}
        {children}
      </Grid>
    );
  };

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#576EDB",
    },
    "& .MuiRating-iconHover": {
      color: "#576EDB",
    },
  });

  return (
    <ProfileLayout>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validateOnblur={false}
        validateOnChange={true}
        enableReinitialize
        validationSchema={OtherDetailSchema}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          dirty,
          isValid,
        }) => {
          console.log(" FreelnacerOther ~ errors:", values, errors);
          return (
            <Form className="oh-signup-form" autoComplete="off">
              {/* Education Details Section */}
              <Grid container lg={12}>
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Education Details"
                  />
                  <FieldArray name="educationDetails">
                    {(fieldArrayProps) => {
                      const { form, push, remove } = fieldArrayProps;
                      const { values } = form;
                      const { educationDetails } = values;
                      return educationDetails.map((elem, index) => {
                        return (
                          <Grid
                            className="oh-company-details"
                            container
                            key={index}
                          >
                            <ChildGrid label="Year" required={true}>
                              <MuiSelect
                                id={educationDetails[index].id}
                                select
                                placeholder={
                                  !values.year &&
                                  values.year !== 0 &&
                                  "Select Year"
                                }
                                name={`educationDetails[${index}].year`}
                                value={educationDetails[index].year}
                                selectOptions={yearsData}
                                handleChange={(e) => {
                                  setFieldValue(
                                    `educationDetails[${index}].year`,
                                    e.target.value
                                  );
                                }}
                                error={
                                  // touched?.educationDetails?.[index]?.year &&
                                  Boolean(
                                    errors?.educationDetails?.[index]?.year
                                  )
                                }
                                helperText={
                                  <FieldError
                                    errorString={
                                      // touched?.educationDetails?.[index]
                                      //   ?.year &&
                                      errors?.educationDetails?.[index]?.year
                                    }
                                  />
                                }
                              />
                            </ChildGrid>
                            <ChildGrid label="University/Board Name" required={true}>
                              <InputField
                                id={educationDetails[index].id}
                                type="text"
                                placeholder="Enter"
                                name={`educationDetails[${index}].university`}
                                value={educationDetails[index].university}
                                validateOnChange={true}
                                error={
                                  // touched?.educationDetails?.[index]
                                  //   ?.university &&
                                  Boolean(
                                    errors?.educationDetails?.[index]
                                      ?.university
                                  )
                                }
                              />
                            </ChildGrid>
                            <ChildGrid label="Percentage" required={true}>
                              <InputField
                                id={educationDetails[index].id}
                                type="number"
                                placeholder="Enter"
                                name={`educationDetails[${index}].percentage`}
                                value={educationDetails[index].percentage}
                                validateOnChange={true}
                                error={
                                  // touched?.educationDetails?.[index]
                                  //   ?.percentage &&
                                  Boolean(
                                    errors?.educationDetails?.[index]
                                      ?.percentage
                                  )
                                }
                              />
                            </ChildGrid>
                            <ChildGrid size="tertiary" required={true}>
                              <Box className="oh-add-section" sx={{ display: "inline-flex", gap: "10px" }}>
                                {values.educationDetails[index].new && (
                                  <IconButton
                                    className="oh-delete-btn"
                                    title="Delete"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                                {index === values.educationDetails.length - 1 &&
                                  values.educationDetails[0].value !== "" && (
                                    <IconButton
                                      className="oh-add-btn"
                                      logo={<AddIcon />}
                                      title="Add"
                                      onClick={() => {
                                        push({
                                          year: "",
                                          university: "",
                                          percentage: "",
                                          new: true,
                                        });
                                      }}
                                    >
                                      <AddIcon />
                                      <span>Add</span>
                                    </IconButton>
                                  )}
                              </Box>
                            </ChildGrid>
                          </Grid>
                        );
                      });
                    }}
                  </FieldArray>
                </Grid>

                {/* Experience details section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Experience Details"
                  />
                  <FieldArray name="experienceDetails">
                    {(fieldArrayProps) => {
                      const { form, push } = fieldArrayProps;
                      const { values } = form;
                      const { experienceDetails } = values;
                      return experienceDetails.map((elem, index) => {
                        return (
                          <Grid
                            className="oh-company-details"
                            container
                            key={index}
                          >
                            <ChildGrid label="Year" required={true}>
                              <MuiSelect
                                id={experienceDetails[index].id}
                                select
                                selectOptions={experienceArr}
                                placeholder={!values.experienceDetails[index].year && values.experienceDetails[index].year != 0 && "Select Year"}
                                name={`experienceDetails[${index}].year`}
                                value={experienceDetails[index].year}
                                handleChange={(e) => {
                                  setFieldValue(
                                    `experienceDetails[${index}].year`,
                                    e.target.value
                                  );
                                }}
                                error={
                                  // touched?.experienceDetails?.[index]?.year &&
                                  Boolean(
                                    errors?.experienceDetails?.[index]?.year
                                  )
                                }
                                helperText={
                                  <FieldError
                                    errorString={
                                      // touched?.experienceDetails?.[index]
                                      //   ?.year &&
                                      errors?.experienceDetails?.[index]?.year
                                    }
                                  />
                                }
                              />
                            </ChildGrid>
                            <ChildGrid label="Company Name" required={true}>
                              <InputField
                                id={experienceDetails[index].id}
                                type="text"
                                placeholder="Enter"
                                name={`experienceDetails[${index}].company`}
                                value={experienceDetails[index].company}
                                validateOnChange={true}
                                error={
                                  // touched?.experienceDetails?.[index]
                                  //   ?.company &&
                                  Boolean(
                                    errors?.experienceDetails?.[index]?.company
                                  )
                                }
                              />
                            </ChildGrid>
                            <ChildGrid label="Designation" required={true}>
                              <InputField
                                id={experienceDetails[index].id}
                                type="text"
                                placeholder="Enter"
                                name={`experienceDetails[${index}].designation`}
                                value={experienceDetails[index].designation}
                                validateOnChange={true}
                                error={
                                  // touched?.experienceDetails?.[index]
                                  //   ?.designation &&
                                  Boolean(
                                    errors?.experienceDetails?.[index]
                                      ?.designation
                                  )
                                }
                              />
                            </ChildGrid>

                            <ChildGrid size="tertiary" required={true}>
                              <Box className="oh-add-section" sx={{ display: "inline-flex", gap: "10px" }}>
                                {values.experienceDetails[index].new && (
                                  <IconButton
                                    className="oh-delete-btn"
                                    title="Delete"
                                    onClick={() => {
                                      fieldArrayProps.remove(index);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                                {index ===
                                  values.experienceDetails.length - 1 &&
                                  values.experienceDetails[0].value !== "" && (
                                    <IconButton
                                      className="oh-add-btn"
                                      logo={<AddIcon />}
                                      title="Add"
                                      onClick={() => {
                                        push({
                                          year: "",
                                          company: "",
                                          designation: "",
                                          new: true,
                                        });
                                      }}
                                    >
                                      <AddIcon />
                                      <span>Add</span>
                                    </IconButton>
                                  )}
                              </Box>
                            </ChildGrid>
                          </Grid>
                        );
                      });
                    }}
                  </FieldArray>
                </Grid>

                {/* key skills section */}
                <Grid item className="oh-details-container">
                  <MuiTypography
                    className="oh-details-label"
                    content="Skills"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid
                      label="Key Skills"
                      required={true}
                      size="default_secondary"
                    >
                      <Autocomplete
                        clearIcon={false}
                        options={skillsOpt}
                        value={values.skills}
                        multiple
                        sx={{
                          ".MuiAutocomplete-tag": {
                            // minWidth: "fit-content",
                            padding: "10px",
                            maxWidth: "fit-content",
                            display: "flex",
                            justifyContent: "space-evenly",
                            gap: "5px",
                          },
                          "& .MuiInputBase-root": {
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                            height: "auto",
                            minHeight: "40px",
                            maxHeight: "fit-content",
                            padding: "8px",
                            borderRadius: "30px",
                          },
                        }}
                        style={{ maxHeight: "auto" }}
                        noOptionsText={"No options"}
                        renderTags={(value, props) =>
                          value.map((option, index) => (
                            <MuiDeletableChip
                              sx={{
                                minWidth: "fit-content",
                                maxWidth: "150px",
                              }}
                              label={option.label}
                              {...props({ index })}
                            />
                          ))
                        }
                        onChange={(e, newValue) => {
                          const remainingOptions = skillsOpt.filter(
                            (option) =>
                              !newValue.find(
                                (selected) => selected.value === option.value
                              )
                          );
                          setSkillsOpt(remainingOptions);
                          setFieldValue("skills", newValue);
                          const removedOptions = values.skills.filter(
                            (option) =>
                              !newValue.find(
                                (selected) => selected.value === option.value
                              )
                          );
                          setSkillsOpt((prevOptions) => [
                            ...prevOptions,
                            ...removedOptions,
                          ]);
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            placeholder="Eg: Non IT"
                            error={Boolean(errors.skills)}
                            helperText={
                              <FieldError
                                errorString={errors.skills}
                              />
                            }
                            param={params}
                          />
                        )}
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>
              </Grid>
              <MuiDivider className="oh-details-divider" />
              <CustomLoadingButton
                style={!dirty ? { opacity: 0.5 } : null}
                disabled={!dirty ? true : false}
                loading={isSubmitting}
                type="submit"
                title={"Submit"}
                className="oh-submit-btn"
              />
            </Form>
          );
        }}
      </Formik>
    </ProfileLayout >
  );
};

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value.replace(/^\s+/, ""), true);
  };

  // const error = (validateOnChange || meta.touched) && meta.error;
  const error = meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default FreelnacerOther;
