import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import "./index.scss";
import { useDispatch } from "react-redux";
import { getPrivacyPolicies } from "../redux/policySlice";
import { useEffect } from "react";
import Layout from "../landingPage/components/Layout/Layout";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const [privacyPolicyData, setPrivacyPolicyData] = useState([]);
  useEffect(() => {
    getPolicies();
  }, []);
  const getPolicies = () => {
    dispatch(getPrivacyPolicies())
      .then((res) => {
        if (res.type === "getPrivacyPolicies/fulfilled") {
          setPrivacyPolicyData(res?.payload?.data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Layout>
      <Box
        marginTop={"80px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={{ xs: "94px", sm: "188px" }}
        backgroundColor={"#F1F3FC"}
      >
        <Typography
          fontSize={{ xs: "20px", sm: "24px" }}
          fontWeight={700}
          color={"#141621"}
        >
          Privacy Policy
        </Typography>
      </Box>
      <Box
        component={"section"}
        padding={{ xs: "35px 25px", md: "50px 194px" }}
      >
        {privacyPolicyData?.map((section) => (
          <Box key={section.id} color={"000"}>
            <Typography
              fontWeight={700}
              fontSize={"20px"}
              className="parent-box-heading"
              lineHeight={{ xs: "160%" }}
              dangerouslySetInnerHTML={{
                __html: section.attributes.title,
              }}
            />
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: section.attributes.content,
              }}
              lineHeight={{ xs: "160%" }}
            />
          </Box>
        ))}
      </Box>
    </Layout>
  );
};

export default PrivacyPolicy;
