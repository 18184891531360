import React from "react";
import { Typography, Chip, Container, Box } from "@mui/material";
import SearchInput from "./searchInput/SearchInput";
import Infinity from "../../../../assets/images/infinity.png";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPortalInfo } from "../../../../redux/globalSlice";

const HeaderSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const portal = localStorage.getItem("portal");

  const handleChipsClick = (portalName, portalValue) => {
    dispatch(
      setPortalInfo({
        portalName: portalName,
        portalValue: portalValue,
      })
    );
    navigate("/signup");
  };

  return (
    <section className={"section"}>
      <Box className="infinityBackground">
        <img src={Infinity} alt="infinity_image" />
      </Box>
      <Container>
        <Typography variant="h4" className={"heading"}>
        India’s Leading Job Search Platform
        </Typography>
        <Typography className={"subheading"}>
        Explore thousands of job opportunities with just one click
        </Typography>
        <SearchInput />
        <div className="chips">
          {(!token || (token && portal === "Jobseeker")) && (
            <Chip
              label="Looking for a Job"
              className={"chip"}
              // onClick={() => handleChipsClick("Jobseeker", "jobseeker")}
              onClick={() => {navigate('/all-jobs')}}
            />
          )}
          {(!token || (token && portal === "Employer")) && (
            <Chip
              // label="I am professional, looking for a change"
              label="Want to Post a Job"
              className={"chip"}
              // onClick={() => handleChipsClick("Employer", "employer")}
              onClick={() => {navigate('/job-openings')}}
            />
          )}
          {(!token || (token && portal == "Freelancer")) && (
            <Chip
              label="Earn Extra as a Freelancer"
              className={"chip"}
              onClick={() => handleChipsClick("Freelancer", "freelancer")}
            />
          )}
          {/* Add more chips as needed */}
        </div>
      </Container>
    </section>
  );
};

export default HeaderSection;
