import React, { useState } from 'react'
import { Form, Formik, useField, useFormikContext } from 'formik'
import { useNavigate } from 'react-router-dom'

// MUI
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

// OTHER
import MuiTypography from '../../mui/Typography/MuiTypograpghy'
import PrimaryLabel from '../../components/Labels/PrimaryLabel'
import MuiDivider from '../../mui/Dividers/MuiDivider'
import MuiTextField from '../../mui/TextField/MuiTextField'
import FieldError from '../../components/Errors/FieldError'

// CSS
import './index.scss'
import { ChangePasswordSchema } from '../../schemas/ChangePasswordSchema'
import CustomLoadingButton from '../../components/Loader/LoadingButton'
import ProfileLayout from './ProfileLayout'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from '../../redux/resetPasswordSlice'
import { Toaster } from '../../components/Toaster/Toaster'
import { CHANGE_PASSWORD, USERS } from '../../apis/Constant/routes'
import { AxiosApi } from '../../apis/AxiosApi'
import { Alert } from '@mui/material'
import { setLogin, setSignUp, setToken } from '../../redux/authSlice'
import { setFreelancerData } from '../../redux/Freelancer/freelancerDetailsSlice'
import { setNotifications } from '../../redux/notificationsSlice'
import { clearOtps } from '../../redux/verifyOtpSlice'
import { clearLocalStorage } from '../../utils'
import { setProfilePhoto } from '../../redux/profileSlice'

const ChangePassword = () => {
  const [isOld, setIsOld] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const initialData = {
    old_password: '',
    new_password: '',
    confirm_password: ''
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const toggleOldPassword = () => setIsOld(prev => !prev)
  const toggleNewPassword = () => setIsNew(prev => !prev)
  const toggleConfirmPassword = () => setIsConfirm(prev => !prev)

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    let data = {
      old_password: values.old_password,
      new_password: values.new_password,
      password_confirmation: values.confirm_password
    }
    setSubmitting(false)

    // client specific requirement,
    // toaster wasn't noticable much due to too much blank spacing on this page, 
    // rather show an error alert or specific messsage of api 
    // api calling structure is not upright, can not access rejected response here, thus, is diff. for this particular call
    await AxiosApi({
      path: `${USERS}${CHANGE_PASSWORD}?user_id=${user?.id}`,
      type: 'PUT',
      data: JSON.stringify({ data: data })
    }).then(res => {
      Toaster.TOAST(res?.message, "success");
      resetForm();
      setErrorMessage("");
    }).catch(err => {
      setErrorMessage(err?.response?.data?.error);
      console.log(err, 'error')
    })

  }


  const handleForgotPassword = () => {
    clearLocalStorage();
    dispatch(setToken(null));
    dispatch(clearOtps(null));
    dispatch(setSignUp(null));
    dispatch(setLogin(null));
    dispatch(setProfilePhoto(null));
    dispatch(setFreelancerData(null));
    dispatch(setNotifications([]));
    navigate("/forgot-password");
  };

  return (
    <ProfileLayout>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, setSubmitting, resetForm)
        }}
        validateOnblur={false}
        validationSchema={ChangePasswordSchema}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form className='oh-signup-form' autoComplete='off'>
              <Box container className='oh-change-password-container'>
                <Box className='oh-label-box'>
                  <MuiTypography
                    className='oh-change-password-label'
                    content='Change Password'
                  />
                  <MuiTypography
                    onClick={() => handleForgotPassword()}
                    className='oh-forgot-password-label'
                    content='Forgot Password'
                  />
                </Box>
                <Grid
                  className='oh-change-password-form'
                  xs={12}
                  md={12}
                  lg={8}
                  container
                >
                  <Grid item xs={12} md={7} lg={7}>
                    <PrimaryLabel
                      label='Current Password'
                      required={true}
                      labelType='secondary'
                    />
                    <InputField
                      id='old_password'
                      type={isOld ? 'text' : 'password'}
                      placeholder='Enter current password'
                      name='old_password'
                      value={values.old_password}
                      validateOnChange={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            sx={{ cursor: 'pointer' }}
                          >
                            {' '}
                            {isOld ? (
                              <Visibility
                                className='cursor_pointer'
                                onClick={toggleOldPassword}
                              />
                            ) : (
                              <VisibilityOff onClick={toggleOldPassword} />
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                    <PrimaryLabel
                      label='New Password'
                      required={true}
                      labelType='secondary'
                    />
                    <InputField
                      id='new_password'
                      type={isNew ? 'text' : 'password'}
                      placeholder='Enter new password'
                      name='new_password'
                      value={values.new_password}
                      validateOnChange={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            sx={{ cursor: 'pointer' }}
                          >
                            {' '}
                            {isNew ? (
                              <Visibility
                                className='cursor_pointer'
                                onClick={toggleNewPassword}
                              />
                            ) : (
                              <VisibilityOff onClick={toggleNewPassword} />
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                    <PrimaryLabel
                      label='Confirm Password'
                      required={true}
                      labelType='secondary'
                    />
                    <InputField
                      id='confirm_password'
                      type={isConfirm ? 'text' : 'password'}
                      placeholder='Enter confirm password'
                      name='confirm_password'
                      value={values.confirm_password}
                      validateOnChange={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            sx={{ cursor: 'pointer' }}
                          >
                            {' '}
                            {isConfirm ? (
                              <Visibility
                                className='cursor_pointer'
                                onClick={toggleConfirmPassword}
                              />
                            ) : (
                              <VisibilityOff onClick={toggleConfirmPassword} />
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  {errorMessage &&
                    <Grid item xs={10} md={8} lg={6}>
                      <Alert severity="error" onClose={() => setErrorMessage("")}>{errorMessage}</Alert>
                    </Grid>
                  }
                </Grid>
              </Box>
              <MuiDivider className='oh-details-divider' />
              <CustomLoadingButton
                loading={isSubmitting}
                type='submit'
                title={'Submit'}
                className='oh-submit-btn'
              />
            </Form>
          )
        }}
      </Formik>
    </ProfileLayout>
  )
}

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [field, meta] = useField(name)

  const handleChange = event => {
    if (validateOnChange) {
      setFieldTouched(name, true, false)
    }
    setFieldValue(name, event.target.value, true)
  }

  const error = (validateOnChange || meta.touched) && meta.error

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  )
}

export default ChangePassword
