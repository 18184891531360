import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

//MUI
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";

//other components
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import MuiButtonContained from "../../../mui/Buttons/MuiButtonContained";
import SelectedDrawer from "../../../components/Drawer/SelectedCandidatedDrawer/SelectedDrawer";
import CardHeader from "../../../components/Freelancer/Card/Header/CardHeader";
import Skills from "../../JobSeeker/Jobs/Components/Skills";
import FreelancerLayout from "../../Layouts/Freelancer/FreelancerLayout";
import {
  clearSelectedCandidates,
  getAllotedJobDataById,
  setAllotedJobById,
  shortlistCandidates,
} from "../../../redux/Freelancer/allotedJobsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  List,
  Typography,
} from "@mui/material";
import {
  checkIfHtml,
  parseHTML,
  returnSubstring,
  truncateDescription,
} from "../../../utils";

import { Toaster } from "../../../components/Toaster/Toaster";
import CandidatesShortListed from "./CandidatesShortListed";
import CandidateSelected from "../../../components/Freelancer/SelectedCandidate/CandidateSelected";
import FilledChip from "../../../components/customChips/FilledChips";
import InfoBox from "../../../components/OtherInfoForDetails";

//CSS
import "./index.scss";

const JobDetails = () => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [selectedDrawer, setSelectedDrawer] = useState(false);

  const [showFullDescription, setShowFullDescription] = useState(false);

  const allotedJobDataById = useSelector(
    (state) => state.allotedJobs.allotedJobDataById
  );
  const addSelectedCandidateForJobPost = useSelector(
    (state) => state.allotedJobs.addSelectedCandidateForJobPost
  );

  const candidateStatus = location?.state?.status;

  const showShortlisted = (val) => {
    switch (val) {
      case "Under Process":
        return <CandidatesShortListed title={val} />;
      case "Rejected":
        return <CandidatesShortListed title={val} />;
      case "Success":
        return <CandidatesShortListed title={val} />;
      default:
        return <span></span>;
    }
  };

  const toggleDescription = () => {
    showFullDescription && window.scrollTo(0, 0);
    setShowFullDescription(!showFullDescription);
  };

  const handleSubmit = () => {
    let data = {
      admin_job_post_id: params?.id,
      freelancer_candidate_ids: addSelectedCandidateForJobPost.map(
        (elem) => elem.id
      ),
    };

    dispatch(shortlistCandidates(data))
      .then((res) => {
        if (res.type === "shortlistCandidates/fulfilled") {
          // Toaster.TOAST(res?.payload?.message, "success");
          // currently no message associated in response from BE temporarily using static message
          Toaster.TOAST("Candidates added successfully", "success");
          getData();
        }
        dispatch(clearSelectedCandidates());
      })
      .catch((err) => err);
  };

  const getData = () => {
    // dispatch(setIsLoading(true));
    dispatch(getAllotedJobDataById(params?.id))
      .then((res) => {
        // dispatch(setIsLoading(false));
        if (res.type === "getAllotedJobDataById/fulfilled") {
          dispatch(setAllotedJobById(res.payload.data));
        }
      })
      .catch((err) => {
        console.log(err, "error");
        // dispatch(setIsLoading(false));
      });
  };

  useEffect(() => {
    if (params?.id) {
      getData();
    }
  }, [params?.id]);

  return (
    <>
      <FreelancerLayout>
        <Box className="oh-job-details-container">
          <CardHeader
            jobData={allotedJobDataById?.attributes}
            postedTimestamp={"Posted 2 days ago"}
          />
          <MuiDivider className="oh-divider" />

          <Box style={{ padding: "20px 0 0" }}>
            <h3 style={{ paddingBottom: "10px" }}>Job Description</h3>

            {allotedJobDataById?.attributes?.description &&
              (checkIfHtml(allotedJobDataById?.attributes?.description) ? (
                <div
                  className="job-description-employer"
                  style={{
                    maxHeight: showFullDescription ? "none" : "200px",
                    overflow: "hidden",
                    transition: "all 0.5s ease-in", // Smooth transition with ease effect
                  }}
                  dangerouslySetInnerHTML={parseHTML(
                    showFullDescription
                      ? allotedJobDataById?.attributes?.description
                      : truncateDescription(
                        allotedJobDataById?.attributes?.description,
                        200
                      )
                  )}
                />
              ) : (
                <MuiTypography
                  className="job-description-employer"
                  style={{
                    maxHeight: showFullDescription ? "none" : "200px",
                    overflow: "hidden",
                    transition: "all 0.5s ease-in", // Smooth transition with ease effect
                  }}
                  content={
                    showFullDescription
                      ? allotedJobDataById?.attributes?.description
                      : returnSubstring(
                        allotedJobDataById?.attributes?.description,
                        200
                      )
                  }
                />
              ))}

            {/* Render the "View More"/"View Less" button */}
            {allotedJobDataById?.attributes?.description &&
              allotedJobDataById?.attributes?.description.length > 200 && (
                <Box sx={{ mt: 2 }}>
                  <Typography
                    className="oh-more-view"
                    onClick={toggleDescription}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    {showFullDescription ? (
                      <Box display={"flex"} alignItems={"center"}>
                        <KeyboardArrowUpIcon />
                        View Less
                      </Box>
                    ) : (
                      <Box display={"flex"} alignItems={"center"}>
                        <KeyboardArrowDownIcon />
                        View More
                      </Box>
                    )}
                  </Typography>
                </Box>
              )}
          </Box>
          <MuiDivider className="oh-divider" />

          <Box style={{ padding: "20px 0 0" }}>
            <h3 style={{ paddingBottom: "10px" }}>Skills</h3>
            <Skills
              size="medium-200"
              skillsData={allotedJobDataById?.attributes?.skills?.map(
                (elem) => elem.name
              )}
            />
          </Box>

          <MuiDivider className="oh-divider" />

          <Box style={{ margin: "30px 0" }}>
            <h3 style={{ paddingBottom: "10px" }}>Other Information</h3>
            <InfoBox
              items={[
                {
                  label: "Experience Required :-",
                  value: allotedJobDataById?.attributes?.experience || "-",
                },
                {
                  label: "Work Mode :-",
                  value: allotedJobDataById?.attributes?.work_mode || "-",
                },
                {
                  label: "No. of Applicants :-",
                  value: allotedJobDataById?.attributes?.applicants_count || "-",
                },
                {
                  label: "Min. CTC Offered :-",
                  value: allotedJobDataById?.attributes?.min_ctc 
                  ? `₹ ${allotedJobDataById.attributes.min_ctc},000`
                  : "-",
                },
                {
                  label: "Max. CTC Offered :-",
                  value: allotedJobDataById?.attributes?.max_ctc 
                  ? `₹ ${allotedJobDataById.attributes.max_ctc},000`
                  : "-",
                },
              ]}
            />
          </Box>

          {allotedJobDataById?.attributes?.freelancer_candidates?.length > 0 && (
              <>
                <MuiDivider className="oh-secondary-divider" />

                <Box className="oh-candidate-shortlist">
                  <h3 style={{ padding: "5px 0px 10px" }}>
                    Candidates Shortlisted
                  </h3>
                  {allotedJobDataById?.attributes?.freelancer_candidates?.map(
                    (elem, idx) => {
                      return (
                        <>
                          <CandidatesShortListed
                            data={elem.attributes}
                            key={idx}
                          />
                        </>
                      );
                    }
                  )}
                </Box>
              </>
            )}

          <MuiDivider className="oh-secondary-divider" />

          <Box className="oh-candidate-shortlist">
            <h3
              style={{
                padding: "5px 0px 10px",
                display: "flex",
                alignContent: "center",
                gap: "10px",
              }}
            >
              {allotedJobDataById?.attributes?.freelancer_candidates?.length > 0
                ? "Add More Candidates"
                : "Add Candidates"}
              <FilledChip
                sx={{
                  transform: "translateY(-6px)",
                  cursor: "pointer",
                  padding: "4px 12px"
                }}
                size="medium-200"
                color="var(--oh-green-color)"
                onClick={() => {
                  setSelectedDrawer(true);
                }}
                label={
                  <>
                    Add
                    <AddIcon sx={{ fontSize: "18px" }} />
                  </>
                }
              />
            </h3>
            {showShortlisted(candidateStatus)}

            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              {addSelectedCandidateForJobPost?.length > 0 && (
                <List
                  component="span"
                  sx={{
                    display: "flex",
                    gap: "0px 25px",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                  }}
                  disablePadding
                >
                  {addSelectedCandidateForJobPost?.map((item, index) => (
                    <CandidateSelected data={item} />
                  ))}
                </List>
              )}

              <MuiTypography
                content={
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      fontStyle: "italic",
                      color: "var(--oh-gray-color)",
                    }}
                    className="more-candidates-text"
                  >
                    {allotedJobDataById?.attributes?.freelancer_candidates
                      ?.length > 0
                      ? `More candidates can be added to the job "${allotedJobDataById?.attributes?.title}".`
                      : "No candidate added yet. Candidate details are not available."}
                  </span>
                }
                className="oh-subtitle"
              />

            </Box>
          </Box>

          {allotedJobDataById?.attributes?.freelancer_candidates?.length >
            0 && (
              <>
                <MuiDivider style={{ margin: "25px 0 10px" }} />
                <Box
                  sx={{ opacity: candidateStatus === "Rejected" && "0.5", mt: 2 }}
                >
                  <MuiTypography
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "700",
                      color: "#445386",
                    }}
                  >
                    ₹ 20,000
                  </MuiTypography>
                  <MuiTypography
                    style={{
                      fontSize: "0.75rem",
                      fontWeight: "400",
                      color: "#445386",
                    }}
                  >
                    Projected Earnings
                  </MuiTypography>
                </Box>
              </>
            )}
        </Box>

          <MuiDivider style={{ margin: "25px 0 10px" }} />
          <MuiButtonContained
            className="oh-submit-btn"
            title="Submit"
            disabled={addSelectedCandidateForJobPost?.length > 0 ? false : true}
            onClick={() => handleSubmit()}
            style={{
              width: "30% !important",
              marginTop: "20px",
              opacity: addSelectedCandidateForJobPost?.length <= 0 ? "0.5" : "",
              color:
                addSelectedCandidateForJobPost?.length <= 0 &&
                "#fff !important",
            }}
          />

        {selectedDrawer && (
          <SelectedDrawer
            jobData={allotedJobDataById?.attributes?.freelancer_candidates}
            selectedDrawer={selectedDrawer}
            setSelectedDrawer={setSelectedDrawer}
          />
        )}
      </FreelancerLayout>
    </>
  );
};

export default JobDetails;
