import React, { useEffect, useState } from "react";
import moment from "moment";
//Mui
import EmployerLayout from "../../Layouts/Employer/EmployerLayout";
import Box from "@mui/material/Box";
//Other component

// ASSETS
import CandidateImage from "../../../assets/images/female.svg";
import LocationIcon from "../../../assets/images/location.svg";
import SuitcaseIcon from "../../../assets/images/suitcaseIcon.svg";
import CurrencyRupeeIcon from "../../../assets/images/ruppeeIcon.svg";

//CSS
import "./index.scss";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import { Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Skills from "../../JobSeeker/Jobs/Components/Skills";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import { useParams } from "react-router-dom";
import {
  getJobseekerDataById,
  setJobseekerDataById,
} from "../../../redux/JobSeeker/jobSeekerJobsSlice";
import { useDispatch, useSelector } from "react-redux";
import { ctcArr, experienceArr } from "../../../Data";
import MuiRating from "../../../mui/Rating/MuiRating";
import { downloadResumeApi } from "../../../redux/JobSeeker/PersonalDetailSlice";
import { Toaster } from "../../../components/Toaster/Toaster";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CandidateDetailsOverview = ({ }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.appliedJobs.jobseekerDataById);
  const [image, setImage] = useState(null); // can not rearrange data?.attributes all over and test now thus making new state for image will fix later
  useEffect(() => {
    if (params?.jobSeekerID) {
      getDataById();
    }
    window.scrollTo(0, 0);
  }, [params?.jobSeekerID]);

  const getDataById = () => {
    dispatch(getJobseekerDataById(params?.jobSeekerID))
      .then((res) => {
        const apiData = res?.payload;
        if (res.type === "getJobseekerDataById/fulfilled") {
          setImage(apiData?.data?.attributes?.profile_photo);
          dispatch(
            setJobseekerDataById(apiData?.data?.attributes?.personal_details)
          );
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };


  const locationsArr = data?.locations?.map((location) => location.city)?.join(", ");

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    showFullDescription && window.scrollTo(0, 0);
    setShowFullDescription(!showFullDescription);
  };

  const RenderInformation = ({
    title = "", children, showDivider = true
  }) => {
    return (
      <>
        {showDivider && <MuiDivider className="oh-divider" />}
        <Box style={{ padding: "10px 0 0", transition: "all 0.5s ease-in" }}>
          <h3 className="oh-details-subheading">{title}</h3>
          {children}
        </Box>
      </>
    )
  }

  const DetailsNotAvailable = ({ text = "Details not available." }) => {
    return (
      <Typography
        variant="body1"
        color="textSecondary"
        sx={{ fontSize: "12px", fontStyle: "italic" }}
      >
        {text}
      </Typography>
    )
  }

  const handleDownload = (resume_name, value) => {
    dispatch(
      downloadResumeApi({
        id: params?.jobSeekerID,
      })
    )
      .then((res) => {
        if (res.type === "personal_details/download_resume/fulfilled") {
          const fileContent = res.payload;
          if (!fileContent) {
            console.error("No file content received.");
            return;
          }
          const fileBlob = new Blob([fileContent]);
          const fileURL = window.URL.createObjectURL(fileBlob);
          const link = document.createElement("a");
          link.href = fileURL;

          link.setAttribute("download", resume_name);
          document.body.appendChild(link);
          link.click();
          Toaster.TOAST("Resume downloaded successfully", "success");
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  return (
    <EmployerLayout>
      <Box className="oh-candidate-details-overview">
        <img
          src={image || CandidateImage}
          alt="candidate"
          className="candidateImage"
        />
        <Box className="oh-candidate-details-section">
          <Box className="oh-details-header">
            <Box className="oh-details-box">
              <MuiTypography
                content={data?.full_name || `CandidateID - #${data?.id}`}
                className="oh-title"
              />
              <MuiTypography
                content={data?.designation || ""}
                className="oh-subtitle"
              />
            </Box>
            {data?.updated_at && (
              <MuiTypography
                content={`Updated: ${moment(data?.updated_at).fromNow()}`}
                className="oh-details-posted-text"
              />
            )}
          </Box>

          <Box className="oh-card-body oh-details-card-body candidate-details-body">
            {(data?.experience || data?.experience == 0) &&
              <MuiTypography key={0} variant="span" className="oh-requirements">
                <img src={SuitcaseIcon} className="overview-suitcase-icon" alt={"work-exp"} />{" "}
                {experienceArr.find((item) => item.value === data?.experience)
                  ?.label || "-"}
              </MuiTypography>
            }

            {(data?.ctc || data?.ctc == 0) &&
              <MuiTypography variant="span" className="oh-requirements">
                <img src={CurrencyRupeeIcon} className="overview-rupee-icon" alt={"salary"} />{" "}
                {ctcArr.find((item) => item.value === data?.ctc)?.label}
              </MuiTypography>
            }
            {locationsArr && (
              <MuiTypography variant="span" className="oh-requirements candidate-details-req">
                <img src={LocationIcon} className="overview-location-icon" alt={"locations"} />
                {locationsArr}
              </MuiTypography>
            )}
          </Box>
          {data?.resume?.url && (
            <Box
              sx={{
                border: "1px solid var(--oh-white-300-color)",
                minWidth: "60%",
                background: "var(--oh-white-color)",
                maxWidth: "500px",
                padding: "10px",
                display: "flex",
                borderRadius: "4px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  lineHeight: "20.5px",
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#445386",
                }}
              >
                <p style={{ letterSpacing: "0.4px", wordBreak: "break-word", paddingRight: "20px" }}>
                  {" "}
                  {data?.resume_name || "Davidelbert-Ser-java.pdf"}
                </p>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "var(--oh-gray-color)",
                  }}
                >
                  Uploaded on {moment(data?.updated_at).fromNow()}
                </span>
              </div>

              <DownloadIcon
                onClick={() =>
                  // it is working fine without second parameter also, not removing it as currently not sure of why it is passed 
                  handleDownload(data?.resume_name, data?.resume?.url)
                }
                sx={{
                  color: "var(--oh-blue-300-color)",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  padding: "5px",
                  background: "var(--oh-white-300-color)",
                  cursor: "pointer",
                  svg: {
                    fontSize: "20px",
                  },
                }}
              />
            </Box>
          )}

          <MuiDivider style={{ marginTop: "20px" }} className="oh-divider" />

          <RenderInformation
            title="Skills"
            showDivider={false}
          >
            {data?.skills?.length ? (<Skills
              size="medium-300"
              skillsData={data?.skills?.map((elem) => elem.name)}
            />) : <DetailsNotAvailable />}

          </RenderInformation>

          <RenderInformation title="Expertise In">
            {data?.expertise && data?.expertise?.length > 0 ? (
              <Skills
                // component Skills could be renamed to a generic name
                size="medium-300"
                skillsData={data?.expertise.map((elem) => elem)}
              />
            ) : <DetailsNotAvailable />}
          </RenderInformation>

          <RenderInformation title="Experience">
            {(data?.experience_details instanceof Object) &&
              Object.keys(data?.experience_details).length ? (
              // could remove this above length condition it is unnecessary, can be optimized in a better way
              Object.keys(data?.experience_details)?.map((idx) => {
                return (
                  <div
                    style={{
                      fontSize: "14px",
                      padding: "5px 15px 10px",
                      lineHeight: "19.5px",
                      textTransform: "capitalize",
                    }}
                    key={idx}
                  >
                    <p
                      style={{
                        display: "list-item",
                        color: "var(--oh-blue-600-color)",
                      }}
                    >
                      {experienceArr.find(opt => opt.value == data?.experience_details[idx]?.year).label}
                    </p>
                    <p>{data?.experience_details[idx]?.designation}</p>
                    <p>{data?.experience_details[idx]?.company}</p>
                  </div>
                );
              })
            ) : <DetailsNotAvailable />}
          </RenderInformation>

          {showFullDescription && (
            <>
              <RenderInformation title="Education">
                {data?.education_details &&
                  Object.keys(data?.education_details).length ? (
                  Object.keys(data?.education_details)?.map((idx) => {
                    return (
                      <div
                        style={{
                          fontSize: "14px",
                          padding: "5px 15px 10px",
                          lineHeight: "19.5px",
                          textTransform: "capitalize",
                        }}
                        key={idx}
                      >
                        <p
                          style={{
                            display: "list-item",
                            color: "var(--oh-blue-600-color)",
                          }}
                        >
                          {data?.education_details[idx]?.year}
                        </p>
                        <p>{data?.education_details[idx]?.percentage} %</p>
                        <p>{data?.education_details[idx]?.university}</p>
                      </div>
                    );
                  })
                ) : <DetailsNotAvailable />}
              </RenderInformation>

              <RenderInformation title="Languages">
                {(data.languages instanceof Array) ? data?.languages?.map((elem, idx) => {
                  return (
                    <>
                      <div
                        style={{
                          fontSize: "14px",
                          padding: "5px 0px",
                          lineHeight: "12px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          gap: "8px",
                        }}
                        key={idx}
                      >
                        <MuiRating
                          label={elem?.language}
                          style={{ pointerEvents: "none" }}
                          value={elem?.proficiency}
                        />
                      </div>
                    </>
                  );
                }) : <DetailsNotAvailable />}
              </RenderInformation>
            </>
          )}
          <Box sx={{ mt: 2 }}>
            <span
              className="oh-more-view"
              onClick={toggleDescription}
              style={{ cursor: "pointer", color: "var(--oh-strongpink-color)" }}
            >
              {showFullDescription ? (
                <Box display={"flex"} alignItems={"center"}>
                  <KeyboardArrowUpIcon />
                  View Less
                </Box>
              ) : (
                <Box display={"flex"} alignItems={"center"}>
                  <KeyboardArrowDownIcon />
                  View More
                </Box>
              )}
            </span>
          </Box>
        </Box>
      </Box>
    </EmployerLayout>
  );
};

export default CandidateDetailsOverview;
