import React from 'react'
//Mui
import Chip from '@mui/material/Chip'
//CSS
import './index.scss'

const MuiIconChip = ({
  className = '',
  label = '',
  sx = {},
  onClick = () => {},
  icon = null,
  onDelete = null,
  deleteIcon = null,
  spanClass = ''
}) => {
  return (
    <span className={`icon-chip ${spanClass}`}>
      <Chip
        icon={icon}
        label={label}
        className={className}
        sx={sx}
        variant='outlined'
        onClick={onClick}
        onDelete={onDelete}
        deleteIcon={deleteIcon}
      />
    </span>
  )
}

export default MuiIconChip
