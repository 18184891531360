import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const MuiSelectWithSearch = ({
    fullWidth = true,
    id = "select-field",
    placeholder = "Please select",
    value = "",
    handleChange = () => { },
    selectOptions = [],
    error = false,
    helperText = {}
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedValue, setSelectedValue] = useState(null);

    const filteredOptions = selectOptions.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Set the selected value initially else undefined will get set
    useEffect(() => {
        const initialValue = selectOptions?.find(option => option?.label === value);
        setSelectedValue(initialValue || null);
    }, [value, selectOptions]);


    return (
        <Autocomplete
            value={selectedValue}
            options={filteredOptions}
            getOptionLabel={option => option.label}
            sx={{
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                    borderRadius: "34px",
                    padding: "6px 16px",
                    fontSize: "14px"
                }
            }}
            onChange={(event, newValue) => {
                if (newValue) {
                    handleChange(newValue)
                } else {
                    handleChange("");
                }
            }}
            onInputChange={(event, newInputValue) => {
                setSearchTerm(newInputValue);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    error={error}
                    helperText={helperText}
                />
            )}
        />
    );
};

export default MuiSelectWithSearch;
