import React, { useEffect, useState } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

//MUI
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
// import SearchIcon from '@mui/icons-material/Search';

//ASSETS
// import applicantImage from '../../../assets/images/applicant.svg';
import Male from '../../../assets/images/male.svg';
import Female from '../../../assets/images/female.svg';

//OTHER
import { freelancerExperienceArr } from '../../../Data';
import MuiTypography from '../../../mui/Typography/MuiTypograpghy';

//CSS
import './index.scss';
import { Checkbox } from '@mui/material';
import MuiDivider from '../../../mui/Dividers/MuiDivider';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFreelancerActiveCandidateData, setFreelancerActiveCandidateData } from '../../../redux/Freelancer/freelancerActiveCandidatesSlice';
import MuiButtonOutlined from '../../../mui/Buttons/MuiButtonOutlined';
import { clearSelectedCandidates, setAddSelectedCandidate } from '../../../redux/Freelancer/allotedJobsSlice';
import MuiButtonContained from '../../../mui/Buttons/MuiButtonContained';
import DrawerSearch from '../Components/DrawerSearch';
import NoJobsFound from '../../NotFound/NoJobsFound';

const SelectedDrawer = ({ jobData = {}, selectedDrawer, setSelectedDrawer }) => {
  const addSelectedCandidateForJobPost = useSelector(state => state.allotedJobs.addSelectedCandidateForJobPost)
  const [selectedItems, setSelectedItems] = useState(addSelectedCandidateForJobPost);
  const [dataFromApi, setDataFromApi] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    if (jobData) {
      let temp_data = jobData?.map((data) => {
        return {
          id: data?.attributes?.id,
          name: `${data?.attributes?.first_name} ${data?.attributes?.last_name}`,
          designation: data?.attributes?.designation,
          experience: data?.attributes?.experience,
          gender: data?.attributes?.gender,
        }
      })
      setDataFromApi(temp_data);
    }
  }, [jobData])


  const handleToggle = (id, name, designation, experience, gender) => {
    const selectedItemIndex = selectedItems.findIndex(item => item.id === id);

    if (selectedItemIndex === -1) {
      // If item is not selected, add it to the array
      setSelectedItems(prevItems => [...prevItems, { id, name, designation, experience, gender }]);
    } else {
      // If item is already selected, remove it from the array
      setSelectedItems(prevItems => prevItems.filter(item => item.id !== id));
    }
  };


  const handleCandidate = () => {
    dispatch(setAddSelectedCandidate(selectedItems));
    setSelectedDrawer(false);
  };

  const handleReset = () => {
    dispatch(clearSelectedCandidates());
    setSelectedItems([]);
    setSearchKeyword("");
    handleSearch();
  };

  const dispatch = useDispatch();
  const params = useParams();

  const freelancerActiveCandidates = useSelector((state) => state.freelancerActiveCandidates.freelancerActiveCandidates);

  useEffect(() => {
    getFreelancerActiveCandidates();
  }, []);

  const getFreelancerActiveCandidates = (keyword = null) => {
    let apiEndPoint = keyword ? getFreelancerActiveCandidateData({ keyword: keyword }) : getFreelancerActiveCandidateData({ page: 1, pageSize: 100 });
    dispatch(apiEndPoint).then((res) => {
      if (res.type === 'getFreelancerActiveCandidateData/fulfilled') {
        dispatch(setFreelancerActiveCandidateData(res.payload.data));
      }
    }).catch(err => console.log(err));
  };

  const handleSearch = (keyword = null) => {
    getFreelancerActiveCandidates(keyword);
  };

  const handleClose = () => {
    dispatch(clearSelectedCandidates());
    setSelectedItems([]);
    setSearchKeyword("");
    setSelectedDrawer(false);
  }

  return (
    <Box>
      <Drawer
        anchor='right'
        sx={{
          '.MuiBackdrop-root': {
            background: 'rgba(0, 0, 0, 0.15)',
          },
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            paddingTop: '20px',
            maxWidth: '350px',
            width: '100%',
          },
        }}
        open={selectedDrawer}
        onClose={() => handleClose()}
      >
        <Box className='oh-selected-drawer'>
          <h3 style={{ margin: "5px 0 25px" }}>Select Candidates</h3>
          <CloseIcon
            className='close-icon'
            onClick={() => handleClose()}
          />
          <MuiDivider style={{ mt: 2 }} />
          <DrawerSearch
            searchData={searchKeyword}
            setSearchData={setSearchKeyword}
            handleSearch={handleSearch} />

          {/* {selectedItems?.length > 0 && <Box sx={{ width: "fit-content", margin: "10px auto" }}>
            <LinkText onClick={handleCandidate} style={{ padding: "12px 25px", fontWeight: 500, color: "var(--oh-blue-300-color)" }} title="Add Candidates" />
          </Box>} */}
          {/* <MuiDivider /> */}

          <List component='div' disablePadding>
            {
              freelancerActiveCandidates?.length > 0 ?
                freelancerActiveCandidates?.map((item, idx) => (
                  <React.Fragment key={item.id}>
                    <ListItem
                      disablePadding
                      sx={{
                        fontSize: '12px',
                        padding: "4px 16px 2px 4px",
                        pointerEvents: dataFromApi?.some(selectedItem => selectedItem.id == item.id) ? 'none' : 'default',
                        cursor: dataFromApi?.some(selectedItem => selectedItem.id == item.id) ? 'default' : 'pointer',
                        // opacity: dataFromApi?.some(selectedItem => selectedItem.id == item.id) ? 0.7 : 1,
                        backgroundColor:
                          dataFromApi?.some(selectedItem => selectedItem.id == item.id) ||
                            selectedItems.some(selectedItem => selectedItem.id == item.id) ?
                            'var(--oh-white-200-color)' : 'var(--oh-white-color)',
                      }}
                    // onClick={() => handleToggle(item.id)}
                    // onClick={() => handleToggle(item.id, item?.attributes?.first_name + ' ' + item?.attributes?.last_name, item?.attributes?.designation, item?.attributes?.experience, item?.attributes?.gender)}

                    >
                      <Checkbox
                        sx={{
                          svg: {
                            width: "18px",
                          },
                          "&.MuiCheckbox-root": {
                            svg: {
                              color: dataFromApi?.some(selectedItem => selectedItem.id == item.id)
                                ? "var(--oh-gray-color)" : "var(--oh-blue-color)"
                            }
                          }
                        }}
                        disabled={dataFromApi?.some(selectedItem => selectedItem.id == item.id)}
                        defaultChecked={false}
                        name="suggest"
                        checked={dataFromApi?.some(selectedItem => selectedItem.id == item.id) || selectedItems.some(selectedItem => selectedItem.id == item.id)}
                        onClick={() => handleToggle(item.id, item?.attributes?.first_name + ' ' + item?.attributes?.last_name, item?.attributes?.designation, item?.attributes?.experience, item?.attributes?.gender)}

                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <ListItemAvatar
                        sx={{
                          minWidth: '40px',
                          width: '40px',
                          marginRight: '10px',
                        }}
                      >
                        <CircularProgressbarWithChildren
                          value={66}
                          strokeWidth={6}
                          backgroundPadding={0}
                          styles={buildStyles({
                            pathColor: '#34A853',
                          })}
                        >
                          <img
                            src={item?.attributes?.gender === 'Male' ? Male : Female}
                            alt='avatar'
                            style={{
                              width: '36px',
                              height: '36px',
                              borderRadius: '50%',
                            }}
                          />
                        </CircularProgressbarWithChildren>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          span: {
                            fontSize: '14px', fontWeight: 700, color: "var(--oh-blue-600-color)", letterSpacing: "0.4px"
                          },
                          p: {
                            fontSize: '12px', fontWeight: 400, color: "var(--oh-blue-300-color)", letterSpacing: "0.4px"
                          },
                        }}
                        primary={item?.attributes?.first_name ? `${item?.attributes?.first_name} ${item?.attributes?.last_name}` : '-'}
                        secondary={
                          <MuiTypography
                            style={{ fontSize: '12px', fontWeight: 400, color: "var(--oh-blue-300-color)" }}
                          //   content={item?.attributes?.designation}
                          // />
                          >
                            <div className='oh-drawer-list-desc' style={{ display: 'flex', gap: "20px", justifyContent: 'space-between', alignItems: 'center' }} >
                              <div>
                                {item?.attributes?.designation}
                              </div>
                              <div className='candidate-experience'>
                                {item?.attributes?.experience ? freelancerExperienceArr.find((option) => option.value == item?.attributes?.experience).label : ""}
                              </div>
                            </div>
                          </MuiTypography>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                )) : (
                  <Box sx={{ paddingBottom: "50px" }}><NoJobsFound height={"calc(60vh - 50px)"} value={"No candidates matched"} applicants={true} /></Box>
                )}
          </List>
        </Box>

        <Box
          sx={{
            position: "sticky",
            bottom: "0",
            height: "80px",
            background: "#fff",
          }}
        >
          <MuiDivider />
          <Box px={2} py={2} gap={2} display={"flex"}>
            <MuiButtonContained
              disabled={selectedItems?.length <= 0}
              title="Select"
              style={{
                padding: "12px 30px !important",
                fontWeight: "400 !important",
              }}
              onClick={handleCandidate}
            />
            <MuiButtonOutlined
              style={{
                padding: "12px 30px !important",
                fontWeight: "400 !important",
                "&:hover": {
                  color: "red !important",
                  border: "1px solid red",
                },
              }}
              title="Reset"
              color="error"
              onClick={handleReset}
              disabled={selectedItems?.length <= 0}
            />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SelectedDrawer;
