import React, { useEffect } from 'react'
import JobSeekerLayout from '../../../Layouts/JobSeeker/JobSeekerLayout'
import TertiaryWrapper from '../../../../components/Wrappers/TertiaryWrapper'
import { Box, Grid, Pagination } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import NoJobsFound from '../../../../components/NotFound/NoJobsFound'
import JobPostCard from '../../../../components/JobPostBox/JobPostCard'
import {
  getAllSavedJobs,
  setAllSavedJobs,
  setSavedJobPage,
  setSavedJobRowCount
} from '../../../../redux/JobSeeker/savedJobsSlice'
import { useNavigate } from 'react-router-dom'
import GlobalLoader from '../../../../components/Loader/GlobalLoader'
import { setIsLoading } from '../../../../redux/globalSlice'

const SavedJobs = () => {
  const { isUpdate, allSavedJobs } = useSelector(state => state?.savedJob)
  const { SavedJobPage, SavedJobRowCount } = useSelector(
    state => state.savedJob
  )

  const { isLoading } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    getSavedData()
  }, [SavedJobPage])

  useEffect(() => {
    if (isUpdate && allSavedJobs?.length == 0) {
      dispatch(setSavedJobPage(SavedJobPage - 1))
    }
  }, [isUpdate])

  useEffect(() => {
    return () => {
      dispatch(setSavedJobPage(1))
      dispatch(setSavedJobRowCount(null))
    }
  }, [])

  const getSavedData = data => {
    dispatch(setIsLoading(true))
    dispatch(getAllSavedJobs())
      .then(res => {
        if (res.type === 'allSavedJobs/fulfilled') {
          dispatch(setAllSavedJobs(res?.payload?.data))
          dispatch(setSavedJobRowCount(res?.payload?.meta?.total_pages))
          dispatch(setIsLoading(false))
        } else if (res.type === 'allSavedJobs/fulfilled') {
          dispatch(setIsLoading(false))
        }
      })
      .catch(e => {
        console.log(e)
        dispatch(setIsLoading(false))
      })
  }

  const handlePageChange = (event, newPage) => {
    dispatch(setSavedJobPage(newPage))
  }
  return (
    <>
      <JobSeekerLayout>
        <TertiaryWrapper content={`All Jobs Saved`}>
          {isLoading ? (
            <GlobalLoader minHeight='300px' />
          ) : (
            <Grid container spacing={2}>
              {allSavedJobs?.length > 0 ? (
                allSavedJobs?.map(elem => {
                  return (
                    <Grid
                      item
                      className='cards-responsive-1400'
                      xs={12}
                      md={12}
                      lg={6}
                    >
                      <JobPostCard
                        cardType='jobsCards'
                        size={'medium-100'}
                        handleNavigate={() => {
                          navigate(`/saved-jobs/job-details/${elem?.id}`)
                        }}
                        data={elem?.attributes}
                        className={'open-total-applicant'}
                        updateData={allSavedJobs}
                      />
                    </Grid>
                  )
                })
              ) : (
                <Box sx={{ width: '100%', minHeight: '200px' }}>
                  <NoJobsFound height={'450px'} value='No jobs found' />
                </Box>
              )}
            </Grid>
          )}
          {allSavedJobs?.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px'
              }}
            >
              <Pagination
                count={SavedJobRowCount}
                onChange={handlePageChange}
                shape='rounded'
              />
            </Box>
          )}
        </TertiaryWrapper>
      </JobSeekerLayout>
    </>
  )
}
export default SavedJobs
