import React, { useEffect } from "react";
//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//OTHER
import ContentWithIcon from "../../ContentWithFilterIcon/ContentWithIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedCandidates,
  getAllAppliedJobsData,
  setAllAppliedJobs,
} from "../../../../redux/Freelancer/allotedJobsSlice";
import AllotedJobsCard from "../../../Cards/JobSeeker/AllotedJobsCard";
import NoJobsFound from "../../../NotFound/NoJobsFound";
import moment from "moment";
import { setIsLoading } from "../../../../redux/globalSlice";
import GlobalLoader from "../../../Loader/GlobalLoader";

const AppliedJobs = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allAppliedJobs = useSelector(
    (state) => state.allotedJobs.allAppliedJobs
  );
  const { isLoading } = useSelector(state => state.app);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getAllAppliedJobsData())
      .then((res) => {
        if (res.type === "getAllAppliedJobsData/fulfilled") {
          dispatch(setIsLoading(false));
          dispatch(setAllAppliedJobs(res.payload.data));
        } else if (res.type === "getAllAppliedJobsData/rejected") {
          dispatch(setIsLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Box className="freelancer-jobs-container">
      <ContentWithIcon
        data={props}
        content={`${allAppliedJobs?.length} Jobs Applied `}
        className="oh-content-icon"
      />
      {
        isLoading ? <GlobalLoader minHeight="300px" /> : (
          <Grid container spacing={1}>
            {allAppliedJobs?.length > 0 ? (
              allAppliedJobs.map((elem) => {
                return (
                  <Grid className="cards-freelancer-responsive-1400" item xs={12} md={6} lg={4}>
                    <AllotedJobsCard
                      cardType="allJobs"
                      footerText={true}
                      footerImg={true}
                      timestamp={moment(elem?.attributes?.created_at).fromNow()}
                      imgShow={false}
                      tags={true}
                      data={elem?.attributes}
                      iconChipClass={"iconChipClass"}
                      handleNavigate={() => {
                        dispatch(clearSelectedCandidates());
                        navigate(`/allotted-jobs/job-details/${elem?.id}`);
                      }}
                    />
                  </Grid>
                );
              })
            ) : (
              <NoJobsFound height={"250px"} value={"No applied jobs yet"} />
            )}
          </Grid>
        )
      }
    </Box>
  );
};

export default AppliedJobs;
