import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../apis/AxiosApi";

const initialState = {
  blogsData: [],
};
export const blogs = createAsyncThunk("blogs", async () => {
  return await AxiosApi({
    path: `/blogs`,
    type: "GET",
    toAppendAuthParams: false,
  });
});

export const blogsDataSlice = createSlice({
  name: "blogsData",
  initialState,
  reducers: {
    setBlogsData: (state, action) => {
      state.blogsData = action.payload;
    },
  },
});

export default blogsDataSlice.reducer;
export const { setBlogsData } = blogsDataSlice.actions;
