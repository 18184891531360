import React from "react";
// MUI
import Divider from "@mui/material/Divider";
//CSS
import "./index.scss";

const MuiDivider = ({
    textAlign = "center",
    label = null,
    size = "small",
    style = {},
    className = "",
    orientation="horizontal",
    flexItem = orientation === "vertical" ? true : false,
}) => {
    return (
        <Divider
            orientation={orientation}
            flexItem={flexItem}
            textAlign={textAlign}
            sx={style}
            size={size}
            className={`oh-divider ${className}`}
        >
            {label}
        </Divider>
    )
}

export default MuiDivider;