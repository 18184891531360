import React from "react";
// MUI
import Button from "@mui/material/Button";
// CSS
import "./index.scss";

const MuiButtonOutlined = ({
  style = {},
  type = "button",
  className = "oh-outlined-button",
  logo = null,
  endLogo = null,
  title = "",
  onClick = () => { },
  color = "primary",
  disabled = false,
}) => {
  return (
    <Button
      variant="outlined"
      sx={style}
      type={type}
      className={`oh-default-button ${className}`}
      onClick={onClick}
      color={color}
      disabled={disabled}
    >
      {logo && <span className="oh-start-logo">{logo}</span>}
      {title}
      {endLogo && <span className="oh-start-logo">{endLogo}</span>}
    </Button>
  );
};

export default MuiButtonOutlined;
