import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from "@mui/icons-material/Close";
import "./index.scss"

export default function ConfirmationDialog({ heading, popupState, setPopupState, handleYes = () => { } }) {
    const handleClose = () => {
        setPopupState(false);
    };
    return (
        <Dialog
            open={popupState}
            onClose={handleClose}
            className="confirmation-dialog-parent-container"
            sx={{ margin: 'auto' }}
        >

            <DialogTitle className='dialog-title'>
                {heading}

                <Button className="ma-cross-btn" onClick={handleClose} color="primary" autoFocus>
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to perform this action ?
                </DialogContentText>
            </DialogContent>
            <DialogActions className='oh-confirm-dialog-footer'>
                <Button className="oh-dialog-no" autoFocus onClick={handleClose}>
                    NO
                </Button>
                <Button className="oh-dialog-yes" autoFocus onClick={handleYes}>
                    YES
                </Button>
            </DialogActions>
        </Dialog>
    )
}
