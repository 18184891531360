import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";

// MUI
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

//ASSETS
import notification from "../../assets/images/notification.svg";
import logo from "../../assets/images/logo.svg";
import humburgerIcon from "../../assets/images/humburgerIcon.svg";
import SearchIconImg from "../../assets/images/search-icon-img.svg";
import SearchIconImgCircle from "../../assets/images/search-icon.svg";
import profile from "../../assets/images/default_profile_picture.svg";

// OTHER
import NotificationDrawer from "../Drawer/NotificationDrawer/CustomNotification";
import ProfileDrawer from "../Drawer/ProfileDrawer/CustomProfile";
import CustomNavigation from "../Drawer/NavigationDrawer/CustomNavigation";
import CustomNavbar from "./Navbar";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import {
  searchForEmployer,
  setSearchTerm,
  setSearchedJobs,
} from "../../redux/Employer/searchSlice";
import { setIsLoading } from "../../redux/globalSlice";
import { globalNotificationsData } from "../../redux/notificationsSlice";

//CSS
import "./index.scss";

const MuiAppBar = styled(AppBar)({
  backgroundColor: "#fff",
  color: "#1A2946",
  padding: "4px 45px 4px 41px",
  borderBottom: "1px solid var(--oh-white-300-color)",
});

const Header = () => {
  let lsToken = localStorage.getItem("token");
  const [searchData, setSearchData] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname === "/search";
  const { notifications } = useSelector((state) => state.globalNotifications);

  const [notificationsCount, setNotificationsCount] = useState("");
  const { search } = useSelector(
    (state) => state.searchForEmployer
  );
  const { isPublicHeader, hideMenuBtn } = useSelector(
    (state) => state.app
  );
  const { profilePhoto } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isNotifyOpen, setNotifyOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const notificationsData = () => {
    if (lsToken) {
      dispatch(globalNotificationsData())
        .then((res) => {
          if (res.type === "globalNotificationsData/fulfilled") {
            setNotificationsCount(res?.payload?.unread_notifications);
          }
        })
        .catch((err) => {
          console.log(err.message, ".err");
        });
    }
  };
  useEffect(() => {
    if (!isPublicHeader) {
      notificationsData();
    }
  }, [isPublicHeader, notifications]);

  useEffect(() => {
    if (currentPath) {
      dispatch(searchForEmployer(""))
        .then((res) => {
          if (res.type === "searchForEmployer/fulfilled") {
            dispatch(setSearchedJobs(res?.payload?.data));
            dispatch(setIsLoading(false));
          }
        })
        .catch((err) => console.log(err));
    }
  }, [currentPath, searchData === ""]);

  const delayedDispatch = debounce((searchData) => {
    if (searchData.length > 1) {
      dispatch(setSearchTerm(searchData));
    }
    if (searchData.length < 1) {
      dispatch(setSearchTerm(""));
    }
  }, 500);

  useEffect(() => {
    delayedDispatch(searchData);
    return () => {
      delayedDispatch.cancel();
    };
  }, [currentPath && searchData]);

  // for profile drawer
  const handleProfileDrawer = () => {
    setProfileOpen(true);
  };

  const handleProfileClose = () => {
    setProfileOpen(false);
  };

  // for notification drawer
  const handleNotificationDrawer = () => {
    setNotifyOpen(true);
  };

  const handleNotifyClose = () => {
    setNotifyOpen(false);
  };

  const handleNavigate = () => {
    if (token) {
      window.open("https://octahire.com", '_blank')
    }
    else {
      window.location.href = "https://octahire.com"
    }
  };

  return (
    <>
      <MuiAppBar elevation={0}>
        <Toolbar className="oh-toolbar">
          <Box className="oh-left-headerBox">
            {
              !hideMenuBtn && (
                <IconButton
                  onClick={() => setMenuOpen((prev) => !prev)}
                  className="oh-menu-icon-btn"
                >
                  <img
                    className="hamburgerMenu-icon"
                    src={humburgerIcon}
                    alt=""
                  />
                </IconButton>
              )
              // )
            }
            <IconButton p={0} onClick={() => handleNavigate()} className="oh-icon-btn">
              <img className="main-logo" src={logo} alt="logo" width="120px"/>
            </IconButton>
            <MuiTypography
              className="oh-menu-nav"
              variant="h6"
              component="div"
              style={{ flexGrow: 1 }}
            >
              <CustomNavbar />
            </MuiTypography>
          </Box>
          <Box className="oh-header-rightBox">
            {!isPublicHeader && (
              <Box display={"flex"}>
                <Box className="search-box">
                  <TextField
                    value={searchData}
                    id="outlined-start-adornment"
                    placeholder="Search"
                    className="oh-search-textfields"
                    onChange={(e) => {
                      setSearchData(e.target.value);
                      dispatch(setSearchTerm(e.target.value));
                      if (location.pathname !== "/search") {
                        navigate("/search");
                      }
                    }}
                    onFocus={() => {
                      if (location.pathname !== "/search") {
                        navigate("/search");
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ cursor: "pointer" }}
                          position="end"
                        >
                          <img src={SearchIconImgCircle} alt={"search"} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  className="mobile-search-box"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/search")}
                  position="end"
                >
                  <img
                    src={SearchIconImg}
                    alt=""
                  />
                </Box>
                <Box
                  className="oh-notificationBell"
                  onClick={() => handleNotificationDrawer()}
                >
                  <Badge badgeContent={notificationsCount}>
                    <img src={notification} alt="" />
                  </Badge>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  className="humburgerMenu"
                  onClick={() => handleProfileDrawer()}
                >
                  <img
                    className="hamburgerMenu-icon"
                    src={humburgerIcon}
                    alt=""
                  />
                  <img
                    src={profilePhoto?.profilePhoto || profilePhoto || profile}
                    alt=""
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Toolbar>

        {isNotifyOpen && (
          <NotificationDrawer
            isNotifyOpen={isNotifyOpen}
            handleNotifyClose={handleNotifyClose}
          />
        )}
        {isProfileOpen && (
          <ProfileDrawer
            isProfileOpen={isProfileOpen}
            handleProfileClose={handleProfileClose}
          />
        )}

        {isMenuOpen && (
          <CustomNavigation
            staticPageHeader={isPublicHeader}
            open={isMenuOpen}
            onClose={() => setMenuOpen((prev) => !prev)}
          />
        )}
      </MuiAppBar>
      {location.pathname === "/search" && <div className="mobile-search-box-holder">
        <TextField
          value={search}
          id="outlined-start-adornment"
          placeholder="Search"
          className="oh-mobile-search-textfields"
          onChange={(e) => {
            setSearchData(e.target.value);
            dispatch(setSearchTerm(e.target.value));
            if (location.pathname !== "/search") {
              navigate("/search");
            }
          }}
          onFocus={() => {
            if (location.pathname !== "/search") {
              navigate("/search");
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                position="end"
                onClick={() => {
                  // handleSearch(searchData);
                  setSearchData("");
                  dispatch(setSearchTerm(""));
                }}
              >
                {searchData !== "" ? <CloseIcon sx={{ svg: { color: "var(--oh-blue-600-color) !important" } }} /> : <SearchIcon />}
              </InputAdornment>
            ),
          }}
        />
      </div>}
    </>
  );
};

export default Header;
