import { Box, CircularProgress, Stack } from "@mui/material";
import "./index.scss";

const Loader = () => {

    return (
        <Stack
            sx={{
                background: "var(--oh-white-200-color)",
                pointerEvents: "none",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 10
            }}
            width="100%"
            height={"100%"}
            display={'flex'}
            alignItems="center"
            justifyContent="center"

        >
            <Box sx={{
                background: "#fff",
                padding: "0px 40px 60px",
                borderRadius: "10px",
                color: "var(--oh-blue-600-color)",
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                flexDirection: "column",
                minHeight: "100px",
                padding: "30px",
                minWidth: "250px",
                fontWeight: 500,
                fontSize: "18px"
            }}>
                <CircularProgress sx={{
                    color: "var(--oh-blue-600-color)",
                    "button .MuiCircularProgress-indeterminate": {
                        width: "20px !important",
                        height: "20px !important",
                        color: "var(--oh-blue-300-color) !important"
                    }
                }} />
                <span style={{
                    color: "var(--oh-blue-600-color)", position: "relative",
                    zIndex: 0
                }}>Please wait...</span>
            </Box>
        </Stack >
    )
}

export default Loader;