import React, { useState } from "react";
import { useDispatch } from "react-redux";

// MUI 
import Grid from "@mui/material/Grid";

//ASSETS
import GoogleLogo from '../../assets/images/google.svg'
import FacebookLogo from '../../assets/images/facebookIcon.svg'

// OTHER
import MuiButtonOutlined from "../../mui/Buttons/MuiButtonOutlined";
import { googleUri } from "../../redux/googleAuthSlice";
import { Toaster } from "../../components/Toaster/Toaster";
import { fbUri } from "../../redux/fbAuthSlice";

// CSS
import "./index.scss";
import { returnRoleValue } from "../../utils";

const ThirdPartyAuth = () => {
    const [googleLoginLoader, setGoogleLoginLoader] = useState(false);
    const [fbLoginLoader, setFbLoginLoader] = useState(false);

    const dispatch = useDispatch();

    const handleGoogleLogin = () => {
        let data = {
            redirect_url: window.location.origin,
        }
        setGoogleLoginLoader(true);
        localStorage.setItem("auth_via", "google");

        dispatch(googleUri(data))
            .then(res => {
                setGoogleLoginLoader(false);
                if (res.type === "googleAuthUri/fulfilled") {
                    window.location.href = res.payload.url;
                }
            })
            .catch(err => {
                setGoogleLoginLoader(false);
                Toaster.TOAST(err.message, "error");
                console.log(err);
            })
    };

    const handleFacebookLogin = () => {
        let data = {
            redirect_url: "http://localhost:3000/"
        }
        setFbLoginLoader(true);
        localStorage.setItem("auth_via", "fb");

        dispatch(fbUri(data))
            .then(res => {
                if (res.type === "fbAuthUri/fulfilled") {
                    window.location.href = res.payload.url;
                }
                setFbLoginLoader(false);
            })
            .catch(err => {
                Toaster.TOAST(err.message, "error");
                console.log(err);
                setFbLoginLoader(false)
            })
    };

    return (
        <>
            <Grid className="oh-auth-btns-holder" pt={2} container>
                <Grid className='oh-google-login' item xs={12} md={12} lg={12}>
                    <MuiButtonOutlined
                        logo={<img className="sso-auth-logo" src={GoogleLogo} alt='' />}
                        title={'Login with Google'}
                        disabled={googleLoginLoader}
                        btnType={"submit"}
                        onClick={() => handleGoogleLogin()}
                    />
                </Grid>
                {/* <Grid
                    className='oh-facebook-login'
                    item
                    xs={12}
                    md={6}
                    lg={6}
                >
                    <MuiButtonOutlined
                        disabled={fbLoginLoader}
                        btnType={"submit"}
                        onClick={() => handleFacebookLogin()}
                        logo={<img src={FacebookLogo} alt='' />}
                        title={'Facebook'}
                    />
                </Grid> */}
            </Grid>
        </>
    );
};
export default ThirdPartyAuth;
