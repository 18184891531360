import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//OTHER
import { experienceArr } from '../../Data'
import SkillsTooltip from '../../components/Tooltip/SkillsChipTooltip'

//CSS
import './Dashboard.scss'

import { useDispatch, useSelector } from 'react-redux'
import {
  getAllCandidates,
  setACTLoader,
  setACTPage,
  setACTRowCount,
  setAllCandidates,
  setShowMessage
} from '../../redux/tableSlice'
import { Toaster } from '../../components/Toaster/Toaster'
import { downloadResumeApi } from '../../redux/JobSeeker/PersonalDetailSlice'
import { returnSubstring } from '../../utils'
import MuiTooltip from '../../mui/Tooltip/MuiTooltip'
import NoCandidatesDataInTable from '../../components/Table/NoCandidatesFound'
import ListingTableWithPagination from '../../components/Table/ListingTableWithPagination'
import MuiButtonOutlined from '../../mui/Buttons/MuiButtonOutlined'

const AllCandidateTable = ({ jobData = [], isDashboard = false }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    allCandidates,
    ACTPage,
    ACTFilter,
    ACTRowCount,
    ACTLoader,
    ACTPageSize
  } = useSelector(state => state.tableData)
  const params = useParams()
  const jobPostId = params.id

  const handlePageChange = newPage => {
    let page = newPage + 1
    dispatch(setACTPage(page))
  }

  const handleNavigate = (id, data) => {
    navigate(`/job-openings/overview/${id}`)
  }

  useEffect(() => {
    getAllCandidatesData()
  }, [ACTFilter, ACTPage, jobPostId])

  const getAllCandidatesData = () => {
    dispatch(getAllCandidates(params?.id))
      .then(res => {
        if (res.type === 'getAllCandidates/fulfilled') {
          dispatch(setAllCandidates(res.payload.data))
          dispatch(setACTRowCount(res.payload.meta.total_records))
          dispatch(setACTLoader(false))
        } else if (res.type === 'getAllCandidates/rejected') {
          dispatch(setACTLoader(false))
        }
      })
      .catch(err => {
        dispatch(setACTLoader(false))
        console.log(err)
      })
  }

  const handleDownload = (resume_name, value, userId, jobseekerId) => {
    dispatch(
      downloadResumeApi({
        id: userId,
        dataId: jobseekerId
      })
    )
      .then(res => {
        if (res.type === 'personal_details/download_resume/fulfilled') {
          const fileContent = res.payload
          if (!fileContent) {
            console.error('No file content received.')
            return
          }
          const fileBlob = new Blob([fileContent])
          const fileURL = window.URL.createObjectURL(fileBlob)
          const link = document.createElement('a')
          link.href = fileURL

          link.setAttribute('download', resume_name || 'Resume Doc')
          document.body.appendChild(link)
          link.click()
          Toaster.TOAST('Resume downloaded successfully', 'success')
          getAllCandidatesData()
        }
      })
      .catch(err => {
        console.log(err.message, '.err')
      })
  }

  const generateClassName = data => {
    let { is_viewed: viewed, is_reviewed: reviewed } = data
    return viewed ? 'row-blue' : reviewed ? 'row-gray' : 'row-white'
  }

  const headCells = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      minWidth: 150,
      flex: 1,
      cellClassName: params => generateClassName(params?.row?.attributes),
      renderCell: params => {
        return (
          <span
            style={{ cursor: 'pointer', textTransform: 'capitalize' }}
            onClick={() => handleNavigate(params.row?.id, params.row)}
          >
            {returnSubstring(
              params?.row?.attributes?.personal_details?.full_name,
              10
            ) || '-'}
          </span>
        )
      }
    },
    {
      field: 'designation',
      headerName: 'Designation',
      sortable: true,
      minWidth: 150,
      flex: 1,
      cellClassName: params => generateClassName(params?.row?.attributes),
      renderCell: params => {
        return (
          <span onClick={() => handleNavigate(params.row?.id)}>
            {params.row?.attributes?.personal_details?.designation ? (
              <MuiTooltip
                length={16}
                title={params.row?.attributes?.personal_details?.designation}
              />
            ) : (
              '-'
            )}
          </span>
        )
      }
    },
    {
      minWidth: 130,
      flex: 1,
      field: 'experiences',
      headerName: 'Experience',
      sortable: true,
      cellClassName: params => generateClassName(params?.row?.attributes),
      renderCell: params => {
        return (
          <span>
            {experienceArr?.[
              params?.row?.attributes?.personal_details?.experience
            ]?.label || '-'}
          </span>
        )
      }
    },
    {
      field: 'skill',
      headerName: 'Skills',
      minWidth: 180,
      flex: 1,
      sortable: true,
      cellClassName: params => generateClassName(params?.row?.attributes),
      renderCell: params => {
        return (
          <SkillsTooltip
            size='small-300'
            array={params.row?.attributes?.personal_details?.skills}
          />
        )
      }
    },
    {
      field: 'date',
      headerName: 'Applied Date',
      minWidth: 180,
      flex: 1,
      sortable: true,
      cellClassName: params => generateClassName(params?.row?.attributes),
      renderCell: params => {
        const applied_date =
          params.row?.attributes?.job_applications_status[0].applied_date
        return <span>{applied_date || '-'}</span>
      }
    },
    {
      minWidth: 180,
      flex: 1,
      field: 'cv',
      headerName: 'CVs',
      sortable: true,
      cellClassName: params => generateClassName(params?.row?.attributes),
      renderCell: params => {
        let resume_url = params.row?.attributes?.personal_details?.resume?.url
        let resume_name = params.row?.attributes?.personal_details?.resume_name
        return (
          <span
            onClick={() =>
              resume_url
                ? handleDownload(
                    resume_name,
                    resume_url,
                    params?.row?.attributes?.id,
                    params?.row?.attributes?.personal_details?.id
                  )
                : {}
            }
            className='cv-style'
          >
            {returnSubstring(resume_name, 18) || '-'}
          </span>
        )
      }
    },
    {
      minWidth: 100,
      flex: 1,
      field: 'status',
      headerName: 'Status',
      sortable: true,
      cellClassName: params => generateClassName(params?.row?.attributes),
      renderCell: params => {
        const status =
          params.row?.attributes?.job_applications_status?.[0]?.status
        return (
          <span style={{ textTransform: 'capitalize' }} className='status'>
            <span
              style={{
                color:
                  status === 'Task' || status === 'viewed'
                    ? '#34A853'
                    : status === 'Hold' || status === 'applied'
                    ? '#576EDB'
                    : status === 'Reject'
                    ? '#B71E58'
                    : '',
                fontWeight: '500'
              }}
            >
              {status || '-'}
            </span>
          </span>
        )
      }
    },
    {
      minWidth: 100,
      flex: 1,
      field: 'message',
      headerName: 'Message',
      sortable: true,
      cellClassName: params => generateClassName(params?.row?.attributes),
      renderCell: params => {
        return (
          <MuiButtonOutlined
            title='Chat'
            className='chat-message'
            // onClick={()=>dispatch(setShowMessage(true))}
          />
        )
      }
    }
  ]

  return (
    <ListingTableWithPagination
      hideFooterPagination={false}
      rows={allCandidates}
      columns={headCells}
      pageSize={ACTPageSize}
      rowCount={ACTRowCount}
      rowsPerPageOptions={[ACTPageSize]}
      page={ACTPage - 1}
      loading={ACTLoader}
      paginationMode='server'
      onPageChange={handlePageChange}
      customNoResultsComponent={() => <NoCandidatesDataInTable />}
    />
  )
}
export default AllCandidateTable
