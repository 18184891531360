import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  ConversationHeader,
  Avatar,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
  MessageSeparator,
  InputToolbox
} from '@chatscope/chat-ui-kit-react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'

import './chatBox.css'
import { Box, Divider, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as DotMenu } from '../../assets/images/dot-menu.svg'
import { ReactComponent as ArrowDown } from '../../assets/images/ArrowDown.svg'
import { ReactComponent as SendWhiteIcon } from "../../assets/images/SendIcon.svg";
import { ReactComponent as SendBlueIcon } from "../../assets/images/send.svg";
import { ReactComponent as BlockIcon } from '../../assets/images/block.svg'
import { ReactComponent as ReportIcon } from '../../assets/images/report.svg'
import { ReactComponent as MinimiseIcon } from '../../assets/images/minimize.svg'
import { ReactComponent as MaximiseIcon } from '../../assets/images/maximise.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg'

import ProfileImg from '../../assets/images/male.svg'
import ChatLayout from './ChatLayout'
import Popover from '@mui/material/Popover'
import MuiTypography from '../../mui/Typography/MuiTypograpghy'
import MuiButtonOutlined from '../../mui/Buttons/MuiButtonOutlined'
import { useLocation } from 'react-router-dom'
import { setShowMessage } from '../../redux/tableSlice'

const ChatBox = ({
  mainContainer = "",
  chatWrapper = "",
  handleMaximise=()=>{},
}) => {
  const [messageInputValue, setMessageInputValue] = useState("");

  const [messages, setMessages] = useState([
    {
      direction: 'incoming',
      message: 'Hello my friend',
      position: 'single',
      sender: 'Zoe',
      sentTime: '15 mins ago'
    },
    {
      direction: 'outgoing',
      message: 'Hello my friend',
      position: 'single',
      sender: 'Patrik',
      sentTime: '15 mins ago'
    }
  ])

  const [isTyping, setIsTyping] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [activeConversation, setActiveConversation] = useState('Zoe')
  const [showChat, setShowChat] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch();
  const { portalName } = useSelector(state => state.app)
  const { pathname } = useLocation();

  const layoutType =
    portalName === 'Jobseeker' && pathname == '/message'
      ? 'Jobseeker'
      : portalName === 'Employer' && pathname == '/message'
        ? 'Employer'
        : 'Simple'

        const conversations = useMemo(
    () => [
      {
        name: 'Zoe',
        info: 'Yes I can do it for you',
        src: 'https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg',
        status: 'available',
        unreadcount: 10
      },
      {
        name: 'John',
        info: 'Hey there',
        src: 'https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg',
        lastSeen: '1 day ago',
        status: 'unavailable',
        unreadcount: 2
      },
      {
        name: 'Protonshub',
        info: 'Lorem Ipsum is not simply random text',
        src: 'https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg',
        lastSeen: '1 day ago'
      }
    ],
    []
  )

  const filteredConversations = useMemo(() => {
    return conversations.filter(conversation =>
      conversation.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [searchTerm, conversations])

  // Resize handler for chat visibility
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 775) {
        setShowChat(false)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize() // Set initial state
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleSend = useCallback(() => {
    if (messageInputValue) {
      const newMessages = [
        ...messages,
        {
          direction: 'outgoing',
          message: messageInputValue,
          position: 'last',
          sender: 'Patrik',
          sentTime: 'Just now'
        }
      ];
      setMessages(newMessages);
      setMessageInputValue(""); // clear input
      setIsTyping(true);
      setTimeout(() => {
        setMessages([
          ...newMessages,
          {
            message: 'I am here!',
            direction: 'incoming',
            sender: activeConversation,
            sentTime: 'Just now',
            position: 'last'
          }
        ]);
        setIsTyping(false);
      }, 1000);
    }
  }, [messageInputValue, messages, activeConversation]);

  const handleBack = () => setShowChat(true)
  const handleOpen = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl)

  return (
    <>
      <ChatLayout layoutType={layoutType}>
        <div className={chatWrapper ? chatWrapper : 'chat-wrapper'}>
          <MainContainer className={mainContainer ? mainContainer : 'MainContainer'} responsive>
            <Sidebar
              position='left'
              className={`${showChat ? 'show-sidebar' : 'hide-sidebar'}`}
            >
              <Search
                className={`custom-placeholder ${showChat ? 'show-search' : 'hide-search'
                  }`}
                placeholder='Search messages'
                value={searchTerm}
                onChange={v => setSearchTerm(v)}
                onClearClick={() => setSearchTerm('')}
              />
              <ConversationList>
                {filteredConversations.map((conv, index) => (
                  <React.Fragment key={index}>
                    <Conversation
                      className={`${conv.name === activeConversation
                        ? 'active-conversation'
                        : ''
                        }`}
                      info={conv.info}
                      onClick={() => {
                        setActiveConversation(conv.name)
                        setShowChat(false)
                      }}
                      name={conv.name}
                      unreadCnt={conv.unreadcount > 9 ? '9+' : conv.unreadcount}
                      lastActivityTime='43 min'
                    >
                      <Avatar
                        name={conv.name}
                        src={conv.src}
                        onClick={() => setShowChat(false)}
                      />
                    </Conversation>
                    <Divider />
                  </React.Fragment>
                ))}
              </ConversationList>
            </Sidebar>

            <ChatContainer
              className={`${showChat ? 'hide-messageList' : 'show-messageList'
                }`}
            >
              <ConversationHeader>
                <ConversationHeader.Back onClick={handleBack} />
                <Avatar
                  name={activeConversation || 'Zoe'}
                  src='https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg'
                />
                <ConversationHeader.Content
                  info='Product Designer'
                  userName={activeConversation || 'Zoe'}
                />
                <ConversationHeader.Actions>
                  {pathname === "/message" ?
                    <DotMenu onClick={handleOpen} />
                    :
                    <Box className="multiple-actions">
                    <MinimiseIcon />
                    <MaximiseIcon onClick={handleMaximise}/>
                    <CloseIcon onClick={()=>dispatch(setShowMessage(false))}/>
                    </Box>
                  }
                </ConversationHeader.Actions>
              </ConversationHeader>

              <MessageList
                typingIndicator={
                  isTyping ? (
                    <TypingIndicator
                      content={`${activeConversation} is typing`}
                    />
                  ) : null
                }
              >
                <Box className='chat-profile-box'>
                  <Avatar src={ProfileImg} className='profile-image' />
                  <Typography className='user-name'>
                    {activeConversation}
                  </Typography>
                  <Typography className='chat-designation'>
                    Product Designer
                  </Typography>
                  <Typography className='chat-description'>
                    This is the very beginning of your direct message with{' '}
                    <span className='organisation'>Protonshub</span>
                  </Typography>
                  <Box className='chat-button-box'>
                    <MuiButtonOutlined
                      className='reject-button'
                      type='button'
                      title='Reject'
                    />
                    <MuiButtonOutlined
                      className='accept-button'
                      type='button'
                      title='Accept'
                    />
                  </Box>
                </Box>
                <MessageSeparator>
                  <span className='separator-content'>
                    <ArrowDown />
                    Today
                  </span>
                </MessageSeparator>
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`message-container ${msg.direction}`}
                  >
                    <Message model={msg}>
                      <Avatar
                        name={msg.sender}
                        src='https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg'
                      />
                      <Message.Header>{msg.sender}</Message.Header>
                      <Message.TextContent>{msg.message}</Message.TextContent>
                      <Message.Footer>{msg.sentTime}</Message.Footer>
                    </Message>
                  </div>
                ))}
              </MessageList>

              <MessageInput
                placeholder='Reply message'
                sendButton={false}
                attachButton={false}
                value={messageInputValue} // controlled input
                onChange={val => setMessageInputValue(val)}
                onSend={() => handleSend()}
              />
              <InputToolbox
                className={`input-toolbox ${!messageInputValue ? 'disabled' : ''}`}
                // className="input-toolbox" 
                onClick={() => handleSend()} >
                {pathname === "/message" ?
                  <SendWhiteIcon />
                  :
                  <SendBlueIcon />}
              </InputToolbox>
            </ChatContainer>
          </MainContainer>
        </div>
      </ChatLayout>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box className='dot-menu-popover'>
          <MuiTypography className='oh-sort-menu-item' onClick={handleClose}>
            <BlockIcon /> Report
          </MuiTypography>
          <MuiTypography className='oh-sort-menu-item' onClick={handleClose}>
            <ReportIcon /> Block
          </MuiTypography>
        </Box>
      </Popover>
    </>
  )
}

export default ChatBox
