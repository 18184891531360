import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";
import {
  JOB_POST,
  JOB_SEEKER_APPLICATION,
  JOB_SEEKER_DETAIL,
  SAVED_JOBS,
  UNSAVED_JOBS,
} from "../../apis/Constant/routes";

const initialState = {
  appliedJobs: [],
  allActiveJobs: [],
  topHiringJobs: [],
  suggestedJobs: [],
  showAppliedJobDetail: {},
  jobseekerDataById: {},
  JobsPage: 1,
  JobsPageSize: 20,
  JobsRowCount: null,
  JobsSortFilter: "asc",
};
//job_posts/?filter_by[suggested_jobs]=true
export const getAppliedJobs = createAsyncThunk(
  "getAppliedJobs",
  async (data, thunkapi) => {
    const {
      JobsPage: page,
      JobsPageSize: pageSize,
      JobsSortFilter: filter,
    } = thunkapi.getState().appliedJobs;
    let pageHeader = `?page=${page}&per_page=${pageSize}`;
    let sortHeader = `&search[sort_attribute]=job_title&search[sort_type]=${filter}`;
    return await AxiosApi({
      path: `${JOB_SEEKER_APPLICATION}${pageHeader}${sortHeader}`,
      type: "GET",
      params: "",
    });
  }
);

export const getTopHiringJob = createAsyncThunk(
  "getTopHiringJob",
  async (data, thunkapi) => {
    const {
      JobsPage: page,
      JobsPageSize: pageSize,
      JobsSortFilter: filter,
    } = thunkapi.getState().appliedJobs;
    let pageHeader = `?page=${page}&per_page=${pageSize}`;
    let sortHeader = `&search[sort_attribute]=job_title&search[sort_type]=${filter}`;
    return await AxiosApi({
      path: `${JOB_SEEKER_DETAIL}/top_hiring${pageHeader}${sortHeader}`,
      type: "GET",
      params: "",
    });
  }
);

export const showAppliedJob = createAsyncThunk("showAppliedJob", async (id) => {
  return await AxiosApi({
    path: `${JOB_POST}/${id}`,
    type: "GET",
    params: "",
  });
});

export const jobApply = createAsyncThunk("jobApply", async ({ data }) => {
  return await AxiosApi({
    path: `${JOB_SEEKER_APPLICATION}`,
    type: "POST",
    data: data,
  });
});

export const getAllActiveJobs = createAsyncThunk(
  "getAllActiveJobs",
  async (data, thunkapi) => {
    const {
      JobsPage: page,
      JobsPageSize: pageSize,
      JobsSortFilter: filter,
    } = thunkapi.getState().appliedJobs;
    let pageHeader = `&page=${page}&per_page=${pageSize}`;
    let sortHeader = `&search[sort_attribute]=job_title&search[sort_type]=${filter}`;
    return await AxiosApi({
      path: `/job_posts/?search[is_active]=true${pageHeader}${sortHeader}`,
      type: "GET",
      params: "",
      toAppendAuthParams: false,
    });
  }
);

export const getSuggestedJobs = createAsyncThunk(
  "getSuggestedJobs",
  async (toAppendAuthParams) => {
    return await AxiosApi({
      path: "/job_posts/?filter_by[suggested_jobs]=true",
      type: "GET",
      params: "",
      toAppendAuthParams,
    });
  }
);

export const getJobseekerDataById = createAsyncThunk(
  "getJobseekerDataById",
  async (id) => {
    return await AxiosApi({
      path: `/jobseekers/${id}`,
      type: "GET",
      params: "",
    });
  }
);

export const allJobsSlice = createSlice({
  name: "allJobs",
  initialState,
  reducers: {
    setAppliedJobs: (state, action) => {
      state.appliedJobs = action.payload;
    },
    setAllActiveJobs: (state, action) => {
      state.allActiveJobs = action.payload;
    },
    setTopHiringJobs: (state, action) => {
      state.topHiringJobs = action.payload;
    },
    setSuggestedJobs: (state, action) => {
      state.suggestedJobs = action.payload;
    },
    setShowAppliedJobDetail: (state, action) => {
      state.showAppliedJobDetail = action.payload;
    },
    setJobseekerDataById: (state, action) => {
      state.jobseekerDataById = action.payload;
    },
    clearJobseekerDataById: (state, action) => {
      state.jobseekerDataById = null;
    },
    setJobsPage: (state, action) => {
      state.JobsPage = action.payload;
    },
    setJobsPageSize: (state, action) => {
      state.JobsPageSize = action.payload;
    },
    setJobsRowCount: (state, action) => {
      state.JobsRowCount = action.payload;
    },
    setJobsSortFilter: (state, action) => {
      state.JobsSortFilter = action.payload;
    }
  },
});

export const saveJob = createAsyncThunk("create-saveJob", async ({ data }) => {
  return await AxiosApi({
    path: `${SAVED_JOBS}`,
    type: "POST",
    data: data,
  });
});

export const unsaveJob = createAsyncThunk("unsave-job", async ({ data }) => {
  return await AxiosApi({
    path: `${UNSAVED_JOBS}`,
    type: "DELETE",
    data: data,
  });
});



export default allJobsSlice.reducer;
export const {
  setAppliedJobs,
  setAllActiveJobs,
  setTopHiringJobs,
  setSuggestedJobs,
  setShowAppliedJobDetail,
  setJobseekerDataById,
  clearJobseekerDataById,
  setJobsPage,
  setJobsPageSize,
  setJobsRowCount,
  setJobsSortFilter
  
} = allJobsSlice.actions;
