import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosApi } from '../apis/AxiosApi'
import { USERS } from '../apis/Constant/routes'

const initialState = {
  email: '',
  mobile_number: '',
}

export const resendBothOtps = createAsyncThunk(
  'resendBothOtps',
  async (data) => {
    return await AxiosApi({
      path: `${USERS}/confirmation/resend_otp`,
      type: 'POST',
      data: JSON.stringify({ data: data })
      //   isLoader: false
    })
  }
)

export const resendBothOtpsSlice = createSlice({
  name: 'resendBothOtpsSlice',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setMobileNumber:(state,action)=>{
        state.mobile_number = action.payload
    },
  },
})

export default resendBothOtpsSlice.reducer
export const { setEmail, setMobileNumber } = resendBothOtpsSlice.actions
