import React from "react";
//MUI
import Typography from "@mui/material/Typography";

const MuiTypography = (props) => {
    return (
        <Typography
            variant={props.variant}
            component={props.component}
            className={props.className}
            sx={props.style}
            color={props.color}
            onClick={props.onClick}
            disabled={props.disabled}
            {...props.href && { href: props.href }}
        >
            {props.content || props.children}
        </Typography>
    )
}

export default MuiTypography;