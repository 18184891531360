import React from 'react'

// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// OTHER
import Header from '../../components/Header/Header';
import { SwiperSlide } from 'swiper/react';
import SignupCoroussel from '../../swiper/SignupCoroussel';

// ASSETS
import Map from '../../assets/images/globalMap.png';
import HiringConcept from "../../assets/images/hiring_concept_collage.png";

// CSS
import "./index.scss";

const SignupLayout = ({ children }) => {
  const renderImgSrc = () => HiringConcept;

  return (
    <>
      <Header />
      <Box className='oh-signup-layout'>
        <Grid container className='oh-layout-grid'>

          <img
            className={"oh-cover-layout"}
            src={Map}
            alt={''}
          />

          <Grid item xs={10} md={7} lg={7}>
            {children}
          </Grid>
          <Grid item xs={"auto"} md={4} lg={5}>
            <div className='oh-layout-img'>
              <SignupCoroussel>
                <div className='oh-layout-signup-img'>
                  <SwiperSlide>
                    <div className='carousel-item'>
                      <img src={renderImgSrc()} className="oh-layout-side-img" alt='' />
                      <p className="text-overlay">
                        Join us today & lead the way to a digital revolution
                      </p>
                    </div>
                  </SwiperSlide>
                </div>
                <div className='oh-layout-signup-img'>
                  <SwiperSlide>
                    <div className='carousel-item'>
                      <img src={renderImgSrc()} className="oh-layout-side-img" alt='' />
                      <p className="text-overlay">
                        Drive Your Success With Us! 5 lakh+ jobs for you to explore
                      </p>
                    </div>
                  </SwiperSlide>
                </div>
                <div className='oh-layout-signup-img'>
                  <SwiperSlide>
                    <div className='carousel-item'>
                      <img src={renderImgSrc()} className="oh-layout-side-img" alt='' />
                      <p className="text-overlay">
                        {/*  */}
                        At <b>Octahire</b>, get quality applies, unlimited calls directly from the candidates, access to the database of over 3.5 Crore candidates andlots more.
                        {/*  */}
                      </p>
                    </div>
                  </SwiperSlide>
                </div>
              </SignupCoroussel>

            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default SignupLayout;