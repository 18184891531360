import React from 'react';

const InfoItem = ({ label, value }) => (
    <div
    className='other-info-div'
        style={{
            display: "flex",
            color: "var(--oh-blue-300-color)",
            fontSize: "14px",
            margin: "5px 0px !important",
            gap: "5px",
            alignItems: "center",
            flexWrap: "wrap",
        }}
    >
        <span className='other-info-span'>
            <b>{label}</b>
        </span>
        <span className="other-description-employer">{value}</span>
    </div>
);

const InfoBox = ({ title, items }) => (
    <>
        {items.map((item, index) => (
            <InfoItem key={index} label={item.label} value={item.value} />
        ))}
    </>
);

export default InfoBox;
