import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";

export const selectionRounds = createAsyncThunk(
  "selectionRounds",
  async (data) => {
    return await AxiosApi({
      path: "/selection_rounds",
      type: "POST",
      data: data,
    });
  }
);
export const updateSelectionRounds = createAsyncThunk(
  "updateSelectionRounds",
  async ({ data, selectionId, jobPostId }) => {
    return await AxiosApi({
      path: `/selection_rounds/${selectionId}?job_post_id=${jobPostId}`,
      type: "PUT",
      data: data,
    });
  }
);

export const getSelectionRounds = createAsyncThunk(
  "getSelectionRounds",
  async (id) => {
    return await AxiosApi({
      path: `/selection_rounds?job_post_id=${id}`,
      type: "GET",
      data: "",
    });
  }
);

const selectionRoundsSlice = createSlice({
  name: "selectionRounds",
  initialState: {
    data: null, // Initial state for the response data
    loading: false,
    error: null,
  },
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectionRounds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(selectionRounds.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(selectionRounds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const { resetSelectionRounds } = selectionRoundsSlice.actions;
export default selectionRoundsSlice.reducer;
