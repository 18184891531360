import React from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const FilterIconWithBadge = ({ filter, value, icon = null, isFilterActive = null }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {
        icon || <FilterAltIcon sx={{ width: '16px', marginRight: "4px" }} />
      }

      {(isFilterActive || filter !== value) && (
        <div
          style={{
            position: 'absolute',
            top: '5px',
            right: '6px',
            width: '8px',
            height: '8px',
            backgroundColor: 'red',
            borderRadius: '50%',
            border: '1px solid #fff',
            transform: 'translate(50%, -50%)',
          }}
        />
      )}
    </div>
  );
};

export default FilterIconWithBadge;
