import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
//MUI
import Box from "@mui/material/Box";

//OTHER
import Sidebar from "../../../components/Sidebar/Sidebar";
import MuiBreadcrumb from "../../../mui/BreadCrumbs/MuiBreadcrumbs";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import JobInfoCard from "../../../components/Cards/JobSeeker/JobInfoCard";

// CSS
import "../layout.scss";
import { Toaster } from "../../../components/Toaster/Toaster";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import NotAuthorized from "../../../components/NotFound/NotAuthorized";
import { setNotAuthorized } from "../../../redux/globalSlice";
import Header from "../../../components/Header/Header";
import {
  getSuggestedJobs,
  setSuggestedJobs,
} from "../../../redux/JobSeeker/jobSeekerJobsSlice";
import NoJobsFound from "../../../components/NotFound/NoJobsFound";
import JobPostCard from "../../../components/JobPostBox/JobPostCard";
import Layout from "../../../landingPage/components/Layout/Layout";

const GlobalJobSeekerLayout = ({ children, searchParam }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const suggestedJobs = useSelector((state) => state.appliedJobs.suggestedJobs);

  const { portalName, portalValue } = useSelector((state) => state.app);

  useEffect(() => {
    localStorage.removeItem("form_type");
    localStorage.removeItem("auth_via");
    getSuggestedJobsData();
  }, []);

  const getSuggestedJobsData = () => {
    const authParams = false;
    dispatch(getSuggestedJobs(authParams))
      .then((res) => {
        if (res.type === "getSuggestedJobs/fulfilled") {
          dispatch(setSuggestedJobs(res?.payload?.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleNavigate = (id) => {
    navigate(`/search-jobs/jobDetails/${id}?from=landingPage`, {
      replace: true,
    });
  };
  return (
    <Layout>
      {/* <LandingPageHeader /> */}
      <Box container className="oh-globaljobseeker-background-setting">
        {/* <Sidebar className="primary-navigation-bar" title="Job Seeker" /> */}

        <Box>
          {location.pathname !== "/jobseeker" &&
            searchParam !== "?from=landingPage" && <MuiBreadcrumb />}
          {children}
        </Box>

        <Box
          className="oh-cards-section"
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <MuiDivider className="oh-vertical-divider" orientation="vertical" />
          <Box flex="1">
            <Box>
              <MuiTypography
                content="Jobs you might be interested"
                className="oh-heading"
              />
            </Box>
            <Box
              className="scrollable-section"
              sx={{
                width: "100%",
                paddingLeft: { xs: 0, sm: 0, md: "15px" },
                flexDirection: "row",
                flexWrap: "wrap",
                position: "sticky",
                top: "100px",
                height:
                  suggestedJobs?.length > 0 ? "calc(100vh - 140px)" : "auto",
                overflow: "auto",
                // paddingRight: "10px",
              }}
            >
              {suggestedJobs.length > 0 ? (
                suggestedJobs.map((elem, idx) => {
                  return (
                    <JobPostCard
                      cardType="globalSuggestedJobs"
                      data={elem.attributes}
                      handleNavigate={() => {
                        handleNavigate(elem?.id);
                      }}
                      // onClick={() => handleNavigate(elem?.attributes?.id)}
                      // onApplyClick={() => handleNavigate(elem?.attributes?.id)}
                      footerText={false}
                      timestamp={true}
                      size="medium-100"
                      updateData={suggestedJobs}
                      setUpdateData={(updatedJobs) => dispatch(setSuggestedJobs(updatedJobs))}
                    />
                  );
                })
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <NoJobsFound value={"No jobs at the moment"} height={"80%"} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default GlobalJobSeekerLayout;
