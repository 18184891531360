import React from "react";
//MUI
import Box from "@mui/material/Box";

//OTHER
import NotificationItem from "./NotificationItem";
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";
import MuiSwitch from "../../../../mui/Switch/MuiSwitchToggle";

//CSS
import "./SettingNotification.scss";

const SettingNotification = () => {
    let data = [
        {
            title: "Email Notification",
            description: " Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took",
            icon: <MuiSwitch checked={true} />
        },
        {
            title: "Website Notification",
            description: " Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took",
            icon: <MuiSwitch checked={true} />
        },
        {
            title: "Mobile Notification",
            description: " Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took",
            icon: <MuiSwitch checked={false} />
        },
        {
            title: "Job Allotted",
            description: " Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took",
            icon: <MuiSwitch checked={false} />
        },

    ]
    return (
        <>
            <Box sx={{ margin: "0px 15px" }} className="oh-settingbox">
                <MuiTypography className="oh-heading" content="Notification" />
                <Box className="oh-main-boxes">
                    {
                        data && data.map((elem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <NotificationItem title={elem.title} description={elem.description} icon={elem.icon} />
                                    <hr className="hr-border" />
                                </React.Fragment>
                            )
                        })
                    }
                </Box>
            </Box>
        </>
    )
}

export default SettingNotification;