import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

//OTHER
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import MuiDivider from "../../mui/Dividers/MuiDivider";
import MuiButtonOutlined from "../../mui/Buttons/MuiButtonOutlined";
import MuiTextField from "../../mui/TextField/MuiTextField";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import { Toaster } from "../../components/Toaster/Toaster";
import FieldError from "../../components/Errors/FieldError";
import {
  addDetailApi,
  deleteDetailApi,
  editDetailApi,
  getDetailApi,
} from "../../redux/Employer/CommunicationDetailSlice";
import { EMAIL_REGEX, NUM_REGEX } from "../../utils/regex";

//CSS
import "./index.scss";
import DeletePopup from "../../components/Popup/Delete/DeletePopup";
import ProfileLayout from "./ProfileLayout";
import { smoothScroll } from "../../utils";

const CommunicationDetails = () => {
  const [fields, setFields] = useState([
    { id: null, email: "", mobile_number: "", errEmail: "", errMobile: "" },
  ]);
  const [disableMobile, setDisableMobile] = useState(true);
  const [editIndex, setEditIndex] = useState(null);
  const [newFieldsAdded, setNewFieldsAdded] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const editFieldRef = useRef();
  let newFields = fields.slice(1);
  let newData = newFields.filter((field) => !field.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let id = JSON.parse(localStorage.getItem("login_data")).id;
  let user_data = useSelector((state) => state.auth.user);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(getDetailApi({ id }))
      .then((res) => {
        if (res.type === "communication_details/list/fulfilled") {
          const communicationDetails = res.payload.data || [];
          const apiFields = communicationDetails.map((detail) => ({
            id: detail.id,
            email: detail.attributes.email || "",
            mobile_number: detail.attributes.mobile_number || "",
          }));
          setDisableMobile(
            !res.payload.data[0]?.attributes?.mobile_number ||
              res.payload.data[0]?.attributes?.mobile_number == ""
              ? true
              : false
          );
          setFields([
            // {
            //   id: user_data.id,
            //   email: user_data.email || "",
            //   mobile_number: user_data.mobile_number || "",
            // },
            ...apiFields,
          ]);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const handleChange = (index, field, value) => {
    let fieldData = [...fields];
    fieldData[index][field] = value;

    if (field === "email") {
      if (!value) {
        fieldData[index].errEmail = `Email ID can't be empty`;
      } else if (!EMAIL_REGEX.test(value)) {
        fieldData[index].errEmail = `Email ID is invalid`;
      } else {
        fieldData[index]["errEmail"] = "";
      }
    }
    setEmailErr(fieldData[index].errEmail);

    if (field === "mobile_number") {
      if (!value) {
        fieldData[index].errMobile = `Mobile Number can't be empty`;
      } else if (!/^(?:\+91)?(?!0{8,12})[0-9]{10,13}$/.test(value)) {
        fieldData[index].errMobile = `Mobile Number is invalid`;
      } else {
        fieldData[index]["errMobile"] = "";
      }
    }
    setMobileErr(fieldData[index].errMobile);
    setFields(fieldData);
  };

  const handleAddField = () => {
    setFields([...fields, { email: "", mobile_number: "", type: "new" }]);
    setNewFieldsAdded(true);
    setIsFormSubmitted(false);
  };

  const handleClose = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleEdit = (index) => {
    if (fields[index]?.id) {
      setEditIndex(index);
    }
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setIsDelete(true);
  };

  const handleSubmit = () => {
    const newFields = fields.slice(1);
    const arrData = newFields.filter((field) => !field.id);

    let data = {
      data: arrData,
    };

    let editdata = {
      data: {
        email: fields[editIndex]?.email,
        mobile_number: fields[editIndex]?.mobile_number,
      },
    };
    if (!emailErr && !mobileErr) {
      setLoading(true);
      if (editIndex && newFieldsAdded) {
        handleUpate(editdata);
        handleCreate(data);
        setLoading(false);
      } else if (editIndex) {
        handleUpate(editdata);
        setLoading(false);
      } else {
        handleCreate(data);
        setLoading(false);
      }
      getData();
    }
  };

  const handleUpdate = () => {
    let data = {
      data: {
        email: fields[0]?.email,
        mobile_number: fields[0]?.mobile_number,
      },
    };
    if (!emailErr && !mobileErr) {
      handleUpate(data, fields[0]?.id);
      setLoading(false);
    }
    getData();
  };

  const handleCreate = (data) => {
    dispatch(addDetailApi({ data, id }))
      .then((res) => {
        if (res.type === "communication_details/fulfilled") {
          Toaster.TOAST(res.payload?.message, "success");
          setLoading(false);
          setIsFormSubmitted(true);
          getData();
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const handleUpate = (data, fieldId = null) => {
    const dataId = fieldId || fields[editIndex]?.id;
    dispatch(editDetailApi({ id, dataId, data }))
      .then((res) => {
        if (res.type === "communication_details/edit/fulfilled") {
          smoothScroll();
          Toaster.TOAST(res.payload?.message, "success");
          setIsFormSubmitted(true);
          setLoading(false);
          getData();
        } else if (res.type === "communication_details/edit/rejected") {
          smoothScroll();
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const handleYes = () => {
    const dataId = fields[deleteIndex]?.id;
    const updatedFields = [...fields];
    setLoading(true);
    dispatch(deleteDetailApi({ dataId, id }))
      .then((res) => {
        if (res.type === "communication_details/delete/fulfilled") {
          updatedFields.splice(deleteIndex, 1);
          setFields(updatedFields);
          setIsDelete(false);
          setLoading(false);
          Toaster.TOAST(res?.payload.message, "success");
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const handleNo = () => {
    setIsDelete(false);
  };

  return (
    <ProfileLayout>
      <Box className="oh-signup-form" autoComplete="off">
        <Box container className="oh-details-container">
          <MuiTypography
            className="oh-details-label"
            content="Communication Details"
          />
          <Grid className="oh-details-form oh-communication-details" container>
            {fields.map((field, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={4} lg={4}>
                  <PrimaryLabel label="Email ID" labelType="secondary" />
                  <MuiTextField
                    ref={editFieldRef}
                    id={`email_${index}`}
                    type="email"
                    placeholder="Enter here Email ID"
                    name="email"
                    value={field.email}
                    onChange={(e) =>
                      handleChange(index, "email", e.target.value)
                    }
                    disabled={
                      index === 0 ||
                      (editIndex === null
                        ? field.id
                        : field?.type === "new"
                        ? false
                        : index !== editIndex)
                    }
                    className={
                      (index === 0 ||
                        (editIndex === null
                          ? field.id
                          : field?.type === "new"
                          ? false
                          : index !== editIndex)) &&
                      "text-feilds"
                    }
                  />
                  {field.errEmail && (
                    <FieldError
                      sx={{ margin: "5px 0px 5px 20px", height: "5px" }}
                      errorString={field.errEmail}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <PrimaryLabel label="Mobile Number" labelType="secondary" />
                  <MuiTextField
                    id={`mobile_number_${index}`}
                    type="text"
                    placeholder="Enter here active mobile number"
                    name="mobile_number"
                    value={field.mobile_number}
                    onChange={(e) =>
                      handleChange(index, "mobile_number", e.target.value)
                    }
                    disabled={
                      index === 0 && user_data?.auth_provider && disableMobile
                        ? false
                        : editIndex === null
                        ? field.id
                        : field?.type === "new"
                        ? false
                        : index !== editIndex
                    }
                    className={
                      (index === 0 && user_data?.auth_provider && disableMobile
                        ? false
                        : editIndex === null
                        ? field.id
                        : field?.type === "new"
                        ? false
                        : index !== editIndex) && "text-feilds"
                    }
                  />

                  {field.errMobile && (
                    <FieldError
                      sx={{ margin: "5px 0px 5px 20px", height: "5px" }}
                      errorString={field.errMobile}
                    />
                  )}
                </Grid>
                {user_data?.auth_provider && disableMobile && index === 0 && (
                  <Grid
                    item
                    xs={10}
                    md={2}
                    lg={2}
                    className="oh-update-section"
                  >
                    <MuiButtonOutlined
                      style={{
                        padding: "10px",
                        transform: "translateY(6px)",
                      }}
                      className="oh-add-btn"
                      title="Update"
                      onClick={() => handleUpdate()}
                    />
                  </Grid>
                )}
                {index !== 0 && field.id ? (
                  <Grid
                    item
                    xs={12}
                    md={2}
                    lg={2}
                    className="oh-update-section"
                  >
                    <IconButton className="oh-edit">
                      <EditIcon
                        className="oh-edit-btn"
                        onClick={() => handleEdit(index)}
                      />
                      <span className="label-txt">Edit</span>
                    </IconButton>
                    <IconButton className="oh-delete">
                      <DeleteIcon
                        className="oh-delete-btn"
                        onClick={() => handleDelete(index)}
                      />
                      <span className="label-txt">Delete</span>
                    </IconButton>
                  </Grid>
                ) : (
                  index !== 0 &&
                  newData.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      md={2}
                      lg={2}
                      className="oh-update-section"
                    >
                      <IconButton
                        className="oh-close"
                        onClick={() => handleClose(index)}
                      >
                        <CloseIcon className="oh-close-btn" />
                        <span className="label-txt">Clear</span>
                      </IconButton>
                    </Grid>
                  )
                )}
              </React.Fragment>
            ))}
          </Grid>
          <Grid container>
            <Grid item xs={12} md={2} lg={2}>
              <MuiButtonOutlined
                style={{
                  padding: "12px 10px",
                  margin: "10px 0px",
                  width: "100%",
                }}
                // className="oh-add-btn"
                className="addIcon"
                logo={<AddIcon />}
                title="Add"
                onClick={handleAddField}
              />
            </Grid>
          </Grid>
        </Box>
        <MuiDivider className="oh-details-divider" />
        <CustomLoadingButton
          disabled={!(editIndex || newFieldsAdded)}
          style={!(editIndex || newFieldsAdded) ? { opacity: 0.5 } : {}}
          loading={loading}
          type="button"
          title={"Submit"}
          className="oh-submit-btn"
          onClick={() => handleSubmit()}
        />
      </Box>
      {isDelete && (
        <DeletePopup
          loading={loading}
          isDelete={isDelete}
          handleYes={handleYes}
          handleNo={handleNo}
          heading={"Delete Detail ?"}
          description={"Do you really want to delete this detail ?"}
        />
      )}
    </ProfileLayout>
  );
};

export default CommunicationDetails;
