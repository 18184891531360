import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import "./helpAndSupport.scss";
import MuiTypography from "../mui/Typography/MuiTypograpghy";
import CustomLoadingButton from "../components/Loader/LoadingButton";
import { Form, Formik, useField, useFormikContext } from "formik";
import MuiTextField from "../mui/TextField/MuiTextField";
import FieldError from "../components/Errors/FieldError";
import * as Yup from "yup";
import { errMessageMaximum } from "../utils";
import { EMAIL_REGEX, MOBILE_NUMBER_REGEX_2 } from "../utils/regex";
import TelephoneIcon from "../assets/images/Telephone.svg";
import SendMessage from "../assets/images/Send_message.svg";
import { useDispatch } from "react-redux";
import { helpAndSupport } from "../redux/help&supportSlice";
import { Toaster } from "../components/Toaster/Toaster";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import LandingPageHeader from "../landingPage/components/header/header";
import Footer from "../landingPage/components/footer/Footer";

function HelpAndSupport() {
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState({
    fullName: "",
    email: "",
    mobile_number: "",
    message: "",
  });
  const OtherDetailSchema = Yup.object().shape({
    fullName: Yup.string(),
    email: Yup.string()
      .matches(EMAIL_REGEX, "Please enter a valid email Id")
      .max(55, errMessageMaximum("Email", 55)),
    mobile_number: Yup.string()
      .matches(MOBILE_NUMBER_REGEX_2, "Please enter a valid Mobile number")
      .max(10, "Mobile number must be exactly 10 characters")
      .required("Mobile number can't be empty"),
    message: Yup.string(),
  });

  const handleSubmit = (values, setSubmitting, props) => {
    const data = {
      full_name: values.fullName,
      email: values.email,
      mobile_number: values.mobile_number,
      message: values.message,
    };
    dispatch(helpAndSupport({ data: data }))
      .then((res) => {
        if (res.type === "helpAndSupport/fulfilled") {
          Toaster.TOAST(res.payload?.message, "success");
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
    props.resetForm();
    setSubmitting(false);
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 10px 19px;
    resize: none !important;
    border-radius: 35px;
    &:focus-visible {
      outline: 0;
    }
    &::placeholder {
      color: #A9A9A9	;
    }
  `
  );
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
        }}
      >
        <LandingPageHeader />
        <Box className="oh-heading-container">
          {" "}
          <Grid
            container
            spacing={2}
            style={{ justifyContent: "center", padding: "0 10px" }}
          >
            <Grid item md={12}>
              <Typography
                fontWeight={700}
                fontSize={{ sx: "20px", md: "32px" }}
              >
                Help & Support
              </Typography>
              <Typography
                fontWeight={600}
                fontSize={{ sx: "14px", md: "16px" }}
              >
                If you have any questions regarding Octahire, please email us at{" "}
                <a
                  style={{
                    fontWeight: 600,
                    background:
                      "-webkit-gradient(linear, left top, right top, from(#576EDB), to(#8957DB))",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    MozTextFillColor: "transparent",
                    WebkitBoxDecorationBreak: "clone",
                    boxDecorationBreak: "clone",
                    textShadow: "none",
                    textDecoration: "none",
                  }}
                  href="mailto:support@octahire.com"
                >
                  support@octahire.com
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box p={6} className="main-section">
          <Grid px={2} container spacing={{ xs: 0, md: 2 }} className="ms-grid">
            {/* <Grid
              item
              md={6}
              xs={12}
              order={{ xs: 2, md: 1 }}
              className="ms-grid-item"
            >
              <Box
                px={4}
                pt={4}
                borderRadius={2}
                border={"1px solid #E5E4E2"}
                minHeight={"100%"}
                className="ms-gi-box"
              >
                <Typography
                  fontSize={{ xs: "24px", md: "32px" }}
                  color={"#445386"}
                  fontWeight={700}
                >
                  {" "}
                  For Help & Support
                </Typography>
                <Typography
                  fontWeight={400}
                  fontSize={"14px"}
                  color={"#6171a5"}
                  pt={2}
                >
                  Call us and speak to our team Monday to Friday from 10am to
                  7pm
                </Typography>
                <Typography
                  fontWeight={400}
                  fontSize={"14px"}
                  color={"#6171a5"}
                >
                  *Local costs apply depending on your service provider
                </Typography>
                <Box display="flex" alignItems="center" pt={6}>
                  <img src={SendMessage} alt="send-icon" />
                  <Typography
                    variant="body1"
                    component="a"
                    href="mail:support@octahire.com"
                    color="#445386"
                    pl={1}
                    fontSize={"20px"}
                    fontWeight={400}
                    sx={{ textDecoration: "underline" }}
                  >
                    support@octahire.com
                  </Typography>
                </Box>
                <Typography
                  display={"inline-block"}
                  variant="body1"
                  component="a"
                  href="mailto:info@octahire.com"
                  color="#445386"
                  fontSize={"20px"}
                  fontWeight={400}
                  pl={4}
                  pt={2}
                  sx={{ textDecoration: "underline" }}
                >
                  info@octahire.com
                </Typography>
                <Box display="flex" alignItems="center" pt={4}>
                  <img src={TelephoneIcon} alt="send-icon" />
                  <Typography
                    variant="body1"
                    component="a"
                    color="#445386"
                    href="tel:+91999XXX00XX"
                    pl={1}
                    fontSize={"20px"}
                    fontWeight={400}
                  >
                    +91-999XXX00XX{" "}
                  </Typography>
                </Box>
                <Typography
                  display={"inline-block"}
                  variant="body1"
                  component="a"
                  color="#445386"
                  href="tel:+91999XXX00XX"
                  fontSize={"20px"}
                  fontWeight={400}
                  p={"12px 0px 0px 33px"}
                >
                  +91-999XXX00XX
                </Typography>
              </Box>
            </Grid> */}
            <Grid item md={3}></Grid>
            <Grid item md={6} xs={12} order={{ xs: 1, md: 2 }}>
              <Box
                p={4}
                borderRadius={{ xs: 0, sm: 2, md: 2 }}
                border={"1px solid #E5E4E2"}
                bgcolor={"#445386"}
                minHeight={"100%"}
                alignItems={"center"}
              >
                <Typography
                  fontSize={{ xs: "24px", md: "32px" }}
                  color={"#fff"}
                  fontWeight={700}
                  mb={"10px"}
                >
                  Enquiry
                </Typography>
                <Formik
                  initialValues={initialData}
                  onSubmit={(values, { setSubmitting, ...props }) => {
                    handleSubmit(values, setSubmitting, props);
                  }}
                  enableReinitialize
                  validateOnblur={false}
                  validationSchema={OtherDetailSchema}
                >
                  {({
                    values,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    errors,
                    touched,
                  }) => {
                    return (
                      <Form className="oh-signup-form" autoComplete="off">
                        {/* Education Details Section */}
                        <Grid container>
                          <Grid width={"100%"} item pb={2}>
                            <Box>
                              <MuiTypography color="#fff" content="Full Name" />
                              <InputField
                                id={"1"}
                                type="text"
                                placeholder="Enter your full name here"
                                name={"fullName"}
                                value={values.fullName}
                                validateOnChange={true}
                              //   error={
                              //     touched?.fullName && Boolean(errors?.fullName)
                              //   }
                              />
                            </Box>
                          </Grid>
                          <Grid width={"100%"} item pb={2}>
                            <Box>
                              <MuiTypography color="#fff" content="Email" />
                              <InputField
                                id={"1"}
                                type="text"
                                placeholder="Enter your active Email ID / Username"
                                name={"email"}
                                value={values.email}
                                validateOnChange={true}
                                error={touched?.email && Boolean(errors?.email)}
                              />
                            </Box>
                          </Grid>
                          <Grid width={"100%"} item pb={2}>
                            <Box>
                              <MuiTypography
                                color="#fff"
                                content="Mobile Number"
                              />
                              <InputField
                                id={"1"}
                                type="text"
                                placeholder="Enter your active Mobile Number"
                                name={"mobile_number"}
                                value={values.mobile_number}
                                validateOnChange={true}
                                error={
                                  touched?.mobile_number &&
                                  Boolean(errors?.mobile_number)
                                }
                              />
                            </Box>
                          </Grid>
                          <Grid width={"100%"} item pb={2}>
                            <Box width={"100%"}>
                              <MuiTypography color="#fff" content="Message" />
                              <Textarea
                                value={values.message}
                                onChange={(e) => {
                                  setFieldValue("message", e.target.value);
                                }}
                                placeholder="Enter here"
                                minRows={7}
                                maxRows={7}
                                style={{ marginTop: "4px" }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <CustomLoadingButton
                          style={{
                            opacity: !values?.email ? "0.5" : null,
                          }}
                          disabled={!values?.email ? true : false}
                          loading={isSubmitting}
                          type="submit"
                          title={"Submit"}
                          className="oh-submit-btn"
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value, true);
  };

  const error = (validateOnChange || meta.touched) && meta.error;

  return (
    <div style={{ marginTop: "5px" }}>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={
          meta.touched &&
          error && (
            <FieldError
              sx={{
                span: {
                  color: "#FF3131",
                },
              }}
              errorString={error}
            />
          )
        }
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}
export default HelpAndSupport;
