import { Box } from "@mui/material";
import React, { useState } from "react";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import JOB_BANNER_IMG from "../../assets/images/job.svg";
import EMPLOYER_BANNER_IMG from "../../assets/images/employer_banner_img.svg";
import IDEA_IMG from "../../assets/images/lightbulb.png";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import "./index.scss";

const BannerImage = ({
  type = "jobseeker",
  src = type === "jobseeker" ? JOB_BANNER_IMG : EMPLOYER_BANNER_IMG,
  title = null,
}) => {
  const { portalName } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const [showBanner, setShowBanner] = useState(true);

  const points = [
    portalName === "Employer"
      ? "Greetings! Begin your journey upon registration."
      : portalName === "Jobseeker"
        ? `${user?.first_name}! Let's find your dream job together.`
        : "",
  ];

  return (
    <>
      {showBanner && (
        <Box className={`oh-banner-main banner-${type}`}>
          <img className="oh-banner-img" src={src} alt="job-seeker-banner" />
          <MuiTypography className="oh-banner-title">
            <span>
              Welcome {portalName === "Employer" && " to "}{" "}
              <span className="oh-greeting-text">
                {title || portalName}
              </span>
            </span>
            <CloseIcon
              onClick={() => setShowBanner(false)}
              sx={{ color: "#000", fontSize: "16px", svg: { color: "#fff" } }}
            />
          </MuiTypography>
          <Box className="oh-banner-content">
            <img className="oh-idea-img" src={IDEA_IMG} alt={"idea-icon"} />
            <Box className="oh-content-list">
              {points &&
                points.map((elem, idx) => {
                  return (
                    <MuiTypography
                      key={idx}
                      content={elem}
                      style={{
                        color: "var(--oh-blue-600-color)",
                        fontSize: "13px",
                        fontWeight: 400,
                        paddingTop: "2px",
                        paddingBottom: "4px",
                      }}
                      className="oh-content-list-item"
                    />
                  );
                })}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BannerImage;
