import React from 'react'
//Mui
import Chip from '@mui/material/Chip'
//CSS
import './index.scss'

const MuiDeletableChip = ({
  className = 'oh-secondary-chip',
  label = '',
  sx = {},
  onClick = () => {},
  icon = null,
  onDelete = null,
  deleteIcon = null,
}) => {
  return (
      <Chip
        icon={icon}
        label={label}
        className={`oh-deletable-chip ${className}`}
        sx={sx}
        variant='outlined'
        onClick={onClick}
        onDelete={onDelete}
        deleteIcon={deleteIcon}
      />
  )
}

export default MuiDeletableChip;
