import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import freelancerLandingImg from "../../../assets/images/freelancerLandingPage.png";
import fastTime from "../../../assets/images/fast-time.png";
import calender from "../../../assets/images/calendar.png";
import jobSearchImg from "../../../assets/images/job-search 2.png";
import quotesImg from "../../../assets/images/quotes.svg";
import annaMary from '../../../assets/images/Anna Mary.svg';
import rishabhBohra from '../../../assets/images/Rishabh Bohra.svg';
import priyankaSharma from '../../../assets/images/Priyanka Sharma.svg';
import rohanVyas from '../../../assets/images/Rohan Vyas.svg';
import shreyaShah from '../../../assets/images/Shreya Shah.svg';
// import { Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Pagination, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout/Layout";
import { setPortalInfo } from "../../../redux/globalSlice";
import "./index.scss";
import { Helmet } from "react-helmet-async";
import { getReviewed, setReviewed } from "../../../redux/LandingPage/LandingPageSlice";

const Freelancer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reviewed = useSelector((state) => state?.search?.reviews)
  const token = localStorage.getItem("token");
  const portal = localStorage.getItem("portal");

  useEffect(() => {
    getReviews();
  }, [])

  const handleNavigation = () => {
    dispatch(
      setPortalInfo({
        portalName: "Freelancer",
        portalValue: "freelancer",
      })
    );
    navigate("/login");
  };

  const getReviews = () => {
    dispatch(getReviewed())
      .then((res) => {
        if (res.type === "reviewed/fulfilled") {
          dispatch(setReviewed(res?.payload?.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <>
      <Helmet>
        <title>Earn Extra Income with OctaHire | Join as a Freelancer</title>
        <meta name="description" content="Are you looking for extra earning opportunities? Start working with OctaHire as a
        freelance recruiter. Earn more by helping us hire the right candidates."/>
        <link rel="canonical" href="https://octahire.com/freelancers" />
      </Helmet>
      <Layout>
        <section className="freelancer-header-section">
          <div className="fhs-left-section">
            <div className="ls-small-heading">
              <p>OctaHire for Freelancer</p>
            </div>
            <div className="ls-heading">
              <h1>Do you want to boost your earnings?</h1>
            </div>
            <div className="ls-subheading">
              <h3>
                Become a Freelancer with <span>OctaHire.com</span>
              </h3>
            </div>
            <div className="ls-paragraph">
              <p style={{ fontSize: 18 }}>
                Share your network, get candidates hired, and earn commission in just a few steps.
              </p>
            </div>
            {(!token || (token && portal === "Freelancer")) && (
              <div className="ls-btn">
                <button type="button" onClick={() => handleNavigation()}>
                  Register Now
                </button>
              </div>
            )}
          </div>
          <div className="fhs-right-section">
            <img src={freelancerLandingImg} alt="smiling-young-businessmen" />
          </div>
          {(!token || (token && portal === "Freelancer")) && (
            <div className="ls-btn-mobile">
              <button type="button" onClick={() => handleNavigation()}>
                Register Now
              </button>
            </div>
          )}
        </section>
        <section className="key-feature-section">
          <Container>
            <div className="kfs-heading">
              <h3>Simple Steps to Success</h3>
              <p>
                Refer and turn your connections into rewarding opportunities.
              </p>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} className="kfs-grid">
                <div className="kfs-card">
                  <div className="card-content">
                    <img src={jobSearchImg} alt="" />
                    <h4>Refer Candidates for Allotted Jobs</h4>
                    <p>
                      Utilize your network to recommend talented individuals for relevant job openings.
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="kfs-grid">
                <div className="kfs-card">
                  <div className="card-content">
                    <img src={fastTime} alt="" />
                    <h4>Wait for Resume Selection</h4>
                    <p>Sit back while our experts review and shortlist resumes based on employer requirements.
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="kfs-grid">
                <div className="kfs-card">
                  <div className="card-content">
                    <img src={calender} alt="" />
                    <h4>Get Commission</h4>
                    <p>
                      Earn a competitive commission once your referred candidates are hired.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <Box className="end-section">
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box className="end-section-content">
                  <h3>Insights from Our Freelancer Community</h3>
                </Box>

                <Swiper
                  spaceBetween={24}
                  slidesPerView={3}
                  className="mySwiper"
                  pagination={{
                    clickable: true,
                  }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Pagination, Navigation]}
                >
                  <Box className="banner-img-section">
                    {
                      reviewed && reviewed.map((elem) => (
                        <SwiperSlide>
                          <Box className="swiper-card">
                            <img className="top-img" src={quotesImg} alt="img" />
                            <p className="card-main-content">
                              {elem?.attributes?.content}
                            </p>
                            <Box className="id-location">
                              <img src={elem?.attributes?.profile_photo?.url} alt="img"
                                style={{ borderRadius: '50%' }}
                              />
                              <div className="id-name-content">
                                <p>{elem?.attributes?.full_name}</p>
                                <p>{elem?.attributes?.address}</p>
                              </div>
                            </Box>
                          </Box>
                        </SwiperSlide>
                      ))
                    }
                    {/* <SwiperSlide>
                      <Box className="swiper-card">
                        <img className="top-img" src={quotesImg} alt="img" />
                        <p className="card-main-content">
                          Octahire is incredibly user-friendly and effective. Highly
                          recommended!
                        </p>
                        <Box className="id-location">
                          <img src={annaMary} alt="img" />
                          <div className="id-name-content">
                            <p>Anna Marray</p>
                            <p>Ahmedabad, Gujarat</p>
                          </div>
                        </Box>
                      </Box>
                    </SwiperSlide> */}
                  </Box>
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-button-next"></div>
                </Swiper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Layout>
    </>
  );
};

export default Freelancer;
