import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//OTHER
import { clearOtps } from "../../redux/verifyOtpSlice";
import { returnRoleValue } from "../../utils";
import CompanyInfoForm from "./CompanyInfoForm";
import PersonalInfoForm from "./PersonalInfoForm";
import FreelancerInfoForm from "./FreenlacerInfoForm";
import SignupLayout from "./SignupLayout";

//CSS
import "./index.scss";

const CreateAccount = () => {
    const portalName = localStorage.getItem("portal");
    const { user } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearOtps());
        if (user?.registration_complete) {
            navigate(`/${returnRoleValue(user?.type)}`)
        }
    }, [user])

    const renderComponent = () => {
        switch (portalName) {
            case 'Employer':
                return <CompanyInfoForm />
            case "Freelancer":
                return <FreelancerInfoForm />
            default:
                return <PersonalInfoForm />;
        }
    }

    return (
        <>
            <SignupLayout>
                {renderComponent()}
            </SignupLayout>
        </>
    )
}

export default CreateAccount;