import React from "react";
//MUI
import Rating from "@mui/material/Rating";

const defaultStyle = { fontSize: "16px", textAlign: "center", paddingLeft: "5px", color: "#F6BD60" };

const MuiRating = ({
    label = null,
    labelStyle = {},
    labelClassName = "",
    className = "",
    name="simple-controlled",
    value = "",
    onChange = () => {},
    style = {}
}) => {
    return (
        <>
        {label ? <span style={labelStyle} className={labelClassName}>{label}</span> : null}
        <Rating
            className={className}
            name={name}
            value={value}
            onChange={(e, value) => onChange(e, value)}
            sx={{...defaultStyle, ...style}}
            />
        </>
    )
}

export default MuiRating;