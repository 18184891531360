import React from "react";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import "./index.scss";

const LinkText = ({
    title = "",
    style = {},
    className = "oh-primary-link-txt",
    onClick = () => { },
}) => {
    return (
        <MuiTypography
            className={className}
            onClick={onClick}
            style={style}
            content={title}
        />
    )
}


export default LinkText;