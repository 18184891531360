import React from "react";

//MUI
import Box from "@mui/material/Box";

//OTHER
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";

const CardFooter = (elem) => {
    return (
        <Box className="card-footer">
            <MuiTypography className="card-post" content={"Projected Earnings"} />
            <MuiTypography>
                <span className="card-rupees">₹</span>
                <span className="card-earning">{"20,000"}</span>
            </MuiTypography>
        </Box>
    );
};

export default CardFooter;
