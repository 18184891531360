
import React, { useState } from "react";
//MUI
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

//OTHER
import AccountSetting from "./AccountSetting";
import SettingNotification from "./SettingNotification/SettingNotification";
import MuiButtonContained from "../../../mui/Buttons/MuiButtonContained";

//CSS
import "../../../Styles/AccountSetting.scss";
import "../../../Styles/Global.scss";
import EmployerLayout from "../../Layouts/Employer/EmployerLayout";

const Setting = () => {
    const [value, setValue] = useState(2);
    const [phase, setPhase] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <EmployerLayout>
                <Box className="oh-main-box">
                    {
                        phase !== 1 &&
                        <Box className="oh-settingTab-box">
                            <Tabs
                                value={value}
                                onChange={(e, val) => handleChange(e, val)}
                                TabIndicatorProps={{
                                    style: { display: 'none' },
                                }}
                                className="oh-account-grid"
                            >
                                <Tab label="Account Settings"
                                    className={`account-tab ${value === 0 ? 'active-tab' : ''}`} />
                                <Tab
                                    label={<span className="tab-label-with-lines">Payment</span>}
                                    className={`account-tab ${value === 1 ? 'active-tab' : ''}`}
                                    sx={{ padding: "0px 60px" }}
                                />
                                <Tab label="Notification"
                                    className={`account-tab ${value === 2 ? 'active-tab' : ''}`}
                                />
                            </Tabs>
                        </Box>
                    }
                    <Box className="oh-settingForm-box" mt={1}>
                        {
                            (phase !== 1 && value === 0) ?
                                <AccountSetting />
                                :
                                (phase === 1 && value === 2) && <SettingNotification />
                        }
                    </Box>
                    <MuiButtonContained className="oh-employer-setting-btn" type={"button"} title={"Submit"} />
                </Box>
            </EmployerLayout>
        </>
    )
}

export default Setting;