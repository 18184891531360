import React from 'react'

//MUI
import Box from '@mui/material/Box'

//ASSETS
import Male from '../../../assets/images/male.svg';
import Female from '../../../assets/images/female.svg';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import { freelancerExperienceArr } from '../../../Data';

//CSS
import './index.scss'


const CandidatesShortListed = ({
    data
}) => {

    const returnColorHex = () => {
        return data?.status === "Selected"
            ? "#34A853"
            : data?.status === "Inprocess"
                ? "#576EDB"
                : data?.status === "Rejected"
                    ? "#B71E58"
                    : "#576EDB"
    }

    return (
        <Box
            className="shortListed-div"
            sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}
        >
            <Box sx={{ borderRadius: "4px", justifyContent: "flex-start", alignItems: "center", background: `${returnColorHex()}2D` }} className={`oh-applicant`}>
                <Box
                    sx={{
                        minWidth: '40px',
                        width: '40px',
                    }}
                >
                    <CircularProgressbarWithChildren
                        value={66}
                        strokeWidth={6}
                        backgroundPadding={0}
                        styles={buildStyles({
                            pathColor: '#34A853',
                        })}
                    >
                        <img
                            src={(data?.gender === 'Male' ? Male : Female) || data?.image}
                            alt='avatar'
                            style={{
                                width: '36px',
                                height: '36px',
                                borderRadius: '50%',
                                backgroundColor: "#fff",
                            }}
                        />
                    </CircularProgressbarWithChildren>
                </Box>
                <Box sx={{ lineHeight: "22px", ml: 2, mt: 0.5, mb: 0, textTransform: "capitalize" }} className='applicant-title'>
                    <p style={{ fontSize: '16px' }} className='title-name'>{data?.first_name ? `${data?.first_name} ${data?.last_name}` : "-"}</p>
                    <div style={{ display: 'flex', gap: "20px", justifyContent: 'space-between', alignItems: 'center', fontSize: '14px' }} >
                        <div>
                            {data?.designation || "-"}
                        </div>
                        <div className='candidate-experience'>
                            {data?.experience ? freelancerExperienceArr.find((option) => option.value == data?.experience)?.label : ""}
                        </div>
                    </div>
                </Box>
            </Box>
            {data?.status && <span style={{
                fontWeight: "500", color: returnColorHex(), backgroundColor: `${returnColorHex()}2D`, borderColor: returnColorHex(),
            }} className="card-applied-status-candidate">
                {data?.status}
            </span>}
        </Box>
    )
}


export default CandidatesShortListed;