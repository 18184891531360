import React, { useEffect, useState } from "react";
//MUI
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TertiaryWrapper from "../../components/Wrappers/TertiaryWrapper";
import SearchLayout from "../Layouts/Search/SearchLayout";
import JobPostCard from "../../components/JobPostBox/JobPostCard";
import NoJobsFound from "../../components/NotFound/NoJobsFound";
import {
  searchForEmployer,
  setSearchedJobs,
} from "../../redux/Employer/searchSlice";
import { setIsLoading } from "../../redux/globalSlice";
import AllotedJobsCard from "../../components/Cards/JobSeeker/AllotedJobsCard";
import moment from "moment";
import { clearSelectedCandidates } from "../../redux/Freelancer/allotedJobsSlice";
import MuiIconChip from "../../mui/Chip/IconChip";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Filter from "../../components/Filter/Filter";
import GlobalLoader from "../../components/Loader/GlobalLoader";

const Search = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popupState, setPopupState] = useState("");
  const { portalName } = useSelector((state) => state.app);
  const { searchedJobs, search } = useSelector(
    (state) => state.searchForEmployer
  );
  const { isLoading } = useSelector(state => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoading(true));
    // if (search) {
      dispatch(searchForEmployer(search || ""))
        .then((res) => {
          if (res.type === "searchForEmployer/fulfilled") {
            dispatch(setSearchedJobs(res?.payload?.data));
            dispatch(setIsLoading(false));
            navigate(location.pathname, { state: {} });
          }
           else if (res.type === "searchForEmployer/pending") {
            dispatch(setIsLoading(true));
          } 
          else {
            dispatch(setIsLoading(false));
          }
        })
        .catch((err) => console.log(err));
    // } else {
    //   dispatch(setIsLoading(false));
    // }
  }, [search]);

  const handlePopupState = (popupToHandle = "") => {
    return setPopupState(popupToHandle);
  };

  return (
    <SearchLayout>
      <Box>
        <TertiaryWrapper
          variant={"div"}
          sx={{
            padding: 0,
            ".wrapper-header": {
              padding: "20px 0px 10px",
              ".oh-heading": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              },
            },
          }}
          showBtnOptions={false}
          content={
            <>
              <div>Search Results {searchedJobs.length}</div>
              <div>
                <MuiIconChip
                  icon={<FilterAltIcon sx={{ marginTop: "-3px" }} />}
                  label="Filter"
                  onClick={() => handlePopupState("filter")}
                  className="search-page-filter-btn"
                />
              </div>
            </>
          }
        >
          {portalName === "Freelancer" ? (
            <Box>
              {isLoading ? <GlobalLoader minHeight="300px" /> : (
              <Grid container spacing={2}>
                {searchedJobs?.length > 0 ? (
                  searchedJobs.map((data) => (
                    <Grid className="freelancer-jobs-search-card" item xs={12} md={4} lg={4} key={data}>
                      <AllotedJobsCard
                        cardType="allJobs"
                        footerText={true}
                        timestamp={moment(
                          data?.attributes?.created_at
                        ).fromNow()}
                        imgShow={false}
                        data={data?.attributes}
                        title="Add Candidates"
                        handleNavigate={() => {
                          dispatch(clearSelectedCandidates());
                          navigate(`/allotted-jobs/job-details/${data?.id}`);
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <Box sx={{ width: "100%", minHeight: "200px" }}>
                    <NoJobsFound height={"450px"} value="No jobs found" />
                  </Box>
                )}
              </Grid>
              )}
            </Box>
          ) : (
            <Box>
              {isLoading ? <GlobalLoader minHeight="300px" /> : (
              <Grid container spacing={2}>
                {searchedJobs?.length > 0 ? (
                  searchedJobs.map((elem, idx) => {
                    return (
                      <Grid key={idx} item xs={12} md={6} lg={4}>
                        <JobPostCard
                          size={"medium-100"}
                          handleNavigate={() => {
                            navigate(
                              `/${portalName === "Employer"
                                ? "job-openings"
                                : "all-jobs"
                              }/job-details/${elem.id}`);
                          }}
                          data={elem.attributes}
                          className={"open-total-applicant"}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Box sx={{ width: "100%", minHeight: "200px" }}>
                    <NoJobsFound height={"450px"} value="No jobs found" />
                  </Box>
                )}
              </Grid>
              )}
            </Box>
          )}
        </TertiaryWrapper>
      </Box>
      <Filter onClose={handlePopupState} open={popupState === "filter"} />
    </SearchLayout>
  );
};

export default Search;
