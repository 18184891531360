import React, { useState } from "react";
//MUI
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';

//ASSETS 
// import DefaultProfilePicture from "../../../assets/images/default_profile_picture.svg";
import Ellipse from "../../../assets/images/Ellipse.svg";
import Male from "../../../assets/images/male.svg";
import Female from "../../../assets/images/female.svg";

//OTHER

//CSS
import "./DrawerBody/index.scss";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import MuiIconChip from '../../../mui/Chip/IconChip';
import DrawerBody from "./DrawerBody/DrawerBody";
import { freelancerExperienceArr } from '../../../Data';
import AddCandidateDrawer from "./AddDrawer/AddDrawer";
import Skills from "../../../pages/JobSeeker/Jobs/Components/Skills";
import { useDispatch, useSelector } from "react-redux";
import { clearIndividualFreelancerData, setIndividualFreelancerData, setIsUpdateCandidateProfile } from "../../../redux/Freelancer/freelancerDetailsSlice";
import { handleDownload } from "../../../utils";

const CandidateDetail = ({
    open = false,
    setOpen = false,
    onClose = () => { },
    isFooter = false,
    setRowCount,
    title = null,
    setPage,
}) => {
    const dispatch = useDispatch();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const data = useSelector(state => state.freelancerDetailData.individualFreelancerData)
    const isUpdateProfile = useSelector(state => state.freelancerDetailData.isUpdateCandidateProfile)
    const handleDrawerEdit = () => {
        setOpen(false);
        setIsDrawerOpen((prev) => !prev)
        dispatch(setIsUpdateCandidateProfile(true));
        // dispatch(setIndividualFreelancerData(data))
    }

    const handleClose = () => {
        if (!isUpdateProfile) {
            dispatch(clearIndividualFreelancerData());
        }
        onClose();
    }

    return (
        <>
            <DrawerBody
                data={data}
                open={open}
                onClose={handleClose}
                isfooter={isFooter}
                // isEdit={isEdit}
                title={title}
            >
                {/* profile */}
                <Box className="oh-box-profile">
                    <Box display={"flex"}>
                        <Avatar
                            src={data?.image || data?.gender === "Male" ? Male : Female}
                            aria-label="recipe"
                            alt="Remy Sharp"
                            className="oh-profile-avatar"
                        />
                        <Box className="profile-txt">
                            <MuiTypography className="oh-user-name" content={data?.first_name ? `${data?.first_name} ${data?.last_name}` : "-"} />
                            <MuiTypography className="oh-user-designation" content={data?.designation || "No designation"} />
                            <span className="profile-span">
                                <img src={Ellipse} alt="Ellipse" />
                                <MuiTypography className="oh-user-experinece" content={freelancerExperienceArr.find(
                                    (item) => item.value === data?.experience
                                )?.label} />
                            </span>
                        </Box>
                    </Box>
                    <ModeEditIcon
                        onClick={() => handleDrawerEdit()}
                        className="edit-icon"
                    />
                </Box>

                {/* skills */}
                <Box className="oh-box-skills">
                    <MuiTypography className="skills-heading" content={"Skills"} />
                    <Skills skillsData={data?.skills?.map(elem => elem.name)} size="medium-200" />
                </Box>

                {/* category */}
                <Box className="oh-box-category">
                    <MuiTypography className="category-heading" content={"Categories"} />
                    <Skills skillsData={data?.categories?.map(elem => elem.name)} size="medium-200" />
                </Box>

                {/* resume */}
                {
                    data?.resume_name &&
                    <Box className="oh-box-resume">
                        <MuiTypography className="resume-heading" content={"Resume"} />
                        <Box className="detail-box">
                            <MuiTypography className="resume-name" content={data?.resume_name || "Davidelbert-Ser-java.pdf"} />
                            <span>
                                <IconButton onClick={() => handleDownload(data?.resume?.url)} aria-label="download" className="oh-download">
                                    <DownloadIcon />
                                </IconButton>
                            </span>
                        </Box>
                        {data?.resume_uploaded_at && <MuiTypography className="resume-date-time" content={`Uploaded on ${data?.resume_uploaded_at}`} />}
                    </Box>
                }
            </DrawerBody>

            {isDrawerOpen && <AddCandidateDrawer
                setRowCount={setRowCount}
                openAddDrawer={isDrawerOpen}
                onClose={() => handleDrawerEdit()}
                id={1}
                setPage={setPage}
                isFooter={false}
                title={"Update Candidate Profile"}
            />}
        </>
    )
}

export default CandidateDetail;