import React, { useEffect, useRef, useState } from "react";
import { Typography, Button, Grid, Box } from "@mui/material";
import GreaterThanIcon from "@mui/icons-material/ArrowForward";
import { getBrowseJobsByLocationsList } from "../../../../redux/LandingPage/LandingPageSlice";
import { useDispatch } from "react-redux";
import { ReactComponent as City } from "../../../../assets/images/city.svg";
import { ReactComponent as RightArrow } from "../../../../assets/images/right-arrow-darkBlue.svg";
import { useNavigate } from "react-router-dom";

function BrowseJobsSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobLocations, setJobLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const countRef = useRef(12);
  const mobileScreenWidth = window.innerWidth;

  useEffect(() => {
    getAllLocationsList();

    if (mobileScreenWidth < 600) {
      countRef.current = 2;
    } else {
      countRef.current = 12;
    }
  }, []);

  const getAllLocationsList = () => {
    setLoading(true);
    dispatch(getBrowseJobsByLocationsList())
      .then((res) => {
        if (res?.error) throw new Error("Something went wrong!");
        const apiData = res?.payload?.data;
        setJobLocations(apiData);
      })
      .catch((err) => {
        console.log(err.message, ".err");
      })
      .finally(() => setLoading(false));
  };

  const handleViewJobs = (value) => {
    if (!value) {
      navigate("/search-jobs");
    } else {
      navigate(`/search-jobs?query=${value}&category=Location`);
    }
  };

  return (
    <Box
      as="section"
      padding={{ xs: "40px 20px", sm: "40px" }}
      style={{
        textAlign: "center",
        borderBottom: "1px solid #DEE4FF",
      }}
    >
      <Typography
        variant="h4"
        fontSize={{ xs: "20px", sm: "24px" }}
        fontWeight={700}
        color={"#141621"}
        lineHeight={{ xs: "32px" }}
      >
        Browse Jobs by Location
      </Typography>
      <Typography
        variant="subtitle1"
        marginBottom={"32px"}
        fontSize={{ xs: "12px", sm: "14px" }}
        fontWeight={400}
        color={"#4A4B4F"}
      >
        Discover job opportunities in your preferred city or region with our location-based job search
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {jobLocations?.slice(0, countRef.current)?.map((location) => {
          const { id, city, job_post_count: totalJobs } = location?.attributes;
          const city_name = city?.split(",")?.[0];
          const urlImage = location?.attributes?.image.url
          return (
            <Grid item xs={6} sm={6} md={2} key={id} height={"173px"}>
              <Box
                key={id}
                height={"100%"}
                background="#fff"
                border={"1px solid #DEE4FF"}
                borderRadius={"8px"}
                position={"relative"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                sx={{
                  "&:hover": {
                    border: "1px solid #97a7f0",
                    transform: "scale(1.1)",
                  },
                }}
              >
                {urlImage !== null ? <img style={{width: 40, height: 40, marginTop: 20}}src={urlImage}/> : 
                <Box marginTop={"20px"}>
                   <City />
                </Box>
        }
                <Box>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={700}
                    color="#445386"
                  >
                    {city_name}
                  </Typography>
                </Box>
                <Box
                  position={"absolute"}
                  left={0}
                  bottom={0}
                  bgcolor={"#F1F3FC"}
                  padding="12px"
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleViewJobs(city)}
                >
                  <Typography
                    fontSize={"14px"}
                    fontWeight={400}
                    color={"#445386"}
                  >
                    {/* 12.7k+ Jobs */}
                    {`${totalJobs !== 0 ? `${totalJobs}+ Jobs` : "No Jobs"}`}
                  </Typography>
                  <RightArrow />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      {jobLocations?.length > 0 && (
        <Box
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={"50px"}
        >
          <Button
            endIcon={<GreaterThanIcon />}
            style={{
              border: "1px solid #DEE4FF",
              borderRadius: "30px",
              padding: "12px 24px",
              color: "#445386",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "none",
              cursor: "pointer",
            }}
            onClick={() => handleViewJobs("")}
          >
            View More
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default BrowseJobsSection;
