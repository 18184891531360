import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

//CSS
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

import "./index.scss";

const SignupCoroussel = ({
    children,
    totalPages = 3, // Set the total number of pages
}) => {
    return (
        <div className="oh-swiper-signup">
            <Swiper
                slidesPerView={1}
                speed={2000}
                autoplay={{
                    delay: 5000
                }}
                pagination={{
                    clickable: true,
                    renderBullet: function (index, className) {
                        // Render only five bullets
                        if (index < totalPages) {
                            return `<span class="${className}"></span>`;
                        }
                        // Return an empty string for non-rendered bullets
                        return "";
                    },
                }}
                modules={[Pagination, Autoplay]}
                onSlideChange={() => { }}
                onSwiper={(swiper) => { }}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    }
                }}
            >
                {children}
            </Swiper>
        </div>
    );
};

export default SignupCoroussel;