import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.scss";
import { getTermsCondition } from "../redux/policySlice";
import { useDispatch } from "react-redux";
import LandingPageHeader from "../landingPage/components/header/header";
import Footer from "../landingPage/components/footer/Footer";
import Layout from "../landingPage/components/Layout/Layout";

const TermsAndCondition = () => {
  const dispatch = useDispatch();
  const [termsCondition, setTermsCondition] = useState([]);
  useEffect(() => {
    getPolicies();
  }, []);
  const getPolicies = () => {
    dispatch(getTermsCondition())
      .then((res) => {
        if (res.type === "getTermsCondition/fulfilled") {
          setTermsCondition(res?.payload?.data);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Layout>
      <Box
        marginTop={"80px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={{ xs: "94px", sm: "188px" }}
        backgroundColor={"#F1F3FC"}
      >
        <Typography
          fontSize={{ xs: "20px", sm: "24px" }}
          fontWeight={700}
          color={"#141621"}
        >
          Terms & Conditions
        </Typography>
      </Box>
      <Box
        component={"section"}
        padding={{ xs: "35px 25px", md: "50px 194px" }}
      >
        {termsCondition?.map((section) => (
          <Box key={section?.id} color={"000"}>
            <Typography
              fontWeight={700}
              fontSize={"20px"}
              className="parent-box-heading"
              lineHeight={{ xs: "160%" }}
              dangerouslySetInnerHTML={{
                __html: section?.attributes?.title,
              }}
            />
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: section?.attributes?.content,
              }}
              lineHeight={{ xs: "160%" }}
            />
          </Box>
        ))}
      </Box>
    </Layout>
  );
};

export default TermsAndCondition;
