import React from "react";
import Layout from "../components/Layout/Layout";
import BrowseJobsSection from "../components/mainLandingPage/browseJobsByLocation/BrowseJobsByLocation";
import BrowseJobs from "../components/mainLandingPage/browseJobs/BrowseJobs";
import Banner from "../components/mainLandingPage/banner/Banner";
import OpportunityBanner from "../components/mainLandingPage/opportunityBanner/OpportunityBanner";

const LandingPage = () => {
  return (
    <>
      <Layout>
        <Banner />
        <BrowseJobsSection />
        <BrowseJobs />
        <OpportunityBanner />
      </Layout>
    </>
  );
};

export default LandingPage;
