import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik, useField, useFormikContext } from "formik";
//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

//OTHER
import MuiTextField from "../../mui/TextField/MuiTextField";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import FieldError from "../../components/Errors/FieldError";
import UploadResume from "../../components/Upload/UploadResume";
import { PersonalInfoSchema } from "../../schemas/CompanyInfoSchema";

//CSS
import "./index.scss";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import MuiSelect from "../../mui/TextField/MuiSelect";
import {
  personalInfo,
  updatePersonalInfo,
} from "../../redux/JobSeeker/personalInfoSlice";
import { Toaster } from "../../components/Toaster/Toaster";
import ConfirmationDialog from "../../components/Dialog/ComfirmationDialog";
import { clearOtps } from "../../redux/verifyOtpSlice";
import { clearAuthStorage, setUserData } from "../../redux/authSlice";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { employmentStatusArr, experienceArr, workExperienceArr } from "../../Data";
import { setPortalInfo } from "../../redux/globalSlice";
import { returnRoleValue } from "../../utils";
import { setProfilePhoto } from "../../redux/profileSlice";

const PersonalnfoForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { portalType, portalValue } = useSelector((state) => state.app);
  const userId = user?.id;
  const [toggle_password, setToggle_password] = useState(false);
  const [otpLink, setOtpLink] = useState(false);
  const [openConfirmationPopup, setComfirmationPopup] = useState(false);

  const [initialData, setInitialData] = useState({
    first_name: user?.personal_details?.first_name || user?.first_name || "",
    last_name: user?.personal_details?.last_name || user?.last_name || "",
    employment_status: user?.personal_details?.employment_status || "",
    experienced: user?.personal_details?.experience || "",
    resume: "",
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("loginBtn").click();
    }
  };

  const handlePersonalInfo = (values, setSubmitting) => {
    const formData = new FormData();
    formData.append("data[employment_status]", values.employment_status || "");
    formData.append("data[experience]", values.experienced);
    formData.append("data[resume]", values.resume);
    formData.append("data[first_name]", values.first_name);
    formData.append("data[last_name]", values.last_name);
    //api calling
    dispatch(personalInfo({ data: formData, userId }))
      .then((res) => {
        if (res.type === "personalInfo/fulfilled") {
          setSubmitting(false);
          Toaster.TOAST(res?.payload?.message, "success");
          let user_data = res.payload.data.attributes[portalValue];
          user_data = { ...user_data, personal_details: res.payload.data.attributes.personal_details };

          localStorage.setItem("login_data", JSON.stringify(user_data));
          dispatch(setUserData(user_data));
          setTimeout(() => {
            navigate(`/${portalValue}`);
          }, 1000);
        } else if (res.type === "personalInfo/pending") {
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.log(err);
        Toaster.TOAST(err.message, "error");
      });
    setSubmitting(false);
  };

  const handleBackNavigation = () => {
    dispatch(clearOtps());
    setComfirmationPopup(false);
    dispatch(clearAuthStorage());
    dispatch(setProfilePhoto(null));
    dispatch(
      setPortalInfo({
        portalName: "Jobseeker",
        portalValue: returnRoleValue("Jobseeker"),
      })
    );
    navigate("/login");
  };

  return (
    <>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          handlePersonalInfo(values, setSubmitting);
        }}
        validateOnblur={false}
        enableReinitialize
        validationSchema={PersonalInfoSchema}
      >
        {({ values, setFieldValue, isSubmitting }) => {
          return (
            <Form className="oh-signup-form" autoComplete="off">
              <Grid container xs={12} md={11} lg={11}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className="oh-heading oh-form2-heading">
                    <MuiTypography
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "26px",
                        textAlign: "center",
                        margin: "10px 0px 12px",
                        color: "var(--oh-blue-color)",
                        cursor: "pointer",
                      }}
                      content={
                        <span onClick={() => setComfirmationPopup(true)} style={{ display: "flex", fontSize: "18px", justifyContent: "flex-start", alignContent: "center", gap: "8px" }}>
                          <ArrowCircleLeftIcon sx={{ svg: { fontSize: "24px" } }} />
                          Back
                        </span>}
                    />
                    <MuiTypography
                      className="oh-signup-title"
                      content="Create Account"
                    />
                    <MuiTypography
                      className="oh-signup-subtitle"
                      content={`Personal Info`}
                    />
                  </Box>
                </Grid>
                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="First Name" required={true} />
                  <InputField
                    id="first_name"
                    type="text"
                    placeholder="Enter here"
                    name="first_name"
                    value={values.first_name}
                    validateOnChange={true}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Last Name" required={true} />
                  <InputField
                    id="last_name"
                    type="text"
                    placeholder="Enter here"
                    name="last_name"
                    value={values.last_name}
                    validateOnChange={true}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Employment Status" />
                  <MuiSelect
                    id="employment_status"
                    select
                    placeholder={!values.employment_status && values.employment_status !== "fresher" && "Please select"}
                    name="employment_status"
                    value={values.employment_status}
                    handleChange={(e) => {
                      setFieldValue("employment_status", e.target.value);
                      if (e.target.value == "fresher") {
                        setFieldValue("experienced", "");
                      }
                    }}
                    selectOptions={employmentStatusArr}
                    props={{
                      onKeyPressCapture: () => handleKeyPress,
                    }}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Experienced" />
                  <MuiSelect
                    id="experienced"
                    select
                    name="experienced"
                    disabled={values.employment_status === "fresher"}
                    placeholder={!values.experienced && values.experienced !== 0 && "Please select"}
                    value={values.experienced}
                    handleChange={(e) => {
                      setFieldValue("experienced", e.target.value);
                    }}
                    selectOptions={workExperienceArr}
                    props={{
                      onKeyPressCapture: () => handleKeyPress,
                    }}
                  />
                </Grid>

                <Grid container xs={12} md={12} lg={12}>
                  <Grid
                    className="oh-textfield-grid oh-form2-textfield-grid"
                    lg={6}
                    md={10}
                    xs={12}
                  >
                    <PrimaryLabel label="Resume" />
                    <UploadResume />
                  </Grid>
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <CustomLoadingButton
                    loading={isSubmitting}
                    type="submit"
                    title={"Complete"}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {
        <ConfirmationDialog
          heading={
            "Going back will expire your session! You have to login again..."
          }
          popupState={openConfirmationPopup}
          setPopupState={setComfirmationPopup}
          handleYes={handleBackNavigation}
        />
      }
    </>
  );
};

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value.replace(/^\s+/, ""), true);
  };

  const error = (validateOnChange || meta.touched) && meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default PersonalnfoForm;
