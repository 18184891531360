import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik, useField, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//OTHER
import MuiTextField from "../../mui/TextField/MuiTextField";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import FieldError from "../../components/Errors/FieldError";
import { FreelancerInfoSchema } from "../../schemas/CompanyInfoSchema";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import MuiSelect from "../../mui/TextField/MuiSelect";
import {
  addFreelancerDetails,
  setFreelancerData,
} from "../../redux/Freelancer/freelancerDetailsSlice";
import { clearAuthStorage, setUserData } from "../../redux/authSlice";
import { clearOtps } from "../../redux/verifyOtpSlice";
import ConfirmationDialog from "../../components/Dialog/ComfirmationDialog";
import { employmentStatusArr, experienceArr } from "../../Data";
import { setPortalInfo } from "../../redux/globalSlice";
import { returnRoleValue } from "../../utils";
import { setProfilePhoto } from "../../redux/profileSlice";

//CSS
import "./index.scss";

const FreelancerInfoForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openConfirmationPopup, setComfirmationPopup] = useState(false);

  const { portalValue } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const [initialData, setInitialData] = useState({
    first_name: user?.personal_details?.first_name || "",
    last_name: user?.personal_details?.last_name || "",
    employment_status: "",
    experience: "",
    zip_code: user?.personal_details?.address?.zip_code || "",
    city: user?.personal_details?.address?.city || "",
    state: user?.personal_details?.address?.state || "",
    address: user?.personal_details?.address?.address || "",
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("loginBtn").click();
    }
  };

  const handleSubmit = (values, setSubmitting) => {
    const formData = new FormData();
    formData.append("data[first_name]", values.first_name);
    formData.append("data[last_name]", values.last_name);
    formData.append("data[employment_status]", values.employment_status ? employmentStatusArr.find(opt => opt.value === values.employment_status).numericalValue : "");
    formData.append("data[experience]", values.experience);
    formData.append("data[address_attributes][zip_code]", values.zip_code);
    formData.append("data[address_attributes][address]", values.address);
    formData.append("data[address_attributes][city]", values.city);
    formData.append("data[address_attributes][state]", values.state);

    dispatch(addFreelancerDetails({ data: formData }))
      .then((res) => {
        if (res.type === "addFreelancerDetails/fulfilled") {
          dispatch(setFreelancerData(res?.payload?.data));
          let user_data = res.payload.data.attributes[portalValue];
          user_data = { ...user_data, personal_details: res.payload.data.personal_details };

          localStorage.setItem("login_data", JSON.stringify(user_data));
          dispatch(setUserData(user_data));
          navigate(`/${portalValue}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setSubmitting(false);
  };

  const handleBackNavigation = () => {
    dispatch(clearOtps());
    setComfirmationPopup(false);
    dispatch(clearAuthStorage());
    dispatch(setProfilePhoto(null));
    dispatch(
      setPortalInfo({
        portalName: "Jobseeker",
        portalValue: returnRoleValue("Jobseeker"),
      })
    );
    navigate("/login");
  };

  const handleChange = (e, setFieldValue, setErrors) => {
    const newPincode = e.target.value;
    setFieldValue("zip_code", newPincode);

    if (newPincode.length === 6) {
      // Make API call
      axios
        .get(`https://api.postalpincode.in/pincode/${newPincode}`)
        .then((response) => {
          if (response.data[0].PostOffice) {
            setFieldValue("city", response.data[0].PostOffice[0].Block);
            setFieldValue("state", response.data[0].PostOffice[0].State);
            setErrors({ zip_code: "" });
            const country = response.data[0].PostOffice[0].Country;
            if (country) {
              setFieldValue("country", country);
            } else {
              setFieldValue("country", ""); // Clear the country field if no country information is found
            }
          } else if (response.data[0].Message === "No records found") {
            setFieldValue("city", "");
            setFieldValue("state", "");
            setFieldValue("country", "");

            setErrors({ zip_code: "Enter a valid pincode" });
          }
        })
        .catch((error) => {
          // Handle errors here
          setErrors({ zip_code: "Enter a valid pincode" });
          console.error("Error fetching pincode data:", error);
        });
    }
  };
  return (
    <>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validateOnblur={false}
        enableReinitialize
        validationSchema={FreelancerInfoSchema}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          errors,
          setErrors,
          touched,
        }) => {
          return (
            <Form className="oh-signup-form" autoComplete="off">
              <Grid container xs={12} md={11} lg={11}>
                <Grid item xs={12} md={12} lg={12}>
                  {console.log(errors, values, ".... leaving comment to test google signup issue")}
                  <Box className="oh-heading oh-form2-heading">
                    <MuiTypography
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "26px",
                        textAlign: "center",
                        margin: "10px 0px 12px",
                        color: "var(--oh-blue-color)",
                        cursor: "pointer",
                      }}
                      content={
                        <span
                          onClick={() => setComfirmationPopup(true)}
                          style={{
                            display: "flex",
                            fontSize: "18px",
                            justifyContent: "flex-start",
                            alignContent: "center",
                            gap: "8px",
                          }}
                        >
                          <ArrowCircleLeftIcon
                            sx={{ svg: { fontSize: "24px" } }}
                          />
                          Back
                        </span>
                      }
                    />
                    <MuiTypography
                      className="oh-signup-title"
                      content="Create Account"
                    />
                    <MuiTypography
                      className="oh-signup-subtitle"
                      content={`Personal Info`}
                    />
                  </Box>
                </Grid>
                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="First Name" />
                  <InputField
                    id="first_name"
                    type="text"
                    placeholder="Enter here"
                    name="first_name"
                    value={values.first_name}
                    validateOnChange={true}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Last Name" />
                  <InputField
                    id="last_name"
                    type="text"
                    placeholder="Enter here"
                    name="last_name"
                    value={values.last_name}
                    validateOnChange={true}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Employment Status" />
                  <MuiSelect
                    id="employment_status"
                    select
                    name="employment_status"
                    value={values.employment_status}
                    placeholder={!values.employment_status && values.employment_status !== "fresher" && "Please select"}
                    handleChange={(e) => {
                      setFieldValue("employment_status", e.target.value);
                      if (e.target.value == "fresher") {
                        setFieldValue("experience", "");
                      }
                    }}
                    selectOptions={employmentStatusArr}
                    props={{
                      onKeyPressCapture: () => handleKeyPress,
                    }}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Experienced" />
                  <MuiSelect
                    id="experience"
                    select
                    placeholder={
                      !values.experience &&
                      values.experience !== 0 &&
                      "Select Year"
                    }
                    disabled={values.employment_status === "fresher"}
                    name="experience"
                    value={values.experience}
                    handleChange={(e) => {
                      setFieldValue("experience", e.target.value);
                    }}
                    selectOptions={experienceArr}
                    props={{
                      onKeyPressCapture: () => handleKeyPress,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Box className="oh-heading oh-form2-heading oh-Address-heading">
                    <MuiTypography
                      className="oh-signup-subtitle"
                      content={`Address Info`}
                    />
                  </Box>
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Pincode" />
                  <MuiTextField
                    id="zip_code"
                    type="number"
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                    placeholder="Enter here"
                    name="zip_code"
                    value={values.zip_code}
                    onChange={(e) => handleChange(e, setFieldValue, setErrors)}
                    onBlur={(e) => handleChange(e, setFieldValue, setErrors)}
                    error={touched.zip_code && Boolean(errors.zip_code)}
                    helperText={<FieldError errorString={errors.zip_code} />}
                    inputProps={{
                      maxLength: 6,
                      inputProps: { min: 6, max: 6 },
                    }}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="City" />
                  <MuiTextField
                    id="city"
                    type="text"
                    placeholder="Enter here"
                    name="city"
                    value={values.city}
                    onChange={(e) =>
                      setFieldValue("city", e.target.value.replace(/^\s+/, ""))
                    }
                    error={touched.city && Boolean(errors.city)}
                    helperText={<FieldError errorString={errors.city} />}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="State" />
                  <MuiTextField
                    id="state"
                    type="text"
                    placeholder="Enter here"
                    name="state"
                    value={values.state}
                    onChange={(e) =>
                      setFieldValue("state", e.target.value.replace(/^\s+/, ""))
                    }
                    error={touched.state && Boolean(errors.state)}
                    helperText={<FieldError errorString={errors.state} />}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <PrimaryLabel label="Address" />
                  <MuiTextField
                    id="address"
                    type="text"
                    placeholder="Enter here"
                    name="address"
                    value={values.address}
                    onChange={(e) =>
                      setFieldValue(
                        "address",
                        e.target.value.replace(/^\s+/, "")
                      )
                    }
                    error={touched.address && Boolean(errors.address)}
                    helperText={<FieldError errorString={errors.address} />}
                  />
                </Grid>

                <Grid
                  className="oh-textfield-grid oh-form2-textfield-grid"
                  item
                  xs={12}
                  md={10}
                  lg={6}
                >
                  <CustomLoadingButton
                    onClick={() => console.log("submit btn clicked")}
                    loading={isSubmitting}
                    type="submit"
                    title={"Complete"}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {
        <ConfirmationDialog
          heading={
            "Going back will expire your session! You have to login again..."
          }
          popupState={openConfirmationPopup}
          setPopupState={setComfirmationPopup}
          handleYes={handleBackNavigation}
        />
      }
    </>
  );
};

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value.replace(/^\s+/, ""), true);
  };

  const error = (validateOnChange || meta.touched) && meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default FreelancerInfoForm;
