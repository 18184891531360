import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SwiperSlide } from 'swiper/react'
//MUI
import Grid from "@mui/material/Grid";
//OTHER
import NoJobsFound from "../../../components/NotFound/NoJobsFound";
import PrimaryWrapper from "../../../components/Wrappers/PrimaryWrapper";
import { getAllAppliedJobsData, setAllAppliedJobs, setTabsValue } from "../../../redux/Freelancer/allotedJobsSlice";
import CardButton from "../../../components/Cards/Freelancer/CardButton/CardButton";
import GenericSwiper from "../../../swiper/CustomSwiper";
import GlobalLoader from "../../../components/Loader/GlobalLoader";

const CandidateOpening = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loadAppliedJobs, setLoadAppliedJobs] = useState(false);

    const allAppliedJobs = useSelector(
        (state) => state.allotedJobs.allAppliedJobs
    );

    useEffect(() => {
        setLoadAppliedJobs(true);
        dispatch(getAllAppliedJobsData())
            .then((res) => {
                if (res.type === "getAllAppliedJobsData/fulfilled") {
                    dispatch(setAllAppliedJobs(res.payload.data));
                    setLoadAppliedJobs(false);
                } else if (res.type === "getAllAppliedJobsData/rejected") {
                    setLoadAppliedJobs(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleChange = () => {
        // setValue(newValue);
        dispatch(setTabsValue("applied_jobs"));
        navigate(`/allotted-jobs`);
    };

    return (
        <PrimaryWrapper
            sx={{ marginTop: 0 }}
            content='Applied Openings'
            linkTitle={allAppliedJobs?.length > 2 ? 'View All' : ''}
            onClick={() => handleChange()}
        >
            {loadAppliedJobs ? <GlobalLoader minHeight="200px" /> : (
                <GenericSwiper configSize="medium_300">
                    <Grid container spacing={3}>
                        {
                            allAppliedJobs?.length > 0 ? allAppliedJobs.map((elem, idx) => {
                                return (
                                    <Grid key={idx} item xs={12} md={12} lg={4}>
                                        <SwiperSlide>
                                            <CardButton
                                                // Note: issues need to be sorted here later on priority, props drilling, components naming structure & unnecessary code, structure not upright
                                                elem={elem.attributes}
                                            />
                                        </SwiperSlide>
                                    </Grid>
                                )
                            }) : <NoJobsFound height={"120px"} value={"No referred candidates yet"} applicants={true} />
                        }
                    </Grid>
                </GenericSwiper>
            )}
        </PrimaryWrapper>
    )
}

export default CandidateOpening;