import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import empLandingPage from "../../../assets/images/empLandingPage.png";
import fastTime from "../../../assets/images/fast-time.png";
import calender from "../../../assets/images/calendar.png";
import jobSearchImg from "../../../assets/images/job-search 2.png";
import goodVibes from "../../../assets/images/good-vibes-logo.png";
import protonshub from "../../../assets/images/protons-logo.png";
import infograins from "../../../assets/images/infograins-logo.png";
import shekunj from "../../../assets/images/shekunj-logo.svg";
import zyan from "../../../assets/images/zyan-logo.png";
import dwellfox from "../../../assets/images/dwellfox-logo.png";
import Layout from "../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPortalInfo } from "../../../redux/globalSlice";
import { returnRoleValue } from "../../../utils";
import "./index.scss";
import { Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";

const Employer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const portal = localStorage.getItem("portal");
  const handleNavigation = () => {
    dispatch(
      setPortalInfo({
        portalName: "Employer",
        portalValue: "employer",
      })
    );
    navigate("/login");
  };
  return (
    <>
      <Helmet>
        <title>Staffing Solutions for Employers | OctaHire</title>
        <meta name="description" content="Discover efficient Staffing Solutions for Employers at Octahire. Streamline your
        hiring process with our comprehensive services. We help you to meet your staffing needs."/>
        <link rel="canonical" href="https://octahire.com/employers" />
      </Helmet>
      <Layout>
        <section className="employer-header-section">
          <div className="ehs-left-section">
          <div className="ls-small-heading">
              <p>OctaHire for Employers</p>
            </div>
            <div className="ls-heading">
              <h1>
              Get Quality Candidates on <span>OctaHire.com</span>
              </h1>
            </div>
            <div className="ls-bullet-points">
              <ul>
                <li>From Intern to Senior Level Hiring.</li>
                <li>
                  Get access to the database of over 5 Lakh candidates.
                </li>
                <li>
                  Quick and easy job posting services.
                </li>
              </ul>
            </div>
            {(!token || (token && portal === "Employer")) && (
              <div className="ls-btn">
                <button type="button" onClick={() => handleNavigation()}>
                  Post Job for Free
                </button>
              </div>
            )}
          </div>
          <div className="ehs-right-section">
            <img src={empLandingPage} alt="smiling-young-businesswoman" />
          </div>
          {(!token || (token && portal === "Employer")) && (
            <div className="ls-btn-mobile">
              <button type="button" onClick={() => handleNavigation()}>
                Post Your First Job
              </button>
            </div>
          )}
        </section>
        <section className="start-posting-section">
          <Container>
            <div className="sps-heading">
              <h3>OctaHire is India’s Leading Hiring Platform</h3>
              <p>
                Find, hire and onboard the perfect candidate for every job.
              </p>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} className="sps-grid">
                <div className="sps-card">
                  <div className="card-content">
                    <img src={fastTime} alt="" />
                    <h4>2 Minutes to Post</h4>
                    <p>
                    Quick and easy job posting with screening questions to filter candidates.
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="sps-grid">
                <div className="sps-card">
                  <div className="card-content">
                    <img src={jobSearchImg} alt="" />
                    <h4>Attract Audience</h4>
                    <p>Reach audiences across different industries.</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="sps-grid">
                <div className="sps-card">
                  <div className="card-content">
                    <img src={calender} alt="" />
                    <h4>Get 30 Days of Visibility</h4>
                    <p>
                      Enjoy top visibility for your job post free for the first 30 days.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="hired-companies-section">
          <div className="hcs-heading">
            <h3>Trusted by 8000+ Recruiters</h3>
          </div>
          <div className="hcs-companies">
            <div>
              <img className="companyImage" src={goodVibes} alt="" />
            </div>
            <div>
              <img  className="companyImage" src={dwellfox} alt="" />
            </div>
            <div>
              <img  className="companyImage" src={zyan} alt="" />
            </div>
            <div>
              <img  className="companyImage" src={infograins} alt="" />
            </div>
            <div>
              <img src={shekunj} alt="" />
            </div>
            <div>
              <img src={protonshub} alt="" />
            </div>
          </div>
        </section>
        {/* <Box className="end-section">
          <Container>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box className="end-section-content">
                  <h3>Join 10,000+ companies who hire with Octahire</h3>
                </Box>
                <Box className="banner-img-section">
                  <ul>
                    <li>
                      <img src={goodVibes} alt="" />
                    </li>
                    <li>
                      <img src={dwellfox} alt="" />
                    </li>
                    <li>
                      <img src={zyan} alt="" />
                    </li>
                    <li>
                      <img src={infograins} alt="" />
                    </li>
                    <li>
                      <img src={shekunj} alt="" />
                    </li>
                    <li>
                      <img src={protonshub} alt="" />
                    </li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box> */}
      </Layout>
    </>
  );
};
export default Employer;
