import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik, useField, useFormikContext } from "formik";
//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//OTHER
import { LoginSchema, LoginWithOTPSchema } from "../../schemas/LoginSchema";
import LoginDropdown from "../../components/Shared/LoginDropdown";
import MuiTextField from "../../mui/TextField/MuiTextField";
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import FieldError from "../../components/Errors/FieldError";
import LinkText from "../../components/Links/LinkText";
import MuiDivider from "../../mui/Dividers/MuiDivider";
import { Toaster } from "../../components/Toaster/Toaster";
//CSS
import "./index.scss";
//REDUX
import {
  setLogin,
  login,
  setToken,
  clearAuthStorage,
} from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import { returnRoleValue } from "../../utils";
import { setPortalInfo } from "../../redux/globalSlice";
import ThirdPartyAuth from "../Auth/ThirdPartyAuth";
import LoginLayout from "./LoginLayout";
import { clearOtps } from "../../redux/verifyOtpSlice";
import { setProfilePhoto } from "../../redux/profileSlice";
import { resendBothOtps } from "../../redux/ResendBothOtpsSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { portalName, portalValue } = useSelector((state) => state.app);
  const { token } = useSelector((state) => state.auth);
  const [toggle_password, setToggle_password] = useState(false);
  const [otpLink, setOtpLink] = useState(false);
  const [initialData, setInitialData] = useState({
    email: "",
    password: "",
    mobile: "",
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("loginBtn").click();
    }
  };

  const togglePasswordHide = () => {
    setToggle_password((toggle_password) => !toggle_password);
  };

  const handleViaEmailOtp = () => {
    setOtpLink(otpLink ? false : true);
  };

  const handleLoginClick = (values, setSubmitting) => {
    let data = {
      user: {
        email: values.email,
        password: values.password,
        type: portalName,
      },
      login_type: "email",
    };
    if (values.email && values.password) {
      dispatch(login(data))
        .then((res) => {
          if (res.type === "auth/login/fulfilled") {
            dispatch(
              setPortalInfo({
                portalName: res?.payload?.data?.user?.type,
                portalValue: returnRoleValue(res?.payload?.data?.user?.type),
              })
            );
            dispatch(setProfilePhoto(res?.payload?.data?.user?.profile_photo));
            dispatch(setToken(res?.payload?.data?.authentication_token));
            dispatch(setLogin(res?.payload?.data?.user));
            res?.payload?.data?.user?.type &&
              localStorage.setItem("portal", res?.payload?.data?.user?.type);
            res?.payload?.data?.authentication_token &&
              localStorage.setItem(
                "token",
                res?.payload?.data?.authentication_token
              );
            res?.payload?.data?.user &&
              localStorage.setItem(
                "login_data",
                JSON.stringify(res?.payload?.data?.user)
              );
            setSubmitting(false);
            Toaster.TOAST(res?.payload?.message, "success");
            if (otpLink) {
              dispatch(clearOtps(null));
              navigate("/otp-verification", {
                state: { email: res?.payload?.data?.user?.email },
                // , replace: true
              });
            } else if (!res?.payload?.data?.user?.otp_verified) {
              dispatch(clearOtps(null));
              dispatch(
                resendBothOtps({ email: res?.payload?.data?.user?.email, mobile_number: res?.payload?.data?.user?.mobile_number })
              )
                .then((res) => {
                  if (res.type === "resendBothOtps/fulfilled") {
                    Toaster.TOAST(res?.payload?.message, "success");
                  }
                })
                .catch((err) => {
                  Toaster.TOAST(err?.message, "error");
                  console.log(err);
                });
              navigate(`/two-factor-verification`, {
                state: {
                  email: res?.payload?.data?.user?.email,
                  mobile_number: res?.payload?.data?.user?.mobile_number,
                },
                replace: true,
              });
            } else if (!res?.payload?.data?.user?.registration_complete) {
              navigate(`/signup-proceed`, { replace: true });
            } else {
              navigate(`/${returnRoleValue(res?.payload?.data?.user?.type)}`, {
                replace: true,
              });
            }
          } else {
            setSubmitting(false);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          console.log(err.message, ".err");
        });
    }
  };

  useEffect(() => {
    localStorage.setItem("form_type", "sign_in");
  }, []);

  return (
    <LoginLayout>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          handleLoginClick(values, setSubmitting);
        }}
        validateOnblur={false}
        validationSchema={LoginSchema}
      >
        {({ values, setErrors, isSubmitting }) => {
          return (
            <Form autoComplete="off">
              <Grid container xs={12} md={10} lg={10}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className="oh-heading">
                    <MuiTypography className="oh-login-title" content="Login as" />
                    <LoginDropdown setErrors={setErrors} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className="oh-account-question">
                    <MuiTypography
                      variant="span"
                      style={{
                        display: "inline-block",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "var(--oh-blue-300-color)",
                        width: "100%",
                        maxWidth: "fit-content",
                        marginRight: "4px",
                      }}
                      content="Don’t have an account?"
                    />
                    <MuiTypography
                      variant="span"
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        width: "fit-content",
                        color: "var(--oh-blue-color)",
                        // width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(
                          "/signup"
                          // , { replace: true }
                        );
                      }}
                      content="Register for Free"
                    />
                  </Box>
                </Grid>
                {!otpLink ? (
                  <>
                    <Grid
                      className="oh-textfield-grid"
                      item
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <PrimaryLabel label="Email ID" />
                      <InputField
                        id="email"
                        type="email"
                        placeholder="Enter your active Email ID / Username"
                        name="email"
                        value={values.email}
                        validateOnChange={true}
                      />
                    </Grid>
                    <Grid
                      className="oh-textfield-grid"
                      item
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <PrimaryLabel label="Password" />
                      <InputField
                        id="password"
                        type={toggle_password ? "text" : "password"}
                        placeholder="Enter your password"
                        name="password"
                        value={values.password}
                        validateOnChange={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ cursor: "pointer" }}
                            >
                              {" "}
                              {toggle_password ? (
                                <Visibility
                                  className="cursor_pointer"
                                  onClick={togglePasswordHide}
                                />
                              ) : (
                                <VisibilityOff onClick={togglePasswordHide} />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <LinkText
                        onClick={() =>
                          navigate("/forgot-password", {
                            state: { from: "Login" },
                            // replace: true
                          })
                        }
                        title="Forgot Password?"
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      className="oh-textfield-grid"
                      item
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <PrimaryLabel label="Mobile Number" />
                      <InputField
                        id="mobile"
                        type="text"
                        placeholder="Enter your active mobile number"
                        name="mobile"
                        value={values.mobile}
                        validateOnChange={true}
                      />
                    </Grid>
                  </>
                )}
                <Grid
                  style={{ margin: "26px 0px" }}
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <CustomLoadingButton
                    loading={isSubmitting}
                    type="submit"
                    title={otpLink ? "Send OTP" : "Login"}
                  />
                </Grid>
                {/* NOTE* currently, there is no OTP services integration, thus, to avoid unnecessary flow break, this code is temporarily  commented  */}
                {/* {!token && (
                  <Grid item xs={12} md={12} lg={12}>
                    <MuiTypography
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textAlign: "center",
                        margin: "2px 0px 32px",
                        color: "var(--oh-blue-color)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleViaEmailOtp();
                        setErrors("");
                      }}
                      content={`Login through ${
                        otpLink ? "Username / Email" : "OTP"
                      }`}
                    />
                  </Grid>
                )} */}
                <Grid item xs={12} md={12} lg={12}>
                  <MuiDivider label="or" />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ThirdPartyAuth />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </LoginLayout>
  );
};

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value, true);
  };

  const error = (validateOnChange || meta.touched) && meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default Login;
