export const large = {
  0: {
    slidesPerView: 1,
  },
  568: {
    slidesPerView: 1.2,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 1.5,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 1.2,
    spaceBetween: 20,
  },
  1225: {
    slidesPerView: 1.5,
    spaceBetween: 20,
  },
  1524: {
    slidesPerView: 2.2,
    spaceBetween: 20,
  },
  2000: {
    slidesPerView: 2.5,
    spaceBetween: 20,
  },
  2500: {
    slidesPerView: 3.5,
    spaceBetween: 20,
  },
};

export const small = {
  0: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  530: {
    slidesPerView: 1.5,
  },
  700: {
    slidesPerView: 2.3,
  },
  900: {
    slidesPerView: 2.5,
  },
  930: {
    slidesPerView: 3,
  },
  1184: {
    slidesPerView: 2.5,
  },
  1525: {
    slidesPerView: 2.7,
  },
  1654: {
    slidesPerView: 2.8,
  },

  2000: {
    slidesPerView: 2.9,
  },
  2500: {
    slidesPerView: 4.5,
  },
};

export const medium = {
  0: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  530: {
    slidesPerView: 1.1,
  },
  700: {
    slidesPerView: 2,
  },
  900: {
    slidesPerView: 2.2,
  },
  930: {
    slidesPerView: 2.3,
  },
  1184: {
    slidesPerView: 2.9,
  },
  1525: {
    slidesPerView: 3.2,
  },
  1654: {
    slidesPerView: 3.5,
  },
};

export const medium_100 = {
  0: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  530: {
    slidesPerView: 1.4,
  },
  700: {
    slidesPerView: 1.95,
  },
  865: {
    slidesPerView: 1.05,
  },
  900: {
    slidesPerView: 1.1,
  },
  930: {
    slidesPerView: 1.2,
  },
  1184: {
    slidesPerView: 1.5,
  },
  1525: {
    slidesPerView: 2.2,
  },
  1654: {
    slidesPerView: 2.5,
  },
};

export const medium_200 = {
  0: {
    slidesPerView: 1,
    spaceBetween: 16,
  },
  360: {
    slidesPerView: 1.2,
    spaceBetween: 16,
  },
  435: {
    slidesPerView: 1.3,
    spaceBetween: 16,
  },
  530: {
    slidesPerView: 1.5,
    spaceBetween: 16,
  },
  570: {
    slidesPerView: 1.7,
    spaceBetween: 16,
  },
  650: {
    slidesPerView: 1.9,
    spaceBetween: 16,
  },
  700: {
    slidesPerView: 2.1,
    spaceBetween: 16,
  },
  800: {
    slidesPerView: 2.3,
    spaceBetween: 16,
  },
  900: {
    slidesPerView: 2.4,
    spaceBetween: 16,
  },
  1000: {
    slidesPerView: 3,
    spaceBetween: 16,
  },
  1300: {
    slidesPerView: 3.1,
    spaceBetween: 16,
  },
  1461: {
    slidesPerView: 2.4,
    spaceBetween: 16,
  },
  1654: {
    slidesPerView: 2.5,
    spaceBetween: 16,
  },
  1760: {
    slidesPerView: 2.8,
    spaceBetween: 16,
  },
  1800: {
    slidesPerView: 3.1,
    spaceBetween: 16,
  },
  2200: {
    slidesPerView: 4,
    spaceBetween: 16,
  },
};

export const medium_300 = {
  0: {
    slidesPerView: 1,
    spaceBetween: 16,
  },
  530: {
    slidesPerView: 1.25,
    spaceBetween: 16,
  },
  700: {
    slidesPerView: 1.6,
    spaceBetween: 16,
  },
  840: {
    slidesPerView: 1.9,
    spaceBetween: 16,
  },
  1000: {
    slidesPerView: 2.2,
    spaceBetween: 16,
  },
  1180: {
    slidesPerView: 2.1,
    spaceBetween: 16,
  },
  1230: {
    slidesPerView: 2.2,
    spaceBetween: 16,
  },
  1330: {
    slidesPerView: 2.4,
    spaceBetween: 16,
  },
  1654: {
    slidesPerView: 3,
    spaceBetween: 16,
  },
  2200: {
    slidesPerView: 4,
    spaceBetween: 16,
  },
};

export const medium_400 = {
  0: {
    slidesPerView: 1,
    spaceBetween: 16,
    spaceBetween: 16,
  },
  530: {
    slidesPerView: 1.3,
    spaceBetween: 16,
  },
  700: {
    slidesPerView: 1.4,
    spaceBetween: 16,
  },
  800: {
    slidesPerView: 1.8,
    spaceBetween: 16,
  },
  865: {
    slidesPerView: 1.1,
    spaceBetween: 16,
  },
  930: {
    slidesPerView: 1.2,
    spaceBetween: 16,
  },
  1184: {
    slidesPerView: 1.5,
    spaceBetween: 16,
  },
  1525: {
    slidesPerView: 2.2,
    spaceBetween: 16,
  },
  1654: {
    slidesPerView: 2.5,
    spaceBetween: 16,
  },
};

export const top_hiring = {
  0: {
    slidesPerView: 1,
    spaceBetween: 16,
  },
  360: {
    slidesPerView: 1.05,
    spaceBetween: 16,
  },
  435: {
    slidesPerView: 1.3,
    spaceBetween: 16,
  },
  600: {
    slidesPerView: 1.8,
    spaceBetween: 16,
  },
  865: {
    slidesPerView: 1.3,
    spaceBetween: 16,
  },
  972: {
    slidesPerView: 1.6,
    spaceBetween: 16,
  },
  1131: {
    slidesPerView: 1.8,
    spaceBetween: 16,
  },
  1231: {
    slidesPerView: 1.4,
    spaceBetween: 16,
  },
  1280: {
    slidesPerView: 1.7,
    spaceBetween: 16,
  },
  1370: {
    slidesPerView: 2,
    spaceBetween: 16,
  },
  1480: {
    slidesPerView: 2.3,
    spaceBetween: 16,
  },
  1654: {
    slidesPerView: 2.5,
    spaceBetween: 16,
  },
  1760: {
    slidesPerView: 2.8,
    spaceBetween: 16,
  },
  1800: {
    slidesPerView: 3.1,
    spaceBetween: 16,
  },
  2200: {
    slidesPerView: 4,
    spaceBetween: 16,
  },
};