import React from "react";

//MUI
import Button from "@mui/material/Button";

//CSS
import "./index.scss";

const MuiTabWithIcon = (props) => {
    const defaultClassName = props.defaultClassName || "oh-tab-btn";
    const startIcon = props.iconComponent ? props.startIcon : <img src={props.startIcon} alt='tab' />
    return (
        <Button
            className={`${defaultClassName} ${props.className}`}
            startIcon={startIcon}
            endIcon={props.endIcon}
            onClick={props.onClick}
            sx={{ ...props.style }}
            type="button"
        >
            {props.label}
        </Button>
    );
}

export default MuiTabWithIcon;