import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { JOB_SEEKER, JOB_SEEKER_DETAIL } from "../../apis/Constant/routes";
import { AxiosApi } from "../../apis/AxiosApi";

const initialState = {};

export const personalInfo = createAsyncThunk(
  "personalInfo",
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${JOB_SEEKER}/${data.userId}${JOB_SEEKER_DETAIL}`,
      type: "POST",
      contentType: "multipart/form-data",
      data: data.data,
    });
  }
);

export const updatePersonalInfo = createAsyncThunk(
  "updatePersonalInfo",
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${JOB_SEEKER}/${data.userId}${JOB_SEEKER_DETAIL}`,
      type: "PUT",
      contentType: "multipart/form-data",
      data: data.data,
    });
  }
);


export const personalInfoSlice = createSlice({
  name: "companyInfo",
  initialState,
  reducers: {},
});

export default personalInfoSlice.reducer;
// export const { setPersonalInfoData } = personalInfoSlice.actions;
