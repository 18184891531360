import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";
import {
  FREELANCER,
  FREELANCER_CANDIDATES,
  FREELANCER_DETAILS,
} from "../../apis/Constant/routes";

const initialState = {
  freelancerData: [],
  loading: false,
  error: false,
  individualFreelancerData: {},
  isUpdateCandidateProfile: false,
  isActiveCandidate: true,
  initialData: {
    fname: "",
    lname: "",
    designation: "",
    experience: "",
    category: [],
    skills: [],
    resume: "",
    suggest: true,
    gender: "Male",
    avtaarImage: "",
  }
};

export const addFreelancerDetails = createAsyncThunk(
  "addFreelancerDetails",
  async ({ data }) => {
    return await AxiosApi({
      path: `${FREELANCER}/${FREELANCER_DETAILS}`,
      type: "POST",
      // contentType: "multipart/form-data",
      data: data,
    });
  }
);
export const updateFreelancerDetails = createAsyncThunk(
  "updateFreelancerDetails",
  async ({ data }) => {
    return await AxiosApi({
      path: `${FREELANCER}/${FREELANCER_DETAILS}`,
      type: "PUT",
      contentType: "multipart/form-data",
      data: data,
    });
  }
);
export const getFreelancerDetails = createAsyncThunk(
  "getFreelancerDetails",
  async () => {
    return AxiosApi({
      path: `${FREELANCER}/${FREELANCER_DETAILS} `,
      type: "GET",
    });
  }
);
export const addFreelancerCv = createAsyncThunk(
  "addFreelancerCv",
  async ({ data }) => {
    return AxiosApi({
      path: `${FREELANCER}/${FREELANCER_CANDIDATES}`,
      type: "POST",
      data: data,
    });
  }
);

export const updateFreelancerCv = createAsyncThunk(
  "updateFreelancerCv",
  async ({ data, id }) => {
    return AxiosApi({
      path: `${FREELANCER}/${FREELANCER_CANDIDATES}/${id}`,
      type: "PUT",
      data: data,
    });
  }
);

export const freelancerDetails = createSlice({
  name: "freelancerDetails",
  initialState,
  reducers: {
    setFreelancerData: (state, action) => {
      state.freelancerData = action.payload;
    },
    setIndividualFreelancerData: (state, action) => {
      state.individualFreelancerData = action.payload;
    },
    setIsUpdateCandidateProfile: (state, action) => {
      state.isUpdateCandidateProfile = action.payload;
    },
    setCandidateDetailsInitialData: (state, action) => {
      state.initialData = action.payload;
    },
    setIsActiveCandidate: (state, action) => {
      state.isActiveCandidate = action.payload;
    },
    clearIndividualFreelancerData: (state, action) => {
      state.individualFreelancerData = {};
      state.isUpdateCandidateProfile = false;
      state.initialData.fname = "";
      state.initialData.lname = "";
      state.initialData.designation = "";
      state.initialData.experience = "";
      state.initialData.category = [];
      state.initialData.skills = [];
      state.initialData.resume = false;
      state.initialData.gender = "Male";
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(addFreelancerDetails.pending, (state, action) => {
  //       state.loading = true;
  //     })
  //     .addCase(addFreelancerDetails.fulfilled, (state, action) => {
  //       state.freelancerData = action.payload;
  //       state.loading = false;
  //       state.error = false;
  //     })
  //     .addCase(addFreelancerDetails.rejected, (state, action) => {
  //       state.error = true;
  //     });
  // },
});
export default freelancerDetails.reducer;
export const { 
  setFreelancerData, 
  setIndividualFreelancerData, 
  clearIndividualFreelancerData, 
  setIsUpdateCandidateProfile,
  setCandidateDetailsInitialData,
  setIsActiveCandidate,
 } = freelancerDetails.actions;
