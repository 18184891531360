import React from "react";
import moment from "moment";

//MUI
import Box from '@mui/material/Box'

//ASSETS
import AirBnbIcon from "../../../../assets/images/Airbnb.svg";
import LocationIcon from "../../../../assets/images/location.svg";
import SuitcaseIcon from "../../../../assets/images/suitcaseIcon.svg";
import CurrencyRupeeIcon from "../../../../assets/images/ruppeeIcon.svg";

//OTHER
import { returnSubstring } from "../../../../utils";
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";

// CSS
import "./index.scss";

const CardHeader = ({
    jobData = {},
}) => {
    const location = jobData?.locations?.map((location) => location.name)?.join(", ");

    return (
        <>
            <Box className="oh-details-card-header">
                <Box sx={{ display: "flex", gap: "10px"}}>
                <img src={AirBnbIcon} alt={"logo"} />
                <Box className="job-desc-box">
                    <MuiTypography content={returnSubstring(jobData?.title || jobData?.job_title, 25)} className="oh-title" />
                    <MuiTypography content={jobData?.job_number || jobData?.category?.name} className="oh-subtitle" />
                </Box>
                </Box>
                {jobData?.created_at && <MuiTypography content={`Posted: ${moment(jobData?.created_at).fromNow()}`} className={`oh-posted-timestamp-text`} />}
            </Box>
            <Box className="oh-card-body">
                <Box className="oh-requirement-box">
                    {jobData?.experience && <MuiTypography variant="span" className="oh-requirements">
                        <img src={SuitcaseIcon} className="suitcase-icon" alt={"work-exp"} /> {jobData?.experience}
                    </MuiTypography>}

                    {jobData?.ctc && <MuiTypography variant="span" className="oh-requirements">
                        <img src={CurrencyRupeeIcon} className="rupee-icon" alt={"salary"} /> {jobData?.ctc}
                    </MuiTypography>}

                    {location && <MuiTypography className="oh-requirements">
                        <img src={LocationIcon} className="location-icon" alt={"locations"} /> {location}
                    </MuiTypography>}
                </Box>
            </Box>

        </>
    )
}

export default CardHeader;