import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../apis/AxiosApi";
import {
  AUTHS,
  GOOGLE_AUTH_URL,
  GOOGLE_OMNI_AUTH,
} from "../apis/Constant/routes";

const initialState = {
  user: null,
  token: "",
};

export const googleUri = createAsyncThunk(
  "googleAuthUri",
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${AUTHS}${GOOGLE_AUTH_URL}?redirect_url=${data.redirect_url}${
        data.redirect_url === "http://localhost:3000" ? "/" : "/auth-callback"
      }`,
      type: "GET",
      params: "",
    });
  }
);

// &flow_type=${data.form_type}
export const googleAuthLogin = createAsyncThunk(
  "googleAuth/login",
  async (data, thunkApi) => {
    return await AxiosApi({
      path: `${AUTHS}${GOOGLE_OMNI_AUTH}?code=${data.code}&redirect_url=${
        data.redirect_url
      }${
        data.redirect_url === "http://localhost:3000" ? "/" : "/auth-callback"
      }&user_type=${data.user_type}`,
      type: "POST",
      params: "",
    });
  }
);

export const googleAuthSlice = createSlice({
  name: "googleAuth",
  initialState,
  reducers: {},
});
export default googleAuthSlice.reducer;
