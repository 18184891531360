import { EMPLOYER, FREELANCER, JOB_SEEKER } from "./constant";

// check roles
const matchRole = (portalName, nameToMatch) => {
  return portalName === nameToMatch;
};

export const checkIfEmployer = (portalName) => {
  return matchRole(portalName, EMPLOYER);
};

export const checkIfJobSeeker = (portalName) => {
  return matchRole(portalName, JOB_SEEKER);
};

export const checkIfFreelancer = (portalName) => {
  return matchRole(portalName, FREELANCER);
};

// check path names
export const checkIfAuthPage = () => {
  const path = window.location.pathname;
  const routes = [
    "/",
    "/login",
    "/signup",
    "/signup-proceed",
    "/forgot-password",
    "/reset-password",
    "/two-factor-verification",
    "/otp-verification",
    "/landingPage",
    "/hr-policy",
    "/searchJobs",
    "/job-description",
    "/frequently-asked",
    "/contact-us",
    "/privacy-policy",
    "/term-condition",
    "/help-support",
    "/blogs",
    "/blogs/",
    "/search-jobs",
    "/faq",
    "/freelancers",
    "/employers",
    "/jobseekers"
  ];
  return (
    routes.includes(path) ||
    window.location.pathname.includes("/blogs") ||
    window.location.pathname.includes("/search-jobs") ||
    window.location.pathname.includes("/searchJobs")
  );
};

export const returnPathName = () => {
  const path = window.location.pathname.split("/");
  return path[1];
};
//maximum character err msg function
export const errMessageMaximum = (name, digit) => {
  const errorMessage = `${name} must be at most ${digit} words`;
  return errorMessage;
};

//minimum character err msg function
export const errMessageMinimum = (name, digit) => {
  const errorMessage = `${name} must be at atleast ${digit} characters`;
  return errorMessage;
};

// check for portal
export const returnRoleValue = (portal) => {
  switch (portal) {
    case "Freelancer":
      return "freelancer";
    case "Employer":
      return "employer";
    default:
      return "jobseeker";
  }
};

// check for substring
export const returnSubstring = (str, num) => {
  if (!str) {
    return null;
  } else if (str.length > num) {
    return str.substring(0, num) + "...";
  }
  return str;
};

//  clear localStorage
export const clearLocalStorage = () => {
  localStorage.clear();
};

// get years data from present till past 100 years
// can be used only in mui select because of the data format being used
export const getYearsData = (present_year, length = 101) => {
  const yrsData = [];
  for (var i = length; i > 0; i--) {
    present_year--;
    yrsData.push({ label: present_year, value: present_year });
  }
  return yrsData;
};

// download function
export const handleDownload = async (url) => {
  const FileSaver = require("file-saver");
  FileSaver.saveAs(url, "document");
};

// check if a string contains html
export const checkIfHtml = (str) => {
  let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  let isValid = regexForHTML.test(str);
  return isValid;
};

// Function to parse the HTML content
export const parseHTML = (htmlString) => {
  if (htmlString !== undefined) {
    htmlString = htmlString.replace(/^"|"$/g, "");
    return { __html: htmlString };
  }
};

export const cleanJobDescription = (description) => {
  if (!description) return "";
  // Remove all HTML tags
  const withoutTags = description.replace(/<\/?[^>]+(>|$)/g, "");
  // Remove double quotes
  const withoutQuotes = withoutTags.replace(/['"]/g, "");
  return withoutQuotes;
};

// Function to truncate the job description to a specified limit
export const truncateDescription = (description, limit) => {
  if (!description) return "";

  // Truncate the description
  let truncatedDescription =
    description.length > limit
      ? description.substring(0, limit) + "..."
      : description;

  // Remove leading and trailing double quotes
  truncatedDescription = truncatedDescription.replace(/^"|"$/g, "");

  return truncatedDescription;
};


export const truncateCharacter = (description, limit) => {
  if (!description) return "";

  // Trim leading and trailing spaces
  description = description.trim();

  // Truncate the description only if it exceeds the limit
  if (description.length > limit) {
    return description.substring(0, limit).trim() + "...";
  }

  return description; // Return the original description if it fits within the limit
};

export const formatAmount = (amount) => {
  const suffixes = ["", "K", "M", "B", "Tr", "Quad", "Quint"];

  if (amount < 1000) {
    return amount.toString();
  }

  const suffixIndex = Math.floor(Math.log10(amount) / 3);
  const adjustedAmount = amount / Math.pow(1000, suffixIndex);

  return adjustedAmount.toFixed(1) + suffixes[suffixIndex];
};

// smooth scroll function
export const smoothScroll = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, 500);
};
