import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../apis/AxiosApi";
import {
  DELETE_RESUME,
  DOWNLOAD_RESUME,
  JOB_SEEKER,
  JOB_SEEKER_DETAIL,
} from "../../apis/Constant/routes";

const initialState = {
  data: [],
  personalData: [],
  loading: false,
  error: false,
  jobseekerOtherDetailsData: {
    educationDetails: [{ year: null, university: "", percentage: "" }],
    experienceDetails: [{ year: null, company: "", designation: "" }],
    skills: [],
    locations: [],
    categories: [],
    ctc: "",
    ectc: "",
    experties_in: [],
    english: 0,
    hindi: 0,
  },
};

export const getPersonalDetailApi = createAsyncThunk(
  "personal_details/list",
  async (id, thunkApi) => {
    return AxiosApi({
      path: `${JOB_SEEKER}/${id}${JOB_SEEKER_DETAIL} `,
      type: "GET",
    });
  }
);

export const getDesignationsList = createAsyncThunk(
  "getDesignationsList",
  async (id, thunkApi) => {
    return AxiosApi({
      path: `/get_designations_list`,
      type: "GET",
    });
  }
);

export const addPersonalDetailApi = createAsyncThunk(
  "personal_details/add",
  async (params, thunkApi) => {
    return await AxiosApi({
      path: `${JOB_SEEKER}/${params.id}${JOB_SEEKER_DETAIL}`,
      type: "POST",
      contentType: "multipart/form-data",
      data: params.data,
    });
  }
);

export const editPersonalDetailApi = createAsyncThunk(
  "personal_details/edit",
  async (params, thunkApi) => {
    return await AxiosApi({
      path: `${JOB_SEEKER}/${params.id}${JOB_SEEKER_DETAIL}`,
      type: "PUT",
      data: params.data,
    });
  }
);

export const deleteResumeApi = createAsyncThunk(
  "personal_details/delete_resume",
  async (params, thunkApi) => {
    return await AxiosApi({
      path: `${JOB_SEEKER}/${params.id}${JOB_SEEKER_DETAIL}/${params.dataId}${DELETE_RESUME}`,
      type: "DELETE",
    });
  }
);

export const downloadResumeApi = createAsyncThunk(
  "personal_details/download_resume",
  async (params) => {
    return await AxiosApi({
      path: `${JOB_SEEKER}/${params.id}${JOB_SEEKER_DETAIL}${DOWNLOAD_RESUME}`,
      type: "GET",
      isdownload: true,
    });
  }
);

export const createPersonalDetail = createSlice({
  name: "personal_details",
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.data = action.payload;
    },
    setJobseekerDetailsData: (state, action) => {
      state.jobseekerOtherDetailsData = action.payload;
    },
    resetJobseekerDetailsData: (state, action) => {
      state.jobseekerOtherDetailsData.educationDetails = [{ year: null, university: "", percentage: "" }];
      state.jobseekerOtherDetailsData.experienceDetails = [{ year: null, company: "", designation: "" }];
      state.jobseekerOtherDetailsData.skills = [];
      state.jobseekerOtherDetailsData.locations = [];
      state.jobseekerOtherDetailsData.categories = [];
      state.jobseekerOtherDetailsData.ctc = "";
      state.jobseekerOtherDetailsData.ectc = "";
      state.jobseekerOtherDetailsData.experties_in = [];
      state.jobseekerOtherDetailsData.english = 0;
      state.jobseekerOtherDetailsData.hindi = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPersonalDetailApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPersonalDetailApi.fulfilled, (state, action) => {
        state.personalData = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(getPersonalDetailApi.rejected, (state, action) => {
        state.error = true;
      });
  },
});

export default createPersonalDetail.reducer;
export const { addPersonalDetail, setJobseekerDetailsData, resetJobseekerDetailsData } = createPersonalDetail.actions;
