import React, { forwardRef, useRef } from "react";
//MUI
import TextField from "@mui/material/TextField";
import "./index.scss";

const MuiTextField = forwardRef(
  (
    {
      fullWidth = true,
      id = "",
      type = "text",
      placeholder = "",
      name = "",
      className = "",
      value = "",
      onChange = () => {},
      onBlur = () => {},
      error = false,
      helperText = "",
      InputProps = {},
      props = {},
      param = {},
      disabled = false,
      autoComplete = "off",
    },
    ref
  ) => {
    const innerRef = useRef(null);
    const inputRef = ref || innerRef;

    return (
      <TextField
        ref={inputRef}
        variant="outlined"
        fullWidth={fullWidth}
        autoComplete={autoComplete}
        id={id}
        type={type}
        placeholder={placeholder}
        name={name}
        className={`oh-primary-textfield ${className}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={InputProps}
        disabled={disabled}
        error={error}
        helperText={helperText}
        {...props}
        {...param}
      />
    );
  }
);

export default MuiTextField;
