import React, { useState } from "react";
//MUI
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
//OTHER
import PrimaryTab from "../../../mui/Tabs/MuiPrimaryTab/PrimaryTab";
import AddCandidateDrawer from "../CandidateDrawer/AddDrawer/AddDrawer";
import Filter from "../../Filter/Filter";
import FilledChip from "../../customChips/FilledChips";
//CSS
import "./index.scss";
import { useDispatch } from "react-redux";
import { setIsUpdateCandidateProfile } from "../../../redux/Freelancer/freelancerDetailsSlice";

const TableHeader = ({
  className,
  children,
  tabData,
  value,
  handleChange,
  setRowCount = () => {},
  renderExtraOptions = null,
  isAddDrawerOpen,
  onClose = () => { },
}) => {
  const dispatch = useDispatch();
  const [popupState, setPopupState] = useState("");

  const handlePopupState = (popupToHandle = "") => {
    return setPopupState(popupToHandle);
  };

  const handleOnAddCvClick = () => {
    dispatch(setIsUpdateCandidateProfile(false));
    onClose();
  }

  return (
    <>
      <Box className={`oh-candidate-box ${className}`}>
        <PrimaryTab
          value={value}
          tabData={tabData}
          handleChange={handleChange}
          className={"active-tabs"}
        />
        <Box sx={{
          display: 'flex',
          justifyContent: 'end',
          minWidth: "fit-content",
          maxWidth: "50%",
          alignItems: "center",
          gap: "0px 10px",
        }}>
          <Box sx={{
            marginLeft: "10px",
            display: "flex"
          }}>
            {value === "active_candidates" && (
              <FilledChip
                sx={{ cursor: "pointer", minWidth: "fit-content", fontSize: "12px", padding: "0px 8px" }}
                onClick={() => handleOnAddCvClick()}
                icon={
                  <AddIcon
                  sx={{
                      transform: "translateY(3px)",
                      "&.MuiChip-icon": {
                        color: "#fff",
                        "&:hover": {
                          color: " #fff",
                        },
                      },
                    }}
                  />
                }
                size={"medium-300"}
                label={"Add CV"}
              />
            )}
          </Box>
          <Box sx={{
            marginRight: "5px"
          }}>
            {renderExtraOptions}
          </Box>
        </Box>
      </Box>
      <Box>{children}</Box>
      {isAddDrawerOpen && <AddCandidateDrawer
        setRowCount={setRowCount}
        openAddDrawer={isAddDrawerOpen}
        onClose={() => onClose()}
        title={"Add Candidate"}
        isFooter={false}
      />}
      <Filter onClose={handlePopupState} open={popupState === "filter"} />
    </>
  );
};

export default TableHeader;
