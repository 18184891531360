import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../apis/AxiosApi";

const initialState = {};

export const getPrivacyPolicies = createAsyncThunk(
  "getPrivacyPolicies",
  async () => {
    return AxiosApi({
      path: `/privacy_policies/`,
      type: "GET",
      params: "",
    });
  }
);

export const getTermsCondition = createAsyncThunk(
  "getTermsCondition",
  async () => {
    return AxiosApi({
      path: `/terms_and_conditions/`,
      type: "GET",
      params: "",
    });
  }
);

export const privacyPolicy = createSlice({
  name: "privacyPolicy",
  initialState,
  reducers: {},
});

export default privacyPolicy.reducer;
export const {} = privacyPolicy.actions;
