import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import Grid from "@mui/material/Grid";

//OTHER
import BannerImage from "../../components/Banner/BannerImage";
import PrimaryWrapper from "../../components/Wrappers/PrimaryWrapper";
import AllCandidateTable from "./AllCandidateTable";

//CSS
import "./Dashboard.scss";
import { Box, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";
import GenericSwiper from "../../swiper/CustomSwiper";
import {
  getOpenJobs,
  setOpenPosition,
} from "../../redux/Employer/JobPostSlice";
import EmployerLayout from "../Layouts/Employer/EmployerLayout";
import NoJobsFound from "../../components/NotFound/NoJobsFound";
import { getEmployerStats, setStatsData } from "../../redux/statsDataSlice";
import openPosition from "../../assets/images/open-postion.svg";
import applicant from "../../assets/images/new-applicant.svg";
import shortlisted from "../../assets/images/shortlisted.svg";
import hiringSuccess from "../../assets/images/hiring-success.svg";
import JobPostCard from "../../components/JobPostBox/JobPostCard";
import { setACTFilter, setACTPage, setACTRowCount } from "../../redux/tableSlice";
import FilterMenu from "./FilterMenu";
import { ACTFilterDashboardMenuItems } from "../../Data";
import FilterIconWithBadge from "./FilterIconWithBadge";
import OutlinedChip from "../../components/customChips/OutlinedChip";


const Dashboard = () => {
  const dispatch = useDispatch();
  const openPositions = useSelector((state) => state.jobPost.openPositions);
  const dashboardStatsData = useSelector((state) => state.statsData.dashboardStatsData);

  const { user } = useSelector((state) => state.auth);
  const { ACTFilter } = useSelector(state => state.tableData);


  const Jobs = [openPosition, applicant, shortlisted, hiringSuccess];



  const getStatsData = () => {
    dispatch(getEmployerStats(user?.id))
      .then((res) => {
        if (res.type === "getEmployerStats/fulfilled") {
          dispatch(setStatsData(res?.payload?.data?.attributes));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getOpenJobsData = () => {
    dispatch(getOpenJobs())
      .then((res) => {
        if (res.type === "getOpenJobs/fulfilled") {
          dispatch(setOpenPosition(res?.payload?.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getOpenJobsData();
    return () => {
      dispatch(setACTFilter("all"));
      dispatch(setACTPage(1));
      dispatch(setACTRowCount(null));
    }
  }, []);

  useEffect(() => {
    if (user?.id) {
      getStatsData();
    }
  }, [user]);

  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/job-openings/job-details/${id}`);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };


  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  const handleFilter = (param) => {
    dispatch(setACTFilter(param));
    dispatch(setACTPage(1));
    handleClose();
  }

  return (
    <EmployerLayout>
      <Grid container lg={12}>
        <Grid item xs={12} md={12} lg={12}>
          <BannerImage
            type="employer"
            title={user?.organization_name}
          />

          <Grid
            sx={{
              margin: "0px",
              width: "auto"
            }}
            spacing={2}
            lg={12}
            className="oh-quad-cards"
            container
          >
            {dashboardStatsData?.map((item, idx) => (
              <Grid className="oh-quad-card-inner-box" item xs={6} md={6} lg={3} key={item.id}>
                <Box>
                  <div className="oh-dashboardInner-box">
                    <span className="ellipse-bg">
                      <img
                        src={Jobs[idx]}
                        alt="ellipse"
                        style={{ paddingLeft: "7px", paddingTop: "27px" }}
                      />
                    </span>
                    <span>
                      <span className="total-count-stats">{item.total_count}<span style={{ fontSize: "18px", marginLeft: "2px" }}>{dashboardStatsData?.length - 1 == idx && "%"}</span></span>
                      <p className="stats-label">{item.name}</p>
                    </span>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>

          <PrimaryWrapper
            content={`Recent Job Post ${openPositions?.length ? "(" + openPositions?.length + ")" : ""
              } `}
            linkTitle={openPositions?.length > 1 ? "View all" : ""}
            onClick={() =>
              openPositions?.length
                ? navigate("/open-positions")
                : {}
            }
          >
            <GenericSwiper configSize="medium_100">
              <Grid container>
                {openPositions?.length > 0 ? (
                  openPositions.map((elem, indx) => {
                    return (
                      <Grid item sx={12} md={12} lg={12} key={indx}>
                        <SwiperSlide>
                          <JobPostCard
                            handleNavigate={() =>
                              handleNavigate(elem.attributes.id)
                            }
                            size={"medium-100"}
                            // footer_primary={true}
                            // footerText={elem.attributes.applicants_count}
                            data={elem.attributes}
                            footerBtn={false}
                          />
                        </SwiperSlide>
                      </Grid>
                    );
                  })
                )
                  : (
                    <NoJobsFound value={"No open jobs found"} />
                  )}
              </Grid>
            </GenericSwiper>
          </PrimaryWrapper>

          {/* candidate table */}
          <Card className="dashboard-table-card">
            <div style={{
              padding: "20px",
              fontSize: "16px",
              fontWeight: 600,
              color: "var(--oh-blue-color)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
              <span>All Candidates</span>

              <OutlinedChip
                style={{
                  cursor: "pointer"
                }}
                icon={<FilterIconWithBadge filter={ACTFilter} value={'all'} />}
                label="Filter"
                size="medium-300"
                onClick={handleClick}
              />


              <FilterMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                handleFilter={handleFilter}
                filter={ACTFilter}
                menuItems={ACTFilterDashboardMenuItems}
              />
            </div>
            <AllCandidateTable />
            {/* <PlacedCandidateTable />  */}
          </Card>
        </Grid>
      </Grid>
    </EmployerLayout>
  );
};
export default Dashboard;
