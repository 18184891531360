import React, { useState } from "react";

//MUI
import Box from "@mui/material/Box";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

//OTHER
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import MuiIconChip from "../../../mui/Chip/IconChip";
import Filter from "../../Filter/Filter";
import SortBy from "../../Sort/Sort";

//CSS
import "./index.scss";

const ContentWithIcon = ({ className, content, data }) => {
  const [popupState, setPopupState] = useState("");

  const handlePopupState = (popupToHandle = "") => {
    return setPopupState(popupToHandle);
  };

  const handleClick = (event) => {
    handlePopupState("sort");
  };
  return (
    <>
      <Box className={`oh-allot-postbox ${className}`}>
        <MuiTypography className="oh-content" content={content} />

        <Box>
          <MuiIconChip
            icon={<FilterAltIcon />}
            label="Filter"
            onClick={() => handlePopupState("filter")}
            className="oh-iconchip-box"
            spanClass={"oh-span"}
          />
          {/* <SortBy
                        // open={popupState === "sort"}
                        // onClose={handlePopupState}
                        // onClick={(event) => handleClick(event)}
                    /> */}
        </Box>
      </Box>
      <Filter
        data={data}
        onClose={handlePopupState}
        open={popupState === "filter"}
      />
    </>
  );
};

export default ContentWithIcon;
