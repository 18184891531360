import React from "react";
import Box from "@mui/material/Box";
import PrimaryMenu from "../Menu/PrimaryMenu";
import { useSelector } from "react-redux";
const CustomNavbar = () => {
  const { isPublicHeader } = useSelector((state) => state.app);
  return (
    <>
      <nav>
        <Box display={"flex"}>
          {isPublicHeader && (
            <>
              <PrimaryMenu
                menuTitle="Employers"
                navigateTo="/employers"
              />
              <PrimaryMenu
                menuTitle="Jobseekers"
                navigateTo="/jobseekers"
              />
              <PrimaryMenu
                menuTitle="Freelancers"
                navigateTo="/freelancers"
              />
              <PrimaryMenu
                menuTitle="HR Documents"
                menuItems={[
                  {
                    title: "HR Policy",
                    to: "/hr-policy",
                  },
                  {
                    title: "Job Descriptions",
                    to: "/job-description",
                  },
                ]}
              />
            </>
          )}{" "}
          <PrimaryMenu menuTitle="Blog and Articles" navigateTo="/blogs" />
          <PrimaryMenu menuTitle="Contact us" navigateTo="/contact-us" />
        </Box>
      </nav>
    </>
  );
};

export default CustomNavbar;
