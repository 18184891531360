import React from "react";
import "./index.scss";

const FilledChip = ({
  label = "",
  size = "small-100",
  color = "var(--oh-blue-300-color)",
  icon = null,
  cursor = false,
  onClick = () => { },
  className = "",
  sx = {},
}) => {
  return (
    <span
      onClick={onClick}
      className={`oh-filled-chip-${size} ${className}`}
      style={{
        background: color,
        cursor: cursor ? "pointer" : "default",
        ...sx
      }}
    >
      {icon && <span className="oh-iconChip">{icon}</span>}
      {label}
    </span>
  );
};

export default FilledChip;
