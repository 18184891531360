import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Loader from "../Loader/Loader";
// CSS
import "./index.scss";
import { useSelector } from "react-redux";

const NotAuthorized = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const { portalValue } = useSelector(state => state.app)

    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 2000)
    }, [])

    const navigateBack = () => {
        navigate(`/${portalValue}`
            // , { replace: true }
        );
    };

    return (
        <Box container className="ma-404container">
            <div className="ma-not-authorized-box">
                {
                    <>
                        <h1>Not Authorized</h1>
                        {/* <img src={PageNotFoundImage} alt="404" className="img-fluid" /> */}
                        <p>You are not authorized to view or access this page. Please click <a onClick={navigateBack}>back</a> to continue...</p>
                    </>
                }
            </div>
        </Box>
    );
};
export default NotAuthorized;
