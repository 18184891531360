import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from "@mui/icons-material/Close";
import DefaultImage from "../../assets/images/job_seeker.svg"
import "./index.scss"
import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setFillProfileFirst } from '../../redux/globalSlice';
import { useDispatch } from 'react-redux';

export default function FillProfileDialogBox({ heading, popupState, handleClose = () => { } }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <Dialog
            open={popupState}
            onClose={handleClose}
            className="profile-dialog-parent-container"
            sx={{ margin: 'auto' }}
        >

            <DialogTitle className='welcome-dialog-title'>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar
                        sx={{
                            width: "90px",
                            height: "90px",
                            borderRadius: "50%",
                            marginBottom: "10px"
                        }}
                        src={DefaultImage}
                        aria-label="recipe"
                        alt="Remy Sharp"
                        className="oh-profile-avatar"
                    />
                    <div style={{ marginTop: '5px', textAlign: 'center' }}>
                        <h4 style={{ fontSize: "20px", fontWeight: 600, color: "var(--oh-blue-300-color)" }}>
                            Please fill your profile to continue applying to this job!                            </h4>
                        <p style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "var(--oh-blue-600-color)",
                            textAlign: "left"
                        }}>
                            Mandatory details to be filled for applying to the job are :-
                            <ul style={{ marginTop: "10px" }}>
                                <li>
                                    Education Details
                                </li>
                                <li>
                                    Experience Details
                                </li>
                                <li>
                                    Resume
                                </li>
                                <li>
                                    Skills
                                </li>
                                <li>
                                    Locations
                                </li>
                            </ul>
                        </p>
                        <Button sx={{
                            background: "var(--oh-white-200-color)",
                            border: "1px solid var(--oh-white-300-color)",
                            fontWeight: 600, fontSize: "13px", letterSpacing: "0.8px", minWidth: "100px",
                            padding: "10px"
                        }} onClick={() => {
                            dispatch(setFillProfileFirst(false));
                            navigate("/personal-details")
                        }}
                        >
                            Go To Profile
                        </Button>
                    </div>
                </div>
                <Button className="ma-cross-btn" onClick={handleClose} color="primary" autoFocus>
                    <CloseIcon />
                </Button>
            </DialogTitle>
        </Dialog >
    )
}
