import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../apis/AxiosApi";

export const helpAndSupport = createAsyncThunk(
  "helpAndSupport",
  async (data) => {
    return await AxiosApi({
      path: `/help_and_supports`,
      type: "POST",
      data: data,
    });
  }
);

export const contactUs = createAsyncThunk("contactUs", async (data) => {
  return await AxiosApi({
    path: `/contact_us`,
    type: "POST",
    data: data,
    toAppendAuthParams: false,
  });
});
