import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import BlogCard from "../../components/blogPage/BlogCard";
import Layout from "../../components/Layout/Layout";
import { useDispatch } from "react-redux";
import { getBlogs } from "../../../redux/LandingPage/LandingPageSlice";
import GreaterThanIcon from "@mui/icons-material/ArrowForward";
import { BLOGS_PER_PAGE } from "../../../routes/constants";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const dispatch = useDispatch();
  const blogsPerPage = BLOGS_PER_PAGE;
  const [pageNo, setPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    getAllBlogsList();
  }, [pageNo]);

  const getAllBlogsList = () => {
    dispatch(getBlogs({ pageNo, blogsPerPage }))
      .then((res) => {
        if (res?.error) throw new Error("Something went wrong!");
        const apiData = res?.payload?.data;
        const metaData = res?.payload?.meta;
        setBlogs((prev) => [...prev, ...apiData]);
        setTotalRecords(metaData?.total_records);
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const handleViewMore = () => {
    setPageNo((prev) => prev + 1);
  };

  return (
    <>
     <Helmet>
        <title>Blog | OctaHire</title>
        <meta name="description" content="Explore Octahire's insightful blogs. Gain valuable insights into staffing, hiring tips,
        and industry trends. Stay informed and inspired with us!"/>
        <link rel="canonical" href="https://octahire.com/blogs" />
      </Helmet>
      <Layout>
        <Box
          marginTop={{ xs: "75px", sm: "80px" }}
          backgroundColor={"#F1F3FC"}
          textAlign={"center"}
          padding={{ xs: "30px 0px", sm: "40px 0px" }}
          // display={"flex"}
          // justifyContent={"center"}
          // alignItems={"center"}
          // height={{ xs: "94px", sm: "188px" }}
        >
          <Typography
            fontSize={{ xs: "20px", sm: "24px" }}
            fontWeight={700}
            color={"#141621"}
          >
            Blogs and Articles
            <Typography className={"subheading oh-description"} style={{ color: "rgb(123 127 139)", fontSize: 18}}>
            Read the latest updates, industry news, interview tips, recruitment trends and career advice on OctaHire.com*
          </Typography>
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          paddingX={{ xs: "20px", sm: "40px" }}
          paddingY={"20px"}
          // marginBottom={{ xs: "40px", sm: "80px" }}
          marginTop={{ xs: "10px" }}
        >
          {blogs?.map((blog) => {
            const data = blog?.attributes;
            return (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <BlogCard
                  id={blog?.id}
                  image={data?.photo?.url}
                  tag={data?.tag}
                  date={data?.created_at}
                  category={data?.blog_category_name || "N/A"}
                  title={data?.title}
                  description={data?.description}
                  slug={data?.slug}
                />
              </Grid>
            );
          })}
        </Grid>
        {blogs?.length < totalRecords && (
          <Box
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={"40px"}
            marginBottom={"80px"}
          >
            <Button
              endIcon={<GreaterThanIcon />}
              style={{
                border: "1px solid #DEE4FF",
                borderRadius: "30px",
                padding: "12px 24px",
                color: "#445386",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                cursor: "pointer",
              }}
              onClick={handleViewMore}
            >
              View More
            </Button>
          </Box>
        )}
      </Layout>
    </>
  );
};

export default Blog;
