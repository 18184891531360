import * as Yup from "yup";
import { EMPTY_SPACES, STRONGPASSWORD_REGEX } from "../utils/regex";
import { errMessageMaximum, errMessageMinimum } from "../utils";

export const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .matches(EMPTY_SPACES, "Password can not contain empty spaces.")
    .required("Old Password can't be empty"),
  new_password: Yup.string()
    .matches(
      STRONGPASSWORD_REGEX,
      "Password must be at least one uppercase, lowercase, special character and number"
    )
    .matches(EMPTY_SPACES, "Password can not contain empty spaces.")
    .max(40, errMessageMaximum("New Password", 40))
    .min(8, errMessageMinimum("New Password", 8))
    .required("New Password can't be empty"),
  confirm_password: Yup.string()
    .matches(EMPTY_SPACES, "Password can not contain empty spaces.")
    .oneOf([Yup.ref("new_password")], "Confirm Password do not match")
    .required("Confirm Password can't be empty"),
});
