import React from "react";
import {
  checkIfHtml,
  parseHTML,
  returnSubstring,
  truncateDescription,
} from "../../../utils";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const RenderJD = ({
  className = "job-description-employer",
  job_description,
  showFullDescription,
  toggleDescription,
  length = 200,
  viewMoreFunctionality = true,
}) => {
  return (
    <>
      {job_description &&
        (checkIfHtml(job_description) ? (
          <div
            className={className}
            style={{
              maxHeight: showFullDescription ? "none" : "200px",
              overflow: "hidden",
              transition: "all 0.5s ease-in",
            }}
            dangerouslySetInnerHTML={parseHTML(
              showFullDescription
                ? job_description
                : truncateDescription(job_description, length)
            )}
          />
        ) : (
          <MuiTypography
            className={className}
            style={{
              maxHeight: showFullDescription ? "none" : "200px",
              overflow: "hidden",
              transition: "all 0.5s ease-in",
            }}
            content={
              showFullDescription
                ? job_description
                : returnSubstring(job_description, length)
            }
          />
        ))}

      {/* Render the "View More"/"View Less" button */}
      {job_description &&
        viewMoreFunctionality &&
        job_description.length > length && (
          <Box sx={{ mt: 2 }}>
            <Typography
              className="oh-more-view"
              onClick={toggleDescription}
              style={{ cursor: "pointer", color: "blue" }}
            >
              {showFullDescription ? (
                <Box display={"flex"} alignItems={"center"}>
                  <KeyboardArrowUpIcon />
                  View Less
                </Box>
              ) : (
                <Box display={"flex"} alignItems={"center"}>
                  <KeyboardArrowDownIcon />
                  View More
                </Box>
              )}
            </Typography>
          </Box>
        )}
    </>
  );
};

export default RenderJD;
