import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { Box } from '@mui/material'
import { Toaster } from '../Toaster/Toaster'
import MuiTypography from '../../mui/Typography/MuiTypograpghy'
import {
  saveJob,
  setAllSavedJobs,
  setIsUpdate,
  setSavedId,
  unsaveJob
} from '../../redux/JobSeeker/savedJobsSlice'
import "./SaveUnSaveJobs.scss";

const SaveUnSaveJobs = ({
  saveData = {},
  updateData = [] || {},
  setUpdateData = () => {}
}) => {
  const [isSavedJob, setIsSavedJob] = useState(saveData?.saved_job)
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.auth)

  useEffect(() => {
    if (saveData?.saved_job) {
      setIsSavedJob(saveData?.saved_job)
    }
  }, [saveData])

  const updateJobData = (jobData, saveData, newSavedState) => {
    if (jobData?.id == saveData?.id) {
      return {
        ...jobData,
        attributes: {
          ...jobData.attributes,
          saved_job: newSavedState
        }
      }
    }
    return jobData
  }

  const onToggleSave = () => {
    if (token) {
      const newSavedState = !isSavedJob
      setIsSavedJob(newSavedState)
      dispatch(setIsUpdate(false))

      const action = newSavedState ? saveJob : unsaveJob
      const successType = newSavedState
        ? 'create-saveJob/fulfilled'
        : 'unsave-job/fulfilled'

      const data = { data: { job_post_id: saveData?.id } }

      dispatch(action({ data }))
        .then(res => {
          if (res.type === successType) {
            let updatedJobs
            let result
            if (Array.isArray(updateData)) {
              updatedJobs = updateData?.map(elem =>
                updateJobData(elem, saveData, newSavedState)
              )
              result = updatedJobs?.filter(elem => elem?.attributes?.saved_job)
            } else if (updateData?.id) {
              updatedJobs = updateJobData(updateData, saveData, newSavedState)
            }

            // let result = updatedJobs?.filter(
            //   elem => elem?.attributes?.saved_job
            // )
            dispatch(setSavedId(saveData?.id))
            dispatch(setAllSavedJobs(result))
            setUpdateData(updatedJobs)
            dispatch(setIsUpdate(true))
            Toaster.TOAST(res?.payload?.message, 'success')
          }
        })
        .catch(err => {
          setIsSavedJob(!newSavedState)
          dispatch(setIsUpdate(false))
          console.log(err.message, '.err')
        })
    } else {
      Toaster.TOAST('Please Login First', 'error')
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        color: `var(--oh-blue-300-color)`
      }}
      onClick={onToggleSave}
      className="save-unsave-box"
    >
      {isSavedJob ? <BookmarkIcon /> : <BookmarkBorderIcon />}
      <MuiTypography content='Save' className='save-text'/>
    </Box>
  )
}

export default SaveUnSaveJobs
