import React from "react";
import FilledChip from "../customChips/FilledChips";
import ExtrasTooltip from "./ExtrasTooltip";
import "./index.scss";
import { returnSubstring } from "../../utils";

const SkillsTooltip = ({ array, size = "small-200" }) => {
  const skillArray = array || [];
  let displayedSkills = skillArray.slice(0, 2).map(elem => elem.name);
  let remainingSkills = skillArray.slice(2).map(elem => elem.name);
  let tooltipText = skillArray.map(elem => elem.name).join(", ");
  let skillsLength = array?.length - 2;
  // Combine the first two skills and check their combined length
  const combinedLength = displayedSkills.join(", ").length;

  if (combinedLength > 15) {
    displayedSkills = skillArray.slice(0, 1).map(elem => elem.name);
    skillsLength = skillsLength + 1;
    remainingSkills = skillArray.slice(0, 1).map(elem => elem.name);
    tooltipText = skillArray.map(elem => elem.name).join(", ");
  }

  return (
    <>
      <span
        className="tooltip-count-main"
      // style={{ display: "flex" }}
      >
        {displayedSkills.map((elem, idx) => {
          return (
            <FilledChip
              key={idx}
              label={returnSubstring(elem, 16) || "-"}
              size={size}
              color="var(--oh-gray-color)"
            />
          );
        })}
      </span>
      {skillsLength > 0 && (
        <ExtrasTooltip tooltipText={tooltipText} count={skillsLength} />
      )}
    </>
  );
};

export default SkillsTooltip;
