import React, { useEffect, useState } from "react";
import TableHeader from "../../../components/Freelancer/TableHeader/TableHeader";
import { Box, Card } from "@mui/material";
import ActiveCandidateTable from "../../../components/Freelancer/ActiveCandidateTable";
import FreelancerLayout from "../../Layouts/Freelancer/FreelancerLayout";
import FreelancerPlacedCandidateTable from "../FreelancerPlacedCandidateTable";
import { useDispatch, useSelector } from "react-redux";
import { setIsActiveCandidate } from "../../../redux/Freelancer/freelancerDetailsSlice";
import {
  getFreelancerActiveCandidateData,
  setFreelancerActiveCandidateData,
} from "../../../redux/Freelancer/freelancerActiveCandidatesSlice";
import {
  getFreelancerPlacedCandidateData,
  setFreelancerPlacedCandidateData,
} from "../../../redux/Freelancer/freelancerPlacedCandidatesSlice";
import TableSearch from "../../../components/Freelancer/TableSearch/TableSearch";

const tabData = [
  { title: "Active Candidates", value: "active_candidates" },
  { title: `Placed Candidates`, value: `placed_candidates` },
];

const Candidates = () => {
  const [value, setValue] = useState(tabData[0]?.value);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [rowCount, setRowCount] = useState(0);

  const isActiveCandidate = useSelector(
    (state) => state.freelancerDetailData.isActiveCandidate
  );

  // NOTE* need to reserve filters and search results alongwith pagination, currently they are not working properly together but working fine individually
  const getFreelancerActiveCandidates = (keyword = null, inputRef) => {
    dispatch(getFreelancerActiveCandidateData({ keyword: keyword }))
      .then((res) => {
        inputRef?.current?.querySelector("input")?.blur();
        if (res.type === "getFreelancerActiveCandidateData/fulfilled") {
          setRowCount(res.payload.meta.total_records);
          dispatch(setFreelancerActiveCandidateData(res.payload.data));
        }
      })
      .catch((err) => console.log(err));
  };

  const getFreelancerPlacedCandidates = (keyword = null, inputRef) => {
    dispatch(getFreelancerPlacedCandidateData({ keyword: keyword })).then(
      (res) => {
        inputRef?.current?.querySelector("input")?.blur();
        if (res.type === "getFreelancerPlacedCandidateData/fulfilled") {
          setRowCount(res.payload.meta.total_records);
          dispatch(setFreelancerPlacedCandidateData(res.payload.data));
        }
      }
    );
  };

  // need to optimise this later.
  useEffect(() => {
    getFreelancerActiveCandidates();
  }, []);

  const handleDrawerOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setIsActiveCandidate(newValue === "active_candidates"));
  };

  const handleSearch = (keyword = null, inputRef) => {
    isActiveCandidate
      ? getFreelancerActiveCandidates(keyword, inputRef)
      : getFreelancerPlacedCandidates(keyword, inputRef);
  };

  return (
    <FreelancerLayout>
      <Card sx={{ mt: 2, borderRadius: "10px" }}>
        <TableHeader
          setRowCount={setRowCount}
          tabData={tabData}
          value={value}
          isAddDrawerOpen={isOpen}
          handleChange={handleChange}
          onClose={handleDrawerOpen}
          filterShow={true}
          renderExtraOptions={
            <TableSearch
              searchData={searchKeyword}
              setSearchData={setSearchKeyword}
              handleSearch={handleSearch}
            />
          }
        >
          {value === "active_candidates" ? (
            <ActiveCandidateTable
              searchKeyword={searchKeyword}
              rowCount={rowCount}
              setRowCount={setRowCount}
            />
          ) : (
            <FreelancerPlacedCandidateTable
              searchKeyword={searchKeyword}
              rowCount={rowCount}
              setRowCount={setRowCount}
            />
          )}
        </TableHeader>
      </Card>
    </FreelancerLayout>
  );
};

export default Candidates;
