import * as Yup from "yup";
import { errMessageMaximum } from "../utils";
import { FIRSTNAME_REGEX, PINCODE_REGEX, WEBSITE_REGEX } from "../utils/regex";
const ADDRESS_REGEX = /^[a-zA-Z0-9\s,.'\/-]+$/;

export const CompanyInfoSchema = Yup.object().shape({
  organization_name: Yup.string()
    .required("Organization name is required.")
    .max(150, errMessageMaximum("Organization name", 150))
    .test(
      "check if not empty",
      "Organization name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "Organization name must be valid."),
  organization_type: Yup.string().required("Organization type is required."),
  website: Yup.string()
    .matches(WEBSITE_REGEX, "Website must be valid")
    .max(50, errMessageMaximum("Website", 50)),
  pincode: Yup.string()
    .matches(PINCODE_REGEX, "Pincode must contain only numbers")
    .max(6, "Pincode can not be more than 6 characters."),
  address: Yup.string().max(60, errMessageMaximum("Address", 60)),
  city: Yup.string().max(40, errMessageMaximum("City", 40)),
  state: Yup.string().max(40, errMessageMaximum("State", 40)),
  social_media_fb: Yup.string().matches(WEBSITE_REGEX, "Enter a valid url"),
  social_media_linkdln: Yup.string().matches(
    WEBSITE_REGEX,
    "Enter a valid url"
  ),
  social_media_instagram: Yup.string().matches(
    WEBSITE_REGEX,
    "Enter a valid url"
  ),
});

export const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required.")
    .max(50, errMessageMaximum("First name", 50))
    .test(
      "check if  First name not empty",
      "First name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "First name must be valid."),
  last_name: Yup.string()
    .required("Last name is required.")
    .max(50, errMessageMaximum("Last name", 50))
    .test(
      "check if  Last name not empty",
      "Last name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "Last name must be valid."),
});

export const FreelancerInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required.")
    .max(150, errMessageMaximum("First name", 150))
    .test(
      "check if  First name not empty",
      "First name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "First name must be valid."),

  last_name: Yup.string()
    .required("Last name is required.")
    .max(150, errMessageMaximum("Last name", 150))
    .test(
      "check if Last name not empty",
      "Last name must be relevant.",
      (val) => {
        if (!val || val.trim().length === 0 || val.trim().length < 2) {
          return false;
        }
        return true;
      }
    )
    .matches(FIRSTNAME_REGEX, "Last name must be valid."),
  zip_code: Yup.string()
    .matches(PINCODE_REGEX, "Pincode must contain only numbers")
    .max(6, errMessageMaximum("Pincode", 6)),
  address: Yup.string()
    // .required("Address is required.")
    .max(150, "Address must be less than 150 characters")
    // .test("check if Address not empty", "Address must be relevant.", (val) => {
    //   return val && val.trim().length >= 2; // Check if the address is at least 2 characters long after trimming
    // })  // not needed as address is not mandatory
    .matches(ADDRESS_REGEX, "Address must be valid."),
  city: Yup.string().max(40, errMessageMaximum("City", 40)),
  state: Yup.string().max(40, errMessageMaximum("State", 40)),
});
